import { PropsWithChildren, useCallback, useState } from 'react'
import { Button, Label, Modal, Textarea, useTheme } from 'carbonarc-ui'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { VisualizationEvaluationContext } from '@contexts/VisualizationEvaluation/context'
import { VisualizationEvaluationOptions } from '@contexts/VisualizationEvaluation/options'
import { VisualizationType } from '@contexts/VisualizationEvaluation/types'
import { usePrevious } from '@hooks'
import { useApi } from '@services/api'
import { cn } from '@utils/className'

const joiner = '::'
const visualizationTypeToStr = (type: VisualizationType) => {
  if (typeof type === 'object') {
    return [type.id, type.subTypes].filter(Boolean).join(joiner)
  }
  return type.toString()
}

type ModalInfo = {
  show: boolean
  score?: number
  visualizationType?: VisualizationType
}

type EvaluationModalProps = ModalInfo & {
  onClose: () => void
}

const EvaluationModal = (props: EvaluationModalProps) => {
  const { t } = useTranslation('common')
  const modalTheme = useTheme().theme.modal
  const { post } = useApi()
  const [saving, setSaving] = useState(false)
  const [comment, setComment] = useState('')
  const [score, setScore] = useState(props.score)
  const lastLoadedScore = usePrevious(props.score)

  const close = useCallback(() => {
    setComment('')
    props.onClose()
  }, [props])

  if (lastLoadedScore !== props.score && score !== props.score) {
    setScore(props.score)
  }

  const canSubmit = score != null && props.visualizationType

  const evaluate = useCallback(async () => {
    if (score == null || !props.visualizationType) return
    setSaving(true)
    try {
      const url = decodeURIComponent(window.location.href)
      const visualization = visualizationTypeToStr(props.visualizationType)
      const body = { visualization, score, comment, url }
      await post(`/app/visualization-evaluation`, body)
      toast.success(t('visualization_evaluation.modal.success_message'))
      close()
    } catch (e) {
      toast.error(t('visualization_evaluation.modal.error_message'))
    } finally {
      setSaving(false)
    }
  }, [score, props.visualizationType, comment, post, t, close])

  return (
    <Modal
      show={props.show}
      position="top-center"
      onClose={close}
      size="md"
      theme={{
        root: {
          positions: {
            'top-center': cn(modalTheme.root.positions['top-center'], 'pt-[100px]'),
          },
        },
      }}
    >
      <Modal.Header
        theme={{
          title: cn(modalTheme.header.title, 'text-lg font-semibold'),
        }}
      >
        {t('visualization_evaluation.modal.title')}
      </Modal.Header>
      <Modal.Body>
        <form className="flex max-w-md flex-col gap-4">
          <div className="flex flex-col gap-5">
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="title"
                  value={t('visualization_evaluation.question')}
                  className="text-sm font-medium text-gray-900 dark:text-gray-100"
                />
              </div>
              <VisualizationEvaluationOptions onSelect={setScore} size="md" value={score} />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="details"
                  value={t('visualization_evaluation.modal.form.details_label')}
                  className="text-sm font-medium text-gray-900 dark:text-gray-100"
                />
              </div>
              <Textarea
                id="details"
                name="details"
                required
                placeholder={t('visualization_evaluation.modal.form.details_placeholder')}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="default"
          type="button"
          onClick={() => evaluate()}
          disabled={saving || !canSubmit}
          isLoading={saving}
        >
          {t('visualization_evaluation.modal.save')}
        </Button>
        <Button variant="outline" type="button" onClick={close} disabled={saving}>
          {t('visualization_evaluation.modal.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const VisualizationEvaluationProvider = ({ children }: PropsWithChildren) => {
  const [modalInfo, setModalInfo] = useState<ModalInfo>({ show: false })

  const openEvaluation = (visualizationType: VisualizationType, score: number) => {
    setModalInfo({
      show: true,
      visualizationType,
      score,
    })
  }

  const reset = () => {
    setModalInfo({ show: false, visualizationType: undefined, score: undefined })
  }

  return (
    <VisualizationEvaluationContext.Provider value={{ openEvaluation }}>
      {children}
      <EvaluationModal {...modalInfo} onClose={reset} />
    </VisualizationEvaluationContext.Provider>
  )
}
