interface getMaxDateFromObjectArrayProps {
  data?: any[]
  dateProperty: string | string[]
}

const extractEntryDates = (data: any[] | any, dateProperty: string | string[]): number[] => {
  if (Array.isArray(dateProperty)) {
    if (dateProperty.length === 1) return extractEntryDates(data, dateProperty[0])
    return Array.isArray(data)
      ? data?.map((d: any) => extractEntryDates(d[dateProperty[0]], dateProperty.slice(1))).flat()
      : extractEntryDates(data[dateProperty[0]], dateProperty.slice(1))
  }
  const entryDates = Array.isArray(data)
    ? data?.map((data) => (data?.[dateProperty] ? +new Date(data[dateProperty]) : 0)) || []
    : data?.[dateProperty]
    ? [+new Date(data[dateProperty])]
    : []
  return entryDates
}

export function getMaxDateFromObjectArray({ data, dateProperty }: getMaxDateFromObjectArrayProps) {
  const entryDates = data ? extractEntryDates(data, dateProperty) : []
  const maxDate = entryDates.length > 0 ? new Date(Math.max(...entryDates)) : null
  return maxDate
}

export const addDays = (date: Date, days: number) => {
  const newDate = new Date(date)
  newDate.setDate(date.getDate() + days)
  return newDate
}

export const subtractDays = (date: Date, days: number) => {
  const newDate = new Date(date)
  newDate.setDate(date.getDate() - days)
  return newDate
}

export const subtractYears = (date: Date, years: number) => {
  const newDate = new Date(date)
  newDate.setFullYear(date.getFullYear() - years)
  return newDate
}

export const generateIntervalFromToday = (value: number): { start: Date; end: Date } => {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const a = new Date(today)
  const b = new Date(today)
  a.setDate(today.getDate() + (value > 0 ? 1 : -1))
  b.setDate(today.getDate() + value)

  return value > 0 ? { start: a, end: b } : { start: b, end: a }
}

export const isValidDateObject = (d: any) => {
  return d instanceof Date && !isNaN(d.getTime())
}

export const parseDate = (dateStr?: string) => {
  if (dateStr === undefined || dateStr === null) return null
  if (typeof dateStr === 'string') {
    let date = new Date(dateStr)
    const userTimezoneOffset = date.getTimezoneOffset() * 60000
    date = new Date(date.getTime() + userTimezoneOffset)
    if (isNaN(date.getTime())) return null
    return date
  }
  return null
}

export const isDate = (value: string) => {
  return new Date().toString() !== 'Invalid Date' && !isNaN(new Date(value).getTime())
}

export const getToday = () => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

export const toISOStringDatePart = (date: Date) => {
  if (typeof date === 'string') return date as string
  return date.toISOString().split('T')[0]
}
