import { Dispatch, useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useEffectOnce } from '@hooks/useEffectOnce'
import {
  Action,
  FetchFailureAction,
  FetchOptions,
  FetchStartAction,
  FetchSuccessAction,
  FetchablePaginatedData,
  ResetAction,
  SetFilterAction,
  SetPageAction,
  SetSizeAction,
  State,
  UsePaginatedDataProps,
} from '@hooks/usePaginatedData/types'
import { useCancelableApiFetch } from '@services/api'
import { getSearchParams, parseSearchParams } from '@utils/url'

export const usePaginatedData = <T, F>(
  { fetchFn, fetchParams, fetchUrl }: UsePaginatedDataProps<T, F>,
  [state, dispatch]: [State<T, F>, Dispatch<Action<T, F>>],
): FetchablePaginatedData<T, F> => {
  const [apiFetch] = useCancelableApiFetch()

  const [fetchQuery, setFetchQuery] = useState<string | null>(null)

  const reactQueryData = useQuery({
    queryKey: [fetchUrl, fetchQuery],
    queryFn: () => apiFetch(`${fetchUrl}?` + fetchQuery, {}, 30 * 1000),
    enabled: fetchParams != null && fetchQuery != null,
    onError: () => dispatch({ type: 'FETCH_FAILURE' } as FetchFailureAction),
  })

  const fetch = useCallback(
    async (options: FetchOptions<F>) => {
      const { page, size, filter, onFetchSuccess, onFetchFailure } = options
      dispatch({ type: 'FETCH_START', onFetchSuccess, onFetchFailure } as FetchStartAction)

      try {
        if (fetchParams != null) {
          const internalParams = new URLSearchParams()
          const parsedSearchParams = parseSearchParams(getSearchParams()) as any

          internalParams.append('page', (parsedSearchParams?.[`${state.prefix}Page`] || page || state.page).toString())
          internalParams.append('size', (parsedSearchParams?.[`${state.prefix}Size`] || size || state.size).toString())

          const externalParams = fetchParams(options.filter || state.filter)?.toString() || ''
          setFetchQuery(`${internalParams.toString()}&${externalParams}`)
        } else {
          const data = await fetchFn(filter || state.filter, page || state.page, size || state.size)
          dispatch({ type: 'FETCH_SUCCESS', data } as FetchSuccessAction<T>)
        }
      } catch (e) {
        dispatch({ type: 'FETCH_FAILURE' } as FetchFailureAction)
      }
    },
    [dispatch, fetchFn, fetchParams, state.filter, state.page, state.size, state.prefix],
  )

  const setPage = useCallback(
    (newPage: number) => {
      if (state.page > 0 && state.page <= state.pages) {
        dispatch({ type: 'SET_PAGE', newPage } as SetPageAction)
        fetch({ page: newPage })
      }
    },
    [dispatch, fetch, state.page, state.pages],
  )

  const setPerPage = useCallback(
    (newSize: number) => {
      const lastItemNumber = newSize * (state.page - 1)
      const newPage = lastItemNumber >= state.total ? Math.ceil(state.total / newSize) : undefined
      dispatch({ type: 'SET_PER_PAGE', newSize, newPage } as SetSizeAction)
      fetch({ size: newSize, page: newPage })
    },
    [dispatch, fetch, state.page, state.total],
  )

  const setFilter = useCallback(
    (filter: F) => {
      let onFetchSuccess
      let onFetchFailure

      const fetchPromise = new Promise<void>((resolve, reject) => {
        onFetchSuccess = resolve
        onFetchFailure = reject
      })

      dispatch({ type: 'SET_FILTER', filter } as SetFilterAction<F>)
      fetch({ filter, page: 1, onFetchSuccess, onFetchFailure })
      return fetchPromise
    },
    [dispatch, fetch],
  )

  const reset = useCallback(async () => {
    dispatch({ type: 'RESET' } as ResetAction)
  }, [dispatch])

  const finishPendingFetch = () => {
    if (state.fetching) {
      if (fetchParams != null && fetchQuery) {
        reactQueryData.refetch()
      } else {
        fetch({})
      }
    }
  }

  useEffect(() => {
    if (reactQueryData.isSuccess && state.fetching) {
      dispatch({ type: 'FETCH_SUCCESS', data: reactQueryData.data } as FetchSuccessAction<T>)
    }
  }, [reactQueryData.isSuccess, reactQueryData.data, state.fetching, dispatch])

  useEffectOnce(() => {
    if (fetchParams != null && state.total > 0) {
      const internalParams = new URLSearchParams()
      internalParams.append('page', state.page.toString())
      internalParams.append('size', state.size.toString())
      const externalParams = fetchParams(state.filter)?.toString() || ''
      setFetchQuery(`${internalParams.toString()}&${externalParams}`)
    }
  })

  return {
    prefix: state.prefix,
    items: state.items,
    page: state.page,
    pages: state.pages,
    size: state.size,
    total: state.total,
    fetching: state.fetching,
    goToPage: setPage,
    setPageSize: setPerPage,
    fetch: setFilter,
    finishPendingFetch,
    reset,
  }
}
