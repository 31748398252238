import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChartCard } from '@components/ChartCard'
import { EventContentProps } from '@components/EventContent'
import { CustomEventsBenchmark } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard/CustomEventsBenchmark'
import { SelectXAxis } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard/SelectXAxis'
import { BenchmarkOption, BenchmarkOptionId } from '@components/EventContent/types'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { PresentationChartLineIcon } from '@heroicons/react/24/outline'
import { objectUtils } from '@utils'
import { getMaxDateFromObjectArray } from '@utils/date'
import { CustomEventFilterGroups } from './CustomEventsBenchmark'
import { AxisType } from './SelectXAxis'
import { TimeSeriesChart } from './TimeSeriesChart'

type TimeSeriesChartCardProps<AdditionalParamsOnInternalChange> = Pick<
  EventContentProps<AdditionalParamsOnInternalChange>,
  | 'loading'
  | 'stringfiedFilterContext'
  | 'pdfHeader'
  | 'hasEventFilter'
  | 'primaryMarket'
  | 'customEvents'
  | 'benchmark'
  | 'additionalParamsForInternalChange'
  | 'exportOptions'
  | 'page'
>

export function TimeSeriesChartCard<AdditionalParamsOnInternalChange>(
  props: TimeSeriesChartCardProps<AdditionalParamsOnInternalChange>,
) {
  const {
    loading,
    stringfiedFilterContext,
    pdfHeader,
    hasEventFilter,
    primaryMarket,
    customEvents,
    benchmark,
    exportOptions,
    page,
  } = props

  const [isLoading, setLoading] = useState(loading)
  const { t } = useTranslation('tour_marketing')
  const [xAxis, setXAxis] = useState(AxisType.DaysUntilShow)

  const timeSeriesMaxDate = getMaxDateFromObjectArray({
    data: primaryMarket.timeSeriesEntity,
    dateProperty: 'entry_date',
  })

  const cumulateTicketsSoldChartLastUpdatedDate = timeSeriesMaxDate
  const customEventsBenchmarkOption: BenchmarkOption = useMemo(() => {
    return {
      id: BenchmarkOptionId.CustomEvents,
      label: t('cumulative_tickets_sold_chart.custom_events'),
    }
  }, [t])
  const applyFilters = useCallback(
    async (newFilters: CustomEventFilterGroups) => {
      try {
        setLoading(true)

        // benchmark.setSelected(customEventsBenchmarkOption, props.additionalParamsForInternalChange)
        customEvents.setEvents(newFilters, props.additionalParamsForInternalChange)
        await customEvents.fetch()
      } catch (error) {
        objectUtils.logErrorMessage(error)
      } finally {
        setLoading(false)
      }
    },
    [customEvents, props.additionalParamsForInternalChange, customEventsBenchmarkOption, benchmark],
  )

  useEffect(() => {
    setLoading(loading)
  }, [loading])
  return (
    <ChartCard
      id={VisualizationId.CumulativeTicketsSold}
      visualization={VisualizationId.CumulativeTicketsSold}
      title={t('cumulative_tickets_sold_chart.title')}
      info={t('cumulative_tickets_sold_chart.info')}
      subtitle={
        <h5 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400 mt-2">
          {t('cumulative_tickets_sold_chart.subtitle')}
        </h5>
      }
      lastUpdatedDate={cumulateTicketsSoldChartLastUpdatedDate}
      chart={
        <TimeSeriesChart
          timeSeriesEntity={primaryMarket.timeSeriesEntity}
          selectedBenchmark={benchmark.selected}
          multipleCustomTimeSeriesEntity={customEvents.timeSeriesEntities}
          xAxis={xAxis}
        />
      }
      showChart={!!primaryMarket.timeSeriesEntity?.length || !!customEvents.timeSeriesEntities?.length}
      noDataIcon={PresentationChartLineIcon}
      isLoading={isLoading}
      exportFilename={`cumulative_tickets_sold${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`}
      chartControlsComponent={
        <div className="flex gap-2 flex-col md:flex-row md:flex-wrap md:justify-center">
          <SelectXAxis
            xAxis={xAxis}
            setXAxis={setXAxis}
            timeSeriesEntity={primaryMarket.timeSeriesEntity}
            selectedBenchmark={benchmark.selected}
            multipleCustomTimeSeriesEntity={customEvents.timeSeriesEntities}
            disabled={!hasEventFilter || isLoading}
          />
        </div>
      }
      controlPanelComponent={<CustomEventsBenchmark handleSelected={applyFilters} customEvents={customEvents.events} />}
      pdfHeader={pdfHeader}
      exportOptions={exportOptions}
      page={page}
    />
  )
}
