import { useCallback, useMemo, useState } from 'react'
import { apiUrl } from '@config/site'
import { useOktaAuth } from '@okta/okta-react'
import { buildURL } from '@utils/url'

export class ApiError extends Error {
  code: number
  status: string
  detail: string

  constructor(message: string, code: number, detail?: string) {
    super(message)
    this.status = message
    this.code = code
    this.name = 'ApiError'
    this.detail = detail || message
  }
}

type CustomRequestInit = RequestInit & {
  multipart?: boolean
}

const defaultCustomRequestInit: CustomRequestInit = {
  multipart: false,
}

export const rawApiFetch = async (url: string, options: RequestInit = {}) => {
  const finalUrl = buildURL(url, apiUrl)
  const response = await fetch(finalUrl, options)

  if (!response.ok) {
    if (response.body) {
      const body = await response.json()
      throw new ApiError(body.code, response.status, body?.detail)
    }
    throw new ApiError(response.statusText, response.status)
  }
  return response.json()
}

export const apiFetch = (url: string, options: CustomRequestInit = {}, token: string) => {
  const defaultHeaders: HeadersInit = {
    ...(options.multipart ? {} : { 'Content-Type': 'application/json' }),
    Authorization: `Bearer ${token}`,
  }
  const mergedHeaders = { ...defaultHeaders, ...(options.headers || {}) }
  const mergedOptions = { ...options, headers: mergedHeaders }
  return rawApiFetch(url, mergedOptions)
}

export const apiDownload = (
  url: string,
  filename = 'file',
  options: RequestInit = {},
  token: string,
  rawUrl = false,
): Promise<void> => {
  const defaultHeaders: HeadersInit = {
    Authorization: `Bearer ${token}`,
  }

  const mergedHeaders = { ...defaultHeaders, ...(options.headers || {}) }
  const mergedOptions = { ...options, headers: mergedHeaders }

  const finalUrl = rawUrl ? url : buildURL(url, apiUrl)

  return new Promise((resolve, reject) => {
    fetch(finalUrl, mergedOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        a.remove()
        resolve()
      })
      .catch((error) => {
        console.log('Download error', error)
        reject(error)
      })
  })
}

export type ApiFetch = (url: string, options?: RequestInit) => Promise<any>

export const useApiFetch = (): ApiFetch => {
  const { authState } = useOktaAuth()
  const token = authState?.accessToken?.accessToken
  return useCallback(
    (url: string, options: RequestInit = {}) => {
      if (!token) {
        throw new ApiError('No token found', 401)
      }

      return apiFetch(url, options, token)
    },
    [token],
  )
}

export const useCancelableApiFetch = (): [
  <T>(url: string, options: RequestInit, timeout?: number) => Promise<T>,
  (reason?: string) => void,
] => {
  const apiFetch = useApiFetch()
  const [refreshController, setRefreshController] = useState(false)
  const controller = useMemo(() => new AbortController(), [])

  const cancelableApiFetch = useCallback(
    <T>(url: string, options: RequestInit, timeout = 120000): Promise<T> =>
      new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          reject({ status: 'timeout', message: 'Request timed out' })
          controller.abort()
        }, timeout)
        apiFetch(url, {
          ...options,
          signal: controller.signal,
        })
          .then((response) => resolve(response as T))
          .catch((error) => {
            if (error.name === 'AbortError') {
              reject({ status: 'abort', message: 'Request aborted by the user' })
              setRefreshController(!refreshController)
            }
            reject(error)
          })
          .finally(() => clearTimeout(timeoutId))
      }),
    [apiFetch, controller, refreshController],
  )

  return [cancelableApiFetch, () => controller.abort()]
}

export const useApiDownload = () => {
  const { authState } = useOktaAuth()
  const token = authState?.accessToken?.accessToken

  return useCallback(
    (url: string, filename = 'file', options: RequestInit = {}, rawUrl = false) => {
      if (!token) {
        throw new ApiError('No token found', 401)
      }

      return apiDownload(url, filename, options, token, rawUrl)
    },
    [token],
  )
}

export const useApi = () => {
  const { authState } = useOktaAuth()
  const token = authState?.accessToken?.accessToken
  const fetch: ApiFetch = useCallback(
    (url: string, options: RequestInit = {}) => {
      if (!token) {
        throw new ApiError('No token found', 401)
      }

      return apiFetch(url, options, token)
    },
    [token],
  )

  const post = useCallback(
    (url: string, body: any, options: CustomRequestInit = {}) => {
      if (!token) {
        throw new ApiError('No token found', 401)
      }

      const fullOptions = { ...defaultCustomRequestInit, ...options }

      return apiFetch(
        url,
        { ...fullOptions, method: 'POST', body: fullOptions.multipart ? body : JSON.stringify(body) },
        token,
      )
    },
    [token],
  )

  const remove = useCallback(
    (url: string, options: RequestInit = {}) => {
      if (!token) {
        throw new ApiError('No token found', 401)
      }

      return apiFetch(url, { ...options, method: 'DELETE' }, token)
    },
    [token],
  )

  return { fetch, post, remove }
}
