import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { createControlPanelSlice } from '@stores/slices/controlPanelSlice'
import { Filters } from '@stores/upcomingEventsStore'
import { UpcomingEventsControlPanelStoreState } from './upcomingEventsControlPanelStoreTypes'

export const useUpcomingEventsControlPanelStore = create<UpcomingEventsControlPanelStoreState>()(
  immer(
    devtools((set, get, store) => ({
      ...createControlPanelSlice<Filters>(set, get, store),
    })),
  ),
)
