import React, {useMemo} from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterSearchIcon } from '@assets/filter-search.svg'
import { ReactComponent as NoDataIcon } from '@assets/no-data.svg'
import { PresentationChartLineIcon } from '@heroicons/react/24/outline'
import {useTourPlanningStore} from '@stores/tourPlanningStore'

interface DataPlaceholderComponents {
  DataPlaceholderIcon: React.ElementType
  DataPlaceholderMessage?: React.ReactNode
}

export function useTourPlanningDataAvailabilityPlaceholder(): DataPlaceholderComponents {
  const { t } = useTranslation('tour_planning')
  const { isFiltersEmpty,  isLoading} = useTourPlanningStore((state) => ({
    isFiltersEmpty: state.filterContext === undefined,
    isLoading: state.isLoading || state.isLoadingEvents,
  }))

  const content = useMemo(() => (
      {
        icon: isFiltersEmpty ? FilterSearchIcon : NoDataIcon,
        title: isFiltersEmpty ? t('common:data_tips.no_filters_applied.title') : t('common:data_tips.no_data.title'),
        description: isFiltersEmpty ? t('common:data_tips.no_filters_applied.description') : t('common:data_tips.no_data.description'),
      }
  ), [isFiltersEmpty, t])

  const DataPlaceholderMessage = React.useMemo(
    () => (
      <div className="flex flex-col items-center justify-center space-y-2">
        <h3 className="text-lg font-semibold">{content.title}</h3>
        <p className="w-[450px] text-sm text-center text-gray-500 dark:text-gray-400">{content.description}</p>
      </div>
    ),
    [content],
  )

  if(isLoading) return { DataPlaceholderIcon: PresentationChartLineIcon }

  return {
    DataPlaceholderIcon: content.icon,
    DataPlaceholderMessage,
  }
}
