import { ReactComponent as FaceFrownIcon } from '@assets/face-frown.svg'
import { ReactComponent as FaceNeutralIcon } from '@assets/face-neutral.svg'
import { ReactComponent as FaceSmileIcon } from '@assets/face-smile.svg'
import { cn } from '@utils/className'

const options = [
  {
    value: 0,
    Icon: FaceFrownIcon,
  },
  {
    value: 1,
    Icon: FaceNeutralIcon,
  },
  {
    value: 2,
    Icon: FaceSmileIcon,
  },
]

type VisualizationEvaluationOptionsProps = {
  size?: 'sm' | 'md'
  className?: string
  value?: number
  onSelect: (value: number) => void
}

export const VisualizationEvaluationOptions = ({
  size = 'md',
  className = '',
  onSelect,
  value: selectedValue,
}: VisualizationEvaluationOptionsProps) => {
  const iconClassName = cn('stroke-[1.5px]', size === 'md' ? 'w-6 h-6' : 'w-4 h-4')

  return (
    <div className="flex">
      {options.map(({ value, Icon }) => (
        <div
          key={value}
          onClick={() => onSelect(value)}
          className={cn(
            'cursor-pointer text-gray-500 dark:text-gray-400 rounded-full flex items-center justify-center',
            size === 'md' ? 'w-8 h-8' : 'w-6 h-6',
            value === selectedValue && 'bg-blue-50 dark:bg-blue-800 text-blue-700 dark:text-blue-300',
            value !== selectedValue &&
              'hover:text-gray-800 hover:bg-gray-50 hover:dark:text-gray-200 hover:dark:bg-gray-700',
            className,
          )}
        >
          <Icon className={iconClassName} />
        </div>
      ))}
    </div>
  )
}
