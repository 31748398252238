import { useEffect, useRef } from 'react'

export function useEffectOnce(effect: React.EffectCallback) {
  const hasRunRef = useRef(false)

  useEffect(() => {
    if (hasRunRef.current) {
      return
    }

    hasRunRef.current = true
    effect()
  }, [effect])
}
