import { Pagination as CAPagination } from 'carbonarc-ui'
import { PaginatedData, StarEventEntity } from '@types'

interface PaginationProps {
  isLoading: boolean
  currentPage: number
  eventsData?: PaginatedData<StarEventEntity> | null
  goToPage: (currentPage: number) => void
}

export function Pagination(props: PaginationProps) {
  const { isLoading, currentPage, eventsData, goToPage } = props

  if (isLoading)
    return (
      <div className="animate-pulse flex items-center justify-around mt-6 mb-4 mx-auto bg-white dark:bg-gray-800 w-72 h-8">
        <div className="rounded-full bg-gray-200 dark:bg-gray-600 w-1.5 h-2.5" />

        {Array(5)
          .fill(1)
          .map((_, index) => (
            <div key={index} className="rounded-full bg-gray-200 dark:bg-gray-600 w-1 h-4" />
          ))}

        <div className="rounded-full bg-gray-200 dark:bg-gray-600 w-1.5 h-2.5" />
      </div>
    )

  if (eventsData && eventsData?.pages <= 1) return null

  return (
    <CAPagination
      currentPage={currentPage}
      onPageChange={(page: number) => goToPage(page)}
      totalPages={eventsData?.pages || 0}
      showIcons
      nextLabel=""
      previousLabel=""
      theme={{
        base: 'font-medium mt-6 text-center',
        pages: {
          selector: {
            base: 'w-9 md:w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white',
            active: '!text-blue-600 !bg-blue-100 dark:!bg-blue-300',
          },
        },
      }}
    />
  )
}
