import { ButtonToggle } from '@components/ButtonToggle'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { useEventSearchStore } from '@stores/eventSearchStore'

export const EventPageToggle = () => {
  const { extended, setExtended } = useEventSearchStore((state) => ({
    extended: state.showExtendedVersion,
    setExtended: state.setShowExtendedVersion,
  }))

  const searchParamsHandler = useSearchParamsObject()

  return (
    <ButtonToggle
      key="event-insight-toggle-mode"
      options={[
        { label: 'Simple', value: 'simple' },
        { label: 'Advanced', value: 'advanced' },
      ]}
      value={extended ? 'advanced' : 'simple'}
      onChange={(value) => setExtended(value === 'advanced', searchParamsHandler)}
    />
  )
}
