import { useTranslation } from 'react-i18next'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'
import { StatType, TableData } from '@pages/data-health'
import { formatUtils, numberUtils } from '@utils'
import { cn } from '@utils/className'

type TableCardProps = {
  data: TableData
  statsTypes: StatType[]
}

export function TableCard({ data, statsTypes }: TableCardProps) {
  const { t } = useTranslation('data_health')
  const translationName = data.name.toLowerCase().replaceAll(' ', '_')

  const stats = statsTypes.map((types) => {
    const currentValue = data.current[types.field]
    const previousValue = data.previous[types.field]

    return {
      name: types.name,
      stat: currentValue == null ? 'N/A' : types.formatter(currentValue),
      prevStat: previousValue == null ? 'N/A' : types.formatter(previousValue),
      ...numberUtils.getChangeBetweenNumbers(previousValue, currentValue),
    }
  })

  return (
    <div className="flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col w-full print:shadow-none print:bg-white print:break-after-page">
      <div className="flex h-full flex-col justify-center py-6 md:py-8 divide-y divide-gray-200 dark:divide-gray-700">
        <div className="px-6 md:px-8">
          <h4 className="text-base md:text-xl font-semibold tracking-tight text-gray-900 dark:text-white mb-2">
            {t(`${translationName}.title`)}
          </h4>
          <span className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-200">
            {t(`${translationName}.description`)}
          </span>
          <dl
            className={`my-6 md:my-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 overflow-hidden rounded-lg divide-y divide-gray-200 dark:divide-gray-700 md:divide-x`}
          >
            {stats.map((item, index) => (
              <div
                key={index}
                className={cn(
                  'py-4 sm:p-8',
                  index < 2 ? 'md:!border-t-0' : '',
                  index % 2 === 0 ? 'md:!border-l-0 lg:!border-l' : '',
                  index < 4 ? 'lg:!border-t-0' : '',
                  index % 4 === 0 ? 'lg:!border-l-0' : '',
                  index === 0 ? '!pt-0 md:!pt-8 md:!pl-0' : '', // first element
                  index === stats.length - 1 ? '!pb-0' : '', // last element
                )}
              >
                <dt className="text-base font-normal leading-6 text-gray-900 dark:text-white">{item.name}</dt>
                <dd className="mt-1 flex items-baseline justify-between gap-2">
                  <div
                    className={cn(
                      'items-baseline text-3xl font-bold leading-8 relative inline text-gray-900 dark:text-white',
                    )}
                  >
                    {item.stat}
                    <span className="block text-sm font-normal text-gray-500 dark:text-gray-200">
                      {t('common:from_value', { value: item.prevStat }).toLowerCase()}
                    </span>
                  </div>

                  <div
                    className={cn(
                      item.changeType === 'increase'
                        ? 'text-green-500'
                        : item.changeType === 'decrease'
                        ? 'text-red-500'
                        : 'text-gray-800 dark:text-gray-200',
                      'inline-flex items-baseline rounded-full py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                    )}
                  >
                    <span className="sr-only">
                      {item.changeType === 'increase'
                        ? 'Increased'
                        : item.changeType === 'decrease'
                        ? 'Decreased'
                        : 'Unchanged'}{' '}
                      by{' '}
                    </span>
                    {item.change}
                    {item.changeType === 'increase' ? (
                      <ArrowUpIcon className="h-4 w-4 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                    ) : item.changeType === 'decrease' ? (
                      <ArrowDownIcon className="h-4 w-4 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                    ) : (
                      <></>
                    )}
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="px-6 pt-6 md:px-8 md:pt-8 text-sm md:text-base font-normal text-gray-500 dark:text-gray-200">
          {t('common:last_updated')}{' '}
          <span className="font-semibold text-gray-900 dark:text-white">
            {data.current?.run_timestamp ? formatUtils.formatDate(new Date(data.current.run_timestamp)) : 'N/A'}
          </span>
        </div>
      </div>
    </div>
  )
}
