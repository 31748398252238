import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { createStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { ApiFetch } from '@services/api'
import { TourMarketingContext } from '@stores/bookmarkStore/utils/types'
import { DEFAULT_SECONDARY_MARKET_CHART_TYPE, createEventDataSlice } from '@stores/slices/eventDataSlice'
import { CompetitorFilters } from '@stores/slices/eventDataSlice/competingEvents/types'
import {
  competitorFilterFieldsToCompetitorFilters,
  competitorFiltersToCompetitorFilterFields,
} from '@stores/slices/eventDataSlice/competingEvents/utils'
import * as api from '@stores/slices/eventDataSlice/eventDataSliceApi'
import { createPageSlice } from '@stores/slices/pageSlice'
import { PaginatedSliceState } from '@stores/slices/paginatedSlice/paginatedSliceTypes'
import {
  FilterField,
  filterFieldsToPageFilters,
  pageFilterToFilterFields,
  useTourMarketingControlPanelStore,
} from '@stores/tourMarketingStore'
import { getLocalFilters, saveLocalFilters } from '@stores/tourMarketingStore/localFilters'
import { contextToQueryParams, queryParamsToContext } from '@stores/tourMarketingStore/queryParams'
import { CompetitorEntity } from '@types'
import { objToQueryParam, updateSearchParam } from '@utils/url'
import {
  Filters,
  TourMarketingStoreGetFunction,
  TourMarketingStoreProps,
  TourMarketingStoreSetFunction,
  TourMarketingStoreState,
} from './tourMarketingStoreTypes'
import { filtersToEventFilter, getPdfHeader, stringifyFilters } from './tourMarketingStoreUtils'

export const createTourMarketingStore = (initialProps: TourMarketingStoreProps) => {
  return createStore<TourMarketingStoreState>()(
    immer(
      (set, get, store) =>
        ({
          ...initialProps,

          ...createPageSlice<Filters>({ stringifyFilters, getPdfHeader }, set, get, store),

          ...createEventDataSlice(
            {
              getApiFetch: () => get().getApiFetch(),
              onSetBenchmark: (additionalParamsForInternalChange) => {
                const { replaceSearchParams } = additionalParamsForInternalChange.searchParamsHandler
                const benchmark = get().selectedBenchmark
                replaceSearchParams(updateSearchParam('selectedBenchmark', objToQueryParam(benchmark)))
              },
              onSetCustomEvents: (additionalParamsForInternalChange) => {
                const { replaceSearchParams } = additionalParamsForInternalChange.searchParamsHandler
                const customEvents = get().customEvents
                const benchmarkEvents = objToQueryParam(customEvents)
                replaceSearchParams(updateSearchParam('customEvents', benchmarkEvents))
              },
              onSetCompetitorsFilters: (additionalParamsForInternalChange) => {
                const context = buildContext(get)
                get().setContext(context, additionalParamsForInternalChange.searchParamsHandler)
              },
              onSetSecondaryMarket: (additionalParamsForInternalChange) => {
                const context = buildContext(get)
                get().setContext(context, additionalParamsForInternalChange.searchParamsHandler)
              },
              getFilterContext: () => get().filterContext,
            },
            set,
            get,
            store,
          ),

          clearData: (searchParamsHandler) => {
            get().competitors.reset()
            set({
              isLoading: false,
              filterContext: undefined,
              stringfiedFilterContext: '',
              secondaryMarketTimeSeriesData: [],
              eventSummaryData: null,
              customEvents: [],
              multipleCustomTimeSeriesEntities: [],
              timeSeriesEntity: [],
              competitorsSummaryData: [],
            })
            if (searchParamsHandler) searchParamsHandler.replaceSearchParams()
          },

          setContext: (context: TourMarketingContext, searchParamsHandler, stateOnly: false) => {
            get().setFilterContext(context.filters)
            if (context.competitorsFilters) {
              const newFilterFields = competitorFiltersToCompetitorFilterFields(context.competitorsFilters)
              if (get().competitorsFilters.length !== newFilterFields.length) {
                set({ competitorsFilters: newFilterFields })
              }
            } else set({ competitorsFilters: [] })
            context.secondaryMarket.chartType && set({ secondaryMarketChartType: context.secondaryMarket.chartType })
            if (stateOnly) return
            const { searchParams, replaceSearchParams } = searchParamsHandler
            saveLocalFilters(pageFilterToFilterFields(context.filters))
            const urlContext = queryParamsToContext(searchParams)
            if (!isEqual(urlContext, context)) {
              replaceSearchParams(contextToQueryParams(context))
            }
          },

          getContext: () => buildContext(get),

          applyFilters: async (filters, searchParamsHandler) => {
            const apiFetch = get().getApiFetch()
            get().clearData()
            await applyFilters(set, apiFetch, get().competitors, filters)
            get().setContext(
              { filters, secondaryMarket: { chartType: DEFAULT_SECONDARY_MARKET_CHART_TYPE } },
              searchParamsHandler,
            )
          },

          loadData: async (searchParamsHandler, options) => {
            const isSelectingCustomEvents = get().isSelectingCustomEvents
            if (isSelectingCustomEvents) {
              set({ isSelectingCustomEvents: false })
              return
            }

            const { enableReload = false } = options || {}
            const firstLoad = get().firstLoad
            const currentContext = buildContext(get)
            const { searchParams, replaceSearchParams, setSearchParams } = searchParamsHandler
            const apiFetch = get().getApiFetch()

            let newFilterFields: FilterField[] = []
            let newContext: TourMarketingContext = {} as TourMarketingContext

            if (searchParams && searchParams.size > 0) {
              newContext = queryParamsToContext(searchParams, setSearchParams)
              newFilterFields = pageFilterToFilterFields(newContext.filters)
            } else if (firstLoad) {
              const localSavedObject = getLocalFilters()
              if (localSavedObject?.length) {
                newFilterFields = localSavedObject
                newContext = {
                  filters: filterFieldsToPageFilters(newFilterFields),
                  secondaryMarket: {
                    chartType: DEFAULT_SECONDARY_MARKET_CHART_TYPE,
                  },
                }
              }
            } else if (currentContext?.filters) {
              replaceSearchParams(contextToQueryParams(currentContext))
              return
            }

            const contextChanged = !isEqual(currentContext, newContext) && !isEmpty(newContext)

            if ((firstLoad || enableReload) && contextChanged) {
              if (newContext.filters.artist) {
                useTourMarketingControlPanelStore.setState({ filters: newFilterFields })
                get().setContext(newContext, searchParamsHandler, true)
                await initLoad(set, apiFetch, get().competitors, newContext)
                get().setContext(newContext, searchParamsHandler)
              }
            } else if (!contextChanged) {
              replaceSearchParams(contextToQueryParams(currentContext))
            }

            set({ firstLoad: false })
          },
        } as TourMarketingStoreState),
    ),
  )
}

const applyFilters = async (
  set: TourMarketingStoreSetFunction,
  apiFetch: ApiFetch,
  competitors: PaginatedSliceState<CompetitorEntity, CompetitorFilters>,
  filters: Filters,
) => {
  try {
    set({ isLoading: true })
    const filter = filtersToEventFilter(filters)
    await Promise.all([
      api.fetchTimeSeriesData(set, apiFetch, filter),
      api.fetchSecondaryMarketTimeSeriesData(set, apiFetch, filter),
      api.fetchSecondaryMarketTimeSeriesInventoryData(set, apiFetch, filter),
      api.fetchSecondaryMarketPriceAndTierData(set, apiFetch, filter),
      api.fetchEventSummaryData(set, apiFetch, filter),
      api.fetchCompetitorsSumaryData(set, apiFetch, filters),
      competitors.fetch(filters, apiFetch),
    ])
  } finally {
    set({ isLoading: false })
  }
}

const initLoad = async (
  set: TourMarketingStoreSetFunction,
  apiFetch: ApiFetch,
  competitors: PaginatedSliceState<CompetitorEntity, CompetitorFilters>,
  context: TourMarketingContext,
) => {
  try {
    set({ isLoading: true })
    const filter = filtersToEventFilter(context.filters)
    const competitorsFilter = { ...context.filters, ...context.competitorsFilters }
    await Promise.all([
      api.fetchTimeSeriesData(set, apiFetch, filter),
      api.fetchSecondaryMarketTimeSeriesData(set, apiFetch, filter),
      api.fetchSecondaryMarketTimeSeriesInventoryData(set, apiFetch, filter),
      api.fetchSecondaryMarketPriceAndTierData(set, apiFetch, filter),
      api.fetchEventSummaryData(set, apiFetch, filter),
      api.fetchBenchmarkData(set, apiFetch, context?.benchmark),
      api.fetchCompetitorsSumaryData(set, apiFetch, competitorsFilter),
      competitors.fetch(competitorsFilter, apiFetch),
    ])
  } finally {
    set({ isLoading: false })
  }
}

const buildContext = (get: TourMarketingStoreGetFunction): TourMarketingContext => {
  const filterContext = get().filterContext
  const selectedBenchmark = get().selectedBenchmark
  const customEvents = get().customEvents
  const competitors = get().competitorsFilters
  const secondaryMarketChartType = get().secondaryMarketChartType

  return {
    filters: filterContext as Filters,
    benchmark: selectedBenchmark
      ? {
          option: selectedBenchmark,
          events: customEvents || undefined,
        }
      : undefined,
    competitorsFilters: competitors.length ? competitorFilterFieldsToCompetitorFilters(competitors) : undefined,
    secondaryMarket: {
      chartType: secondaryMarketChartType,
    },
  }
}
