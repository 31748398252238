import {
  FilterDate,
  FilterItem,
  FiltersDate,
  defaultFilters,
  generateDateFields,
  pageFilterToFilterFields,
} from '@stores/tourPlanningStore'
import { FilterField } from './tourPlanningControlPanelStoreTypes'
import { Filters as PageFilters } from './tourPlanningStoreTypes'

const FILTER_VERSION = 1
const LOCALSTORAGE_KEY = 'session_tourPlanningFilters'

export const saveLocalFilters = (filters: PageFilters | FilterField[]) => {
  const data = Array.isArray(filters) ? filters : pageFilterToFilterFields(filters)
  const options = {
    version: FILTER_VERSION,
    data: data.map((filter: FilterField) => {
      return {
        name: filter.name,
        data: filter.data,
        type: filter instanceof FilterDate ? 'Date' : 'Item',
      }
    }),
  }
  localStorage[LOCALSTORAGE_KEY] = JSON.stringify(options)
}

export const getLocalFilters = () => {
  const savedOptions = localStorage[LOCALSTORAGE_KEY]
  if (!savedOptions) return null

  const savedFilters = JSON.parse(savedOptions)
  if (savedFilters.version === 1) {
    const filters: FilterField[] = []
    savedFilters.data.forEach((saved: any) => {
      const FilterKlass = saved.type === 'Date' ? FilterDate : FilterItem
      filters.push(new FilterKlass(saved.name, saved.data))
    })
    return filters
  } else {
    const mergedOptions = { ...defaultFilters, ...savedFilters }
    let dates: FiltersDate = {
      since: null,
      until: null,
      dateRangeOption: null,
    }
    if (mergedOptions?.dateRangeOption?.value) {
      dates = generateDateFields(mergedOptions.dateRangeOption.value, mergedOptions.since, mergedOptions.until)
    } else if (mergedOptions.dateRangeOptionValue) {
      // Retrocompatible version
      dates = generateDateFields(mergedOptions.dateRangeOptionValue, mergedOptions.since, mergedOptions.until)
      delete mergedOptions.dateRangeOptionValue
      // End retrocompatible version
    }

    if (dates?.dateRangeOption) {
      mergedOptions.dateRangeOption = dates.dateRangeOption
      mergedOptions.since = dates.since
      mergedOptions.until = dates.until
    }

    const filters: FilterField[] = pageFilterToFilterFields(mergedOptions)
    return filters
  }
}

export const clearLocalFilters = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY)
}
