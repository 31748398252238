import { MutableRefObject, createRef } from 'react'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { ApiFetch } from '@services/api'
import { getLocalFilters, saveLocalFilters } from '@stores/upcomingEventsStore/localFilters'
import { useUpcomingEventsControlPanelStore } from '@stores/upcomingEventsStore/upcomingEventsControlPanelStore'
import { setRef } from '@utils/zustand'
import * as api from './upcomingEventsApi'
import {
  Filters,
  UpcomingEventsStoreSetFunction,
  UpcomingEventsStoreState,
  getDefaultFilters,
} from './upcomingEventsStoreTypes'

export const useUpcomingEventsStore = create<UpcomingEventsStoreState>()(
  immer((set, get) => ({
    isLoading: false,
    isLoadingStats: false,
    firstLoad: true,
    setFirstLoad: (firstLoad) => {
      set({ firstLoad })
    },
    currentPage: 1,
    eventsData: null,
    eventsStatsData: null,
    previousEventsStatsData: null,
    filterButtonRef: createRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>,
    mainRef: createRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>,

    setFilterButtonRef: (ref) => setRef(set, get, 'filterButtonRef', ref),
    setMainRef: (ref) => setRef(set, get, 'mainRef', ref),

    clearData: () =>
      set({
        isLoading: false,
        isLoadingStats: false,
        currentPage: 1,
        eventsData: null,
        eventsStatsData: null,
        previousEventsStatsData: null,
        hasPrivateArtist: false,
      }),

    setPage: async (apiFetch, page) => {
      const filters = useUpcomingEventsControlPanelStore.getState().filters[0]

      return setPage(set, apiFetch, filters, page)
    },

    applyFilters: async (apiFetch, filters) => {
      saveLocalFilters(filters)
      return initLoad(set, apiFetch, filters)
    },

    loadData: async (apiFetch) => {
      const firstLoad = get().firstLoad

      if (firstLoad) {
        set({ isLoading: true, isLoadingStats: true })
        const hasPrivateArtist = await api.checkHasPrivateArtist(apiFetch)
        set({ hasPrivateArtist })
        const newFilters: Filters = getLocalFilters(hasPrivateArtist) || getDefaultFilters(hasPrivateArtist)
        useUpcomingEventsControlPanelStore.setState({ filters: [newFilters] })
        await initLoad(set, apiFetch, newFilters)
      }

      set({ firstLoad: false })
    },

    hasPrivateArtist: false,
  })),
)

const setPage = async (set: UpcomingEventsStoreSetFunction, apiFetch: ApiFetch, filters: Filters, page: number) => {
  set({ currentPage: page })
  try {
    set({ isLoading: true })
    await api.fetchEvents(set, apiFetch, filters, page)
  } finally {
    set({ isLoading: false })
  }
}

const initLoad = async (set: UpcomingEventsStoreSetFunction, apiFetch: ApiFetch, filters: Filters) => {
  set({ currentPage: 1 })
  try {
    set({ isLoading: true, isLoadingStats: true })
    await Promise.all([
      api.fetchEvents(set, apiFetch, filters, 1),
      api.fetchEventsStatsData(set, apiFetch, filters),
      api.fetchPreviousEventsStatsData(set, apiFetch, filters),
    ])
  } finally {
    set({ isLoading: false, isLoadingStats: false })
  }
}
