import { useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { StoresContext } from '@stores/StoresContext'
import { EventSearchStoreState } from './eventSearchStoreTypes'

export function useEventSearchStore<T>(
  selector: (state: EventSearchStoreState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(StoresContext)
  if (!store) throw new Error('Missing Provider in the tree')

  return useStoreWithEqualityFn(store.eventSearchStore, selector, equalityFn)
}
