import { MutableRefObject } from 'react'
import { DateRangeInput, DateRangeInputOption } from 'carbonarc-ui'
import { Item } from '@components/RemoteSelect'
import i18n from '@config/react-i18next'
import { ApiFetch } from '@services/api'
import { MY_BRAND_ID } from '@stores/upcomingEventsStore/queryParams'
import { PaginatedData, StarEventEntity } from '@types'
import { dateUtils } from '@utils'
import { StoreSetFunction } from '@utils/zustand'

export type Filters = {
  artist: Item[]
  venue: Item[]
  region: Item[]
  since: Date | null
  until: Date | null
  dateRangeOption: DateRangeInputOption | null
}

export const getDefaultFilters = (myBrands = false): Filters => ({
  artist: myBrands
    ? [{ id: MY_BRAND_ID, name: i18n.t('upcoming_events:filters.my_brands') }]
    : [{ id: '0', name: i18n.t('upcoming_events:filters.all_brands') }],
  region: [{ id: '0', name: i18n.t('upcoming_events:filters.all_regions') }],
  venue: [{ id: '0', name: i18n.t('upcoming_events:filters.all_venues') }],
  since: new Date(),
  until: dateUtils.addDays(new Date(), 7),
  dateRangeOption: DateRangeInput.defaultOptions.find((option: DateRangeInputOption) => option.value === 7) || null,
})

export type StatsData = {
  total_upcoming_events: number
  total_tickets_sold: number
}

export interface UpcomingEventsStoreState {
  isLoading: boolean

  isLoadingStats: boolean

  firstLoad: boolean
  setFirstLoad: (firstLoad: boolean) => void

  currentPage: number

  eventsData: PaginatedData<StarEventEntity> | null

  eventsStatsData: StatsData | null

  previousEventsStatsData: StatsData | null

  filterButtonRef: MutableRefObject<HTMLButtonElement>
  mainRef: MutableRefObject<HTMLDivElement>

  setFilterButtonRef: (filterButtonRef: HTMLButtonElement | null) => void
  setMainRef: (mainRef: HTMLDivElement | null) => void

  clearData: () => void
  applyFilters: (apiFetch: ApiFetch, filters: Filters) => Promise<void>
  setPage: (apiFetch: ApiFetch, page: number) => Promise<void>
  loadData: (apiFetch: ApiFetch) => Promise<void>

  hasPrivateArtist: boolean
}

export type UpcomingEventsStoreSetFunction = StoreSetFunction<UpcomingEventsStoreState>

export type UpcomingEventsStoreGetFunction = () => UpcomingEventsStoreState
