import { useCallback, useEffect, useMemo, useState } from 'react'
import { EChartsOption, SeriesOption } from 'echarts'
import { useTranslation } from 'react-i18next'
import { useChartCardData } from '@components/ChartCard'
import { EChartsComponent, getDefaultChartColors } from '@components/EChartsComponent'
import { useTheme } from '@contexts/Theme'
import { BinPriceTierData } from '@types'
import { chartsUtils, formatUtils, screenUtils } from '@utils'
import { getChartStyle, showAnimation, showSlider } from '@utils/charts'

interface SecondaryMarketStackedBarChartProps {
  secondarySeriesData?: BinPriceTierData[]
  primarySeriesData?: BinPriceTierData[]
  tierBinSize: number
}

const generateBarDataArray = (data: BinPriceTierData, bins: number[]): number[] =>
  bins.map((bin) => (bin === data.bin ? data.quantity : null)) as number[]

export const SecondaryMarketStackedBarChart = ({
  secondarySeriesData = [],
  primarySeriesData = [],
  tierBinSize,
}: SecondaryMarketStackedBarChartProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const { setData: setDownloadableData } = useChartCardData()
  const { theme } = useTheme()
  const { t } = useTranslation('tour_marketing')

  const secondaryTotal = useMemo(() => {
    return secondarySeriesData.reduce((acc, data) => acc + data.quantity, 0)
  }, [secondarySeriesData])

  const getChartOption = useCallback(async () => {
    const color = getDefaultChartColors(theme)

    const secondaryBins = [...new Set(secondarySeriesData.map((data) => data.bin))].sort((a, b) => a - b)
    const primaryBins = [...new Set(primarySeriesData.map((data) => data.bin))].sort((a, b) => a - b)

    const primarySeries: SeriesOption[] = primarySeriesData.map((data) => ({
      name: `${data.tier} ($${data.price_total})`,
      data: generateBarDataArray(data, primaryBins),
      stack: 'primary',
      type: 'bar',
      emphasis: {
        focus: 'series',
        label: {
          show: true,
          formatter: '{a}',
          position: 'top',
        },
      },
      xAxisIndex: 0,
      yAxisIndex: 0,
      tooltip: {
        formatter: function (params) {
          const data = primarySeriesData[params.dataIndex]
          return `${data?.tier} ($${data?.price_total})<br/>
          <div style='margin-bottom:1rem'>
            ${params.marker}${params.name}
          </div>
          ${t(
            'quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.ticket_quantity',
          )}: <span style='font-weight: bold'>${formatUtils.formatNumber(data?.quantity)}</span>`
        },
      },
    }))

    const secondarySeries: SeriesOption[] = secondarySeriesData.map((data) => ({
      name: `${data.tier} ($${data.price_total})`,
      data: generateBarDataArray(data, secondaryBins),
      stack: 'secondary',
      type: 'bar',
      emphasis: {
        focus: 'series',
        label: {
          show: true,
          formatter: '{a}',
          position: 'top',
        },
      },
      xAxisIndex: 1,
      yAxisIndex: 1,
      tooltip: {
        formatter: function (params) {
          const data = params.seriesIndex != null ? secondarySeriesData[params.seriesIndex] : undefined
          return `${data?.tier} ($${data?.price_total})<br/>
          <div style='margin-bottom:1rem'>
            ${params.marker}${params.name}
          </div>
          ${t(
            'quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.ticket_quantity',
          )}: <span style='font-weight: bold'>${formatUtils.formatNumber(data?.quantity)}</span><br/>
          ${t(
            'quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.avg_days_listed',
          )}: ${formatUtils.formatNumber(data?.avg_number_of_days_listed)}<br/>
          ${t(
            'quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.avg_price_changes',
          )}: ${formatUtils.formatNumber(data?.avg_number_of_price_changes)}`
        },
      },
    }))

    setChartOption({
      tooltip: {
        trigger: 'item',
      },
      color,
      xAxis: [
        {
          type: 'category',
          gridIndex: 0,
          data: primaryBins.map(
            (v) => `${formatUtils.formatCurrency(v)} - ${formatUtils.formatCurrency(v + tierBinSize)}`,
          ),
        },
        {
          type: 'category',
          gridIndex: 1,
          data: secondaryBins.map(
            (v) => `${formatUtils.formatCurrency(v)} - ${formatUtils.formatCurrency(v + tierBinSize)}`,
          ),
        },
      ],
      yAxis: [
        {
          name: t('secondary_market_chart.subtitle_primary_market'),
          nameGap: 24,
          nameTextStyle: {
            align: 'left',
            padding: [0, -30],
          },
          gridIndex: 0,
          type: 'value',
          offset: 0,
          axisLabel: {
            show: true,
            formatter: chartsUtils.yAxisFormatter,
          },
        },
        {
          name: t('secondary_market_chart.subtitle_secondary_market'),
          nameGap: 24,
          nameTextStyle: {
            align: 'left',
            padding: [0, -30],
          },
          gridIndex: 1,
          type: 'value',
          offset: 0,
          axisLabel: {
            show: true,
            formatter: chartsUtils.yAxisFormatter,
          },
        },
        {
          name: t('secondary_market_chart.subtitle_secondary_market_count', {
            value: formatUtils.formatNumber(secondaryTotal),
          }),
          nameGap: 24,
          nameTextStyle: {
            align: 'right',
            padding: [0, -30],
          },
          nameLocation: 'end',
          gridIndex: 1,
          type: 'value',
        },
      ],
      grid: screenUtils.isMobile(window)
        ? [
            { bottom: '60%', top: '10%', left: '15%', right: '5%' },
            { bottom: '15%', top: '65%', left: '15%', right: '5%' },
          ]
        : [
            { left: '8%', right: '55%', top: '10%', bottom: '18%' },
            { left: '55%', right: '8%', top: '10%', bottom: '18%' },
          ],
      series: [...secondarySeries, ...primarySeries],
      dataZoom: [
        {
          xAxisIndex: [0],
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
          ...(screenUtils.isMobile(window) && {
            top: '45%',
            left: '5%',
            right: '5%',
          }),
        },
        {
          xAxisIndex: [1],
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
          ...(screenUtils.isMobile(window) && {
            bottom: '3%',
            left: '5%',
            right: '5%',
          }),
        },
      ],
      animation: showAnimation(),
    })

    setDownloadableData([
      [],
      [
        `${t('secondary_market_chart.subtitle_primary_market')} - ${t('secondary_market_chart.tier_price')}`,
        `${t('secondary_market_chart.subtitle_primary_market')} - ${t(
          'quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.ticket_quantity',
        )}`,
      ],
      ...primarySeriesData.map((item) => [`${item.tier} ($${item.price_total})`, `${item.quantity}`]),
      [
        `${t('secondary_market_chart.subtitle_secondary_market')} - ${t('secondary_market_chart.tier_price')}`,
        `${t('secondary_market_chart.subtitle_secondary_market')} - ${t(
          'quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.ticket_quantity',
        )}`,
        `${t('secondary_market_chart.subtitle_secondary_market')} - ${t(
          'quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.avg_days_listed',
        )}`,
        `${t('secondary_market_chart.subtitle_secondary_market')} - ${t(
          'quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.avg_price_changes',
        )}`,
      ],
      ...secondarySeriesData.map((item) => [
        `${item.tier} ($${item.price_total})`,
        `${item.quantity}`,
        `${item.avg_number_of_days_listed}`,
        `${item.avg_number_of_price_changes}`,
      ]),
    ])
  }, [theme, secondarySeriesData, primarySeriesData, t, secondaryTotal, setDownloadableData, tierBinSize])

  useEffect(() => {
    getChartOption()
  }, [getChartOption])

  if (!chartOption) return null

  return <EChartsComponent option={chartOption} style={getChartStyle()} />
}
