import { APP, App } from '@config/site/app'
import { cn } from '@utils/className'

export const LOGO =
  APP === App.STAR ? (
    <>
      <img
        className="mx-auto mb-8 h-auto scale-125 dark:invisible dark:hidden"
        src="star/logo-light.svg"
        alt="Carbon Arc"
      />
      <img
        className="mx-auto mb-8 h-auto scale-125 hidden invisible dark:visible dark:block"
        src="star/logo-dark.svg"
        alt="Carbon Arc"
      />
    </>
  ) : undefined

export const HeaderLogo = () => {
  const file = APP === App.STAR ? 'star/logo-min-light.svg' : 'gp/logo-min-light.svg'

  return <img className={cn('mx-auto h-[60px] mr-2 md:mr-0')} src={file} alt="Carbon Arc" />
}

export const AppExportLogo = APP === App.STAR ? '/star/logo-light.png' : '/gp/export-header.png'
// width, height
export const AppExportLogoSize = APP === App.STAR ? [100, 52] : [260, 52]
