import { useCallback } from 'react'
import { Link } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { BookmarkButton } from '@components/Bookmarks/BookmarkButton'
import { CopyReportUrlButton } from '@components/Bookmarks/CopyReportUrlButton'
import { Table } from '@components/Table'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { useEventSearchStore } from '@stores/eventSearchStore'
import { buildEventName } from '@stores/eventSearchStore/utils'
import { StarEventEntity } from '@types'
import { toISOStringDatePart } from '@utils/date'

const EventListRowContent = ({ event }: { event: StarEventEntity }) => {
  const { t } = useTranslation('event_search_advanced')
  const searchParamsHandler = useSearchParamsObject()
  const { selectEvent } = useEventSearchStore((state) => ({
    selectEvent: state.selectEvent,
  }))
  const handleEventClick = useCallback(() => {
    if (event.brand?.id && event.venue?.id && event.performance_date) {
      selectEvent(
        {
          artist: event.brand.id.toString(),
          venue: event.venue.id.toString(),
          date: toISOStringDatePart(event.performance_date),
        },
        searchParamsHandler,
      )
    }
  }, [event, selectEvent, searchParamsHandler])
  return (
    <div className="flex justify-between items-center w-full">
      <Link
        className="text-normal hover:text-blue-600 hover:dark:text-blue-500 underline underline-offset-4"
        onClick={handleEventClick}
      >
        <span className="truncate">
          {buildEventName(
            event.brand?.name || '',
            event.venue?.name || '',
            event.performance_date || '',
            event.venue?.location?.city,
            event.venue?.location?.state,
          )}
        </span>
      </Link>
      <div className="flex divide-x gap-2 items-center dark:divide-gray-600">
        <div
          className="flex ml-2 items-center text-blue-600 dark:text-blue-500 text-sm font-medium hover:bg-gray-200 dark:hover:bg-slate-800 h-9 px-2 rounded-md"
          onClick={handleEventClick}
        >
          {t('overview:favorites.view')} <ArrowRightIcon className="w-4 h-4 stroke-2 ml-1.5" />
        </div>
      </div>
    </div>
  )
}

export const ListContent = () => {
  const { t } = useTranslation('event_search_advanced')
  const { events, context } = useEventSearchStore((state) => ({
    events: state.events,
    context: state.context,
  }))

  const filters = context.filters

  const tableBody = events.items.map((event) => ({
    row: [<EventListRowContent event={event} />],
    className: 'font-normal cursor-pointer',
  }))

  return (
    <div className="px-4 py-6 md:p-8 !pb-0 group-[.pdf-report]:p-0 w-full">
      <div className="flex flex-row justify-between gap-2 mb-6 md:mb-8 w-full">
        <div className="min-w-0">
          <div className="flex flex-row items-center w-full min-w-0">
            <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white truncate">{t('title')}</h1>
          </div>
          <h2 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">{t('subtitle')}</h2>
        </div>
        <div className="flex gap-2">
          <CopyReportUrlButton disabled={filters.length === 0} />
          <BookmarkButton
            page={BookmarkPageEnum.EVENT_ADVANCED_LIST}
            context={filters.length > 0 ? context : undefined}
          />
        </div>
      </div>
      <Table
        id={VisualizationId.EventsList}
        ignoreMaxHeight
        header={[{ column: t('main_list.events') }]}
        body={tableBody}
        showTable={events.total > 0}
        isLoading={events.fetching}
        totalLabel="All shows"
        hideDownload
        pagination={events}
      />
    </div>
  )
}
