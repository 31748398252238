import snakeCase from 'lodash/snakeCase'
import { Item } from '@components/RemoteSelect'
import { ControlPanelSlice } from '@stores/slices/controlPanelSlice'
import { dateUtils } from '@utils'

export type FilterKey = 'artist' | 'showType' | 'venue' | 'perfDate'

export interface FilterField {
  name: FilterKey
  data: any
  toParams(): Record<string, string | string[] | undefined>
}

export class FilterItem implements FilterField {
  name: FilterKey
  data: Item | null
  constructor(name: FilterKey, data: Item | null) {
    this.name = name
    this.data = data
  }

  toParams() {
    const filterKey = snakeCase(this.name)
    return {
      [filterKey]: this.data?.id,
    }
  }
}

export class FilterDate extends FilterItem {
  toParams() {
    const since = dateUtils.parseDate(this.data?.id)
    const until = dateUtils.parseDate(this.data?.id)
    return {
      since: since?.toISOString().split('T')[0],
      until: until?.toISOString().split('T')[0],
    }
  }
}

export interface TourMarketingControlPanelStoreState extends ControlPanelSlice<FilterField> {
  lastTouchedFilterKey: FilterKey | null
  setLastTouchedFilterKey: (lastTouchedFilterKey: FilterKey | null) => void
}
