interface SiteConfig {
  name: string
  description: string
}

export const siteConfig: SiteConfig = {
  name: 'Ticketing',
  description: 'Ticketing',
}

export const apiUrl = import.meta.env.VITE_API_BASE_URL || ''
