import { Dispatch, Ref, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Combobox } from '@headlessui/react'
import { ChevronDownIcon, MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { cn } from '@utils/className'

export type InputRef = Ref<HTMLInputElement> | ((node: HTMLInputElement) => void)

export interface InputProps {
  id?: string
  value: string
  placeholder?: string
  disabled?: boolean
  icon?: React.ElementType | null
  tabIndex?: number
  openOnClick?: boolean
  showRightArrow?: boolean
  preventReplace?: boolean
  hasSelected?: boolean
  inputRef?: InputRef
  className?: string
  onChange: (value: string) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const Input = (props: InputProps) => {
  const {
    id,
    value,
    placeholder = '',
    disabled = false,
    icon: Icon = MagnifyingGlassIcon,
    tabIndex = 0,
    openOnClick = true,
    showRightArrow = false,
    preventReplace = false,
    hasSelected = false,
    onChange,
    onFocus: externalOnFocus,
    onBlur,
    onKeyUp,
    setOpen,
    inputRef,
    className,
  } = props
  const { t } = useTranslation('')

  const onClick = useCallback(() => {
    if (preventReplace && hasSelected) {
      alert(t('clear_selection'))
    } else if (openOnClick) {
      setOpen(true)
    }
  }, [t, hasSelected, openOnClick, preventReplace, setOpen])

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      externalOnFocus?.(event)
      if (preventReplace && hasSelected) {
        event.preventDefault()
        event.target.blur()
      }
    },
    [externalOnFocus, hasSelected, preventReplace],
  )

  const showCloseIcon = !!value && !disabled

  return (
    <div className="relative print:hidden">
      <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"></div>
      {Icon && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Icon className="w-4 h-4 text-gray-500 dark:text-gray-400 stroke-2" />
        </div>
      )}
      <Combobox.Input
        className={cn(
          'block border w-full rounded-lg py-3 shadow-sm ring-0 ring-inset focus:ring-0 focus:ring-inset sm:text-sm sm:leading-4 font-normal disabled:opacity-50 text-ellipsis',
          'border-gray-300 bg-gray-50 text-gray-500 ring-gray-300 focus:ring-indigo-600',
          'dark:border-gray-600 dark:bg-gray-700 dark:ring-gray-600 dark:text-white',
          Icon && '!pl-10',
          (showRightArrow || showCloseIcon) && 'pr-8',
          className,
        )}
        displayValue={() => value}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        autoComplete="off"
        ref={inputRef}
      />
      {showCloseIcon && (
        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
          onClick={(event) => {
            event.preventDefault()
            onChange('')
          }}
        >
          <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>
      )}
      {showRightArrow && !showCloseIcon && (
        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
          onClick={() => setOpen((open) => !open)}
        >
          <ChevronDownIcon className="h-4 w-4 text-gray-500 stroke-2" aria-hidden="true" />
        </Combobox.Button>
      )}
    </div>
  )
}
