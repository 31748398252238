import { useEffect, useState } from 'react'
import { usePrevious } from '@hooks/usePrevious'

export const useActiveElement = () => {
  const [activeElement, setActiveElement] = useState<Element | null>(null)
  const previousActiveElement = usePrevious(activeElement)

  useEffect(() => {
    const detectFocus = (event: FocusEvent) => {
      if (event?.target) setActiveElement(event.target as Element)
    }

    window.addEventListener('focus', detectFocus, true)

    return () => {
      window.removeEventListener('focus', detectFocus, true)
    }
  }, [])

  return { activeElement, previousActiveElement }
}
