import { createContext } from 'react'
import { EventSearchStore } from '@stores/eventSearchStore/eventSearchStoreTypes'
import { PredictionAssessmentStore } from '@stores/predictionAssessmentStore'
import { SingleEventStore } from '@stores/singleEventStore'

type Stores = {
  predictionAssessmentStore: PredictionAssessmentStore
  eventSearchStore: EventSearchStore
  singleEventStore: SingleEventStore
}

export const StoresContext = createContext<Stores | null>(null)
