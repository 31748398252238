import { Button, ButtonProps } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { cn } from '@utils/className'

export const ClearAllButton = (props: ButtonProps) => {
  const { t } = useTranslation('common')
  const { className, ...otherProps } = props
  return (
    <Button
      variant="outline"
      className={cn(
        'w-full sm:max-w-[250px] md:w-[250px] lg:w-full disabled:opacity-25 px-1 flex gap-2 text-sm font-medium text-gray-800 dark:text-gray-200',
        className,
      )}
      {...(otherProps as any)}
    >
      <XMarkIcon className="w-4 h-4 stroke-[1.5px] text-gray-400 dark:text-gray-500" />
      {t('clear_all')}
    </Button>
  )
}
