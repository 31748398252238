import { createContext } from 'react'

export type Theme = 'dark' | 'light'

type ThemeContextType = {
  theme: Theme
  setTheme: (theme: Theme) => void
  getOsTheme: () => string
}

export const ThemeContext = createContext<ThemeContextType>({
  theme: 'light',
  setTheme: () => {
    return
  },
  getOsTheme: () => {
    return ''
  },
})
