import { useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'carbonarc-ui'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useOktaAuth } from '@okta/okta-react'
import { useApiFetch } from '@services/api'

export const useDocumentationLink = () => {
  const { authState } = useOktaAuth()
  const accessToken = authState?.accessToken?.accessToken

  const previousAccessToken = usePrevious(accessToken)
  const [docsLink, setDocsLink] = useState<string | null>(null)
  const [loaded, setLoaded] = useState<boolean>(false)
  const apiFetch = useApiFetch()

  const updateDocsLink = useCallback(() => {
    apiFetch('/gitbook')
      .then((d) => d?.docs_url)
      .then(setDocsLink)
      .finally(() => setLoaded(true))
  }, [apiFetch])

  useEffectOnce(updateDocsLink)

  useEffect(() => {
    if (previousAccessToken && accessToken !== previousAccessToken) {
      updateDocsLink()
    }
  }, [accessToken, previousAccessToken, updateDocsLink])

  return { link: docsLink, loaded }
}
