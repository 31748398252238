import {
  Bookmark,
  BookmarkContext,
  BookmarkData,
  BookmarkPage,
  BookmarkPageEnum,
  BookmarkSerializedData,
  Deserializer,
  EventSearchContext,
  PredictionAssessmentContext,
  Serializer,
  TourMarketingContext,
  TourPlanningContext,
} from '@stores/bookmarkStore/utils/types'
import { generateDateFields } from '@stores/tourPlanningStore/tourPlanningStoreUtils'

const deserializePageAliases: Record<string, BookmarkPageEnum> = {
  'tour-marketing': BookmarkPageEnum.EVENT_INSIGHTS,
  'tour-planning': BookmarkPageEnum.BRAND_VENUE_INSIGHTS,
}

const serializers: Record<BookmarkPage, Serializer> = {
  [BookmarkPageEnum.EVENT_INSIGHTS]: (ctx: BookmarkContext) => ctx as TourMarketingContext,
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS]: (ctx: BookmarkContext) => {
    const { filters, ...originalContext } = ctx as TourPlanningContext
    const { since, until, dateRangeOption, ...primitiveFields } = filters

    const modifiedDateFields =
      dateRangeOption?.value !== 'custom'
        ? {
            // do not save dates when it's relative
            since: null,
            until: null,
            dateRangeOption,
          }
        : {
            dateRangeOption,
            since: since?.toISOString() || null,
            until: until?.toISOString() || null,
          }

    return {
      ...originalContext,
      filters: {
        ...primitiveFields,
        ...modifiedDateFields,
      },
    }
  },
  [BookmarkPageEnum.PREDICTION_ASSESSMENT]: (ctx: BookmarkContext) => {
    const context = ctx as PredictionAssessmentContext

    const hasGenerate =
      context?.filters?.generate?.artist || context?.filters?.generate?.venue || !!context?.filters?.generate?.season

    const retrocompatibleContext = {
      ...context,
      filters: {
        ...context.filters?.backTest,
        ...(hasGenerate ? { generate: context.filters?.generate } : {}),
      },
    }
    return retrocompatibleContext
  },
  [BookmarkPageEnum.EVENT_ADVANCED_LIST]: (ctx: BookmarkContext) => ctx as EventSearchContext,
  [BookmarkPageEnum.EVENT_ADVANCED_SINGLE]: (ctx: BookmarkContext) => ctx as EventSearchContext,
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS_SINGLE]: (ctx: BookmarkContext) => {
    const { filters, ...originalContext } = ctx as TourPlanningContext
    const { since, until, dateRangeOption, ...primitiveFields } = filters

    const modifiedDateFields =
      dateRangeOption?.value !== 'custom'
        ? {
            // do not save dates when it's relative
            since: null,
            until: null,
            dateRangeOption,
          }
        : {
            dateRangeOption,
            since: since?.toISOString() || null,
            until: until?.toISOString() || null,
          }

    return {
      ...originalContext,
      filters: {
        ...primitiveFields,
        ...modifiedDateFields,
      },
    }
  },
}

const deserializers: Record<BookmarkPage, Deserializer> = {
  [BookmarkPageEnum.EVENT_INSIGHTS]: (context: any) => context as TourMarketingContext,
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS]: (context: any) => {
    const { filters, ...originalContext } = context as TourPlanningContext
    const { since, until, dateRangeOption, ...primitiveFilterFields } = filters

    let modifiedDateFields = {}
    if (dateRangeOption) {
      modifiedDateFields = generateDateFields(dateRangeOption.value, since, until)
    }

    return {
      ...originalContext,
      filters: {
        ...primitiveFilterFields,
        ...modifiedDateFields,
      },
    } as TourPlanningContext
  },
  [BookmarkPageEnum.PREDICTION_ASSESSMENT]: (context: any) => context as PredictionAssessmentContext,
  [BookmarkPageEnum.EVENT_ADVANCED_LIST]: (context: any) => context as EventSearchContext,
  [BookmarkPageEnum.EVENT_ADVANCED_SINGLE]: (context: any) => context as EventSearchContext,
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS_SINGLE]: (context: any) => {
    const { filters, ...originalContext } = context as TourPlanningContext
    const { since, until, dateRangeOption, ...primitiveFilterFields } = filters

    let modifiedDateFields = {}
    if (dateRangeOption) {
      modifiedDateFields = generateDateFields(dateRangeOption.value, since, until)
    }

    return {
      ...originalContext,
      filters: {
        ...primitiveFilterFields,
        ...modifiedDateFields,
      },
    } as TourPlanningContext
  },
}

export const serializeBookmarkData = (data: BookmarkData): BookmarkSerializedData => {
  const serializedContext = serializeBookmarkContext(data)
  return {
    page: data.page,
    context: serializedContext,
  }
}

const deserializeBookmarkData = (json: BookmarkSerializedData): BookmarkData => {
  const { page: jsonPage, context } = json

  const page = deserializePageAliases[jsonPage] || jsonPage

  return {
    page,
    context: deserializers[page](context),
  }
}

export const deserializeBookmark = (json: any): Bookmark => {
  const { uid, name, checksum, data: serializedData } = json
  const data = deserializeBookmarkData(serializedData)
  return {
    uid,
    name,
    checksum,
    data,
  }
}

const serializeBookmarkContext = (bookmark: BookmarkData): any => {
  return serializers[bookmark.page](bookmark.context)
}
