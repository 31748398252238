import { Dispatch } from 'react'
import { DateRangeInputOption } from 'carbonarc-ui'
import { CheckboxOption } from '@components/Export'
import { Item } from '@components/RemoteSelect'
import { Action, FetchablePaginatedData, State } from '@hooks/usePaginatedData/types'
import { ApiFetch } from '@services/api'
import { TourPlanningContext } from '@stores/bookmarkStore/utils/types'
import { AdditionalParamsOnInternalChangeForEventSearch } from '@stores/eventSearchStore'
import { SingleEventFilter } from '@stores/singleEventStore'
import { EventDataSlice } from '@stores/slices/eventDataSlice'
import { PageSlice } from '@stores/slices/pageSlice'
import { PaginatedSliceState } from '@stores/slices/paginatedSlice/paginatedSliceTypes'
import { EventEntityWithSeries, EventsData, StarEventEntity } from '@types'
import { StoreSetFunction } from '@utils/zustand'
import { createTourPlanningStore } from './tourPlanningStore'

type Events = [State<StarEventEntity, Filters>, Dispatch<Action<StarEventEntity, Filters>>]

export type FiltersDate = {
  since: Date | null
  until: Date | null
  dateRangeOption: DateRangeInputOption | null
}

export type Filters = {
  artist: Item[]
  showType: Item[]
  region: Item[]
  venue: Item[]
} & FiltersDate

export const defaultFilters: Filters = {
  artist: [],
  showType: [],
  venue: [],
  region: [],
  since: null,
  until: null,
  dateRangeOption: null,
}

type LoadOptions = {
  enableReload?: boolean
}

export interface TourPlanningStoreProps {
  events: Events
  getApiFetch: () => ApiFetch
}

export type TourPlanningStore = ReturnType<typeof createTourPlanningStore>

export type PaginatedControllers = {
  events: FetchablePaginatedData<StarEventEntity, Filters>
}

export interface TourPlanningStoreState
  extends PageSlice<Filters>,
    EventDataSlice<AdditionalParamsOnInternalChangeForEventSearch> {
  getApiFetch: () => ApiFetch

  events: Events

  eventsListbox: PaginatedSliceState<StarEventEntity, Filters>

  isLoadingEvents: boolean
  isSelectingEvents: boolean
  selectedEventIds: number[]
  selectedEvents: EventEntityWithSeries[]
  page: string
  exportOptions: CheckboxOption[]
  context: TourPlanningContext
  syncingUrl: boolean
  setExportOptions: (exportOptions: CheckboxOption[]) => void
  backToList: (searchParamsHandler: SearchParamsObject) => void

  addSelectedEvents: (newEvents: (EventsData | number)[], searchParamsHandler: SearchParamsObject) => Promise<void>
  removeSelectedEvents: (
    removeEvents: (EventsData | number)[],
    searchParamsHandler: SearchParamsObject,
  ) => Promise<void>
  setSelectedEvents: (selectedEvents: (EventsData | number)[], searchParamsHandler: SearchParamsObject) => Promise<void>
  isShowingSingleEvent: () => boolean
  selectEvent: (singleEventFilter: SingleEventFilter | null, searchParamsHandler: SearchParamsObject) => void

  setContext: (context: TourPlanningContext, searchParamsHandler: SearchParamsObject, stateOnly?: boolean) => void
  clearData: (searchParamsHandler?: SearchParamsObject) => void
  applyFilters: (
    filters: Filters,
    paginatedControllers: PaginatedControllers,
    searchParamsHandler: SearchParamsObject,
  ) => Promise<void>
  loadData: (
    paginatedControllers: PaginatedControllers,
    searchParamsHandler: SearchParamsObject,
    options?: LoadOptions,
  ) => Promise<void>
}

export type TourPlanningStoreSetFunction = StoreSetFunction<TourPlanningStoreState>

export type TourPlanningStoreGetFunction = () => TourPlanningStoreState
