import { useEffect, useRef } from 'react'
import { useApiFetch } from '@services/api'
import { EventSearchStore, createEventSearchStore } from '@stores/eventSearchStore'
import { PredictionAssessmentStore, createPredictionAssessmentStore } from '@stores/predictionAssessmentStore'
import { SingleEventStore, createSingleEventStore } from '@stores/singleEventStore'
import { StoresContext } from './StoresContext'

export function StoresProvider({ children }: { children: React.ReactNode }) {
  const apiFetch = useApiFetch()
  const getApiFetch = () => apiFetch

  const predictionAssessmentStoreRef = useRef<PredictionAssessmentStore>(
    createPredictionAssessmentStore({ getApiFetch }),
  )
  const eventSearchStoreRef = useRef<EventSearchStore>(createEventSearchStore({ getApiFetch }))
  const singleEventStoreRef = useRef<SingleEventStore>(createSingleEventStore({ getApiFetch }))

  useEffect(() => {
    const getApiFetch = () => apiFetch
    if (predictionAssessmentStoreRef.current) {
      predictionAssessmentStoreRef.current.setState({
        getApiFetch,
      })
    } else {
      predictionAssessmentStoreRef.current = createPredictionAssessmentStore({
        getApiFetch,
      })
    }

    if (eventSearchStoreRef.current) {
      eventSearchStoreRef.current.setState({
        getApiFetch,
      })
    } else {
      eventSearchStoreRef.current = createEventSearchStore({
        getApiFetch,
      })
    }
    if (singleEventStoreRef.current) {
      singleEventStoreRef.current.setState({
        getApiFetch,
      })
    } else {
      singleEventStoreRef.current = createSingleEventStore({
        getApiFetch,
      })
    }
  }, [apiFetch])

  return (
    <StoresContext.Provider
      value={{
        predictionAssessmentStore: predictionAssessmentStoreRef.current,
        eventSearchStore: eventSearchStoreRef.current,
        singleEventStore: singleEventStoreRef.current,
      }}
    >
      {children}
    </StoresContext.Provider>
  )
}
