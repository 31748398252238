import { useMemo, useState } from 'react'
import { Carousel } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { screenUtils } from '@utils'
import { cn } from '@utils/className'

const figureClassNames =
  'flex flex-col items-start justify-center px-6 py-8 text-left bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700 text-gray-900'
const textOverflowClassNames = 'overflow-hidden text-ellipsis max-w-full'
const carouselControlClassNames = [
  'absolute w-10 h-10 p-2 rounded-full top-1/2 transform -translate-y-1/2',
  'bg-gray-800 hover:bg-gray-600 dark:bg-white dark:hover:bg-gray-300',
  'text-white dark:text-gray-800',
]

const Metric = ({
  title,
  value,
  isLoading,
  rightStats,
}: {
  title: string
  value?: number | string | JSX.Element
  isLoading: boolean
  rightStats?: JSX.Element
}) => {
  const { t } = useTranslation()

  return isLoading ? (
    <SkeletonGridText />
  ) : (
    <div className={cn('w-full', rightStats && 'flex flex-row gap-2 justify-between')}>
      <div className="flex flex-col">
        <span className={cn('text-base font-normal dark:text-white', textOverflowClassNames)} title={title}>
          {title}
        </span>
        {value ? (
          <span
            className={cn('text-sm font-bold md:text-lg md:font-semibold dark:text-gray-100', textOverflowClassNames)}
            title={value?.toString()}
          >
            {value}
          </span>
        ) : (
          <span
            className={cn('text-sm font-normal text-gray-500 dark:text-gray-400', textOverflowClassNames)}
            title={t('data_tips.no_data.title')}
          >
            {t('data_tips.no_data.title')}
          </span>
        )}
      </div>
      {rightStats && <div>{rightStats}</div>}
    </div>
  )
}

const SkeletonGridText = () => (
  <div className="py-1 w-full">
    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full max-w-[8rem] mb-4"></div>
    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full max-w-[12rem] mb-2.5"></div>
  </div>
)

type Metric = {
  title: string
  value?: number | string | JSX.Element
  rightStats?: JSX.Element
  className?: string
}

interface GridProps {
  metrics: Metric[]
  isLoading: boolean
  className?: string
}

export const FourGrid = ({ metrics = [], isLoading, className }: GridProps) => (
  <div
    className={cn(
      'grid mb-4 border border-gray-200 rounded-lg shadow-md dark:border-gray-700 md:grid-cols-2',
      isLoading && 'animate-pulse',
      className,
      'print:shadow-none print:break-inside-avoid-page',
      'group-[.pdf-report]:border-none group-[.pdf-report]:shadow-none',
    )}
  >
    <figure className={cn(figureClassNames, 'border-b rounded-t-lg md:rounded-t-none md:rounded-tl-lg md:border-r')}>
      <Metric title={metrics[0].title} value={metrics[0]?.value} isLoading={isLoading} />
    </figure>
    <figure className={cn(figureClassNames, 'border-b rounded-tr-lg')}>
      <Metric title={metrics[1].title} value={metrics[1]?.value} isLoading={isLoading} />
    </figure>
    <figure className={cn(figureClassNames, 'border-b md:rounded-bl-lg md:border-b-0 md:border-r')}>
      <Metric title={metrics[2].title} value={metrics[2]?.value} isLoading={isLoading} />
    </figure>
    <figure className={cn(figureClassNames, 'rounded-b-lg md:rounded-br-lg')}>
      <Metric title={metrics[3].title} value={metrics[3]?.value} isLoading={isLoading} />
    </figure>
  </div>
)

export const TwoGrid = ({ metrics = [], isLoading, className }: GridProps) => (
  <div
    className={cn(
      'grid mb-4 border border-gray-200 rounded-lg shadow-md dark:border-gray-700 md:grid-cols-2',
      isLoading && 'animate-pulse',
      className,
      'print:shadow-none print:break-inside-avoid-page',
      'group-[.pdf-report]:border-none group-[.pdf-report]:shadow-none',
    )}
  >
    <figure
      className={cn(
        figureClassNames,
        'border-b md:border-b-0 rounded-t-lg md:rounded-bl-lg md:rounded-t-none md:rounded-tl-lg md:border-r',
      )}
    >
      <Metric title={metrics[0].title} value={metrics[0]?.value} isLoading={isLoading} />
    </figure>
    <figure className={cn(figureClassNames, 'rounded-b-lg rounded-tr-lg rounded-br-lg')}>
      <Metric title={metrics[1].title} value={metrics[1]?.value} isLoading={isLoading} />
    </figure>
  </div>
)

export const SeparatedGrid = ({ metrics = [], isLoading, className }: GridProps) => (
  <div
    className={cn('grid space-y-4 mb-4 md:grid-cols-3 md:space-y-0 md:gap-4', isLoading && 'animate-pulse', className)}
  >
    {metrics.map((metric, index) => (
      <figure
        key={index}
        className={cn(
          figureClassNames,
          'print:shadow-none print:break-inside-avoid-page',
          'group-[.pdf-report]:border-none group-[.pdf-report]:shadow-none',
          'border border-gray-200 rounded-lg shadow-md dark:border-gray-700 ml-0',
          metric.className,
        )}
      >
        <Metric title={metric.title} value={metric?.value} isLoading={isLoading} rightStats={metric.rightStats} />
      </figure>
    ))}
  </div>
)

export const CarouselGrid = ({ metrics = [], isLoading, className }: GridProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const chunkSize = screenUtils.isMobile(window) ? 2 : 4
  const metricsChunks = useMemo(
    () =>
      Array.from({ length: Math.ceil(metrics.length / chunkSize) }, (_, i) =>
        metrics.slice(i * chunkSize, i * chunkSize + chunkSize),
      ),
    [metrics, chunkSize],
  )

  if (metrics.length === 0) {
    return null
  }

  return (
    <Carousel
      onSlideChange={setCurrentSlide}
      slide={false}
      indicators={false}
      className="mb-4 h-32 px-12"
      leftControl={
        <div
          className={cn(
            carouselControlClassNames,
            'left-0 group-[.pdf-report]:hidden',
            currentSlide === 0 && 'bg-gray-400 dark:bg-gray-500',
          )}
        >
          <ChevronLeftIcon />
        </div>
      }
      rightControl={
        <div
          className={cn(
            carouselControlClassNames,
            'right-0 group-[.pdf-report]:hidden',
            (currentSlide === metricsChunks.length - 1 || metricsChunks.length < 2) && 'bg-gray-400 dark:bg-gray-500',
          )}
        >
          <ChevronRightIcon />
        </div>
      }
    >
      {metricsChunks.map((chunk, index) => (
        <div
          className={cn(
            'grid grid-cols-2 md:grid-cols-4',
            isLoading && 'animate-pulse',
            index > 0 && 'group-[.pdf-report]:hidden',
            className,
          )}
          key={index}
        >
          {chunk.map((metric, index) => (
            <figure
              key={index}
              className={cn(
                figureClassNames,
                'mx-2',
                'print:shadow-none print:break-inside-avoid-page',
                'group-[.pdf-report]:border-none group-[.pdf-report]:shadow-none',
                'border border-gray-200 rounded-lg shadow-md dark:border-gray-700',
              )}
            >
              <Metric title={metric.title} value={metric?.value} isLoading={isLoading} />
            </figure>
          ))}
        </div>
      ))}
    </Carousel>
  )
}
