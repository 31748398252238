import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { BackTestFilters } from '@stores/predictionAssessmentStore'
import {
  BackTestFilterKey,
  FilterField,
  GenerateFilterKey,
  predictionAssessmentControlPanelStoreState,
} from './predictionAssessmentControlPanelStoreTypes'

export const defaultBackTestFilterValue: BackTestFilters = {
  metric: null,
  artist: [],
  venue: [],
  year: [],
}

export const usePredictionAssessmentControlPanelStore = create<predictionAssessmentControlPanelStoreState>()(
  immer(
    devtools((set, _get, _store) => ({
      backTest: {
        applied: false,
        setApplied: (applied: boolean) =>
          set((state) => {
            state.backTest.applied = applied
          }),

        filters: [],
        setFilters: (filters: FilterField<BackTestFilterKey>[]) =>
          set((state) => {
            state.backTest.filters = filters
          }),
      },
      generate: {
        applied: false,
        setApplied: (applied: boolean) =>
          set((state) => {
            state.generate.applied = applied
          }),

        filters: [],
        setFilters: (filters: FilterField<GenerateFilterKey>[]) =>
          set((state) => {
            state.generate.filters = filters
          }),
      },
    })),
  ),
)
