import { useEffect, useRef } from 'react'
import { useApiFetch } from '@services/api'
import { createTourMarketingStore } from './tourMarketingStore'
import { TourMarketingContext } from './tourMarketingStoreContext'
import { TourMarketingStore } from './tourMarketingStoreTypes'

export function TourMarketingStoreProvider({ children }: { children: React.ReactNode }) {
  const apiFetch = useApiFetch()
  const getApiFetch = () => apiFetch
  const storeRef = useRef<TourMarketingStore>(createTourMarketingStore({ getApiFetch }))

  useEffect(() => {
    const getApiFetch = () => apiFetch
    if (storeRef.current) {
      storeRef.current.setState({
        getApiFetch,
      })
    } else {
      storeRef.current = createTourMarketingStore({
        getApiFetch,
      })
    }
  }, [apiFetch])

  return <TourMarketingContext.Provider value={storeRef.current}>{children}</TourMarketingContext.Provider>
}
