import { createContext } from 'react'
import { VisualizationType } from '@contexts/VisualizationEvaluation/types'

type VisualizationEvaluationType = {
  openEvaluation: (visualization: VisualizationType, score: number) => void
}

export const VisualizationEvaluationContext = createContext<VisualizationEvaluationType>({
  openEvaluation: () => {
    return
  },
})
