import { DateRangeInputValue } from 'carbonarc-ui'
import snakeCase from 'lodash/snakeCase'
import type { Item } from '@components/RemoteSelect'
import { ControlPanelSlice } from '@stores/slices/controlPanelSlice'

export type FilterKey = 'artist' | 'showType' | 'region' | 'venue' | 'date'

export interface FilterField {
  name: FilterKey
  data: any
  toParams(): Record<string, string | string[] | undefined>
}

type DateRange = {
  min: Date | undefined
  max: Date | undefined
  loading: boolean
}

export class FilterItem implements FilterField {
  name: FilterKey
  data: Item[]
  constructor(name: FilterKey, data: Item[]) {
    this.name = name
    this.data = data
  }

  toParams() {
    const filterKey = snakeCase(this.name)
    return {
      [filterKey]: this.data?.map((v) => v.id),
    }
  }
}

export class FilterDate implements FilterField {
  name: FilterKey

  data: DateRangeInputValue | null
  constructor(name: FilterKey, data: DateRangeInputValue | null) {
    this.name = name
    this.data = data
    if (this.data) {
      this.data.start = this.data.start ? new Date(this.data.start) : null
      this.data.end = this.data.end ? new Date(this.data.end) : null
    }
  }

  toParams() {
    return {
      since: this.data?.start?.toISOString().split('T')[0],
      until: this.data?.end?.toISOString().split('T')[0],
    }
  }
}

export interface TourPlanningControlPanelStoreState extends ControlPanelSlice<FilterField> {
  dateRange: DateRange
  setDateRange: (dateRange: DateRange) => void
}
