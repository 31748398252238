export enum App {
  STAR = 'star',
  MUSIC = 'music',
  SPORTS = 'sports',
}

export const DEFAULT_APP = App.MUSIC

const isValidOrganization = (name: string) => Object.values(App).includes(name as App)

const AppEnvironmentVariable: string = import.meta.env.VITE_APP || ''

export const APP = isValidOrganization(AppEnvironmentVariable) ? (AppEnvironmentVariable as App) : DEFAULT_APP
