import { BasePriceTierEntity, BinPriceTierData } from '@types'
import { formatUtils, screenUtils, urlUtils } from '@utils'

const mapPropFromObjectArray = <R, T>(array: T[], prop: string): R[] => {
  const props = prop.split('.')
  return array?.map((item: T) => {
    let result = item as any
    props.forEach((p) => {
      result = result?.[p]
    })
    return result as R
  })
}

export const getXAxisNumberFromObjectMatrix = <T>(data: T[][], prop: string): number[] => {
  const flattenedData = data.map((d) => mapPropFromObjectArray<number, T>(d, prop)).flat(1)

  return [...new Set(flattenedData)].sort((a, b) => b - a)
}

interface DaysFromEventData {
  days_from_event: number
}

export const getXAxisDaysFromEventData = (data: DaysFromEventData[][]) =>
  getXAxisNumberFromObjectMatrix(data, 'days_from_event')

interface DaysFromEventWithinEventData {
  event: { days_from_event: number }
}

export const getXAxisDaysFromEventWithinEventData = (data: DaysFromEventWithinEventData[][]) =>
  getXAxisNumberFromObjectMatrix(data, 'event.days_from_event')

interface DaysSinceSalesOpenedData {
  days_since_sales_opened?: number
}

export const getXAxisDaysSinceSalesOpenedData = (data: DaysSinceSalesOpenedData[][]) =>
  getXAxisNumberFromObjectMatrix(data, 'days_since_sales_opened')

export const getXAxisInterval = (xAxisData: number[]) => {
  const xAxisMaxPoints = screenUtils.isMobile(window) ? 10 : 30
  const xAxisRange = xAxisData[0] - xAxisData[xAxisData.length - 1]
  const xAxisInterval = Math.round(xAxisRange / xAxisMaxPoints / 10) * 10 // round to closest ten

  return xAxisInterval === 0 ? 10 : xAxisInterval
}

export const getMinMaxFromXAxisData = (xAxisData: number[]) => {
  if (!xAxisData?.length) return {}

  return {
    min: xAxisData[xAxisData.length - 1],
    max: xAxisData[0],
  }
}

export const showSlider = () => !urlUtils.hasUrlParam('download-pdf')

export const showAnimation = () => !urlUtils.hasUrlParam('download-pdf')

export const yAxisFormatter = (value: number, index: number) => {
  const suffixes = ['', 'K', 'M', 'B']
  let roundN = 0

  if ((index === 0 && value > 0) || index === 1) {
    let v = value
    roundN = 0
    while (v >= 1000) {
      v /= 1000
      roundN++
    }
    roundN = Math.min(roundN, suffixes.length - 1)
  }
  let divider = 1
  for (let i = 0; i < roundN; i++) {
    divider *= 1000
  }
  const roundedValue = Math.round(value / divider)
  const suffix = suffixes?.[roundN]
  return formatUtils.formatNumber(roundedValue) + (roundedValue === 0 ? '' : suffix)
}

export const TIER_BIN_SIZE = 5
const getTierByPrice = (price: number, tierBinSize = TIER_BIN_SIZE) =>
  price <= 0 ? 0 : (Math.ceil(price / tierBinSize) - 1) * tierBinSize

export const calculateBins = (priceTiersData: BasePriceTierEntity[]): BinPriceTierData[] => {
  return priceTiersData
    .filter((priceTierData) => priceTierData.price_total > 0)
    .map((data) => ({ ...data, bin: getTierByPrice(data.price_total) }))
}

export const getChartStyle = () => {
  if (screenUtils.isMobile(window)) {
    return { width: '100%', height: '70vh', padding: '0.5rem' }
  }

  return { width: '100%', height: '50vh', padding: '1rem' }
}
