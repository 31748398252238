import { useEffect, useMemo, useState } from 'react'
import { Button } from 'carbonarc-ui'
import { useQuery } from 'react-query'
import { useFlag } from '@config/site/app'
import { Flags } from '@config/site/app/flags'
import { StarIcon } from '@heroicons/react/24/outline'
import { StarIcon as StarIconFill } from '@heroicons/react/24/solid'
import { useApi } from '@services/api'
import { useBookmarkStore } from '@stores/bookmarkStore'
import { Bookmark, BookmarkContext, createBookmark } from '@stores/bookmarkStore/utils'
import { BookmarkPageEnum, CreateBookmarkExtraData } from '@stores/bookmarkStore/utils/types'
import { BookmarkModalDelete } from './BookmarkModalDelete'
import { BookmarkModalSave } from './BookmarkModalSave'

type BookmarkButtonProps = {
  page: BookmarkPageEnum
  context?: BookmarkContext
  extraData?: CreateBookmarkExtraData
}

export const BookmarkButton = ({ page, context, extraData }: BookmarkButtonProps) => {
  const featureEnabled = useFlag(Flags.ENABLE_FAVORITES)
  const { setShow } = useBookmarkStore((state) => ({
    setShow: state.setShow,
  }))
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [alreadyFavorite, setAlreadyFavorite] = useState(false)

  const { fetch: apiFetch } = useApi()
  const bookmark = useMemo(
    () => (context ? createBookmark(page, context as any, extraData) : null),
    [page, context, extraData],
  )

  const remoteBookmark = useQuery<Bookmark>({
    queryKey: [page, bookmark?.checksum],
    queryFn: () => apiFetch(`/app/userbookmarks/by_checksum?checksum=${bookmark?.checksum}`),
    enabled: !!featureEnabled && page != null && bookmark?.checksum != null,
  })

  const canAddToFavorite = context && remoteBookmark.isSuccess && !remoteBookmark.data
  const checkingFavorite = context && remoteBookmark.isLoading

  useEffect(() => {
    const newAlreadyFavorite = context && remoteBookmark.isSuccess && remoteBookmark.data
    if (newAlreadyFavorite !== alreadyFavorite && !remoteBookmark.isRefetching) {
      setAlreadyFavorite(!!newAlreadyFavorite)
    }
  }, [context, remoteBookmark.isSuccess, remoteBookmark.data, alreadyFavorite, remoteBookmark.isRefetching])

  if (!featureEnabled) return null
  return (
    <>
      <Button
        variant="outline"
        onClick={() => {
          if (canAddToFavorite) setShowSaveModal(true)
          else if (alreadyFavorite) setShowDeleteModal(true)
        }}
        disabled={!context || checkingFavorite}
        className="bg-white dark:bg-transparent dark:border-gray-800 dark:text-gray-300 dark:hover:bg-gray-800 group-[.pdf-report]:hidden"
      >
        {alreadyFavorite ? (
          <StarIconFill className="w-4 h-4 text-yellow-300" />
        ) : (
          <StarIcon className="w-4 h-4 stroke-2" />
        )}
      </Button>

      <BookmarkModalSave
        bookmark={bookmark}
        remoteBookmark={remoteBookmark}
        show={showSaveModal}
        setShow={setShowSaveModal}
        onClose={() => {
          setShowSaveModal(false)
          setShow(true)
        }}
        setAlreadyFavorite={setAlreadyFavorite}
      />

      <BookmarkModalDelete
        remoteBookmark={remoteBookmark}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        onClose={() => {
          setShowDeleteModal(false)
        }}
        setAlreadyFavorite={setAlreadyFavorite}
      />
    </>
  )
}
