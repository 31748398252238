import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { createControlPanelSlice } from '@stores/slices/controlPanelSlice'
import { FilterField, TourMarketingControlPanelStoreState } from './tourMarketingControlPanelStoreTypes'

export const useTourMarketingControlPanelStore = create<TourMarketingControlPanelStoreState>()(
  immer(
    devtools((set, get, store) => ({
      ...createControlPanelSlice<FilterField>(set, get, store),

      lastTouchedFilterKey: null,
      setLastTouchedFilterKey: (lastTouchedFilterKey) => set({ lastTouchedFilterKey }),
    })),
  ),
)
