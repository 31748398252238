import { App } from '@config/site/app'
import { Page } from '@config/site/app/pages'

export const MainNav: Partial<Record<App, Page[]>> = {
  [App.STAR]: [
    Page.OVERVIEW,
    Page.EVENT_INSIGHTS,
    Page.ARTIST_VENUE_INSIGHTS,
    Page.UPCOMING_EVENTS,
    Page.PREDICTION_ASSESSMENT,
  ],
  [App.MUSIC]: [Page.PREDICTION_ASSESSMENT],
}
