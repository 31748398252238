import { useCallback, useEffect, useMemo, useState } from 'react'
import { EChartsOption, SeriesOption } from 'echarts'
import { useTranslation } from 'react-i18next'
import { useChartCardData } from '@components/ChartCard'
import { EChartsComponent, getDefaultChartColors } from '@components/EChartsComponent'
import { useTheme } from '@contexts/Theme'
import { BinPriceTierData } from '@types'
import { formatUtils } from '@utils'
import { getChartStyle, showAnimation, showSlider, yAxisFormatter } from '@utils/charts'

interface TicketsSoldByPriceAndTierChartProps {
  seriesData?: BinPriceTierData[]
  tierBinSize: number
  showTotal?: boolean
  showAvgKpis?: boolean
}
const generateBarDataArray = (data: BinPriceTierData, bins: number[]): number[] =>
  bins.map((bin) => (bin === data.bin ? data.quantity : null)) as number[]

export const TicketsSoldByTierAndPriceChart = ({
  seriesData = [],
  tierBinSize,
  showTotal = false,
  showAvgKpis = false,
}: TicketsSoldByPriceAndTierChartProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const { setData: setDownloadableData } = useChartCardData()
  const { theme } = useTheme()
  const { t } = useTranslation('quantity_sold_by_price_tier')

  const total = useMemo(() => {
    return showTotal ? seriesData.reduce((acc, v) => acc + +v.quantity, 0) : null
  }, [showTotal, seriesData])

  const getChartOption = useCallback(async () => {
    const bins = [...new Set(seriesData.map((data) => data.bin))].sort((a, b) => a - b)

    const series: SeriesOption[] = seriesData.map((data) => ({
      name: `${data.tier} ($${data.price_total})`,
      data: generateBarDataArray(data, bins),
      stack: 'total',
      type: 'bar',
      emphasis: {
        focus: 'series',
        label: {
          show: true,
          formatter: '{a}',
          position: 'top',
        },
      },
    }))

    const color = getDefaultChartColors(theme)
    setChartOption({
      xAxis: {
        name: t('quantity_sold_by_price_tier_chart.tier_price'),
        nameLocation: 'middle',
        nameGap: 30,
        type: 'category',
        data: bins.map((v) => `${formatUtils.formatCurrency(v)} - ${formatUtils.formatCurrency(v + tierBinSize)}`),
      },
      color,
      yAxis: showTotal
        ? [
            {
              type: 'value',
              offset: 0,
              axisLabel: {
                show: true,
                formatter: yAxisFormatter,
              },
            },
            {
              name: t('quantity_sold_by_price_tier_chart.ticket_count_total', {
                value: formatUtils.formatNumber(total),
              }),
              type: 'value',
              offset: 0,
              axisLabel: {
                show: false,
              },
              nameTextStyle: {
                align: 'right',
              },
            },
          ]
        : {
            name: t('quantity_sold_by_price_tier_chart.ticket_count'),
            type: 'value',
            offset: 0,
            axisLabel: {
              show: true,
              formatter: yAxisFormatter,
            },
          },
      grid: {
        left: '8%',
        right: '3%',
        bottom: 95,
        top: '7%',
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          if (Array.isArray(params)) {
            const showParams = params.filter((p) => p.value != null)
            const total = showParams.reduce((acc, p) => acc + +p.value, 0)
            return `<b>${t('quantity_sold_by_price_tier_chart.ticket_price_tier', {
              value: showParams?.[0]?.name,
            })}</b><br/>${showParams
              .map((p) => `${p.marker} ${p.seriesName}: ${formatUtils.formatNumber(p.value as number)}`)
              .join('<br/>')} <br/><br/><b>${t('quantity_sold_by_price_tier_chart.total', {
              value: formatUtils.formatNumber(total),
            })}</b>`
          }

          const data = params.seriesIndex != null ? seriesData[params.seriesIndex] : undefined
          const avgKpis = showAvgKpis
            ? `${t('quantity_sold_by_price_tier_chart.avg_days_listed')}: ${formatUtils.formatNumber(
                data?.avg_number_of_days_listed,
              )}
            <br/>
            ${t('quantity_sold_by_price_tier_chart.avg_price_changes')}: ${formatUtils.formatNumber(
                data?.avg_number_of_price_changes,
              )}`
            : ''

          return `${data?.tier} ($${data?.price_total})<br/>
            <div margin-bottom: 1rem'>
              ${params.marker}${params.name}
            </div>
            ${t(
              'quantity_sold_by_price_tier_chart.ticket_quantity',
            )}: <span style='font-weight: bold'>${formatUtils.formatNumber(data?.quantity)}</span><br/>
            ${avgKpis}`
        },
      },
      series,
      dataZoom: [
        {
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
        },
      ],
      animation: showAnimation(),
    })

    setDownloadableData([
      [
        t('quantity_sold_by_price_tier_chart.tier_price_csv'),
        t('quantity_sold_by_price_tier_chart.ticket_quantity'),
        ...(showAvgKpis
          ? [
              t('quantity_sold_by_price_tier_chart.avg_days_listed'),
              t('quantity_sold_by_price_tier_chart.avg_price_changes'),
            ]
          : []),
      ],
      ...seriesData.map((item) => [
        `${item.tier} ($${item.price_total})`,
        `${item.quantity}`,
        ...(showAvgKpis ? [`${item.avg_number_of_days_listed}`, `${item.avg_number_of_price_changes}`] : []),
      ]),
    ])
  }, [seriesData, theme, t, showTotal, total, setDownloadableData, tierBinSize, showAvgKpis])

  useEffect(() => {
    getChartOption()
  }, [getChartOption])

  if (!chartOption) return null

  return <EChartsComponent option={chartOption} style={getChartStyle()} />
}
