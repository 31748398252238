import { useContext } from 'react'
import { VisualizationEvaluationContext } from '@contexts/VisualizationEvaluation/context'
import { VisualizationType } from '@contexts/VisualizationEvaluation/types'

export const useVisualizationEvaluation = (type: VisualizationType) => {
  const ctx = useContext(VisualizationEvaluationContext)
  return {
    openEvaluation: (score: number) => ctx.openEvaluation(type, score),
  }
}
