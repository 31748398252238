import { DateRangeInputOption } from 'carbonarc-ui'
import cloneDeep from 'lodash/cloneDeep'
import qs from 'qs'
import { Item } from '@components/RemoteSelect'
import { TourPlanningContext } from '@stores/bookmarkStore/utils/types'
import { SingleEventFilter } from '@stores/singleEventStore'
import { dateUtils } from '@utils'
import { updateSearchParamQuery } from '@utils/url'
import { Filters as PageFilters, defaultFilters } from './tourPlanningStoreTypes'

export const queryParamsToContext = (searchParams: string | URLSearchParams): TourPlanningContext => {
  const paramsData = qs.parse(searchParams.toString(), {
    parseArrays: true,
  })
  const filters: PageFilters = {
    artist: (paramsData.artist as Item[]) || defaultFilters.artist,
    showType: (paramsData.showType as Item[]) || defaultFilters.showType,
    region: (paramsData.region as Item[]) || defaultFilters.region,
    venue: (paramsData.venue as Item[]) || defaultFilters.venue,
    since: paramsData.since ? new Date(paramsData.since as string) : defaultFilters.since,
    until: paramsData.until ? new Date(paramsData.until as string) : defaultFilters.until,
    dateRangeOption: (paramsData.dateRangeOption as DateRangeInputOption) || defaultFilters.dateRangeOption,
  }
  if (filters.artist && !Array.isArray(filters.artist)) filters.artist = [filters.artist]
  if (filters.showType && !Array.isArray(filters.showType)) filters.showType = [filters.showType]
  if (filters.region && !Array.isArray(filters.region)) filters.region = [filters.region]
  if (filters.venue && !Array.isArray(filters.venue)) filters.venue = [filters.venue]

  if (filters.dateRangeOption && filters.dateRangeOption?.value !== 'custom') {
    filters.dateRangeOption.value = +filters.dateRangeOption.value
  }
  const singleEventFilter =
    'singleEventFilter' in paramsData ? (paramsData.singleEventFilter as SingleEventFilter) : null

  let selectedEvents: number[] = []
  if ('selectedEvents' in paramsData) {
    selectedEvents = Array.isArray(paramsData.selectedEvents)
      ? (paramsData.selectedEvents as string[]).map((selectedEventIds) => +selectedEventIds)
      : [+(paramsData.selectedEvents as string)]
  }
  return { filters, selectedEvents, singleEventFilter }
}

export const contextToQueryParams = (context: TourPlanningContext) => {
  const { selectedEvents, filters, singleEventFilter } = context
  const filtersToParams = cloneDeep(filters) as any
  if (filtersToParams.dateRangeOption === null) {
    delete filtersToParams['dateRangeOption']
    delete filtersToParams['since']
    delete filtersToParams['until']
  }
  let params = filtersToQueryParams(filtersToParams, true).toString()

  if (selectedEvents) {
    params = updateSearchParamQuery(params, 'selectedEvents', selectedEvents)
  }
  if (singleEventFilter) {
    params = updateSearchParamQuery(params, 'singleEventFilter', singleEventFilter)
  }

  return params
}

export const filtersToQueryParams = (filters: PageFilters, complete = false) => {
  if (complete) return qs.stringify(filters)

  const { artist, showType, region, venue, since, until, dateRangeOption } = filters
  const urlSearchParams = new URLSearchParams()

  artist?.forEach((artist) => urlSearchParams.append('artist', artist.id))
  region?.forEach((region) => urlSearchParams.append('region', region.id))
  venue?.forEach((venue) => urlSearchParams.append('venue', venue.id))
  showType?.forEach((showType) => urlSearchParams.append('show_type', showType.id))
  if (since) urlSearchParams.append('since', since.toISOString().split('T')[0])
  if (until) urlSearchParams.append('until', until.toISOString().split('T')[0])
  urlSearchParams.append('dateRangeOption', qs.stringify(dateRangeOption))

  return urlSearchParams
}

export const listToQueryParam = (name: string, selectedEvents: number[]) => {
  const urlSearchParams = new URLSearchParams()

  selectedEvents?.forEach((id) => urlSearchParams.append(name, id.toString()))

  return urlSearchParams
}

const addDefaultFilters = (filters: PageFilters): PageFilters => {
  let { since, until } = filters
  if (!since && !until) {
    // default to last 12 months when no date range specified
    until = dateUtils.subtractDays(dateUtils.getToday(), 1)
    since = dateUtils.subtractYears(dateUtils.getToday(), 1)
  }
  return { ...filters, since, until }
}

export const filtersToQueryParamsWithDefaults = (filters: PageFilters) =>
  filtersToQueryParams(addDefaultFilters(filters)) as URLSearchParams
