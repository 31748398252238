import { Card } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { Bars4Icon } from '@heroicons/react/24/outline'
import { useUpcomingEventsStore } from '@stores/upcomingEventsStore'

export function NoUpcomingEventsCard() {
  const { filterButtonRef } = useUpcomingEventsStore((state) => ({ filterButtonRef: state.filterButtonRef }))
  const { t } = useTranslation('upcoming_events')

  return (
    <Card
      theme={{
        root: {
          children: 'flex h-full flex-col justify-center gap-4 p-6 flex-1',
        },
      }}
      className="flex-1"
    >
      <div className="flex flex-col items-center justify-center text-center">
        <div className="bg-gray-50 text-gray-200 flex items-center justify-center p-4 rounded-full w-24 h-24 mb-4 dark:bg-gray-700 dark:text-gray-400">
          <Bars4Icon className="w-10 h-10" />
        </div>
        <p className="mb-2 text-sm font-normal text-gray-500 w-[205px] dark:text-gray-200">{t('no_upcoming_events')}</p>
        <p
          className="text-sm text-blue-700 font-medium cursor-pointer dark:text-blue-500"
          onClick={() => {
            if (filterButtonRef.current) {
              filterButtonRef.current.click()
            }
          }}
        >
          {t('please_update_your_filters')}
        </p>
      </div>
    </Card>
  )
}
