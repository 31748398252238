import { useSearchParamsObject } from '@hooks/useSearchParamsObject'

export const ExportComment = () => {
  const { searchParams } = useSearchParamsObject()
  return (
    <div className="flex flex-col gap-2 mb-6 md:mb-8 hidden group-[.pdf-report-comment]:flex">
      <h2 className="text-xl md:text-3xl font-semibold dark:text-white">Agents comments</h2>
      <h3 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400" id="pdf-comment">
        {searchParams.get('pdf-report-comment')}
      </h3>
    </div>
  )
}
