import { BenchmarkOption } from '@components/EventContent'
import { CustomEventFilterGroups } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard/CustomEventsBenchmark'
import { ApiFetch } from '@services/api'
import { CompetitorFilterField, CompetitorFilters } from '@stores/slices/eventDataSlice/competingEvents/types'
import { PaginatedSliceState } from '@stores/slices/paginatedSlice/paginatedSliceTypes'
import { SliceStateCreator } from '@stores/slices/types'
import { Filters } from '@stores/tourMarketingStore'
import {
  CompetitorEntity,
  CompetitorSummaryData,
  SecondaryMarketPriceAndTierData,
  SecondaryMarketTimeSeriesData,
  SecondaryMarketTimeSeriesInventoryBySectionData,
  SecondaryMarketTimeSeriesInventoryData,
  SecondaryMarketTimeSeriesInventoryDataV2,
  SecondaryMarketTimeSeriesInventoryDataV3,
  StarEventSummaryEntity,
  TimeSeriesEntityWithEvent,
} from '@types'
import { StoreSetFunction } from '@utils/zustand'

export type EventFilter = {
  artist: string
  venue: string
  date: string
}

export type BenchmarkDataFilter = {
  option: BenchmarkOption
  events?: CustomEventFilterGroups
}

export enum SecondaryMarketChartType {
  StackedBarChart = 'stackedBarChart',
  BoxPlot = 'boxPlot',
  ScatterPlot = 'scatterPlot',
  TimeSeries = 'timeSeries',
  TimeSeriesInventory = 'timeSeriesInventory',
  Table = 'table',
}

export const DEFAULT_SECONDARY_MARKET_CHART_TYPE = SecondaryMarketChartType.StackedBarChart

export interface EventDataSlice<AdditionalParamsOnInternalChange> {
  timeSeriesEntity: TimeSeriesEntityWithEvent[]
  setTimeSeriesEntity: (timeSeriesData: TimeSeriesEntityWithEvent[]) => void

  secondaryMarketTimeSeriesData: SecondaryMarketTimeSeriesData[]
  setSecondaryMarketTimeSeriesData: (secondaryMarketTimeSeriesData: SecondaryMarketTimeSeriesData[]) => void

  secondaryMarketTimeSeriesInventoryData: SecondaryMarketTimeSeriesInventoryData[]
  setSecondaryMarketTimeSeriesInventoryData: (
    secondaryMarketTimeSeriesInventoryData: SecondaryMarketTimeSeriesInventoryData[],
  ) => void

  secondaryMarketTimeSeriesInventoryDataV2: SecondaryMarketTimeSeriesInventoryDataV2
  setSecondaryMarketTimeSeriesInventoryDataV2: (
    secondaryMarketTimeSeriesInventoryData: SecondaryMarketTimeSeriesInventoryDataV2,
  ) => void

  secondaryMarketTimeSeriesInventoryDataV3: SecondaryMarketTimeSeriesInventoryDataV3
  setSecondaryMarketTimeSeriesInventoryDataV3: (
    secondaryMarketTimeSeriesInventoryData: SecondaryMarketTimeSeriesInventoryDataV3,
  ) => void

  secondaryMarketMaxDayFromEvent: number | undefined
  setSecondaryMarketMaxDayFromEvent: (value: number | undefined) => void

  secondaryMarketMinDayFromEvent: number | undefined
  setSecondaryMarketMinDayFromEvent: (value: number | undefined) => void

  secondaryMarketTimeSeriesInventoryBySectionData: SecondaryMarketTimeSeriesInventoryBySectionData[]
  setSecondaryMarketTimeSeriesInventoryBySectionData: (
    secondaryMarketTimeSeriesInventoryData: SecondaryMarketTimeSeriesInventoryBySectionData[],
  ) => void

  secondaryMarketPriceAndTierData: SecondaryMarketPriceAndTierData[]
  setSecondaryMarketPriceAndTierData: (secondaryMarketPriceAndTierData: SecondaryMarketPriceAndTierData[]) => void

  secondaryMarketChartType: SecondaryMarketChartType
  setSecondaryMarketChartType: (
    secondaryMarketChartType: SecondaryMarketChartType,
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void

  eventSummaryData: StarEventSummaryEntity | null
  setEventSummaryData: (eventSummaryData: StarEventSummaryEntity | null) => void

  customEvents: CustomEventFilterGroups
  isSelectingCustomEvents: boolean
  setCustomEvents: (
    customEvents: CustomEventFilterGroups,
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void

  selectedBenchmark: BenchmarkOption | null
  setSelectedBenchmark: (
    benchmark: BenchmarkOption | null,
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void

  multipleCustomTimeSeriesEntities: TimeSeriesEntityWithEvent[][]
  fetchMultipleCustomTimeSeriesData: () => void

  competitorsSummaryLoading: boolean
  competitorsFilters: CompetitorFilterField[]
  setCompetitorsFilters: (
    filters: CompetitorFilterField[],
    additionalParamsForInternalChange: AdditionalParamsOnInternalChange,
  ) => void
  getCompetitorsFilterContext: () => CompetitorFilters
  competitorsSummaryData: CompetitorSummaryData[]
  setCompetitorsSummaryData: (competitorsSummaryData: CompetitorSummaryData[]) => void

  competitors: PaginatedSliceState<CompetitorEntity, CompetitorFilters>

  resetDataSlice: () => void
}

export type EventDataSliceStateCreator<AdditionalParamsOnInternalChange> = SliceStateCreator<
  EventDataSlice<AdditionalParamsOnInternalChange>
>

export type EventDataProps<AdditionalParamsOnInternalChange> = {
  getApiFetch(): ApiFetch
  getFilterContext: () => Filters | undefined
  onSetCompetitorsFilters?: (additionalParamsForInternalChange: AdditionalParamsOnInternalChange) => void
  onSetBenchmark?: (additionalParamsForInternalChange: AdditionalParamsOnInternalChange) => void
  onSetCustomEvents?: (additionalParamsForInternalChange: AdditionalParamsOnInternalChange) => void
  onSetSecondaryMarket?: (additionalParamsForInternalChange: AdditionalParamsOnInternalChange) => void
}

export type EventDataSliceStoreSetFunction<AdditionalParamsOnInternalChange> = StoreSetFunction<
  EventDataSlice<AdditionalParamsOnInternalChange>
>
