import { useCallback, useEffect, useState } from 'react'
import { Button } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { SingleEventFilterFields } from '@components/EventContent/SingleEventFilterFields'
import { Selected } from '@components/Inputs'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { FilterField, FilterKey } from '@stores/tourMarketingStore/tourMarketingControlPanelStoreTypes'
import { cn } from '@utils/className'

type Filters = FilterField[]
export type CustomEventFilterGroups = Filters[]

const areFiltersValid = (filters: CustomEventFilterGroups) => {
  // Filters are not valid if at least one does not have all fields fulfilled

  let hasOneCompleteFilter = false
  let hasOneIncompleteFilter = false

  filters.forEach((filter) => {
    if (filter.length === 0) return

    const everyFilterIsFulfilled = filter.length === 4 && filter.every((filter) => !!filter?.data?.id)
    const someFilterIsNotFulfilled = filter.length !== 4 || filter.some((filter) => !filter?.data?.id)

    if (everyFilterIsFulfilled) {
      hasOneCompleteFilter = true
      return
    }

    if (someFilterIsNotFulfilled) {
      hasOneIncompleteFilter = true
      return
    }
  })

  return hasOneCompleteFilter && !hasOneIncompleteFilter
}

interface CustomEventsBenchmarkProps {
  handleSelected: (filters: CustomEventFilterGroups) => void
  customEvents: CustomEventFilterGroups
}

interface EventFiltersProps {
  filters: Filters
  handleRemove: () => void
  label: string
}

function Event({ label, filters, handleRemove }: EventFiltersProps) {
  const { t } = useTranslation('common')
  return (
    <div className="flex items-center gap-2 mx-2">
      <h5 className="text-base md:text-md font-bold text-gray-900 dark:text-white">{label}</h5>
      <Selected className="mt-0 max-w-fit" items={filters.map((filter) => filter.data)} />
      <Button
        variant="outline"
        size="sm"
        onClick={handleRemove}
        disabled={filters.length === 0}
        className={cn(
          'flex gap-2 text-blue-700 dark:text-blue-500 border-blue-700 dark:border-blue-500',
          filters.length === 0 ? 'hidden' : '',
          'print:hidden',
        )}
      >
        <XMarkIcon className="w-4 h-4 stroke-1.5" />
        {t('clear')}
      </Button>
    </div>
  )
}

export function CustomEventsBenchmark(props: CustomEventsBenchmarkProps) {
  const { handleSelected, customEvents } = props
  const [lastTouchedFilterKey, setLastTouchedFilterKey] = useState<FilterKey | null>(null)

  const setFiltersFromCustomEvents = (customEvents: CustomEventFilterGroups) =>
    customEvents.length ? customEvents.filter((customEvent) => customEvent.length) : []

  const [events, setEvents] = useState<CustomEventFilterGroups>(setFiltersFromCustomEvents(customEvents))
  const [newEventToAdd, setNewEventToAdd] = useState<Filters>([])

  useEffect(() => {
    setEvents(setFiltersFromCustomEvents(customEvents))
  }, [customEvents])

  const apply = useCallback(
    (events: CustomEventFilterGroups) => {
      if (events.length && !areFiltersValid(events)) return
      handleSelected(events)
      setEvents(events)
    },
    [handleSelected, setEvents],
  )

  const handleAddEvent = useCallback(() => {
    apply([...events, newEventToAdd])
    setNewEventToAdd([])
  }, [events, newEventToAdd, setNewEventToAdd, apply])

  const handleRemoveEvent = useCallback(
    (filterIndex: number) => {
      apply(events.filter((_, index) => index !== filterIndex))
    },
    [apply, events],
  )
  return (
    <div className="flex flex-col w-full gap-2 my-4">
      <h4 className="text-base md:text-l font-semibold text-gray-900 dark:text-white">Compare against custom events</h4>
      <div className="flex flex-col gap-4 items-stretch font-bold md:flex-row w-full">
        <SingleEventFilterFields
          isLoading={false}
          showLabel={false}
          labelContainerClassName="p-0 w-full"
          filters={newEventToAdd}
          setFilters={setNewEventToAdd}
          lastTouchedFilterKey={lastTouchedFilterKey}
          setLastTouchedFilterKey={setLastTouchedFilterKey}
          defaultParams={{ exclude_empty: 'true', include_past: 'true' }}
        />
        <Button
          variant="default"
          onClick={handleAddEvent}
          disabled={!areFiltersValid([newEventToAdd])}
          className="mt-2 w-[350px]"
        >
          Add Event
        </Button>
      </div>
      {events.map((eventFilters, index) => (
        <Event
          label={`Event ${index + 1}`}
          filters={eventFilters}
          handleRemove={() => {
            handleRemoveEvent(index)
          }}
        />
      ))}
    </div>
  )
}
