import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'

type Params = URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit)

export const useSearchParamsObject = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  return {
    searchParams,
    setSearchParams,
    replaceSearchParams: (params: Params) => setSearchParams(params, { replace: true }),
  }
}
