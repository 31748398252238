import { useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { TourMarketingContext } from './tourMarketingStoreContext'
import { TourMarketingStoreState } from './tourMarketingStoreTypes'

export function useTourMarketingStore<T>(
  selector: (state: TourMarketingStoreState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(TourMarketingContext)
  if (!store) throw new Error('Missing Provider in the tree')

  return useStoreWithEqualityFn(store, selector, equalityFn)
}
