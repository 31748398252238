import { useEffect, useMemo, useRef } from 'react'
import { usePrevious } from 'carbonarc-ui'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { BookmarkButton } from '@components/Bookmarks/BookmarkButton'
import { CopyReportUrlButton } from '@components/Bookmarks/CopyReportUrlButton'
import { CheckboxOption, ExportButton, ExportComment } from '@components/Export'
import { Footer } from '@components/Footer'
import { Generate } from '@components/PredictionAssesmentPage/Generate'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { usePredictionAssessmentStore } from '@stores/predictionAssessmentStore'
import { exportComponentToPDF, hasDownloadPDFUrlParam } from '@utils/pdf'

export default function PredictionAssessmentPage() {
  const contentRef = useRef<HTMLElement>(null)
  const { t } = useTranslation('prediction_assessment')
  const searchParamsHandler = useSearchParamsObject()
  const previousSearchParams = usePrevious(searchParamsHandler.searchParams)
  const {
    isLoading,
    filterContext,
    stringfiedFilterContext,
    predictionsBackTest,
    generateData,
    getContext,
    pdfHeader,
    loadData,
    urlChanged,
  } = usePredictionAssessmentStore((state) => ({
    isLoading: state.isLoading,
    filterContext: state.filterContext,
    stringfiedFilterContext: state.stringfiedFilterContext,
    predictionsBackTest: state.predictionsBackTest,
    generateData: state.generateData,
    getContext: state.getContext,
    pdfHeader: state.pdfHeader,
    loadData: state.loadData,
    urlChanged: state.urlChanged,
  }))

  const context = getContext()

  useEffect(() => {
    if (!isLoading && !!pdfHeader && hasDownloadPDFUrlParam(BookmarkPageEnum.PREDICTION_ASSESSMENT)) {
      exportComponentToPDF({
        component: contentRef.current,
        filename: `${t('title')}${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`,
        header: pdfHeader,
      })
    }
  }, [t, isLoading, stringfiedFilterContext, pdfHeader, contentRef])

  useEffectOnce(() => {
    loadData(searchParamsHandler)
  })

  useEffect(() => {
    if (previousSearchParams !== searchParamsHandler.searchParams) urlChanged(searchParamsHandler)
  }, [previousSearchParams, searchParamsHandler.searchParams, urlChanged, searchParamsHandler])

  const exportOptions: CheckboxOption[] = useMemo(() => {
    return [
      {
        id: 'pdf-report-hide-generate',
        label: t('generate.title'),
        disabled: !generateData,
      },
      //   {
      //     id: 'pdf-report-hide-back-test',
      //     label: t('back_test.title'),
      //     disabled: (predictionsBackTest || [])?.length === 0,
      //   },
    ]
  }, [t, predictionsBackTest, generateData])

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('title')} />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-transparent min-h-[calc(100vh-var(--main-nav-height))]">
        <section
          className="px-4 pt-6 md:p-8 !pb-0 h-full w-full max-w-[1232px] flex-1 flex flex-col gap-8 group-[.pdf-report]:bg-white group-[.pdf-report]:dark:bg-gray-700 group-[.pdf-report]:p-0"
          ref={contentRef}
        >
          <div className="flex flex-col-reverse items-start md:flex-row md:items-center justify-between gap-4">
            <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white">{t('title')}</h1>
            <div className="flex gap-2">
              <ExportButton
                isLoading={isLoading}
                pageId={BookmarkPageEnum.PREDICTION_ASSESSMENT}
                options={exportOptions}
              />
              <CopyReportUrlButton disabled={!filterContext} />
              <BookmarkButton page={BookmarkPageEnum.PREDICTION_ASSESSMENT} context={context} />
            </div>
          </div>
          <ExportComment />

          <Generate />
          {/* <BackTest /> */}
        </section>
        <Footer />
      </div>
    </>
  )
}
