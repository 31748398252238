import { Item } from '@components/RemoteSelect'
import { Filters } from '@stores/upcomingEventsStore'
import { dateUtils } from '@utils'

type FilterToQueryParamsOptions = {
  page: number
  isPrevious: boolean
}

const defaultFilterToQueryParamsOptions: FilterToQueryParamsOptions = {
  page: 1,
  isPrevious: false,
}

export const MY_BRAND_ID = 'my'

export const MY_BRAND_QUERY_PARAM = 'include_my_brands'

export const filtersToQueryParams = (filters: Filters, options?: Partial<FilterToQueryParamsOptions>) => {
  const config: FilterToQueryParamsOptions = {
    ...defaultFilterToQueryParamsOptions,
    ...options,
  }
  const { artist, venue, region } = filters
  let { since, until } = filters
  const urlSearchParams = new URLSearchParams()

  const isAllOptionSelected = (data: Item[]) => data?.map((d) => d.id).includes('0')

  if (!isAllOptionSelected(artist)) {
    if (artist?.map((a) => a.id).includes(MY_BRAND_ID)) {
      urlSearchParams.append(MY_BRAND_QUERY_PARAM, 'true')
    }
    artist?.filter((opt) => opt.id !== MY_BRAND_ID).forEach((artist) => urlSearchParams.append('artist', artist?.id))
  }

  if (!isAllOptionSelected(venue)) {
    venue?.forEach((venue) => urlSearchParams.append('venue', venue?.id))
  }

  if (!isAllOptionSelected(region)) {
    region?.forEach((region) => urlSearchParams.append('region', region?.id))
  }

  if (since && until) {
    if (config.isPrevious) {
      until = dateUtils.subtractYears(until, 1)
      since = dateUtils.subtractYears(since, 1)
    }

    urlSearchParams.append('since', since.toISOString().split('T')[0])
    urlSearchParams.append('until', until.toISOString().split('T')[0])
  }

  urlSearchParams.append('page', config.page.toString())
  urlSearchParams.append('size', '5')

  return urlSearchParams
}
