import { useState } from 'react'
import { TextInput, useTheme } from 'carbonarc-ui'
import { usePrevious } from '@hooks'
import * as Slider from '@radix-ui/react-slider'
import { cn } from '@utils/className'
import { formatNumber } from '@utils/format'

type RangeSliderProps = {
  min?: number
  max?: number
  value: [number | undefined, number | undefined]
  onChange: (value: [number, number]) => void
  disabled?: boolean
  displayInputs?: boolean
}

const defaultMin = 0
const defaultMax = 100

export const RangeSlider = (props: RangeSliderProps) => {
  const [innerValue, setInnerValue] = useState<[number, number]>([
    props.value[0] || props.min || defaultMin,
    props.value[1] || props.max || defaultMax,
  ])
  const displayInputs = props.displayInputs ?? true
  const inputTheme = useTheme().theme.textInput
  const { min = defaultMin, max = defaultMax } = props
  const previousMin = usePrevious(min)
  const previousMax = usePrevious(max)
  const [minValue, maxValue] = innerValue

  let newMinValue = minValue
  let newMaxValue = maxValue
  if (min !== previousMin && (min > minValue || props.value[0] == null)) {
    newMinValue = min
  }
  if (max !== previousMax && (max < maxValue || props.value[1] == null)) {
    newMaxValue = max
  }

  if (minValue !== newMinValue || maxValue !== newMaxValue) {
    setInnerValue([newMinValue, newMaxValue])
  }

  const textInputTheme = {
    field: {
      input: {
        withAddon: {
          off: cn(inputTheme.field.input.withAddon.off, 'font-normal'),
        },
      },
    },
  }

  return (
    <div>
      <Slider.Root
        min={props.min}
        max={props.max}
        disabled={props.disabled}
        value={innerValue}
        onValueChange={(args) => setInnerValue(args as [number, number])}
        onValueCommit={props.onChange}
        className="SliderRoot"
      >
        <Slider.Track className="SliderTrack">
          <Slider.Range className="SliderRange" />
        </Slider.Track>
        <Slider.Thumb className="SliderThumb" />
        <Slider.Thumb className="SliderThumb" />
      </Slider.Root>
      {displayInputs && (
        <div className="flex flex-row gap-2 mt-3 items-center">
          <TextInput type="text" value={formatNumber(innerValue[0])} theme={textInputTheme} readOnly />
          <div className="font-normal text-sm">to</div>
          <TextInput type="text" value={formatNumber(innerValue[1])} theme={textInputTheme} readOnly />
        </div>
      )}
    </div>
  )
}
