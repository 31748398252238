import type { DateRangeInputOption } from 'carbonarc-ui'
import { DateRangeInput } from 'carbonarc-ui'
import { dateUtils } from '@utils'
import { Filters, getDefaultFilters } from './upcomingEventsStoreTypes'

const LOCALSTORAGE_KEY = 'session_upcomingEventsFilters'

export const saveLocalFilters = (filters: Filters) => {
  localStorage[LOCALSTORAGE_KEY] = JSON.stringify(filters)
}

export const getLocalFilters = (myBrands = false) => {
  const savedOptions = localStorage[LOCALSTORAGE_KEY]
  if (!savedOptions) return null

  const savedFilters = JSON.parse(savedOptions)

  const mergedOptions = { ...getDefaultFilters(myBrands), ...savedFilters }
  mergedOptions.since = mergedOptions.since ? new Date(mergedOptions.since) : null
  mergedOptions.until = mergedOptions.until ? new Date(mergedOptions.until) : null

  const resetDateRangeValues = () => {
    mergedOptions.dateRangeOption = null
    mergedOptions.since = null
    mergedOptions.until = null
  }

  const setCustomDateRange = () => {
    mergedOptions.dateRangeOption = { label: 'Custom', value: 'custom' }
  }

  const setPresetDateRange = (value: number) => {
    mergedOptions.dateRangeOption = DateRangeInput.defaultOptions.find((o: DateRangeInputOption) => o.value === value)
    if (!mergedOptions.dateRangeOption) resetDateRangeValues()
    else {
      const dates = dateUtils.generateIntervalFromToday(value)
      mergedOptions.since = dates.start
      mergedOptions.until = dates.end
    }
  }

  if (!dateUtils.isValidDateObject(mergedOptions.since)) mergedOptions.since = null
  if (!dateUtils.isValidDateObject(mergedOptions.until)) mergedOptions.until = null
  // Retrocompatible version
  if (mergedOptions.dateRangeOptionValue) {
    if (mergedOptions.dateRangeOptionValue === 'custom') {
      if (mergedOptions.since && mergedOptions.until) setCustomDateRange()
      else resetDateRangeValues()
    } else setPresetDateRange(mergedOptions.dateRangeOptionValue)
    delete mergedOptions.dateRangeOptionValue
  }
  // End retrocompatible version

  if (mergedOptions?.dateRangeOption?.value && mergedOptions.dateRangeOption.value !== 'custom')
    setPresetDateRange(mergedOptions.dateRangeOption.value)

  return mergedOptions
}

export const clearLocalFilters = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY)
}
