import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactComponent as UpcomingIcon } from '@assets/music/actions/upcoming-events-icon.svg'
import { ReactComponent as TicketIcon } from '@assets/ticket-icon.svg'
import { EventCard, StatCard } from '@components/UpcomingEventsPage'
import { usePages } from '@config/site/app'
import { Page } from '@config/site/app/pages'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useApiFetch } from '@services/api'
import { useUpcomingEventsStore } from '@stores/upcomingEventsStore'
import { cn } from '@utils/className'

export function UpcomingEvents() {
  const { t } = useTranslation('upcoming_events')
  const { isLoading, isLoadingStats, eventsData, eventsStatsData, previousEventsStatsData, loadData, setPage } =
    useUpcomingEventsStore((state) => ({
      isLoading: state.isLoading,
      isLoadingStats: state.isLoadingStats,
      currentPage: state.currentPage,
      eventsData: state.eventsData,
      eventsStatsData: state.eventsStatsData,
      previousEventsStatsData: state.previousEventsStatsData,
      loadData: state.loadData,
      applyFilters: state.applyFilters,
      setPage: state.setPage,
    }))
  const apiFetch = useApiFetch()

  useEffectOnce(() => {
    setPage(apiFetch, 1)
    loadData(apiFetch)
  })
  const { getPageData } = usePages()
  const pageData = getPageData(Page.UPCOMING_EVENTS)
  if (!pageData) return null
  const notLoadingAndData = !isLoading && !!eventsData?.items?.length
  return (
    <div
      className={cn(
        'bg-white dark:bg-gray-800 rounded-lg shadow-md basis-2/5',
        'flex flex-col',
        'overflow-auto max-h-[80vh]',
        'gap-4 p-6',
      )}
    >
      <div>
        <div className="flex gap-2 items-center justify-between">
          <Link
            to={pageData?.path}
            className="w-full flex flex-col items-start gap-6 rounded-lg border border-gray-200 dark:border-gray-700 shadow-md p-4 bg-white dark:bg-gray-800"
          >
            <div className="flex items-center flex-1">
              <div className="rounded-full w-16 h-16 bg-blue-100 text-blue-600 flex items-center justify-center mr-3 md:mr-6 shrink-0">
                <UpcomingIcon className="mx-auto h-10 w-10" />
              </div>
              <div className="">
                <div className="text-xl font-semibold text-gray-900 dark:text-white">{t('title')}</div>
                <div className="text-base font-normal text-gray-500 dark:text-gray-400">{t('short_description')}</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <StatCard
        size="sm"
        stat={{
          name: t('total_upcoming_events'),
          value: eventsStatsData?.total_upcoming_events,
          previousValue: previousEventsStatsData?.total_upcoming_events,
        }}
        isLoading={isLoadingStats}
        icon={UpcomingIcon}
        iconColor="text-orange-500"
        iconContainerColor="bg-orange-100"
      />
      <StatCard
        size="sm"
        stat={{
          name: t('total_tickets_sold'),
          value: eventsStatsData?.total_tickets_sold,
          previousValue: previousEventsStatsData?.total_tickets_sold,
        }}
        isLoading={isLoadingStats}
        icon={TicketIcon}
        iconColor="text-blue-500"
        iconContainerColor="bg-blue-100"
      />
      {isLoading &&
        Array(1)
          .fill(1)
          .map((_, index) => <EventCard key={index} isLoading />)}
      {notLoadingAndData && (
        <>
          {eventsData?.items?.map((event, index) => (
            <EventCard
              size="sm"
              key={`${index}-${event.brand?.id}-${event.venue?.id}-${event.performance_date}`}
              event={event}
            />
          ))}
        </>
      )}
      <Link to={pageData.path} className="self-center">
        <div className="flex items-center text-blue-600 dark:text-blue-500 text-sm font-medium hover:bg-gray-200 dark:hover:bg-slate-800 h-9  rounded-md">
          {t('view_more')} <ArrowRightIcon className="w-4 h-4 stroke-2 ml-1.5" />
        </div>
      </Link>
    </div>
  )
}
