import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { usePrevious } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { Input, InputProps } from '@components/Inputs/Input'
import { Selected } from '@components/Inputs/Select/Selected'
import { SelectionPanel } from '@components/Inputs/Select/SelectionPanel'
import { Combobox } from '@headlessui/react'
import { useDebounce, useOnClickOutside } from '@hooks'
import { useActiveElement } from '@hooks/useActiveElement'

export type Item = {
  id: string
  name: string
}

type SingleSelectProps = {
  value: Item | null
  onChange: (value: Item | null) => void
  multiple?: false
}

type MultipleSelectProps = {
  value: Item[]
  onChange: (value: Item[]) => void
  multiple: true
}

type ConditionalProps = SingleSelectProps | MultipleSelectProps

export type SelectProps = {
  items: Item[]
  searchMinimumLength?: number
  emptyMessage?: string
  limitTo?: number
  isLoading?: boolean
  isError?: boolean
  allOption?: Item
  onSearchInputValueChange?: (value: string) => void
  inputClassName?: string
  hideSelectedPanel?: boolean
  expandSelectionPanelTo?: HTMLElement
} & ConditionalProps &
  Omit<InputProps, 'hasSelected' | 'value' | 'onChange' | 'setOpen'>

export type SelectRef = {
  onRemove: undefined | ((item: Item) => void)
  clearInput: undefined | (() => void)
}

export const Select = forwardRef<SelectRef, SelectProps>((props, ref) => {
  const { t } = useTranslation('')
  const {
    id,
    value,
    placeholder = t('search'),
    disabled = false,
    icon,
    tabIndex,
    openOnClick = false,
    showRightArrow = false,
    preventReplace = false,
    onChange,
    items,
    searchMinimumLength = 0,
    emptyMessage = t('data_tips.no_results.title'),
    limitTo = 50,
    isLoading = false,
    isError = false,
    multiple = false,
    onSearchInputValueChange,
    allOption,
    inputRef,
    inputClassName,
    hideSelectedPanel = false,
    expandSelectionPanelTo,
  } = props

  const [openSelection, setOpenSelection] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState('')
  const searchQuery = useDebounce(searchInputValue)
  const previousSearchQuery = usePrevious(searchQuery)

  const containerRef = useRef<HTMLElement>(null)
  const { previousActiveElement } = useActiveElement()

  const clickOutside = useCallback(() => {
    setOpenSelection(false)
  }, [])

  useOnClickOutside({ ref: containerRef, handler: clickOutside })

  const singleValue = value as Item | null
  const multipleValue = value as Item[]

  const singleOnChange = useCallback(
    (item: Item | null) => {
      const externalOnChange = onChange as (value: Item | null) => void
      const isSameItem = singleValue?.id === item?.id

      if (isSameItem) {
        externalOnChange(null)
      } else {
        externalOnChange(item)
        setSearchInputValue('')
        setOpenSelection(false)
      }
    },
    [onChange, singleValue?.id],
  )

  const multipleOnChange = useCallback(
    (innerValue: Item[]) => {
      // remove even occurencies
      const evenIds: string[] = []
      innerValue.forEach((item) => innerValue.filter((i) => i.id === item.id).length % 2 === 0 && evenIds.push(item.id))
      innerValue = innerValue.filter((item) => !evenIds.includes(item.id))

      const externalOnChange = onChange as (value: Item[]) => void
      externalOnChange(innerValue)
    },
    [onChange],
  )

  const removeForSingle = useCallback(() => {
    singleOnChange(null)
  }, [singleOnChange])

  const removeItemForMultiple = useCallback(
    (item: Item) => multipleOnChange(multipleValue.filter((i) => i.id !== item.id)),
    [multipleOnChange, multipleValue],
  )

  const onRemoveSingle = disabled ? undefined : removeForSingle
  const onRemoveMultiple = disabled ? undefined : removeItemForMultiple

  useImperativeHandle(
    ref,
    () => ({
      onRemove: multiple ? onRemoveMultiple : onRemoveSingle,
      clearInput: () => {
        setSearchInputValue('')
        setOpenSelection(false)
      },
    }),
    [multiple, onRemoveMultiple, onRemoveSingle],
  )

  const shouldOpenOnFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>): boolean => {
      const previous = (event.relatedTarget as Node) || previousActiveElement
      if (!previous || !previous.nodeType) return false
      return !containerRef?.current?.contains(previous)
    },
    [previousActiveElement],
  )

  const shouldCloseOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>): boolean => {
      const previous = (event.relatedTarget as Node) || previousActiveElement
      if (!previous || !previous.nodeType) return false

      return !containerRef?.current?.contains(previous)
    },
    [previousActiveElement],
  )

  useEffect(() => {
    if (
      searchQuery.trim().length >= searchMinimumLength &&
      previousSearchQuery != null &&
      previousSearchQuery !== searchQuery &&
      searchQuery !== ''
    ) {
      setOpenSelection(true)
    }
  }, [searchMinimumLength, searchQuery, previousSearchQuery])

  useEffect(() => {
    if (searchInputValue.trim().length < searchMinimumLength) {
      setOpenSelection(false)
    }
  }, [searchMinimumLength, searchInputValue])

  useEffect(() => {
    onSearchInputValueChange?.(searchQuery)
  }, [searchQuery, onSearchInputValueChange])

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (searchInputValue.trim().length >= searchMinimumLength && shouldOpenOnFocus(event)) {
      setOpenSelection(true)
    }
  }

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (shouldCloseOnBlur(event)) {
      setOpenSelection(false)
    }
  }

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setOpenSelection(false)
    } else if (event.key === 'ArrowDown' && !openSelection) {
      setOpenSelection(true)
    }
  }

  const filteredItems =
    searchQuery === ''
      ? allOption?.id
        ? [allOption]
        : items
      : items
          .filter((item) =>
            item.name.toLowerCase().replace(/\s+/g, '').includes(searchQuery.toLowerCase().replace(/\s+/g, '')),
          )
          .slice(0, limitTo)

  return (
    <div className="relative w-full">
      {multiple ? (
        <Combobox value={multipleValue} onChange={multipleOnChange} disabled={disabled} multiple>
          <span ref={containerRef}>
            <Input
              id={id}
              value={searchInputValue}
              placeholder={placeholder}
              disabled={disabled}
              icon={icon}
              tabIndex={tabIndex}
              openOnClick={openOnClick}
              showRightArrow={showRightArrow}
              preventReplace={preventReplace}
              hasSelected={!!value}
              onChange={setSearchInputValue}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onKeyUp={handleOnKeyUp}
              setOpen={setOpenSelection}
              inputRef={inputRef}
              className={inputClassName}
            />
            <SelectionPanel
              selectedValue={multipleValue}
              items={filteredItems}
              searchValue={searchQuery}
              isLoading={isLoading}
              isError={isError}
              emptyMessage={emptyMessage}
              close={() => setOpenSelection(false)}
              open={openSelection}
              closeOnSelect={allOption?.id ? [allOption.id] : false}
              expandWidthTo={expandSelectionPanelTo}
            />
          </span>
          {!hideSelectedPanel && <Selected items={multipleValue} onRemove={onRemoveMultiple} />}
        </Combobox>
      ) : (
        <Combobox value={singleValue} onChange={singleOnChange} disabled={disabled}>
          <span ref={containerRef}>
            <Input
              id={id}
              value={searchInputValue}
              placeholder={placeholder}
              disabled={disabled}
              icon={icon}
              tabIndex={tabIndex}
              openOnClick={openOnClick}
              showRightArrow={showRightArrow}
              preventReplace={preventReplace}
              hasSelected={!!value}
              onChange={setSearchInputValue}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onKeyUp={handleOnKeyUp}
              setOpen={setOpenSelection}
              inputRef={inputRef}
              className={inputClassName}
            />
            <SelectionPanel
              selectedValue={singleValue || null}
              items={filteredItems}
              searchValue={searchQuery}
              isLoading={isLoading}
              isError={isError}
              emptyMessage={emptyMessage}
              close={() => setOpenSelection(false)}
              open={openSelection}
              closeOnSelect={true}
              expandWidthTo={expandSelectionPanelTo}
            />
          </span>
          {!hideSelectedPanel && <Selected items={singleValue ? [singleValue] : []} onRemove={onRemoveSingle} />}
        </Combobox>
      )}
    </div>
  )
})
