import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { ApiFetch } from '@services/api'
import { BookmarkStoreState } from '@stores/bookmarkStore/bookmarkStoreTypes'
import { deserializeBookmark } from '@stores/bookmarkStore/utils'

export const useBookmarkStore = create<BookmarkStoreState>()(
  immer((set) => ({
    isLoading: false,
    setLoading: (isLoading) => set({ isLoading }),

    show: false,
    setShow: (show) => set({ show }),

    bookmarks: [],
    setBookmarks: (bookmarks) => set({ bookmarks }),
    fetchBookmarks: (apiFetch) => fetchBookmarks(set, apiFetch),
  })),
)

const fetchBookmarks = async (set: any, apiFetch: ApiFetch) => {
  set({ isLoading: true })
  try {
    const data: any[] = await apiFetch(`/app/userbookmarks`)
    const bookmarks = data.map((bookmark) => deserializeBookmark(bookmark))
    set({ bookmarks })
  } finally {
    set({ isLoading: false })
  }
}
