import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EventContentProps } from '@components/EventContent/types'
import { CarouselGrid } from '@components/Grid'
import { Table } from '@components/Table'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { PresentationChartLineIcon } from '@heroicons/react/24/outline'
import { competitorFiltersToQueryParams } from '@stores/slices/eventDataSlice/competingEvents/utils'
import { formatUtils } from '@utils'
import { cn } from '@utils/className'
import { ControlPanel } from './ControlPanel'

type CompetingEventsProps<AdditionalParamsOnInternalChange> = Pick<
  EventContentProps<AdditionalParamsOnInternalChange>,
  | 'loading'
  | 'pdfHeader'
  | 'hasEventFilter'
  | 'stringfiedFilterContext'
  | 'eventFilter'
  | 'competitors'
  | 'additionalParamsForInternalChange'
  | 'exportOptions'
  | 'page'
>

export function CompetingEvents<AdditionalParamsOnInternalChange>(
  props: CompetingEventsProps<AdditionalParamsOnInternalChange>,
) {
  const { t } = useTranslation('tour_marketing')

  const { loading, pdfHeader, hasEventFilter, stringfiedFilterContext, competitors, exportOptions, page } = props
  const id = 'pdf-report-hide-competing-events-table'
  const metrics = useMemo(() => {
    if (!competitors.summaryData) {
      return []
    }

    return competitors.summaryData?.map((item) => ({
      title: item.competitor_event_category,
      value: formatUtils.formatNumber(item.total_competing_events),
    }))
  }, [competitors.summaryData])

  return (
    <div
      className={cn(
        'mt-6 md:mt-8',
        competitors.data.total > 0
          ? `group-[.pdf-report-hide-competing-events-table]:hidden`
          : 'group-[.pdf-report]:hidden',
      )}
    >
      <div className="flex flex-col gap-2 mb-5 md:mb-6">
        <h2 className="text-xl md:text-3xl font-semibold dark:text-white">{t('competing_events.title')}</h2>
        <h3 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">
          {t('competing_events.subtitle')}
        </h3>
      </div>

      <CarouselGrid isLoading={competitors.summaryDataLoading} className="md:grid-cols-4" metrics={metrics} />

      <div className="flex flex-col gap-8">
        <Table
          id={VisualizationId.CompetingEvents}
          visualization={VisualizationId.CompetingEvents}
          header={[
            { column: t('competing_events_table.event_name') },
            { column: t('competing_events_table.event_category') },
            { column: t('competing_events_table.event_subcategory') },
            { column: t('competing_events_table.event_location') },
            { column: t('competing_events_table.event_date') },
            { column: t('competing_events_table.event_distance') },
          ]}
          body={competitors.data.items.map((item) => ({
            row: [
              item.competitor_event.brand?.name,
              item.competitor_event_category,
              item.competitor_sub_category,
              item.competitor_event.venue?.name,
              formatUtils.formatDateNumbersOnly(item.competitor_event.performance_date),
              formatUtils.formatNumber(item.distance_between_events_in_mi) + ' miles',
            ],
          }))}
          filter={
            <ControlPanel
              competitors={competitors}
              eventFilter={props.eventFilter}
              hasEventFilter={hasEventFilter}
              loading={loading}
              additionalParamsForInternalChange={props.additionalParamsForInternalChange}
            />
          }
          truncateColumns={[0, 3]}
          showTable={competitors.data.total > 0 || competitors.filters.length > 0}
          isLoading={loading || competitors.data.fetching}
          noDataIcon={PresentationChartLineIcon}
          totalLabel={t('competing_events_table.all_competing_events')}
          csvDownload={
            hasEventFilter
              ? {
                  filename: `competing_events-${stringfiedFilterContext}`,
                  apiUrl: `/data/competitor_density/download?${competitorFiltersToQueryParams(
                    competitors.getFilterContext(),
                  ).toString()}`,
                }
              : undefined
          }
          pagination={competitors.data}
          exportFilename={`competing_events${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`}
          pdfHeader={pdfHeader}
          exportOptions={exportOptions?.map((option) => {
            return { ...option, checked: option.id === id }
          })}
          page={page}
        />
      </div>
    </div>
  )
}
