import { BackTestFilterKey, FilterField, FilterItem, GenerateFilterKey } from '@stores/predictionAssessmentStore'
import { Filters as PageFilters } from './predictionAssessmentStoreTypes'
import { pageFilterToFilterFields } from './predictionAssessmentStoreUtils'

const FILTER_VERSION = 2
const LOCALSTORAGE_KEY = 'session_predictionAssessmentFilters'

export const saveLocalFilters = (filters: PageFilters) => {
  const data = pageFilterToFilterFields(filters)

  const options = {
    version: FILTER_VERSION,
    data,
  }

  localStorage[LOCALSTORAGE_KEY] = JSON.stringify(options)
}

export const getLocalFilters = () => {
  const savedOptions = localStorage[LOCALSTORAGE_KEY]
  if (!savedOptions) return null

  const savedFilters = JSON.parse(savedOptions)

  if (savedFilters.version === 1) {
    savedFilters.version = FILTER_VERSION
    const backTestFilters = savedFilters.data
    savedFilters.data = { backTestFilters, generateFilters: [] }
    saveLocalFilters(savedFilters.data)
  }

  const backTestFilters: FilterField<BackTestFilterKey>[] = []
  const generateFilters: FilterField<GenerateFilterKey>[] = []
  savedFilters.data.backTestFilters.forEach((saved: any) => {
    backTestFilters.push(new FilterItem(saved.name, saved.data))
  })
  savedFilters.data.generateFilters.forEach((saved: any) => {
    generateFilters.push(new FilterItem(saved.name, saved.data))
  })
  return { backTestFilters, generateFilters }
}

export const clearLocalFilters = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY)
}
