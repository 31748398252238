const trimRight = (str: string, char: string) => str.replace(new RegExp(`[${char}]*$`, 'g'), '')

const trimLeft = (str: string, char: string) => str.replace(new RegExp(`^[${char}]*`, 'g'), '')

const trim = (str: string, char: string) => trimLeft(trimRight(str, char), char)

export const removeRightSlashes = (str: string) => str.replace(/\/*$/g, '')

export const removeLeftSlashes = (str: string) => str.replace(/^\/*/g, '')

export const filefyString = (name?: string) =>
  name
    ? trim(
        name
          .toString()
          .replace(/[^a-z0-9]/gi, '_')
          .replaceAll(/_+/g, '_')
          .toLowerCase()
          .trim(),
        '_',
      ).trim()
    : ''

export const filefyArray = (names?: string[], max = 3) =>
  names && names.length > 0
    ? names?.map(filefyString).slice(0, max).join('__') + (names.length > max ? '__more' : '')
    : ''

export const copyToClipboard = (textToCopy: string) => {
  if (typeof navigator?.clipboard?.writeText === 'function') {
    navigator.clipboard.writeText(textToCopy)
  } else {
    const element = document.body
    const textArea = document.createElement('textarea')
    textArea.value = textToCopy
    element.appendChild(textArea)
    textArea.select()
    textArea.setSelectionRange(0, 99999)
    document.execCommand('copy')
    element.removeChild(textArea)
  }
}
