import { queryClient } from '@config/react-query'
import { ApiFetch } from '@services/api'
import {
  PaginatedDataStateCreator,
  PaginatedSliceProps,
  PaginatedSliceState,
} from '@stores/slices/paginatedSlice/paginatedSliceTypes'
import { PaginatedData } from '@types'
import { StoreSetFunction } from '@utils/zustand'

export function createPaginatedSlice<T, F>(
  props: PaginatedSliceProps<F>,
  ...[set, get]: Parameters<PaginatedDataStateCreator<T, F>>
): ReturnType<PaginatedDataStateCreator<T, F>> {
  const getInner = () => {
    const state = get() as any
    return state[props.fieldName] as PaginatedSliceState<T, F>
  }

  const setInner = (args: Partial<PaginatedSliceState<T, F>>) => {
    const currentState = getInner()
    set({
      [props.fieldName]: { ...currentState, ...args },
    })
  }

  const fetch = async (filters: F, apiFetch: ApiFetch) => {
    await fetchApi(getInner, setInner, apiFetch, props.toQueryParams(filters), props.appendItems)
  }

  const goToPage = (newPage: number, apiFetch: ApiFetch) => {
    setInner({ page: newPage })
    const filters = props.getFilters() || ({} as F)
    fetch(filters, apiFetch)
  }

  const setPageSize = (newSize: number, apiFetch: ApiFetch) => {
    setInner({ size: newSize })
    const filters = props.getFilters() || ({} as F)
    fetch(filters, apiFetch)
  }

  return {
    prefix: '',
    items: [] as T[],
    page: 1,
    size: 10,
    pages: 0,
    total: 0,
    fetching: false,
    fetchUrl: props.fetchUrl,
    fetch,
    reset: () => {
      setInner({ page: 1, items: [], pages: 0, total: 0 })
    },
    goToPage,
    setPageSize,
  }
}

const fetchApi = async <T, F>(
  get: () => PaginatedSliceState<T, F>,
  set: (arg: Partial<PaginatedSliceState<T, F>>) => void,
  apiFetch: ApiFetch,
  queryParams: URLSearchParams,
  appendItems = false,
) => {
  const state = get()
  const { fetchUrl } = state
  try {
    set({ fetching: true })
    queryParams.append('page', state.page.toString())
    queryParams.append('size', state.size.toString())
    const response = await queryClient.fetchQuery({
      queryKey: [fetchUrl, queryParams.toString()],
      queryFn: () => apiFetch(`${fetchUrl}?${queryParams.toString()}`, {}) as Promise<PaginatedData<T>>,
    })
    let items = response.items
    if (appendItems) {
      items = [...get().items, ...items]
    }
    set({
      items,
      pages: response.pages,
      total: response.total,
      size: response.size,
      page: response.page,
    })
  } finally {
    set({ fetching: false })
  }
}

export type PaginatedSliceSetFunction<T, F> = StoreSetFunction<PaginatedSliceState<T, F>>

export type PaginatedSliceGetFunction<T, F> = () => PaginatedSliceState<T, F>
