export enum VisualizationId {
  // Event Insights
  EventsList = 'eventsList',
  TicketsSoldVSTicketsRemaining = 'ticketsSoldVSTicketsRemaining',
  CumulativeTicketsSold = 'cumulativeTicketsSold',
  PricingInsights = 'pricingInsights',
  CompetingEvents = 'competingEvents',

  // Artist Venue Insights
  TicketsSoldByPriceAndTierPrimaryMarket = 'ticketsSoldByPriceAndTierPrimaryMarket',
  TicketsSoldByPriceAndTierSecondaryMarket = 'ticketsSoldByPriceAndTierSecondaryMarket',
  HistoricalSummary = 'historicalSummary',

  // Predictions
  PredictionsBackTest = 'predictionsBackTest',

  // Overview
  OverviewBookmarks = 'overviewBookmarks',

  // Deprecated
  Chatbot = 'chatbot',
}

export type VisualizationType = VisualizationId | { id: VisualizationId; subTypes?: string | string[] }
