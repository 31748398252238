import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { cn } from '@utils/className'

export interface DropdownOption {
  id: string
  label: string
  icon?: React.ElementType
  onClick?(): void
  disabled?: boolean
}

interface DropdownProps {
  label?: string
  options: DropdownOption[]
  defaultOption?: DropdownOption
  className?: string
}

export function Dropdown(props: DropdownProps) {
  const { label, options = [], defaultOption = null } = props
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(defaultOption)

  return (
    <Listbox
      as="div"
      className={cn('relative group-[.pdf-report]:hidden', props.className)}
      value={selectedOption}
      onChange={setSelectedOption}
      by="id"
    >
      <Listbox.Button className="cursor-pointer flex items-center h-[26px] w-full min-w-[90px] rounded bg-white p-2 border border-gray-300 text-gray-500 text-xs font-medium text-left dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 overflow-hidden">
        {!label && selectedOption?.icon && <selectedOption.icon className="h-4 w-4" aria-hidden="true" />}
        <span className="block break-all text-ellipsis mx-1">{label || selectedOption?.label}</span>
        <ChevronDownIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
      </Listbox.Button>
      <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
        <Listbox.Options className="absolute z-10 right-0 border border-gray-300 py-1 mt-1 min-w-[160px] w-full overflow-auto rounded-lg bg-white text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-700 dark:border-gray-500">
          {options.map((option) => (
            <Listbox.Option
              key={option.id}
              value={option}
              onClick={option.onClick}
              disabled={option.disabled}
              className={({ active, selected }) =>
                cn(
                  'cursor-pointer w-full select-none py-2 px-3 rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 text-xs flex items-center justify-start',
                  selected && 'font-medium bg-gray-100 dark:bg-gray-800',
                  active && 'hover:bg-gray-50 dark:hover:bg-gray-800/50',
                  option.disabled && 'opacity-25 cursor-not-allowed',
                )
              }
            >
              {option.icon && <option.icon className="w-5 h-5 mr-2" />}
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  )
}
