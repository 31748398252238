import { useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { TourPlanningContext } from './tourPlanningStoreContext'
import { TourPlanningStoreState } from './tourPlanningStoreTypes'

export function useTourPlanningStore<T>(
  selector: (state: TourPlanningStoreState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(TourPlanningContext)
  if (!store) throw new Error('Missing Provider in the tree')

  return useStoreWithEqualityFn(store, selector, equalityFn)
}
