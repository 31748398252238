import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LoadingIcon } from '@components/LoadingIcon'
import { useDocumentationLink } from '@hooks/useDocumentationLink'
import { EmptyLayout } from '@layouts/EmptyLayout'

const DocumentationInitialPage = () => {
  const [searchParams, _] = useSearchParams()
  const { link, loaded } = useDocumentationLink()

  useEffect(() => {
    const path = searchParams.get('location') || ''
    if (link !== null) {
      const url = new URL(link)
      url.pathname = url.pathname + path
      window.location.href = url.toString()
    }
  }, [link, searchParams])

  return (
    <div className="flex items-center justify-center w-full h-full">
      {loaded && !link ? 'An error ocurred when redirecting' : <LoadingIcon className="h-4 w-4" />}
    </div>
  )
}

export const layout = EmptyLayout

export default DocumentationInitialPage
