import { useCallback, useEffect } from 'react'
import type { DateRangeInputOption, DateRangeInputValue } from 'carbonarc-ui'
import { Button, DateRangeInput } from 'carbonarc-ui'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { Badge } from '@components/Badge'
import { ClearAllButton } from '@components/ClearAllButton'
import { Item, RemoteSelect } from '@components/RemoteSelect'
import { CalendarIcon, GlobeAltIcon, MapPinIcon, TicketIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { usePrevious } from '@hooks'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { useApiFetch } from '@services/api'
import {
  FilterDate,
  FilterField,
  FilterItem,
  FilterKey,
  Filters as PageFilters,
  defaultFilterValue,
  filterFieldsToPageFilters,
  useTourPlanningControlPanelStore,
  useTourPlanningStore,
} from '@stores/tourPlanningStore'
import { clearLocalFilters } from '@stores/tourPlanningStore/localFilters'
import { dateUtils, formatUtils } from '@utils'
import { cn } from '@utils/className'

const isValidFilters = (filters: FilterField[]) => {
  return filters.length > 0
}

interface ControlPanelProps {
  handleSelected: (filters: PageFilters) => void
}
const dateInputLabel = (filter: DateRangeInputValue) => {
  return filter?.option?.value === 'custom'
    ? `${filter.start ? formatUtils.formatDateNumbersOnly(filter.start) : '...'} - ${
        filter.end ? formatUtils.formatDateNumbersOnly(filter.end) : '...'
      }`
    : DateRangeInput.defaultOptions.find((o: DateRangeInputOption) => o.value === filter?.option?.value)?.label || ''
}

export const ControlPanel: React.FC<ControlPanelProps> = ({ handleSelected }) => {
  const searchParamsHandler = useSearchParamsObject()
  const { isLoading, filterContext, clearState } = useTourPlanningStore((state) => ({
    isLoading: state.isLoading,
    filterContext: state.filterContext,
    clearState: state.clearData,
  }))

  const { applied, setApplied, dateRange, setDateRange, filters, setFilters } = useTourPlanningControlPanelStore(
    (state) => ({
      applied: state.applied,
      setApplied: state.setApplied,
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      filters: state.filters,
      setFilters: state.setFilters,
    }),
  )

  const previousFilters = usePrevious(filters)
  const apiFetch = useApiFetch()
  const { t } = useTranslation('tour_planning')

  const getFiltersFor = useCallback(
    (key: FilterKey) => {
      const preFilters: FilterField[] = []
      for (const filter of filters) {
        if (filter.name === key) break
        preFilters.push(filter)
      }
      const params: Record<string, string | string[] | undefined> = {
        include_past: 'true',
      }
      for (const filter of preFilters) {
        Object.assign(params, filter.toParams())
      }
      return params
    },
    [filters],
  )

  const getValueFor = useCallback(
    (key: FilterKey) => {
      const idx = filters.findIndex((filter) => filter.name === key)
      return idx > -1 ? filters[idx].data : defaultFilterValue[key]
    },
    [filters],
  )

  const isFirstFilter = useCallback(
    (key: FilterKey) => {
      return filters.length === 0 || filters.findIndex((filter) => filter.name === key) === 0
    },
    [filters],
  )

  const handleSelectItem = (key: FilterKey, value?: Item[]) => {
    if (!value || value.length === 0) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters)
    } else {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters([...newFilters, new FilterItem(key, value)])
    }
  }

  const handleSelectDate = (key: FilterKey, value?: DateRangeInputValue | null) => {
    if (!value) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters)
    } else {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters([...newFilters, new FilterDate(key, value)])
    }
  }

  const _handleSelected = useCallback(
    (filters: FilterField[]) => {
      handleSelected(filterFieldsToPageFilters(filters))
    },
    [handleSelected],
  )

  const apply = useCallback(() => {
    if (isValidFilters(filters)) {
      _handleSelected(filters)
      setApplied(true)
    }
  }, [filters, _handleSelected, setApplied])

  useEffect(() => {
    if (previousFilters && previousFilters.length > 0 && filters.length === 0) clearLocalFilters()
  }, [filters, previousFilters, setApplied])

  useEffect(() => {
    const filterApplied = isEqual(filterFieldsToPageFilters(filters), filterContext)
    if (filterApplied && !applied) {
      setApplied(true)
    } else if (!filterApplied && applied) {
      setApplied(false)
    }
  }, [filters, setApplied, filterContext, applied])

  const canApply = isValidFilters(filters) && !applied

  const updateDateRange = useCallback(async () => {
    setDateRange({ min: undefined, max: undefined, loading: true })
    try {
      const query = new URLSearchParams()
      const dateFilters = getFiltersFor('date')
      Object.entries(dateFilters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => query.append(key, v))
        } else if (value !== undefined) {
          query.append(key, value)
        }
      })
      const data = await apiFetch(`/data/perfdaterange?${query.toString()}`)
      const min = dateUtils.parseDate(data.min) || undefined
      const max = dateUtils.parseDate(data.max) || undefined
      setDateRange({ min, max, loading: false })
    } catch {
      setDateRange({ min: undefined, max: undefined, loading: false })
    }
  }, [apiFetch, setDateRange, getFiltersFor])

  useEffect(() => {
    if (
      !isEqual(
        previousFilters?.filter((v) => v.name !== 'date'),
        filters.filter((v) => v.name !== 'date'),
      )
    ) {
      updateDateRange()
    }
  }, [filters, previousFilters, updateDateRange])

  return (
    <section
      className={cn(
        'bg-white border-gray-200 dark:border-gray-700 dark:bg-gray-800 border-r',
        'subnav-control-panel',
        'w-full lg:w-[250px] lg:shrink-0 lg:!overflow-y-auto group-[.pdf-report]:hidden',
      )}
      id="control_panel"
    >
      <div className="w-full text-center font-bold flex flex-col md:grid md:grid-cols-5 lg:flex lg:flex-col divide-y divide-gray-200 dark:divide-gray-700">
        <div className="px-3 py-4">
          <label
            className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
            htmlFor="artists"
          >
            <UserGroupIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
            {t('control_panel.artists')}
          </label>
          <div className="mt-2">
            <RemoteSelect
              id="artists"
              multiple
              onChange={(item: Item[]) => {
                handleSelectItem('artist', item)
              }}
              extraParams={getFiltersFor('artist')}
              value={getValueFor('artist')}
              dataUrl="/data/artist"
              placeholder={t('common:search_type', { type: t('control_panel.artists') })}
              nameField="artist_name"
              idField="artistid"
              openOnClick={!isFirstFilter('artist') && !isFirstFilter('showType')}
              disableRemoteSearch={!isFirstFilter('artist') && !isFirstFilter('showType')}
              searchMinimumLength={isFirstFilter('artist') || isFirstFilter('showType') ? 2 : 0}
              tabIndex={1}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="px-3 py-4">
          <label
            className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
            htmlFor="eventSubCategories"
          >
            <TicketIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
            {t('control_panel.sub_categories')}
          </label>
          <div className="mt-2">
            <RemoteSelect
              id="eventSubCategories"
              multiple
              onChange={(item: Item[]) => {
                handleSelectItem('showType', item)
              }}
              extraParams={getFiltersFor('showType')}
              value={getValueFor('showType')}
              dataUrl="/data/events/sub_categories"
              placeholder={t('common:search_type', { type: t('control_panel.sub_categories') })}
              openOnClick
              nameField="name"
              idField="name"
              disableRemoteSearch={true}
              tabIndex={2}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="px-3 py-4">
          <label
            className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
            htmlFor="regions"
          >
            <GlobeAltIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
            {t('control_panel.regions')}
          </label>
          <div className="mt-2">
            <RemoteSelect
              id="regions"
              multiple
              onChange={(item: Item[]) => handleSelectItem('region', item)}
              extraParams={getFiltersFor('region')}
              value={getValueFor('region')}
              dataUrl="/data/region"
              placeholder={t('common:search_type', { type: t('control_panel.regions') })}
              nameField="region_name"
              idField="regionid"
              openOnClick={!isFirstFilter('region') && !isFirstFilter('showType')}
              disableRemoteSearch={!isFirstFilter('region') && !isFirstFilter('showType')}
              searchMinimumLength={isFirstFilter('region') || isFirstFilter('showType') ? 2 : 0}
              tabIndex={3}
              limitTo={0}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="px-3 py-4">
          <label
            className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
            htmlFor="venues"
          >
            <MapPinIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
            {t('control_panel.venues')}
          </label>
          <div className="mt-2">
            <RemoteSelect
              id="venues"
              multiple
              onChange={(item: Item[]) => handleSelectItem('venue', item)}
              extraParams={getFiltersFor('venue')}
              value={getValueFor('venue')}
              dataUrl="/data/venue"
              placeholder={t('common:search_type', { type: t('control_panel.venues') })}
              nameField="venue_name"
              idField="venueid"
              openOnClick={!isFirstFilter('venue') && !isFirstFilter('showType')}
              disableRemoteSearch={!isFirstFilter('venue') && !isFirstFilter('showType')}
              searchMinimumLength={isFirstFilter('venue') || isFirstFilter('showType') ? 2 : 0}
              tabIndex={4}
              disabled={isLoading}
            />
          </div>
        </div>
        <div className="px-3 py-4">
          <label
            className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
            htmlFor="dates"
          >
            <CalendarIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
            {t('control_panel.time_period')}
          </label>
          <div className="mt-2">
            <DateRangeInput
              value={getValueFor('date')}
              onChange={(dates) => handleSelectDate('date', dates)}
              placeholder={t('common:select_type', { type: t('control_panel.time_period') })}
              tabIndex={5}
              disabled={isLoading || dateRange.loading}
              hideInputValue
              minDate={dateRange.min}
              maxDate={dateRange.max}
            />
            {getValueFor('date')?.option && (
              <div className="mt-5 text-left relative max-w-[230px] flex flex-wrap gap-2 md:max-h-[63px] md:overflow-y-auto lg:max-h-none print:max-w-none print:gap-0">
                <Badge
                  text={dateInputLabel(getValueFor('date') || {})}
                  onRemove={
                    isLoading
                      ? undefined
                      : () => {
                          handleSelectDate('date', null)
                        }
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="px-3 py-4 lg:pb-[80px] w-full border-t border-gray-200 dark:border-gray-700 print:hidden grid grid-rows-2 sm:grid-rows-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-2">
        <Button
          variant="default"
          onClick={() => apply()}
          disabled={!canApply || isLoading}
          className="w-full sm:max-w-[250px] disabled:opacity-25"
          tabIndex={6}
          isLoading={isLoading}
        >
          {t('common:apply')}
        </Button>

        <ClearAllButton
          disabled={filters.length === 0 || isLoading}
          onClick={() => {
            clearLocalFilters()
            setFilters([])
            clearState(searchParamsHandler)
          }}
          tabIndex={7}
        />
      </div>
    </section>
  )
}
