import { Tooltip } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { SeparatedGrid } from '@components/Grid'
import { usePredictionAssessmentStore } from '@stores/predictionAssessmentStore'
import { formatUtils } from '@utils'
import { cn } from '@utils/className'

interface ConfidenceItemProps {
  title: string
  tooltip: string
  color: string
}

function ConfidenceItem(props: ConfidenceItemProps) {
  return (
    <div className="flex gap-2">
      <div className={cn(`w-6 h-6 rounded`, props.color)} />
      <Tooltip content={props.tooltip}>
        <p className="underline underline-offset-4">{props.title}</p>
      </Tooltip>
    </div>
  )
}

function Confidence() {
  const { t } = useTranslation('prediction_assessment')
  const items = [
    { title: t('confidence.high.title'), color: 'bg-green-400', tooltip: t('confidence.high.tooltip') },
    { title: t('confidence.medium.title'), color: 'bg-yellow-400', tooltip: t('confidence.medium.tooltip') },
    { title: t('confidence.low.title'), color: 'bg-orange-400', tooltip: t('confidence.low.tooltip') },
    { title: t('confidence.outlier.title'), color: 'bg-red-400', tooltip: t('confidence.outlier.tooltip') },
    { title: t('confidence.default.title'), color: 'bg-gray-400', tooltip: t('confidence.default.tooltip') },
  ]
  return (
    <div className="flex w-full mb-4 gap-4 flex-wrap">
      {items.map((item) => (
        <ConfidenceItem title={item.title} color={item.color} tooltip={item.tooltip} />
      ))}
    </div>
  )
}

export function Predictions() {
  const { t } = useTranslation('prediction_assessment')

  const { isLoading, data } = usePredictionAssessmentStore((state) => ({
    isLoading: state.isLoadingGenerate,
    data: state.generateData,
  }))

  const confidenceStyles = {
    High: `border-l-green-400 dark:border-l-green-400`,
    Medium: `border-l-yellow-400 dark:border-l-yellow-400`,
    Low: `border-l-orange-400 dark:border-l-orange-400`,
    Outlier: `border-l-red-400 dark:border-l-red-400`,
    default: `border-l-gray-400 dark:border-l-gray-400`,
  }
  if (!data) return
  const confidenceClassName = `p-4 border-l-8 group-[.pdf-report]:border-solid`
  return (
    <div className="w-full mb-4">
      <SeparatedGrid
        isLoading={isLoading}
        className="md:grid-cols-4 gap-y-2"
        metrics={
          data
            ? [
                {
                  title: t('generate.predicted_max_ticket_quantity'),
                  value: data
                    ? formatUtils.formatNumber(data.predicted_max_ticket_quantity, {
                        maximumFractionDigits: 0,
                      })
                    : undefined,
                  className: cn(
                    confidenceClassName,
                    confidenceStyles[data?.predicted_max_ticket_quantity_confidence_bucket || 'default'],
                  ),
                },
                {
                  title: t('generate.predicted_price_per_ticket'),
                  value: data ? formatUtils.formatCurrency(data?.predicted_price_per_ticket) : undefined,
                  className: cn(
                    confidenceClassName,
                    confidenceStyles[data?.predicted_price_per_ticket_confidence_bucket || 'default'],
                  ),
                },
                {
                  title: t('generate.predicted_revenue'),
                  value: data ? formatUtils.formatCurrency(data?.predicted_revenue) : undefined,
                  className: cn(
                    confidenceClassName,
                    confidenceStyles[data?.predicted_price_per_ticket_confidence_bucket || 'default'],
                  ),
                },
                {
                  title: t('generate.venue_capacity'),
                  value: data ? formatUtils.formatNumber(data.carc_capacity, { maximumFractionDigits: 0 }) : undefined,
                  className: cn(
                    confidenceClassName,
                    confidenceStyles[data?.carc_capacity_confidence_bucket || 'default'],
                  ),
                },
              ]
            : []
        }
      />
      <Confidence />
    </div>
  )
}
