import { Button, ButtonProps, Modal, useTheme } from 'carbonarc-ui'
import { cn } from '@utils/className'

interface ConfirmationModalProps {
  show: boolean
  setShow: (show: boolean) => void
  title: string
  confirmButton?: ButtonProps & { label: string }
  cancelButton?: ButtonProps & { label: string }
  children: React.ReactNode
}

export function ConfirmationModal(props: ConfirmationModalProps) {
  const { show, setShow, title, confirmButton, cancelButton, children } = props
  const modalHeaderTheme = useTheme().theme.modal.header
  const modalBodyTheme = useTheme().theme.modal.body

  return (
    <Modal
      show={show}
      onClose={() => setShow(false)}
      size="md"
      theme={{
        content: {
          inner: 'relative rounded-lg bg-white shadow dark:bg-gray-800',
        },
      }}
    >
      <Modal.Header
        theme={{
          base: cn(modalHeaderTheme.base, 'items-center p-6'),
          title: cn(modalHeaderTheme.title, 'text-lg font-semibold'),
          close: {
            icon: cn(modalHeaderTheme.close.icon, 'text-gray-900 dark:text-gray-300'),
          },
        }}
      >
        Welcome Test {title}
      </Modal.Header>

      <Modal.Body theme={{ base: cn(modalBodyTheme.base, 'max-h-[500px] overflow-y-auto') }}>
        <div className="flex flex-col gap-3">{children}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button {...confirmButton}>{confirmButton?.label}</Button>
        <Button {...cancelButton}>{cancelButton?.label}</Button>
      </Modal.Footer>
    </Modal>
  )
}
