import { RefObject, useEffect } from 'react'

type Props<T extends HTMLElement> = {
  ref: RefObject<T>
  handler: (event: MouseEvent | TouchEvent) => void
  avoidElements?: HTMLElement[]
}

export const useOnClickOutside = <T extends HTMLElement>({ ref, handler, avoidElements }: Props<T>) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || (event.target && ref.current.contains(event.target as Node))) {
        return
      }
      if (avoidElements) {
        for (const avoidElement of avoidElements) {
          if (avoidElement.contains(event.target as Node)) return
        }
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, avoidElements])
}
