import { APP } from '@config/site/app'

const configCatShowPredictionsFlagName = `ticketing_predictions_${APP}`

const configCatShowPricingInsightsTimeSeriesSalesFlagName = `ticketing_pricing_insights_time_series_sales_${APP}`

const configCatShowUpcomingEventsFlagName = `ticketing_upcoming_events_${APP}`
const configCatShowEventInsightsFlagName = `ticketing_event_insights_${APP}`

export enum Flags {
  SHOW_PREDICTIONS = 'show_predictions',
  SHOW_PRICING_INSIGHTS_TIME_SERIES_SALES = 'show_pricing_insights_time_series_sales',
  ENABLE_FAVORITES = 'enable_favorites',
  ENABLE_EXPORT = 'enable_export',
  SHOW_UPCOMING_EVENTS = 'show_upcoming_events',
  SHOW_EVENT_INSIGHTS = 'show_event_insights',
}

export const FlagMap = {
  [Flags.SHOW_PREDICTIONS]: configCatShowPredictionsFlagName,
  [Flags.SHOW_PRICING_INSIGHTS_TIME_SERIES_SALES]: configCatShowPricingInsightsTimeSeriesSalesFlagName,
  [Flags.SHOW_UPCOMING_EVENTS]: configCatShowUpcomingEventsFlagName,
  [Flags.SHOW_EVENT_INSIGHTS]: configCatShowEventInsightsFlagName,
}

export type FlagResult = {
  value: string | number | boolean
  loading: boolean
}
