import { queryClient } from '@config/react-query'
import { ApiFetch } from '@services/api'
import { SingleEventStoreSetFunction } from '@stores/singleEventStore/singleEventStoreTypes'
import { EventEntityWithSeries } from '@types'

export const fetchEventWithSeries = async (
  set: SingleEventStoreSetFunction,
  apiFetch: ApiFetch,
  selectedEventId: number,
) => {
  set({ isLoadingEvent: true })

  const event = await queryClient.fetchQuery({
    queryKey: ['event', selectedEventId, 'series'],
    queryFn: () => apiFetch(`/data/event/${selectedEventId}/series`) as Promise<EventEntityWithSeries>,
  })
  set({ event: event, isLoadingEvent: false })
}
