export const minutesUntil = (date: Date | number, round = true) => {
  const exp = date instanceof Date ? date.getTime() / 1000 : date
  const now = new Date().getTime() / 1000
  const minutes = (exp - now) / 60
  return round ? Math.floor(minutes) : minutes
}

export const subtractHours = (date: Date, hours: number) => {
  const newDate = new Date(date)
  newDate.setHours(date.getHours() - hours)
  return newDate
}

export const diffInHours = (date1: Date, date2: Date) => {
  const diff = date1.getTime() - date2.getTime()
  return diff / (1000 * 60 * 60)
}
