import { FunctionComponent } from 'react'
import { NotFoundPage } from 'carbonarc-ui'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoadingPage } from '@components/LoadingPage'
import { usePages } from '@config/site/app'
import { Page } from '@config/site/app/pages'
import { BaseLayout } from '@layouts/BaseLayout'
import { aliases } from '@routes/aliases'
import { layoutRoutes } from '@routes/paths'
import Root from '@routes/root'

const LayoutPage = ({ page, component: Component }: { page: Page; component: FunctionComponent }) => {
  const { canSeePage, isLoadingPageFlag } = usePages()
  const loading = isLoadingPageFlag(page)
  const pageAvailable = !page || canSeePage(page)
  if (loading) return <LoadingPage />
  else if (!pageAvailable) return <NotFoundPage />
  else return <Component />
}

export const ProtectedRouter = () => {
  return (
    <Routes>
      <Route element={<Root />}>
        {layoutRoutes.map(({ layout: Layout, routes }, index) => (
          <Route key={index} element={<Layout />}>
            {routes.map(({ path, component: Component, page }) => {
              return <Route key={path} path={path} element={<LayoutPage component={Component} page={page} />} />
            })}
          </Route>
        ))}
        <Route element={<BaseLayout />}>
          {Object.entries(aliases).map(([fromPath, toPath]) => (
            <Route key={fromPath} path={`/${fromPath}`} element={<Navigate to={`/${toPath}`} replace={true} />} />
          ))}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    </Routes>
  )
}
