import qs from 'qs'
import { Item } from '@components/RemoteSelect'
import { PredictionAssessmentContext } from '@stores/bookmarkStore/utils/types'
import {
  BackTestFilters,
  GenerateFilters,
  Filters as PageFilters,
  defaultBackTestFilters,
  defaultGenerateFilters,
} from './predictionAssessmentStoreTypes'

export const queryParamsToContext = (searchParams: string | URLSearchParams): PredictionAssessmentContext => {
  const paramsData = qs.parse(searchParams.toString(), {
    parseArrays: true,
  })

  const version = 'backTest' in paramsData || 'generate' in paramsData ? 2 : 1

  let filters: PageFilters
  if (version === 1) {
    const params = paramsData as BackTestFilters
    filters = {
      backTest: {
        metric: params.metric || defaultBackTestFilters.metric,
        artist: params.artist || defaultBackTestFilters.artist,
        venue: params.venue || defaultBackTestFilters.venue,
        year: params.year || defaultBackTestFilters.year,
      },
      generate: defaultGenerateFilters,
    }
  } else {
    const paramsBackTest =
      'backTest' in paramsData ? (paramsData.backTest as BackTestFilters) : (paramsData as BackTestFilters)

    const backTest = {
      metric: paramsBackTest?.metric || defaultBackTestFilters.metric,
      artist: paramsBackTest?.artist || defaultBackTestFilters.artist,
      venue: paramsBackTest?.venue || defaultBackTestFilters.venue,
      year: paramsBackTest?.year || defaultBackTestFilters.year,
    }

    const paramsGenerate = paramsData.generate as GenerateFilters
    const generate = {
      artist: paramsGenerate?.artist || defaultGenerateFilters.artist,
      venue: paramsGenerate?.venue || defaultGenerateFilters.venue,
      season: paramsGenerate?.season || defaultGenerateFilters.season,
    }
    filters = { backTest, generate }
  }

  return { filters }
}

export const contextToQueryParams = (context: PredictionAssessmentContext) => {
  const { filters } = context

  const params = qs.stringify(filters).toString()

  return params
}

export const backTestFiltersToQueryParams = (filters: BackTestFilters) => {
  const { artist, venue, year } = filters

  const isAllOptionSelected = (data: Item[]) => data?.map((d) => d.id).includes('0')

  const urlSearchParams = new URLSearchParams()

  if (!isAllOptionSelected(artist)) {
    artist?.forEach((artist) => urlSearchParams.append('artists_ids', artist?.id))
  }

  if (!isAllOptionSelected(venue)) {
    venue?.forEach((venue) => urlSearchParams.append('venues_ids', venue?.id))
  }

  if (!isAllOptionSelected(year)) {
    year?.forEach((year) => urlSearchParams.append('years', year?.name))
  }

  return urlSearchParams
}

export const generateFiltersToQueryParams = (filters: GenerateFilters) => {
  const { artist, venue, season } = filters

  const urlSearchParams = new URLSearchParams()

  if (artist?.id) urlSearchParams.append('artist', artist?.id)
  if (venue?.id) urlSearchParams.append('venue', venue?.id)
  if (season?.id) urlSearchParams.append('season', season?.id)

  return urlSearchParams
}
