import { EventFilter } from '@stores/slices/eventDataSlice'
import { FilterDate, FilterField, FilterItem } from '@stores/tourMarketingStore/tourMarketingControlPanelStoreTypes'
import { stringUtils } from '@utils'
import { PdfHeader } from '@utils/pdf'
import { Filters as PageFilters, defaultFilters } from './tourMarketingStoreTypes'

export const stringifyFilters = (filters: PageFilters) => {
  const artist = stringUtils.filefyString(filters.artist?.name)
  const venue = filters.venue?.id !== '0' ? stringUtils.filefyString(filters.venue?.name) : ''

  return venue ? `${artist}-${venue}` : artist
}

export const getPdfHeader = (filters: PageFilters): PdfHeader => {
  const artist = filters.artist?.name
  const showType = filters.showType?.name
  const venue = filters.venue?.name
  const perfDate = stringUtils.filefyString(filters.perfDate?.name).replaceAll('_', '/')

  return { text: `${artist}\n${venue} (${showType})\n${perfDate}`, lines: 3 }
}

export const pageFilterToFilterFields = (obj: PageFilters) => {
  const filters: FilterField[] = []
  if (obj?.artist) filters.push(new FilterItem('artist', obj.artist))
  if (obj?.showType) filters.push(new FilterItem('showType', obj.showType))
  if (obj?.venue) filters.push(new FilterItem('venue', obj.venue))
  if (obj?.perfDate) filters.push(new FilterDate('perfDate', obj.perfDate))
  return filters
}

export const filterFieldsToPageFilters = (filters: FilterField[]) => {
  return {
    artist: filters.find((filter) => filter.name === 'artist')?.data || defaultFilters.artist,
    showType: filters.find((filter) => filter.name === 'showType')?.data || defaultFilters.showType,
    venue: filters.find((filter) => filter.name === 'venue')?.data || defaultFilters.venue,
    perfDate: filters.find((filter) => filter.name === 'perfDate')?.data || defaultFilters.perfDate,
  }
}

export const filtersToEventFilter = (filters?: PageFilters) => {
  return {
    artist: filters?.artist?.id || '',
    venue: filters?.venue?.id || '',
    date: filters?.perfDate?.id || '',
  } as EventFilter
}
