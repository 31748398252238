import { FocusEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Input, InputProps } from '@components/Inputs/Input'
import { SelectRef, Selected, SelectionPanel } from '@components/Inputs/Select'
import { Combobox } from '@headlessui/react'
import { useDebounce, useOnClickOutside, usePrevious } from '@hooks'
import { useActiveElement } from '@hooks/useActiveElement'
import { useApiFetch } from '@services/api'

export type Item = {
  id: string
  name: string
}

type CustomOption = {
  id: string
  name: string
  preventOthers?: boolean
}

type ConditionalProps =
  | {
      value: Item | null
      onChange: (value: Item | undefined) => void
      multiple?: false
    }
  | {
      value: Item[]
      onChange: (value: Item[]) => void
      multiple: true
    }

type RemoteSelectProps = {
  emptyMessage?: string
  dataUrl: string
  extraParams?: Record<string, string | string[] | undefined>
  disabled?: boolean
  customOptions?: CustomOption[]
  idField?: string
  nameField?: string | ((item: any) => string)
  searchMinimumLength?: number
  disableRemoteSearch?: boolean
  showSelectedValue?: boolean
  limitTo?: number
  isLastAvailableFilter?: boolean
  onSearchStart?: () => void
  onSearchFinish?: (options: Item[]) => void
} & ConditionalProps &
  Omit<InputProps, 'hasSelected' | 'value' | 'onChange' | 'multiple' | 'setOpen'>

const queryParams = (params: Record<string, string | string[]>): string => {
  const query = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => query.append(key, v))
    } else if (value !== undefined) {
      query.append(key, value)
    }
  })
  return query.toString()
}

export const RemoteSelect = forwardRef<SelectRef, RemoteSelectProps>((props, remoteSelectRef) => {
  const {
    placeholder = 'Search...',
    emptyMessage = 'No results found',
    dataUrl,
    value,
    extraParams = {},
    disabled = false,
    onChange,
    customOptions = null,
    showRightArrow = false,
    idField = 'id',
    nameField = 'name',
    openOnClick = false,
    preventReplace = false,
    searchMinimumLength = 0,
    multiple = false,
    disableRemoteSearch = false,
    showSelectedValue = true,
    id,
    tabIndex,
    icon,
    limitTo = 50,
    isLastAvailableFilter = false,
    onSearchStart,
    onSearchFinish,
  } = props
  const [searchInputValue, setSearchInputValue] = useState('')
  const [openSelection, setOpenSelection] = useState(false)
  const [items, setItems] = useState<Item[]>([])
  const ref = useRef<HTMLElement>(null)
  const searchQuery = useDebounce(searchInputValue)
  const previousSearchQuery = usePrevious(searchQuery)
  const { previousActiveElement } = useActiveElement()
  const apiFetch = useApiFetch()

  const mapItem = useCallback(
    (apiItem: any) => ({
      id: apiItem[idField].toString(),
      name: typeof nameField === 'function' ? nameField(apiItem) : apiItem[nameField].toString(),
    }),
    [idField, nameField],
  )

  const qry = queryParams({
    ...extraParams,
    ...(disableRemoteSearch ? {} : { search: searchQuery }),
  })

  const clickOutside = useCallback(() => {
    setOpenSelection(false)
  }, [])

  useOnClickOutside({ ref, handler: clickOutside })

  const { data, isLoading, isError } = useQuery({
    queryKey: [dataUrl, qry],
    queryFn: () => apiFetch(`${dataUrl}?` + qry),
    enabled: !disabled && searchQuery.trim().length >= searchMinimumLength,
  })

  const previousData = usePrevious(data)
  const previousQry = usePrevious(qry)

  useEffect(() => {
    if (onSearchStart && qry != null && qry !== previousQry) {
      onSearchStart()
    }
  }, [onSearchStart, previousQry, qry])

  useEffect(() => {
    if (onSearchFinish && data !== undefined && data !== previousData) {
      onSearchFinish(data?.map(mapItem))
    }
  }, [data, mapItem, onSearchFinish, previousData])

  useEffect(() => {
    let items = [...(customOptions || []), ...(data?.map?.(mapItem) || [])]
      .filter((i) => !!i?.id)
      .filter(
        (i) => searchQuery.trim().length === 0 || i.name.toLowerCase().indexOf(searchInputValue.toLowerCase()) !== -1,
      )
    if (limitTo && limitTo > 0) items = items.slice(0, limitTo)

    setItems(items)

    if (items?.length === 1 && isLastAvailableFilter) {
      onChange(items[0])
    }
  }, [
    data,
    searchQuery,
    searchInputValue,
    idField,
    nameField,
    customOptions,
    setItems,
    limitTo,
    onChange,
    isLastAvailableFilter,
    mapItem,
  ])

  const singleValue = value as Item | undefined
  const multipleValue = value as Item[]

  const singleOnChange = onChange as (value: Item | undefined) => void
  const multipleOnChange = useCallback(
    (innerValue: Item[]) => {
      // remove even occurencies
      const evenIds: string[] = []
      innerValue.forEach((item) => innerValue.filter((i) => i.id === item.id).length % 2 === 0 && evenIds.push(item.id))
      innerValue = innerValue.filter((item) => !evenIds.includes(item.id))
      const externalOnChange = onChange as (value: Item[]) => void
      externalOnChange(innerValue)
    },
    [onChange],
  )

  const removeForSingle = useCallback(() => {
    singleOnChange(undefined)
  }, [singleOnChange])

  const removeItemForMultiple = useCallback(
    (item: Item) => multipleOnChange(multipleValue.filter((i) => i.id !== item.id)),
    [multipleOnChange, multipleValue],
  )

  const onRemoveSingle = disabled ? undefined : removeForSingle
  const onRemoveMultiple = disabled ? undefined : removeItemForMultiple

  useImperativeHandle(remoteSelectRef, () => ({
    clearInput: () => {
      setSearchInputValue('')
      setOpenSelection(false)
    },
    onRemove: multiple ? onRemoveMultiple : onRemoveSingle,
  }))

  const shouldOpenOnFocus = useCallback(
    (event: FocusEvent): boolean => {
      const previous = (event.relatedTarget as Node) || previousActiveElement
      if (!previous || !previous.nodeType) return false
      return !ref?.current?.contains(previous)
    },
    [previousActiveElement],
  )

  const shouldCloseOnBlur = useCallback(
    (event: FocusEvent): boolean => {
      const previous = (event.relatedTarget as Node) || previousActiveElement
      if (!previous || !previous.nodeType) return false
      return !ref?.current?.contains(previous)
    },
    [previousActiveElement],
  )

  useEffect(() => {
    if (
      searchQuery.trim().length >= searchMinimumLength &&
      previousSearchQuery != null &&
      previousSearchQuery !== searchQuery &&
      searchQuery !== ''
    ) {
      setOpenSelection(true)
    }
  }, [searchMinimumLength, searchQuery, previousSearchQuery])

  useEffect(() => {
    if (searchInputValue.trim().length < searchMinimumLength) setOpenSelection(false)
  }, [searchMinimumLength, searchInputValue])

  return (
    <div className="relative w-full">
      {multiple ? (
        <Combobox value={multipleValue} disabled={disabled} onChange={multipleOnChange} multiple>
          <span ref={ref}>
            <Input
              openOnClick={openOnClick}
              showRightArrow={showRightArrow}
              value={searchInputValue}
              onChange={setSearchInputValue}
              preventReplace={preventReplace}
              hasSelected={!!value}
              placeholder={placeholder}
              setOpen={setOpenSelection}
              id={id}
              disabled={disabled}
              tabIndex={tabIndex}
              onFocus={(event) => {
                if (searchInputValue.trim().length >= searchMinimumLength && shouldOpenOnFocus(event)) {
                  setOpenSelection(true)
                }
              }}
              onBlur={(event) => {
                if (shouldCloseOnBlur(event)) setOpenSelection(false)
              }}
              onKeyUp={(event) => {
                if (event.key === 'Escape') {
                  setOpenSelection(false)
                } else if (event.key === 'ArrowDown' && !openSelection) {
                  setOpenSelection(true)
                }
              }}
              icon={icon}
            />
            <SelectionPanel
              selectedValue={multipleValue}
              items={items}
              searchValue={searchQuery}
              isLoading={isLoading}
              isError={isError}
              emptyMessage={emptyMessage}
              close={() => {
                setOpenSelection(false)
              }}
              open={openSelection}
              closeOnSelect={customOptions?.filter((opt) => opt?.preventOthers).map((opt) => opt.id) || false}
            />
          </span>
          <Selected
            items={multipleValue}
            onRemove={
              disabled
                ? undefined
                : (item: Item) => {
                    multipleOnChange(multipleValue.filter((i) => i.id !== item.id))
                  }
            }
          />
        </Combobox>
      ) : (
        <Combobox
          value={singleValue}
          onChange={(item: Item | undefined) => {
            if (singleValue?.id === item?.id) {
              singleOnChange(undefined)
            } else {
              singleOnChange(item)
              setSearchInputValue('')
              setOpenSelection(false)
            }
          }}
          disabled={disabled}
        >
          <span ref={ref}>
            <Input
              openOnClick={openOnClick}
              showRightArrow={showRightArrow}
              value={searchInputValue}
              onChange={setSearchInputValue}
              preventReplace={preventReplace}
              hasSelected={!!value}
              placeholder={placeholder}
              setOpen={setOpenSelection}
              id={id}
              disabled={disabled}
              tabIndex={tabIndex}
              onFocus={(event) => {
                if (searchInputValue.trim().length >= searchMinimumLength && shouldOpenOnFocus(event)) {
                  setOpenSelection(true)
                }
              }}
              onBlur={(event) => {
                if (shouldCloseOnBlur(event)) setOpenSelection(false)
              }}
              onKeyUp={(event) => {
                if (event.key === 'Escape') {
                  setOpenSelection(false)
                } else if (event.key === 'ArrowDown' && !openSelection) {
                  setOpenSelection(true)
                }
              }}
              icon={icon}
            />
            <SelectionPanel
              selectedValue={singleValue || null}
              items={items}
              searchValue={searchQuery}
              isLoading={isLoading}
              isError={isError}
              emptyMessage={emptyMessage}
              close={() => {
                setOpenSelection(false)
              }}
              open={openSelection}
              closeOnSelect={true}
            />
          </span>

          {showSelectedValue && (
            <Selected
              items={singleValue ? [singleValue] : []}
              onRemove={
                disabled
                  ? undefined
                  : () => {
                      singleOnChange(undefined)
                    }
              }
            />
          )}
        </Combobox>
      )}
    </div>
  )
})
