import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { ErrorMessage, SkeletonTableCard, TableCard } from '@components/DataHealthPage'
import { Footer } from '@components/Footer'
import { useApiFetch } from '@services/api'
import { formatUtils } from '@utils'

type ReferenceData = {
  run_timestamp: string | null
  brand_count: number | null
  venue_count: number | null
  record_count: number | null
}

export type TableData = {
  name: string
  description: string
  current: ReferenceData
  previous: ReferenceData
}

export type StatType = {
  name: string
  formatter: (value: any) => string
  field: keyof Omit<ReferenceData, 'run_timestamp'>
}

const DataHealth = () => {
  const { t } = useTranslation('data_health')
  const apiFetch = useApiFetch()
  const { data, isLoading, error, isError } = useQuery({
    queryKey: ['/data/metadata'],
    queryFn: () => apiFetch('/data/metadata') as Promise<TableData[]>,
    refetchInterval: 60000, // refresh every minute
    retry: 3,
  })

  const statsTypes: StatType[] = [
    {
      name: t('total_records'),
      field: 'record_count',
      formatter: formatUtils.formatNumber,
    },
    {
      name: t('total_number_of_artists'),
      field: 'brand_count',
      formatter: formatUtils.formatNumber,
    },
    {
      name: t('total_number_of_venues'),
      field: 'venue_count',
      formatter: formatUtils.formatNumber,
    },
  ]

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('title')} />
      </Helmet>

      <div className="flex w-full flex-col items-center">
        <section className="px-4 pt-6 md:p-8 !pb-0 w-full max-w-[1232px]">
          <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white mb-6 md:mb-8">
            {t('title')}
          </h1>

          <div className="flex flex-col gap-6 md:gap-8 items-center">
            {isLoading ? (
              <>
                {[...Array(5)].map((_, index) => (
                  <SkeletonTableCard key={index} />
                ))}
              </>
            ) : isError ? (
              <ErrorMessage error={error} />
            ) : data?.length === 0 ? (
              <>{t('common:data_tips.no_data.title')}</>
            ) : (
              <>
                {data?.map((item, index) => (
                  <TableCard key={index} data={item} statsTypes={statsTypes} />
                ))}
              </>
            )}
          </div>
        </section>

        <Footer />
      </div>
    </>
  )
}

export default DataHealth
