import { ApiFetch } from '@services/api'
import {
  BackTestFilters,
  GenerateFilters,
  PredictionAssessmentStoreSetFunction,
} from '@stores/predictionAssessmentStore'
import { EventPredictionBackTestEntity, EventPredictionEntity } from '@types'
import { backTestFiltersToQueryParams, generateFiltersToQueryParams } from './queryParams'

export const fetchPredictionsBackTest = async (
  set: PredictionAssessmentStoreSetFunction,
  apiFetch: ApiFetch,
  filters: BackTestFilters,
) => {
  const predictionsBackTest: EventPredictionBackTestEntity[] = await apiFetch(
    `/data/event_prediction_backtest?` + backTestFiltersToQueryParams(filters).toString(),
  )

  set({ predictionsBackTest })
}

export const fetchGenerate = async (
  set: PredictionAssessmentStoreSetFunction,
  apiFetch: ApiFetch,
  filters: GenerateFilters,
) => {
  try {
    const generateData: EventPredictionEntity = await apiFetch(
      `/data/predictions/v2?` + generateFiltersToQueryParams(filters).toString(),
    )
    set({ generateData })
  } catch (error) {
    set({ generateData: null })
  }
}
