import snakeCase from 'lodash/snakeCase'
import { Item } from '@components/RemoteSelect'
import { Filters } from '@stores/tourMarketingStore'

export type CompetitorsExclusiveFilters = {
  category: Item[]
  subcategory: Item[]
  distance: Item | null
  time: Item | null
}

export type CompetitorFilters = Filters & Partial<CompetitorsExclusiveFilters>

export type CompetitorFilterKey = 'category' | 'subcategory' | 'distance' | 'time'

export interface CompetitorFilterField {
  name: CompetitorFilterKey
  data: any
  toParams(): Record<string, string | string[] | undefined>
}

export class CompetitorFilterItem implements CompetitorFilterField {
  name: CompetitorFilterKey
  data: Item | null
  constructor(name: CompetitorFilterKey, data: Item | null) {
    this.name = name
    this.data = data
  }

  toParams() {
    const filterKey = snakeCase(this.name)
    return {
      [filterKey]: this.data?.id,
    }
  }
}

export class CompetitorFilterItemArray implements CompetitorFilterField {
  name: CompetitorFilterKey
  data: Item[]
  constructor(name: CompetitorFilterKey, data: Item[]) {
    this.name = name
    this.data = data
  }

  toParams() {
    const filterKey = snakeCase(this.name)
    return {
      [filterKey]: this.data?.map((v) => v.id),
    }
  }
}
