import { Fragment, useEffect, useState } from 'react'
import { SiteHeaderHeight } from '@components/SiteHeader'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { cn } from '@utils/className'

const SubNavHeight = 48

const InnerNav = ({ label, active = false, onClick }: { label: string; active?: boolean; onClick: () => void }) => {
  return (
    <li
      className={cn(
        'cursor-pointer text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white',
        active &&
          'text-blue-600 dark:text-blue-400 dark:hover:text-blue-400 hover:text-blue-600 shadow-blue-600 shadow-[0_1px]',
        'truncate',
      )}
      onClick={() => onClick()}
    >
      {label}
    </li>
  )
}

export type SubNavItem = { element: HTMLElement; label: string }

type SubNavProps = {
  container: HTMLElement
  items: SubNavItem[]
}

const isInViewport = (element: HTMLElement, container: HTMLElement, offset = 0) => {
  if (!element) return false
  const elementBounds = element.getBoundingClientRect()
  const top = elementBounds.top
  const bottom = elementBounds.bottom

  return (
    (top >= offset && top - offset <= container.clientHeight) || // top showing
    (bottom >= offset && bottom - offset <= container.clientHeight) || // bottom showing
    (top <= offset && bottom + offset >= container.clientHeight) // center showing
  )
}

export const SubNav = ({ container, items }: SubNavProps) => {
  const [firstIndexInViewport, setFirstIndexInViewport] = useState(0)
  const [lastClicked, setLastClicked] = useState<number | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (items.length === 0) return
      if (lastClicked !== null && isInViewport(items[lastClicked].element, container, SiteHeaderHeight + SubNavHeight))
        return
      setLastClicked(null)
      const firstIndexInViewport = items.findIndex((item) =>
        isInViewport(item.element, container, SiteHeaderHeight + SubNavHeight),
      )
      if (firstIndexInViewport !== -1) setFirstIndexInViewport(firstIndexInViewport)
    }
    if (container) {
      container.addEventListener('scroll', handleScroll)
      return () => container.removeEventListener('scroll', handleScroll)
    }
  }, [container, items, lastClicked])

  const onClick = (item: SubNavItem, index: number) => {
    if (item.element && container) {
      const adjustBy = SubNavHeight
      item.element.scrollIntoView()
      if (item.element.offsetTop - container.scrollTop < adjustBy) {
        container.scrollTop = container.scrollTop - adjustBy
      }
      setLastClicked(index)
      setFirstIndexInViewport(index)
    }
  }

  return (
    <div className="w-full px-4 pt-3 mx-auto bg-white dark:border-gray-700 dark:bg-gray-800 border-b sticky top-0 h-[var(--sub-nav-height)] z-[5] group-[.pdf-report]:hidden">
      <ul className="hidden md:flex flex-row font-medium mt-0 mr-6 space-x-8 text-sm h-full">
        {items.map((item, index) => (
          <InnerNav
            key={index}
            label={item.label}
            active={index === firstIndexInViewport}
            onClick={() => onClick(item, index)}
          />
        ))}
      </ul>
      <Listbox as="div" className="relative group-[.pdf-report]:hidden block md:hidden" value={firstIndexInViewport}>
        <Listbox.Button className="cursor-pointer flex items-center justify-between h-[26px] w-full min-w-[90px] rounded bg-white p-2 border border-gray-300 text-gray-500 text-xs font-medium text-left dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 overflow-hidden">
          <span className="block break-all text-ellipsis mx-1">
            {items.length > 0 ? items[firstIndexInViewport].label : ''}
          </span>
          <ChevronDownIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 right-0 border border-gray-300 py-1 mt-1 min-w-[160px] w-full overflow-auto rounded-lg bg-white text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-700 dark:border-gray-500">
            {items.map((item, index) => (
              <Listbox.Option
                key={index}
                value={index}
                onClick={() => onClick(item, index)}
                className={cn(
                  'cursor-pointer w-full select-none py-2 px-3 rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 text-xs flex items-center justify-start',
                  firstIndexInViewport === index && 'text-blue-600 dark:text-blue-400',
                )}
              >
                {item.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )
}
