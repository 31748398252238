import { ChangeEvent } from 'react'
import { useTheme } from '@contexts/Theme'
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid'
import { cn } from '@utils/className'

export function ThemeToggle() {
  const { theme, setTheme } = useTheme()

  const toggle = (event: ChangeEvent<HTMLInputElement>) => {
    setTheme(event.target.checked ? 'dark' : 'light')
  }

  return (
    <div className="rounded-2xl flex items-center justify-center p-[5px] border border-gray-100 dark:border-gray-600">
      <label className="relative inline-flex items-center cursor-pointer ">
        <input type="checkbox" value="" className="sr-only peer" onChange={toggle} />

        <div
          className={cn(
            'flex items-center w-10 h-5 bg-gray-200 rounded-full dark:bg-gray-700 dark:border-gray-600 pl-[2px]',
          )}
        >
          <div
            className={cn(
              'flex justify-center items-center bg-white rounded-full h-4 w-4 transition-all dark:bg-gray-200',
              {
                'translate-x-full border-white ml-[2px]': theme === 'dark',
              },
            )}
          >
            <SunIcon className="absolute dark:invisible visible text-gray-400 w-3 h-3" />
            <MoonIcon className="absolute dark:visible invisible text-gray-400 w-3 h-3" />
          </div>
        </div>
      </label>
    </div>
  )
}
