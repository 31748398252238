import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChartCard } from '@components/ChartCard'
import {
  TicketsSoldByPriceAndTierTable,
  TicketsSoldByTierAndPriceChart,
} from '@components/Charts/TicketsSoldByPriceAndTier'
import { Dropdown, DropdownOption } from '@components/Dropdown'
import { SingleEventContentProps } from '@components/EventContent'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { ChartBarIcon, TableCellsIcon } from '@heroicons/react/24/outline'
import { useTourPlanningDataAvailabilityPlaceholder } from '@hooks/TourPlanning/useTourPlanningDataAvailabilityPlaceholder'
import { useSingleEventStore } from '@stores/singleEventStore'
import { BasePriceTierEntity } from '@types'
import { TIER_BIN_SIZE, calculateBins } from '@utils/charts'

enum DisplayType {
  StackedBarChart = 'stackedBarChart',
  Table = 'table',
}

type SingleEventCumulativeTicketsSoldChartCardProps<AdditionalParamsOnInternalChange> = Pick<
  SingleEventContentProps<AdditionalParamsOnInternalChange>,
  'stringfiedFilterContext' | 'pdfHeader' | 'exportOptions' | 'page'
>

export function SingleEventCumulativeTicketsSoldChartCard<AdditionalParamsOnInternalChange>(
  props: SingleEventCumulativeTicketsSoldChartCardProps<AdditionalParamsOnInternalChange>,
) {
  const { t } = useTranslation('tour_planning')
  const [displayType, setDisplayType] = useState<DisplayType>(DisplayType.StackedBarChart)

  const { event: event, isLoading } = useSingleEventStore((state) => ({
    event: state.event,
    isLoading: state.isLoadingEvent,
  }))

  const { DataPlaceholderIcon, DataPlaceholderMessage } = useTourPlanningDataAvailabilityPlaceholder()

  const seriesData = useMemo(() => {
    const priceTiersData = [event]
      .filter((event) => event.price_tiers && event.price_tiers.length > 0)
      .map((event) => event.price_tiers as BasePriceTierEntity[])
      .flat(1)
    return calculateBins(priceTiersData)
  }, [event])

  const chartsByType = {
    [DisplayType.StackedBarChart]: (
      <TicketsSoldByTierAndPriceChart seriesData={seriesData} tierBinSize={TIER_BIN_SIZE} />
    ),
    [DisplayType.Table]: (
      <TicketsSoldByPriceAndTierTable
        id={VisualizationId.TicketsSoldByPriceAndTierPrimaryMarket}
        seriesData={seriesData}
      />
    ),
  }
  const dropdownOptions: DropdownOption[] = [
    {
      id: DisplayType.StackedBarChart,
      label: t('common:stacked_bar_chart'),
      icon: ChartBarIcon,
      onClick: () => setDisplayType(DisplayType.StackedBarChart),
    },
    {
      id: DisplayType.Table,
      label: t('common:table'),
      icon: TableCellsIcon,
      onClick: () => setDisplayType(DisplayType.Table),
    },
  ]

  return (
    <ChartCard
      id={VisualizationId.CumulativeTicketsSold}
      visualization={VisualizationId.CumulativeTicketsSold}
      title={t('quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.title')}
      hideDownload
      info={t('quantity_sold_by_price_tier:quantity_sold_by_price_tier_chart.info')}
      chart={<>{chartsByType[displayType]}</>}
      showChart={[event].filter((event) => event.time_series && event.time_series.length > 0).length > 0}
      noDataIcon={DataPlaceholderIcon}
      noDataMessage={DataPlaceholderMessage}
      isLoading={isLoading}
      exportFilename={`cumulative_percent_sold${
        props.stringfiedFilterContext ? '-' + props.stringfiedFilterContext : ''
      }`}
      pdfHeader={props.pdfHeader}
      page={props.page}
      chartControlsComponent={<Dropdown options={dropdownOptions} defaultOption={dropdownOptions[0]} />}
      exportOptions={props?.exportOptions?.map((option) => {
        return { ...option, checked: option.id === 'pdf-report-hide-timeseries-chart' }
      })}
    />
  )
}
