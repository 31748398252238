export const feedbackEmail = () => {
  const emailBody = encodeURIComponent(
    `Hello,
  
  Please type in your feedback or questions you need help with?
  
  Thank you.`,
  )
  return `mailto:musicinsights@teamwass.com?subject=STAR App feedback&body=${emailBody}`
}
