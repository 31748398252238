import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import type { DateRangeInputOption, DateRangeInputValue } from 'carbonarc-ui'
import { Button, DateRangeInput } from 'carbonarc-ui'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BallPileIcon } from '@assets/ball-pile.svg'
import { Badge } from '@components/Badge'
import { ClearAllButton } from '@components/ClearAllButton'
import { Mode, RemoteSelectAndSearch } from '@components/Inputs/RemoteSelectAndSearch'
import { SelectRef } from '@components/Inputs/Select'
import { LoadingIcon } from '@components/LoadingIcon'
import { RangeSlider } from '@components/RangeSlider'
import { Item, RemoteSelect } from '@components/RemoteSelect'
import {
  BuildingOfficeIcon,
  CalendarIcon,
  GlobeAltIcon,
  MapPinIcon,
  MegaphoneIcon,
  TicketIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { usePrevious } from '@hooks'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { useApiFetch } from '@services/api'
import { useEventSearchStore } from '@stores/eventSearchStore'
import {
  Filter,
  FilterDateRange,
  FilterItem,
  FilterKey,
  FilterNumberRange,
  FilterText,
  NumberRange,
} from '@stores/eventSearchStore/filterTypes'
import { dateUtils, formatUtils } from '@utils'
import { cn } from '@utils/className'

interface FieldProps {
  label: string
  htmlFor?: string
  labelIcon?: React.ElementType
  containerClassName?: string
  children: React.ReactNode
}

const dateInputLabel = (filter: DateRangeInputValue) => {
  return filter?.option?.value === 'custom'
    ? `${filter.start ? formatUtils.formatDateNumbersOnly(filter.start) : '...'} - ${
        filter.end ? formatUtils.formatDateNumbersOnly(filter.end) : '...'
      }`
    : DateRangeInput.defaultOptions.find((o: DateRangeInputOption) => o.value === filter?.option?.value)?.label || ''
}

type FilterChangedOptions = {
  ignoreKeys?: FilterKey | FilterKey[]
  onlyKeys?: FilterKey | FilterKey[]
}

const filtersChanged = (
  previousFilters: Filter[] | undefined,
  filters: Filter[],
  options: FilterChangedOptions = {},
) => {
  const { ignoreKeys, onlyKeys } = options
  const filterIgnoreKeys = Array.isArray(ignoreKeys) ? ignoreKeys : ignoreKeys ? [ignoreKeys] : []
  const filterOnlyKeys = Array.isArray(onlyKeys) ? onlyKeys : onlyKeys ? [onlyKeys] : []

  const filter =
    filterOnlyKeys.length > 0
      ? (f: Filter) => filterOnlyKeys.includes(f.name)
      : filterIgnoreKeys.length > 0
      ? (f: Filter) => !filterIgnoreKeys.includes(f.name)
      : () => true

  const oldFilters = previousFilters?.filter(filter)
  const newFilters = filters.filter(filter)
  return !isEqual(oldFilters, newFilters)
}

function Field(props: FieldProps) {
  const { label, labelIcon: LabelIcon, containerClassName, children } = props

  return (
    <div className={cn('px-3 py-4', containerClassName)}>
      <label
        className={cn('block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1')}
        htmlFor={props.htmlFor}
      >
        {LabelIcon && <LabelIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />}
        {label}
      </label>
      <div className="mt-2">{children}</div>
    </div>
  )
}

type InputsRef = {
  clearInputs: () => void
}

const Inputs = forwardRef<InputsRef, any>((_, ref) => {
  const { filters, setFilters, isLoading, dateRange, setDateRange, capacityRange, setCapacityRange } =
    useEventSearchStore((state) => ({
      filters: state.pageFilters,
      setFilters: state.setPageFilters,
      isLoading: state.isLoadingEvents,
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      capacityRange: state.capacityRange,
      setCapacityRange: state.setCapacityRange,
    }))
  const previousFilters = usePrevious(filters)
  const apiFetch = useApiFetch()
  const { t } = useTranslation('tour_marketing')

  const artistRef = useRef<SelectRef>(null)
  const venueRef = useRef<SelectRef>(null)
  const stateRef = useRef<SelectRef>(null)
  const cityRef = useRef<SelectRef>(null)
  const promoterRef = useRef<SelectRef>(null)
  const showTypeRef = useRef<SelectRef>(null)

  useImperativeHandle(ref, () => ({
    clearInputs: () => {
      artistRef.current?.clearInput?.()
      venueRef.current?.clearInput?.()
      stateRef.current?.clearInput?.()
      cityRef.current?.clearInput?.()
      promoterRef.current?.clearInput?.()
      showTypeRef.current?.clearInput?.()
      setFilters([])
    },
  }))

  const [venueMode, setVenueMode] = useState<Mode>('select')
  const [promoterMode, setPromoterMode] = useState<Mode>('select')

  const getFiltersFor = useCallback(
    (key: FilterKey) => {
      const preFilters: Filter[] = []
      for (const filter of filters) {
        if (filter instanceof FilterText) continue
        if (filter.name === key) break
        preFilters.push(filter)
      }
      const params: Record<string, string | string[] | undefined> = {}
      for (const filter of preFilters) {
        Object.assign(params, filter.toParams())
      }
      return params
    },
    [filters],
  )

  const getValueFor = useCallback(
    (key: FilterKey) => {
      const idx = filters.findIndex((filter) => filter.name === key)
      return idx > -1 ? filters[idx].data : []
    },
    [filters],
  )

  const getValueByModeFor = useCallback(
    (selectKey: FilterKey, textKey: FilterKey, mode: Mode) => {
      return mode === 'select' ? getValueFor(selectKey) : getValueFor(textKey) || ''
    },
    [getValueFor],
  )

  const getNumberRangeValueFor = useCallback(
    (key: FilterKey): [number | undefined, number | undefined] => {
      const idx = filters.findIndex((filter) => filter.name === key)
      const filter = idx > -1 ? (filters[idx] as FilterNumberRange) : null
      return [filter?.data?.min || undefined, filter?.data?.max || undefined]
    },
    [filters],
  )

  const isFirstFilter = useCallback(
    (key: FilterKey) => {
      const filtersNonText = filters.filter((filter) => !(filter instanceof FilterText))
      return filtersNonText.length === 0 || filtersNonText.findIndex((filter) => filter.name === key) === 0
    },
    [filters],
  )

  const insertOrUpdateFilter = (newFilter: Filter) => {
    const currentIndex = filters.findIndex((filter) => filter.name === newFilter.name)
    if (currentIndex === -1) {
      setFilters([...filters, newFilter])
    } else {
      const newFilters = [...filters]
      newFilters[currentIndex] = newFilter
      setFilters(newFilters)
    }
  }

  const handleSelectItem = (key: FilterKey, value?: Item[]) => {
    if (!value || value.length === 0) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters)
    } else {
      const newFilter = new FilterItem(key, value)
      insertOrUpdateFilter(newFilter)
    }
  }

  const handleSearchInput = (key: FilterKey, value?: string) => {
    if (!value || value.trim().length === 0) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters)
    } else {
      const newFilter = new FilterText(key, value)
      insertOrUpdateFilter(newFilter)
    }
  }

  const handleSelectNumberRange = (key: FilterKey, value?: [number, number] | null) => {
    if (!value) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters)
    } else {
      const numberRange: NumberRange = { min: value[0], max: value[1] }
      const newFilter = new FilterNumberRange(key, numberRange)
      insertOrUpdateFilter(newFilter)
    }
  }

  const handleSelectDate = (key: FilterKey, value?: DateRangeInputValue | null) => {
    if (!value) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters)
    } else {
      const newFilter = new FilterDateRange(key, value)
      insertOrUpdateFilter(newFilter)
    }
  }

  const handleSelectSearchItem = (
    selectKey: FilterKey,
    textKey: FilterKey,
    mode: Mode,
    value?: Item[] | string | null,
  ) => {
    if (!value || value?.length === 0) {
      const newFilters = filters.filter((filter) => filter.name !== selectKey && filter.name !== textKey)
      setFilters(newFilters)
    } else {
      let newFilters: Filter[] = []
      let newFilter: Filter
      if (mode === 'text') {
        newFilters = filters.filter((filter) => filter.name !== selectKey)
        newFilter = new FilterText(textKey, value as string)
      } else {
        newFilters = filters.filter((filter) => filter.name !== textKey)
        newFilter = new FilterItem(selectKey, value as Item[])
      }
      const currentIndex = newFilters.findIndex((filter) => filter.name === newFilter.name)
      if (currentIndex === -1) {
        newFilters = [...newFilters, newFilter]
      } else {
        newFilters = [...newFilters]
        newFilters[currentIndex] = newFilter
      }
      setFilters(newFilters)
    }
  }

  const handleChangeMode =
    (selectKey: FilterKey, textKey: FilterKey, currentMode: Mode, setMode: Dispatch<SetStateAction<Mode>>) =>
    (newMode: Mode) => {
      if (newMode !== currentMode) {
        setMode(newMode)
        if (currentMode === 'select') handleSelectItem(selectKey, undefined)
        else handleSearchInput(textKey, undefined)
      }
    }

  const updateDateRange = useCallback(async () => {
    setDateRange({ min: undefined, max: undefined, loading: true })
    try {
      const query = new URLSearchParams()
      const dateFilters = getFiltersFor('date')
      Object.entries(dateFilters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => query.append(key, v))
        } else if (value !== undefined) {
          query.append(key, value)
        }
      })
      const data = await apiFetch(`/data/events/lookups/date/range?${query.toString()}`)
      const min = dateUtils.parseDate(data.min) || undefined
      const max = dateUtils.parseDate(data.max) || undefined
      setDateRange({ min, max, loading: false })
    } catch {
      setDateRange({ min: undefined, max: undefined, loading: false })
    }
  }, [apiFetch, setDateRange, getFiltersFor])

  useEffect(() => {
    if (
      filtersChanged(previousFilters, filters, { ignoreKeys: ['date', 'venueName', 'promoterName'] }) ||
      (previousFilters && previousFilters?.length > 0 && filters.length === 0)
    ) {
      updateDateRange()
    }
  }, [filters, previousFilters, updateDateRange])

  const updateCapacityRange = useCallback(async () => {
    setCapacityRange({ ...capacityRange, loading: true })
    try {
      const query = new URLSearchParams()
      const capacityFilters = getFiltersFor('sellableCapacity')
      Object.entries(capacityFilters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => query.append(key, v))
        } else if (value !== undefined) {
          query.append(key, value)
        }
      })
      const data = await apiFetch(`/data/events/lookups/capacity/range?${query.toString()}`)
      const min = +data.min || undefined
      const max = +data.max || undefined
      setCapacityRange({ min, max, loading: false })
    } catch {
      setCapacityRange({ min: undefined, max: undefined, loading: false })
    }
  }, [setCapacityRange, capacityRange, getFiltersFor, apiFetch])

  useEffect(() => {
    if (
      filtersChanged(previousFilters, filters, { ignoreKeys: ['sellableCapacity', 'venueName', 'promoterName'] }) ||
      (previousFilters && previousFilters?.length > 0 && filters.length === 0)
    ) {
      updateCapacityRange()
    }
  }, [filters, previousFilters, updateCapacityRange])

  useEffect(() => {
    if (filtersChanged(previousFilters, filters, { onlyKeys: ['venue', 'venueName'] })) {
      const selectIndex = filters.findIndex((filter) => filter.name === 'venue')
      const textIndex = filters.findIndex((filter) => filter.name === 'venueName')
      if (textIndex > -1 || selectIndex > -1) {
        let newMode: Mode = venueMode
        if (textIndex > -1 && (selectIndex === -1 || textIndex < selectIndex)) newMode = 'text'
        if (newMode !== venueMode) setVenueMode(newMode)
      }
    }
  }, [filters, previousFilters, venueMode])

  useEffect(() => {
    if (filtersChanged(previousFilters, filters, { onlyKeys: ['promoter', 'promoterName'] })) {
      const selectIndex = filters.findIndex((filter) => filter.name === 'promoter')
      const textIndex = filters.findIndex((filter) => filter.name === 'promoterName')
      if (textIndex > -1 || selectIndex > -1) {
        let newMode: Mode = promoterMode
        if (textIndex > -1 && (selectIndex === -1 || textIndex < selectIndex)) newMode = 'text'
        if (newMode !== promoterMode) setPromoterMode(newMode)
      }
    }
  }, [filters, previousFilters, promoterMode])

  return (
    <div
      className={cn(
        'w-full text-center font-bold flex flex-col md:flex-row lg:flex-col divide-y divide-gray-200 dark:divide-gray-700 md:justify-between',
        'lg:!overflow-y-auto',
      )}
    >
      <Field label={t('control_panel.artists')} labelIcon={UserGroupIcon} htmlFor="artists">
        <RemoteSelect
          id="artists"
          multiple
          onChange={(item) => handleSelectItem('artist', item)}
          extraParams={getFiltersFor('artist')}
          value={getValueFor('artist')}
          dataUrl="/data/events/lookups/brands"
          placeholder={t('common:search_type', { type: t('control_panel.artists') })}
          openOnClick={!isFirstFilter('artist') && !isFirstFilter('showType')}
          disableRemoteSearch={!isFirstFilter('artist') && !isFirstFilter('showType')}
          searchMinimumLength={isFirstFilter('artist') ? 2 : 0}
          tabIndex={1}
          disabled={isLoading()}
          ref={artistRef}
        />
      </Field>

      <Field label={t('control_panel.venues')} labelIcon={MapPinIcon} htmlFor="venues">
        <RemoteSelectAndSearch
          id="venues"
          multiple
          onChange={(item) => handleSelectSearchItem('venue', 'venueName', venueMode, item as any)}
          mode={venueMode}
          changeMode={handleChangeMode('venue', 'venueName', venueMode, setVenueMode)}
          extraParams={getFiltersFor('venue')}
          value={getValueByModeFor('venue', 'venueName', venueMode)}
          dataUrl="/data/events/lookups/venues"
          placeholder={{
            search: t('common:search_type', { type: t('control_panel.venues') }),
            text: t('common:text_placeholder', { type: t('control_panel.venue') }),
          }}
          openOnClick={!isFirstFilter('venue') && !isFirstFilter('showType')}
          disableRemoteSearch={!isFirstFilter('venue') && !isFirstFilter('showType')}
          searchMinimumLength={isFirstFilter('venue') ? 2 : 0}
          tabIndex={2}
          disabled={isLoading()}
          ref={venueRef}
        />
      </Field>

      <Field label={t('control_panel.date_range')} labelIcon={CalendarIcon}>
        <div className="mt-2">
          <DateRangeInput
            value={getValueFor('date')}
            onChange={(dates) => handleSelectDate('date', dates)}
            placeholder={t('common:select_type', { type: t('control_panel.date_range') })}
            tabIndex={3}
            disabled={isLoading() || dateRange.loading}
            hideInputValue
            minDate={dateRange.min}
            maxDate={dateRange.max}
          />
          {getValueFor('date')?.option && (
            <div className="mt-5 text-left relative max-w-[230px] flex flex-wrap gap-2 md:max-h-[63px] md:overflow-y-auto lg:max-h-none print:max-w-none print:gap-0">
              <Badge
                text={dateInputLabel(getValueFor('date') || {})}
                onRemove={
                  isLoading()
                    ? undefined
                    : () => {
                        handleSelectDate('date', null)
                      }
                }
              />
            </div>
          )}
        </div>
      </Field>

      <Field label={t('control_panel.cities')} labelIcon={BuildingOfficeIcon} htmlFor="cities">
        <RemoteSelect
          id="cities"
          multiple
          onChange={(item) => handleSelectItem('city', item)}
          value={getValueFor('city')}
          dataUrl="/data/events/lookups/cities"
          extraParams={getFiltersFor('city')}
          placeholder={t('common:search_type', { type: t('control_panel.city') })}
          openOnClick
          disableRemoteSearch={!isFirstFilter('city') && !isFirstFilter('showType')}
          searchMinimumLength={isFirstFilter('city') ? 2 : 0}
          tabIndex={4}
          disabled={isLoading()}
          ref={cityRef}
        />
      </Field>

      <Field label={t('control_panel.states')} labelIcon={GlobeAltIcon} htmlFor="states">
        <RemoteSelect
          id="states"
          multiple
          onChange={(item) => handleSelectItem('state', item)}
          value={getValueFor('state')}
          dataUrl="/data/events/lookups/states"
          extraParams={getFiltersFor('state')}
          placeholder={t('common:search_type', { type: t('control_panel.state') })}
          openOnClick
          disableRemoteSearch={!isFirstFilter('state') && !isFirstFilter('showType')}
          searchMinimumLength={isFirstFilter('state') ? 2 : 0}
          tabIndex={5}
          disabled={isLoading()}
          ref={stateRef}
        />
      </Field>

      <Field label={t('control_panel.promoter')} labelIcon={MegaphoneIcon} htmlFor="promoters">
        <RemoteSelectAndSearch
          id="promoters"
          multiple
          onChange={(item) => handleSelectSearchItem('promoter', 'promoterName', promoterMode, item as any)}
          mode={promoterMode}
          changeMode={handleChangeMode('promoter', 'promoterName', promoterMode, setPromoterMode)}
          extraParams={getFiltersFor('promoter')}
          value={getValueByModeFor('promoter', 'promoterName', promoterMode)}
          dataUrl="/data/events/lookups/promoters"
          placeholder={{
            search: t('common:search_type', { type: t('control_panel.promoter') }),
            text: t('common:text_placeholder', { type: t('control_panel.promoter') }),
          }}
          openOnClick={!isFirstFilter('promoter') && !isFirstFilter('showType')}
          disableRemoteSearch={!isFirstFilter('promoter') && !isFirstFilter('showType')}
          searchMinimumLength={isFirstFilter('promoter') ? 2 : 0}
          tabIndex={6}
          disabled={isLoading()}
          ref={promoterRef}
        />
      </Field>

      <Field label={t('control_panel.sub_categories')} labelIcon={TicketIcon} htmlFor="showtypes">
        <RemoteSelect
          id="showtypes"
          multiple
          onChange={(item) => handleSelectItem('showType', item)}
          value={getValueFor('showType')}
          dataUrl="/data/events/lookups/sub_categories"
          extraParams={getFiltersFor('showType')}
          placeholder={t('common:search_type', { type: t('control_panel.sub_categories') })}
          openOnClick
          disableRemoteSearch
          tabIndex={7}
          disabled={isLoading()}
          ref={showTypeRef}
        />
      </Field>
      <Field label={t('control_panel.sellable_capacity')} labelIcon={BallPileIcon}>
        <RangeSlider
          min={capacityRange.min}
          max={capacityRange.max}
          value={getNumberRangeValueFor('sellableCapacity')}
          onChange={(v) => handleSelectNumberRange('sellableCapacity', v)}
          disabled={isLoading() || capacityRange.loading}
        />
      </Field>
    </div>
  )
})

export const ControlPanel = () => {
  const { applyFilters, canApply, isLoading, filters, isShowingSingleEvent, clearData } = useEventSearchStore(
    (state) => ({
      applyFilters: state.applyFilters,
      canApply: state.canApplyFilters,
      isLoading: state.isLoadingEvents,
      filters: state.pageFilters,
      isShowingSingleEvent: state.isShowingSingleEvent,
      clearData: state.clearData,
    }),
  )

  const searchParamsHandler = useSearchParamsObject()
  const inputsRef = useRef<InputsRef>(null)

  const { t } = useTranslation('tour_marketing')

  return (
    <section
      className={cn(
        'bg-white border-gray-200 dark:border-gray-700 dark:bg-gray-800 border-r lg:sticky w-full lg:w-[250px] lg:shrink-0 group-[.pdf-report]:hidden lg:flex lg:flex-col lg:justify-between',
        isShowingSingleEvent()
          ? 'lg:max-h-[calc(100vh-var(--main-nav-height)-var(--sub-nav-height))] lg:top-[var(--sub-nav-height)]'
          : 'lg:max-h-[calc(100vh-var(--main-nav-height))] lg:top-0',
      )}
      id="control_panel"
    >
      <Inputs ref={inputsRef} />
      <div className="px-3 py-4 w-full bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 print:hidden grid grid-rows-2 sm:grid-rows-1 sm:grid-cols-2 md:flex lg:grid-cols-2 gap-2 shrink-0">
        <Button
          variant="default"
          onClick={() => applyFilters(searchParamsHandler)}
          disabled={!canApply() || isLoading()}
          className="w-full md:w-[250px] lg:w-full disabled:opacity-25"
          tabIndex={8}
        >
          {isLoading() ? <LoadingIcon className="w-6" /> : t('common:apply')}
        </Button>
        <ClearAllButton
          disabled={filters.length === 0 || isLoading()}
          onClick={() => {
            inputsRef.current?.clearInputs?.()
            clearData(searchParamsHandler)
          }}
          tabIndex={9}
        />
      </div>
    </section>
  )
}
