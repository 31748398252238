import React, { useEffect } from 'react'
import { Table } from '@components/Table'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { isDate } from '@utils/date'
import { formatCurrency, formatDateNumbersOnly } from '@utils/format'

interface InsightsTableProps {
  tableData: (string | number)[][]
  tableColumns: string[]
}

const formatCellIfDate = (cell: string | number) => {
  if (typeof cell === 'number') return cell
  return isDate(cell) ? formatDateNumbersOnly(cell) : cell
}

const MONETARY_COLUMNS = ['artist_net', 'gross_potential']

export const ChatBotTable = React.memo(({ tableData, tableColumns }: InsightsTableProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [previousScrollSize, setPreviousScrollSize] = React.useState(0)

  useEffect(() => {
    const containerParent = containerRef.current?.querySelector('table')?.parentElement
    containerRef.current
      ?.querySelector('table')
      ?.parentElement?.scrollTo({ top: previousScrollSize - 50, behavior: 'smooth' })
    setPreviousScrollSize(containerParent?.scrollHeight || 0)
  }, [tableData.length, previousScrollSize])

  return (
    <div ref={containerRef}>
      <Table
        id={VisualizationId.Chatbot}
        header={tableColumns.map((column) => ({ column: column.replace(/_/g, ' ') }))}
        body={tableData.map((row) => ({
          row: row.map((cell, i) => {
            if (MONETARY_COLUMNS.includes(tableColumns[i])) {
              return formatCurrency(Number(cell))
            }
            return formatCellIfDate(cell) || 'N/A'
          }),
          className: '!p-4 whitespace-nowrap lg:whitespace-normal',
        }))}
        showTable
        isLoading={false}
        hideDownload
        exportFilename={`insights-table-${new Date().toISOString().slice(0, 10)}`}
      />
    </div>
  )
})
