import { MouseEvent, ReactNode, useCallback, useEffect, useRef } from 'react'
import { Table as FlowbiteTable } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { CheckboxOption, ExportDropdown } from '@components/Export'
import { ExportCsvParams } from '@components/Export/ExportDropdown/ExportCSVOption'
import { TCustomConfirmMethod } from '@components/Export/ExportModal'
import { VisualizationEvaluation } from '@components/VisualizationEvaluation'
import { VisualizationId, VisualizationType } from '@contexts/VisualizationEvaluation/types'
import { TableCellsIcon } from '@heroicons/react/24/outline'
import { formatUtils } from '@utils'
import { cn } from '@utils/className'
import { PdfHeader, exportComponentToPDF, hasDownloadPDFUrlParam } from '@utils/pdf'
import { TablePagination } from './TablePagination'

interface Body {
  row: ReactNode[]
  className?: string
  onClick?: (e: MouseEvent) => void
}

interface Header {
  column: ReactNode
  className?: string
}

interface TableProps {
  id: VisualizationId
  visualization?: VisualizationType
  header: Header[]
  body?: Body[]
  footer?: ReactNode
  filter?: ReactNode
  showTable?: boolean
  isLoading?: boolean
  showTotal?: boolean
  totalLabel?: string
  totalPostfix?: string
  hideDownload?: boolean
  exportFilename?: string
  csvDownload?: ExportCsvParams
  csvDownloadSelected?: ExportCsvParams
  pdfHeader?: PdfHeader
  noDataMessage?: React.ReactNode
  noDataIcon?: React.ElementType
  noDataClassName?: string
  className?: string
  pagination?: TablePagination
  truncateColumns?: number[]
  ignoreMaxHeight?: boolean
  exportOptions?: CheckboxOption[]
  page?: string
}

const tableHeadCellClassNames =
  '!bg-gray-100 text-gray-500 text-xs font-semibold uppercase dark:!bg-gray-900 dark:text-gray-100 h-12'

export function Table(props: TableProps) {
  const { t } = useTranslation()
  const {
    id,
    visualization,
    header = [],
    body = [],
    showTable,
    isLoading,
    hideDownload = false,
    exportFilename = 'table',
    csvDownload,
    csvDownloadSelected,
    pdfHeader,
    showTotal = true,
    totalLabel = 'Total',
    totalPostfix,
    noDataMessage = t('data_tips.no_data.title'),
    noDataIcon: NoDataIcon = TableCellsIcon,
    pagination = undefined,
    truncateColumns = [],
    footer = null,
    ignoreMaxHeight = false,
    noDataClassName = null,
    className = null,
    filter = null,
    exportOptions,
    page,
  } = props
  const tableRef = useRef<HTMLDivElement>(null)

  const handleCustomExportPDF: TCustomConfirmMethod = useCallback(
    (customPdfReportHide, customPdfComment) => {
      if (!isLoading && showTable) {
        exportComponentToPDF({
          component: tableRef.current,
          filename: exportFilename || 'table',
          header: pdfHeader,
          autoClose: false,
          customPdfReportHide,
          customPdfComment,
        })
      }
    },
    [isLoading, pdfHeader, tableRef, exportFilename, showTable],
  )

  useEffect(() => {
    if (!isLoading && showTable && hasDownloadPDFUrlParam(id)) {
      exportComponentToPDF({
        component: tableRef.current,
        filename: exportFilename || 'table',
        header: pdfHeader,
      })
    }
  }, [isLoading, showTable, id, exportFilename, pdfHeader])

  if (isLoading) {
    // Limit number of cols to 8
    const numCols = header?.length > 8 ? 8 : header?.length || 6
    const numRows = 5
    return (
      <div className="w-full">
        {(showTotal || !hideDownload) && (
          <div className="rounded-t-lg bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400 sticky top-0 min-h-[4rem]">
            <div>
              <div className="flex justify-between items-center p-5 md:px-6 font-normal text-base">
                <span>
                  <div className="animate-pulse my-1 h-3 w-24 inline-block rounded-lg bg-gray-200 dark:bg-gray-700"></div>
                </span>
              </div>
              {filter && <div className="mx-4 border-t py-4">{filter}</div>}
            </div>
          </div>
        )}

        <FlowbiteTable className={cn('animate-pulse', (!pagination || pagination.pages < 2) && 'rounded-lg')}>
          <FlowbiteTable.Head>
            {[...Array(numCols)].map((_, index) => (
              <FlowbiteTable.HeadCell className={tableHeadCellClassNames} key={index}>
                <div className="h-4 w-full rounded-lg bg-gray-200 dark:bg-gray-700"></div>
              </FlowbiteTable.HeadCell>
            ))}
          </FlowbiteTable.Head>
          <FlowbiteTable.Body className="divide-y">
            {[...Array(numRows)].map((_, index) => (
              <FlowbiteTable.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 h-[50px]" key={index}>
                {[...Array(numCols)].map((_, cIndex) => (
                  <FlowbiteTable.Cell key={cIndex}>
                    <div className="h-3 w-full rounded-lg bg-gray-200 dark:bg-gray-700"></div>
                  </FlowbiteTable.Cell>
                ))}
              </FlowbiteTable.Row>
            ))}
          </FlowbiteTable.Body>
        </FlowbiteTable>
        <TablePagination pagination={pagination} loading={isLoading} />
      </div>
    )
  }

  if (!showTable) {
    return (
      <div
        className={cn(
          'bg-white w-full h-72 flex items-center justify-center shadow rounded-lg dark:bg-gray-800 print:shadow-none',
          isLoading && 'animate-pulse',
          'group-[.pdf-report]:hidden',
          !isLoading && noDataClassName,
        )}
      >
        <div className="text-sm text-gray-500 flex flex-col items-center justify-center dark:text-gray-400">
          <div className="w-24 h-24 bg-gray-50 dark:bg-gray-700 rounded-full gap-2 flex items-center justify-center mb-2">
            <NoDataIcon className="w-16 text-gray-300 dark:text-gray-500 stroke-1" />
          </div>

          {!isLoading && noDataMessage}
        </div>
      </div>
    )
  }
  const headerCSV = header.map(({ column }) => (typeof column === 'string' ? column.toString() : '-'))
  const bodyCSV = body.map(({ row }) => row.map((cell) => (typeof cell === 'string' ? cell.toString() : '-')))

  const downloadCsvProps = {
    filename: csvDownload?.filename || exportFilename,
    ...(csvDownload?.apiUrl ? { apiUrl: csvDownload?.apiUrl } : { rows: [headerCSV, ...bodyCSV] }),
  }
  const downloadSelectedCsvProps = {
    filename: csvDownloadSelected?.filename || exportFilename,
    ...(csvDownloadSelected?.apiUrl ? { apiUrl: csvDownloadSelected?.apiUrl } : { rows: [headerCSV, ...bodyCSV] }),
  }
  return (
    <div className={cn('shadow print:shadow-none rounded-lg overflow-hidden w-full', className)}>
      {(showTotal || !hideDownload) && (
        <div className="rounded-t-lg bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400 group-[.pdf-report]:hidden">
          <div>
            <div className="flex justify-between items-center p-4 md:px-6 font-normal text-base">
              {showTotal && !isLoading && showTable ? (
                <span>
                  {totalLabel}:{' '}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {formatUtils.formatNumber(pagination ? pagination.total : body.length)}{' '}
                    {totalPostfix && <>{totalPostfix}</>}
                  </span>{' '}
                </span>
              ) : (
                <></>
              )}
              {!isLoading && showTable && !hideDownload && page && (
                <ExportDropdown
                  pdfOptions={{ id: page, options: exportOptions }}
                  {...(!hideDownload && {
                    csvOptions: {
                      all: csvDownload ? downloadCsvProps : undefined,
                      selected: csvDownloadSelected ? downloadSelectedCsvProps : undefined,
                    },
                  })}
                  pdfCustomConfirm={handleCustomExportPDF}
                />
              )}
            </div>
            {filter && <div className="mx-4 border-t py-4">{filter}</div>}
          </div>
        </div>
      )}

      <div
        className={cn(
          'overflow-auto',
          !ignoreMaxHeight && {
            'max-h-[20rem]': showTotal || !hideDownload,
            'max-h-[24rem]': !(showTotal || !hideDownload),
          },
          'print:max-h-none',
          'group-[.pdf-report]:overflow-visible group-[.pdf-report]:max-h-none',
        )}
        ref={tableRef}
      >
        <table className="w-full table-fixed text-left text-sm text-gray-500 dark:text-gray-400">
          <thead className="group/head text-xs uppercase text-gray-700 dark:text-gray-400 sticky top-0">
            <tr>
              {header.map(({ column, className }, index) => (
                <th
                  className={cn(
                    'p-4 w-[140px] h-[50px]',
                    {
                      'group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg': !(
                        showTotal || !hideDownload
                      ),
                    },
                    tableHeadCellClassNames,
                    className,
                  )}
                  key={index}
                >
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map(({ row, className, onClick }, rowIndex) => (
              <tr
                className={cn('bg-white dark:bg-gray-800 dark:text-gray-200', className)}
                onClick={onClick}
                key={rowIndex}
              >
                {row.map((data, colIndex) => (
                  <td
                    className={cn(
                      'border-b px-4 w-[200px] h-[50px] dark:border-gray-700',
                      truncateColumns.includes(colIndex) && 'truncate',
                    )}
                    key={colIndex}
                    title={truncateColumns.includes(colIndex) ? data?.toString() : undefined}
                  >
                    {data}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {footer && (
            <tfoot className="group/foot text-xs font-medium text-gray-500 dark:text-gray-100">
              <tr>
                <td className="group-last/foot:first:rounded-bl-lg group-last/foot:last:rounded-br-lg bg-gray-100 dark:bg-gray-900 px-6 py-3 text-center">
                  {footer}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      <TablePagination pagination={pagination} />
      {visualization && (
        <div className="rounded-b-lg bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400 flex items-end gap-4 p-4 flex-col border-t dark:border-gray-700 group-[.pdf-report]:hidden">
          <VisualizationEvaluation type={visualization} />
        </div>
      )}
    </div>
  )
}
