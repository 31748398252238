import { ApiFetch } from '@services/api'
import { Filters, StatsData, UpcomingEventsStoreSetFunction } from '@stores/upcomingEventsStore'
import { PaginatedData, StarEventEntity } from '@types'
import { filtersToQueryParams } from './queryParams'

export const fetchEvents = async (
  set: UpcomingEventsStoreSetFunction,
  apiFetch: ApiFetch,
  filters: Filters,
  page: number,
) => {
  const eventsData: PaginatedData<StarEventEntity> = await apiFetch(
    `/data/eventsummaryupcoming?` + filtersToQueryParams(filters, { page }).toString(),
  )

  set({ eventsData })
}

export const fetchEventsStatsData = async (
  set: UpcomingEventsStoreSetFunction,
  apiFetch: ApiFetch,
  filters: Filters,
) => {
  const eventsStatsData: StatsData = await apiFetch(
    `/data/eventsummaryupcomingstats?` + filtersToQueryParams(filters).toString(),
  )

  set({ eventsStatsData })
}

export const fetchPreviousEventsStatsData = async (
  set: UpcomingEventsStoreSetFunction,
  apiFetch: ApiFetch,
  filters: Filters,
) => {
  const previousEventsStatsData: StatsData = await apiFetch(
    `/data/eventsummaryupcomingstats?` + filtersToQueryParams(filters, { isPrevious: true }).toString(),
  )

  set({ previousEventsStatsData })
}

export const checkHasPrivateArtist = async (apiFetch: ApiFetch) => {
  const hasPrivateArtist = await apiFetch(`/data/artist/has_private_artist`)
  return hasPrivateArtist
}
