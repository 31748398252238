import { useTranslation } from 'react-i18next'
import { EventContentProps } from '@components/EventContent'
import { SecondaryMarketChartCard } from '@components/EventContent/InsightsAndTrends/SecondaryMarketChartCard'
import { TimeSeriesChartCard } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard'
import { cn } from '@utils/className'

type InsightsAndTrendsProps<AdditionalParamsOnInternalChange> = Pick<
  EventContentProps<AdditionalParamsOnInternalChange>,
  | 'loading'
  | 'pdfHeader'
  | 'hasEventFilter'
  | 'stringfiedFilterContext'
  | 'primaryMarket'
  | 'secondaryMarket'
  | 'summaryData'
  | 'customEvents'
  | 'benchmark'
  | 'additionalParamsForInternalChange'
  | 'exportOptions'
  | 'page'
>

export function InsightsAndTrends<AdditionalParamsOnInternalChange>(
  props: InsightsAndTrendsProps<AdditionalParamsOnInternalChange>,
) {
  const { t } = useTranslation('tour_marketing')

  const {
    loading,
    stringfiedFilterContext,
    pdfHeader,
    hasEventFilter,
    primaryMarket,
    customEvents,
    benchmark,
    secondaryMarket,
    summaryData,
    exportOptions,
    page,
    additionalParamsForInternalChange,
  } = props
  const timeSeriesId = 'pdf-report-hide-timeseries-chart'
  const secondaryMarketId = 'pdf-report-hide-secondary-market-chart'
  const timeSeriesProps = {
    loading,
    stringfiedFilterContext,
    pdfHeader,
    hasEventFilter,
    primaryMarket,
    customEvents,
    benchmark,
    exportOptions: exportOptions?.map((option) => {
      return { ...option, checked: option.id === timeSeriesId }
    }),
    page: page,
    additionalParamsForInternalChange,
  }
  const secondaryMarketProps = {
    loading,
    pdfHeader,
    stringfiedFilterContext,
    secondaryMarket,
    summaryData,
    exportOptions: exportOptions?.map((option) => {
      return { ...option, checked: option.id === secondaryMarketId }
    }),
    page: page,
    additionalParamsForInternalChange,
  }
  return (
    <div className="flex flex-col items-start group-[.pdf-report-hide-timeseries-chart.pdf-report-hide-secondary-market-chart]:hidden">
      <h2 className="text-xl md:text-3xl font-semibold leading-tight tracking-tighter sm:text-xl lg:text-3xl dark:text-white mb-5 md:mb-6">
        {t('insights_and_trends')}
      </h2>

      <div className={cn('w-full mb-4', `group-[.pdf-report-hide-timeseries-chart]:hidden`)}>
        <TimeSeriesChartCard {...timeSeriesProps} />
      </div>

      <div className={cn('w-full', `group-[.pdf-report-hide-secondary-market-chart]:hidden`)}>
        <SecondaryMarketChartCard {...secondaryMarketProps} />
      </div>
    </div>
  )
}
