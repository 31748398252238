import { stringUtils } from '@utils'
import { PdfHeader } from '@utils/pdf'
import {
  BackTestFilterKey,
  FilterField,
  FilterItem,
  GenerateFilterKey,
  GroupedFilterField,
} from './predictionAssessmentControlPanelStoreTypes'
import {
  Filters as PageFilters,
  defaultBackTestFilters,
  defaultGenerateFilters,
} from './predictionAssessmentStoreTypes'

export const stringifyFilters = (allFilters: PageFilters) => {
  const filters = allFilters.backTest
  const metric = stringUtils.filefyString(filters?.metric?.name)
  const artist = stringUtils.filefyArray(filters?.artist?.map((i) => i.name))
  const venue = stringUtils.filefyArray(filters?.venue?.map((i) => i.name))

  return [metric, artist, venue].filter((v) => !!v).join('-')
}

export const getPdfHeader = (filters: PageFilters): PdfHeader => {
  const useGenerateInfo = filters.generate?.artist

  const artist = useGenerateInfo
    ? filters.generate?.artist?.name
    : filters.backTest?.artist?.map((i) => i.name).join(', ')
  const venue = useGenerateInfo ? filters.generate?.venue?.name : filters.backTest?.venue?.map((i) => i.name).join(', ')

  const metricOrSeason = useGenerateInfo ? filters.generate.season?.name : filters.backTest.metric?.name

  let text = ''
  let lines = 0

  const buildText = (str: string) => {
    text += `${str}\n`
    ++lines
  }

  if (metricOrSeason) buildText(metricOrSeason)
  if (artist) buildText(artist)
  if (venue) buildText(venue)

  return { text: text.substring(0, text.length - 1), lines }
}

export const filterFieldsToPageFilters = (
  generateFilters: FilterField<GenerateFilterKey>[] | null,
  backTestFilters: FilterField<BackTestFilterKey>[] | null,
) => {
  return {
    generate: generateFilters ? generateFilterFieldsToGenerateFilters(generateFilters) : defaultGenerateFilters,
    backTest: backTestFilters ? backTestFilterFieldsToBackTestFilters(backTestFilters) : defaultBackTestFilters,
  }
}

export const backTestFilterFieldsToBackTestFilters = (filters: FilterField<BackTestFilterKey>[]) => {
  return {
    metric: filters.find((filter) => filter.name === 'metric')?.data || defaultBackTestFilters.metric,
    artist: filters.find((filter) => filter.name === 'artist')?.data || defaultBackTestFilters.artist,
    venue: filters.find((filter) => filter.name === 'venue')?.data || defaultBackTestFilters.venue,
    year: filters.find((filter) => filter.name === 'year')?.data || defaultBackTestFilters.year,
  }
}

export const generateFilterFieldsToGenerateFilters = (filters: FilterField<GenerateFilterKey>[]) => {
  return {
    artist: filters.find((filter) => filter.name === 'artist')?.data || defaultGenerateFilters.artist,
    venue: filters.find((filter) => filter.name === 'venue')?.data || defaultGenerateFilters.venue,
    season: filters.find((filter) => filter.name === 'season')?.data || defaultGenerateFilters.season,
  }
}

export const pageFilterToFilterFields = (pageFilters: PageFilters): GroupedFilterField => {
  const backTestFilters: FilterField<BackTestFilterKey>[] = []
  const generateFilters: FilterField<GenerateFilterKey>[] = []

  const backTest = pageFilters.backTest
  const generate = pageFilters.generate
  if (backTest?.metric) {
    backTestFilters.push(new FilterItem('metric', backTest.metric))
  }
  if (backTest?.artist?.length > 0) {
    backTestFilters.push(new FilterItem('artist', backTest.artist))
  }
  if (backTest?.venue?.length > 0) {
    backTestFilters.push(new FilterItem('venue', backTest.venue))
  }
  if (backTest?.year?.length > 0) {
    backTestFilters.push(new FilterItem('year', backTest.year))
  }

  if (generate?.artist) {
    generateFilters.push(new FilterItem('artist', generate.artist))
  }
  if (generate?.venue) {
    generateFilters.push(new FilterItem('venue', generate.venue))
  }
  if (generate?.season) {
    generateFilters.push(new FilterItem('season', generate.season))
  }

  return {
    backTestFilters,
    generateFilters,
  }
}
