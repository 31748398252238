import { DateRangeInput, DateRangeInputOption } from 'carbonarc-ui'
import '@stores/tourPlanningStore'
import { dateUtils, stringUtils } from '@utils'
import { formatDateNumbersOnly } from '@utils/format'
import { PdfHeader } from '@utils/pdf'
import { FilterDate, FilterField, FilterItem } from './tourPlanningControlPanelStoreTypes'
import { FiltersDate, Filters as PageFilters, defaultFilters } from './tourPlanningStoreTypes'

export const stringifyFilters = (filters: PageFilters) => {
  const artist = stringUtils.filefyArray(filters.artist?.map((i) => i.name))
  const region = stringUtils.filefyArray(filters.region?.map((i) => i.name))
  const venue = stringUtils.filefyArray(filters.venue?.map((i) => i.name))

  return [artist, region, venue].filter((v) => !!v).join('-')
}

export const getPdfHeader = (filters: PageFilters): PdfHeader => {
  const artist = filters.artist?.map((i) => i.name).join(', ')
  const showType = filters.showType?.map((i) => i.name).join(', ')
  const region = filters.region?.map((i) => i.name).join(', ')
  const venue = filters.venue?.map((i) => i.name).join(', ')
  const perfDate = filters.dateRangeOption?.label
    ? `${filters.dateRangeOption?.label} (${formatDateNumbersOnly(filters.since)} - ${formatDateNumbersOnly(
        filters.until,
      )})`
    : ''

  let text = ''
  let lines = 0

  const buildText = (str: string) => {
    text += `${str}\n`
    ++lines
  }

  if (artist) buildText(artist)
  if (showType) buildText(showType)
  if (region) buildText(region)
  if (venue) buildText(venue)
  if (perfDate) buildText(perfDate)

  return { text: text.substring(0, text.length - 1), lines }
}

export const filterFieldsToPageFilters = (filters: FilterField[]) => {
  const since = filters.find((filter) => filter.name === 'date')?.data?.start
  const until = filters.find((filter) => filter.name === 'date')?.data?.end

  return {
    artist: filters.find((filter) => filter.name === 'artist')?.data || defaultFilters.artist,
    showType: filters.find((filter) => filter.name === 'showType')?.data || defaultFilters.showType,
    region: filters.find((filter) => filter.name === 'region')?.data || defaultFilters.region,
    venue: filters.find((filter) => filter.name === 'venue')?.data || defaultFilters.venue,
    since: since ? new Date(since) : defaultFilters.since,
    until: until ? new Date(until) : defaultFilters.until,
    dateRangeOption: filters.find((filter) => filter.name === 'date')?.data?.option || defaultFilters.dateRangeOption,
  }
}

export const pageFilterToFilterFields = (obj: PageFilters) => {
  let filters: FilterField[] = []
  if (obj.artist) filters.push(new FilterItem('artist', Array.isArray(obj.artist) ? obj.artist : [obj.artist]))
  if (obj.showType)
    filters.push(new FilterItem('showType', Array.isArray(obj.showType) ? obj.showType : [obj.showType]))
  if (obj.region && (obj.region.length !== 1 || obj.region[0].id !== '0')) {
    filters.push(new FilterItem('region', obj.region))
  }
  if (obj.venue && (obj.venue.length !== 1 || obj.venue[0].id !== '0')) {
    filters.push(new FilterItem('venue', obj.venue))
  }
  if (obj.dateRangeOption) {
    const dates = generateDateFields(obj.dateRangeOption.value, obj.since, obj.until)
    if (dates.dateRangeOption)
      filters.push(
        new FilterDate('date', {
          option: dates.dateRangeOption,
          start: dates.since,
          end: dates.until,
        }),
      )
  }
  filters = filters.filter((f) => !(f instanceof FilterItem) || f.data.length > 0)
  return filters
}

export const generateDateFields = (
  optionValue: number | string,
  start: Date | string | null,
  end: Date | string | null,
) => {
  const value = optionValue === 'custom' ? 'custom' : +optionValue
  const result: FiltersDate = {
    dateRangeOption: null,
    since: null,
    until: null,
  }
  if (!value) return result
  if (value === 'custom') {
    if (start && end) {
      result.dateRangeOption = { label: 'Custom', value: 'custom' }
      result.since = start instanceof Date ? start : new Date(start) || defaultFilters.since
      result.until = end instanceof Date ? end : new Date(end) || defaultFilters.until
    }
  } else {
    const option = DateRangeInput.defaultOptions.find((o: DateRangeInputOption) => o.value === value)
    if (option) {
      const dates = dateUtils.generateIntervalFromToday(value)
      result.dateRangeOption = option
      result.since = dates.start
      result.until = dates.end
    }
  }
  return result
}
