import { pageFilterToFilterFields } from '@stores/tourMarketingStore'
import { FilterDate, FilterField, FilterItem } from './tourMarketingControlPanelStoreTypes'
import { Filters as PageFilters, defaultFilters } from './tourMarketingStoreTypes'

const FILTER_VERSION = 1
const LOCALSTORAGE_KEY = 'session_tourMarketingFilters'

export const saveLocalFilters = (filters: PageFilters | FilterField[]) => {
  const data = Array.isArray(filters) ? filters : pageFilterToFilterFields(filters)
  const options = {
    version: FILTER_VERSION,
    data,
  }
  localStorage[LOCALSTORAGE_KEY] = JSON.stringify(options)
}

export const getLocalFilters = () => {
  const savedOptions = localStorage[LOCALSTORAGE_KEY]
  if (!savedOptions) return null

  const savedFilters = JSON.parse(savedOptions)
  if (savedFilters.version === 1) {
    const filters: FilterField[] = []
    savedFilters.data.forEach((saved: any) => {
      const FilterKlass = saved.type === 'Date' ? FilterDate : FilterItem
      filters.push(new FilterKlass(saved.name, saved.data))
    })
    return filters
  } else {
    const mergedOptions = { ...defaultFilters, ...savedFilters }
    const filters = pageFilterToFilterFields(mergedOptions)
    return filters
  }
}

export const clearLocalFilters = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY)
}
