import { Button } from 'carbonarc-ui'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Square2StackIcon } from '@heroicons/react/24/outline'
import { copyToClipboard } from '@utils/string'

type CopyReportUrlButtonProps = {
  disabled?: boolean
}

export const CopyReportUrlButton = ({ disabled = false }: CopyReportUrlButtonProps) => {
  const { t } = useTranslation()

  const copyUrlToClipboard = async () => {
    try {
      copyToClipboard(window.location.href)
      toast.success(t('bookmark.copied_report_to_clipboard'))
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button
        variant="outline"
        onClick={() => copyUrlToClipboard()}
        disabled={disabled}
        className="bg-white dark:bg-transparent dark:border-gray-800 dark:text-gray-300 dark:hover:bg-gray-800 group-[.pdf-report]:hidden"
      >
        <Square2StackIcon className="w-4 h-4 stroke-2" />
      </Button>
    </>
  )
}
