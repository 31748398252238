import { Filters } from '@stores/eventSearchStore'
import { createFilterInstances } from '@stores/eventSearchStore/utils'
import { SingleEventContext } from '@stores/singleEventStore/singleEventStoreTypes'

const FILTER_VERSION = 1
const LOCALSTORAGE_KEY = 'session_eventSearchFilters'

export const saveLocalContext = (context: SingleEventContext) => {
  const options = {
    version: FILTER_VERSION,
    data: context,
  }
  if (context.filters.length === 0) clearLocalContext()
  else localStorage[LOCALSTORAGE_KEY] = JSON.stringify(options)
}

export const getLocalContext = (): SingleEventContext | null => {
  const savedOptions = localStorage[LOCALSTORAGE_KEY]
  if (!savedOptions) return null

  const savedFilters = JSON.parse(savedOptions)
  if (savedFilters.version === 1) {
    const filters: Filters = createFilterInstances(savedFilters.data.filters)
    savedFilters.data.filters = filters
    return savedFilters.data as SingleEventContext
  }
  return null
}

export const clearLocalContext = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY)
}
