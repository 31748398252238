import qs from 'qs'
import { SetURLSearchParams } from 'react-router-dom'
import { Item } from '@components/RemoteSelect'
import { TourMarketingContext } from '@stores/bookmarkStore/utils/types'
import { DEFAULT_SECONDARY_MARKET_CHART_TYPE, SecondaryMarketChartType } from '@stores/slices/eventDataSlice'
import { FilterDate, FilterField, FilterItem } from '@stores/tourMarketingStore'
import { objToQueryParam, queryParamToObj, updateSearchParamQuery } from '@utils/url'
import { Filters as PageFilters, defaultFilters } from './tourMarketingStoreTypes'

export const customEventObjectFilterToFilterFields = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj
      .map((filter) => {
        if (!('name' in filter) || !('data' in filter)) return null
        if (filter.name === 'perfDate') return new FilterDate(filter.name, filter.data)
        return new FilterItem(filter.name, filter.data)
      })
      .filter((f) => !!f)
  } else {
    const filters: FilterField[] = []
    if (obj?.artist) filters.push(new FilterItem('artist', obj.artist))
    if (obj?.showType) filters.push(new FilterItem('showType', obj.showType))
    if (obj?.venue) filters.push(new FilterItem('venue', obj.venue))
    if (obj?.perfDate) filters.push(new FilterDate('perfDate', obj.perfDate))
    return filters
  }
}

export const filterFieldsToCustomEventObjectFilter = (arr: FilterField[]) => {
  const filters: any = {}
  arr.forEach((filter) => {
    filters[filter.name] = filter.data
  })
  return filters
}

export const queryParamsToContext = (
  searchParams: string | URLSearchParams,
  setSearchParams?: SetURLSearchParams,
): TourMarketingContext => {
  const paramsData = qs.parse(searchParams.toString())

  const filters = {
    artist: (paramsData.artist as Item) || defaultFilters.artist,
    showType: (paramsData.showType as Item) || defaultFilters.showType,
    venue: (paramsData.venue as Item) || defaultFilters.venue,
    perfDate: (paramsData.perfDate as Item) || defaultFilters.perfDate,
  }

  let benchmark = undefined
  if ('selectedBenchmark' in paramsData) {
    benchmark = {
      option: queryParamToObj(paramsData.selectedBenchmark as string),
      events: [] as any[],
    }
    if ('customEvents' in paramsData) {
      benchmark.events = queryParamToObj(paramsData.customEvents as string)
      if (Array.isArray(benchmark.events) && benchmark.events.length > 0) {
        benchmark.events = benchmark.events.map(customEventObjectFilterToFilterFields)
        if (setSearchParams) {
          setSearchParams(updateSearchParamQuery(searchParams, 'customEvents', objToQueryParam(benchmark.events)), {
            replace: true,
          })
        }
      }
    }
  }

  let competitorsFilters = undefined
  if ('competitorsFilters' in paramsData) {
    const competitorParams = paramsData.competitorsFilters as qs.ParsedQs
    competitorsFilters = {
      category: (competitorParams.category as Item[]) || [],
      subcategory: (competitorParams.subcategory as Item[]) || [],
      distance: (competitorParams.distance as Item) || null,
      time: (competitorParams.time as Item) || null,
    }
  }
  let secondaryMarket = {
    chartType: DEFAULT_SECONDARY_MARKET_CHART_TYPE,
  }
  if ('secondaryMarket' in paramsData) {
    const secondaryMarketParams = paramsData.secondaryMarket as qs.ParsedQs
    if ('chartType' in secondaryMarketParams) {
      secondaryMarket = {
        chartType: (secondaryMarketParams.chartType as SecondaryMarketChartType) || DEFAULT_SECONDARY_MARKET_CHART_TYPE,
      }
    }
  }

  return { filters, benchmark, competitorsFilters, secondaryMarket }
}

export const contextToQueryParams = (context: TourMarketingContext) => {
  const { benchmark, filters, competitorsFilters, secondaryMarket } = context
  let contextToStringify: any = { ...filters, competitorsFilters }
  if (secondaryMarket?.chartType !== DEFAULT_SECONDARY_MARKET_CHART_TYPE) {
    contextToStringify = {
      ...contextToStringify,
      secondaryMarket,
    }
  }

  let params = qs.stringify(contextToStringify).toString()

  if (benchmark && benchmark.option) {
    params = updateSearchParamQuery(params, 'selectedBenchmark', objToQueryParam(benchmark.option))
    if (benchmark.events && benchmark.events?.length > 0) {
      const benchmarkEvents = objToQueryParam(benchmark.events)
      params = updateSearchParamQuery(params, 'customEvents', benchmarkEvents)
    }
  }
  return params
}

export const filtersToQueryParams = (filters: PageFilters) => {
  const { artist, showType, venue, perfDate } = filters

  const urlSearchParams = new URLSearchParams()

  artist && urlSearchParams.append('artist', artist?.id)
  showType && urlSearchParams.append('show_type', showType?.id)
  venue && urlSearchParams.append('venue', venue?.id)
  perfDate && urlSearchParams.append('perfdate', perfDate?.id)

  return urlSearchParams
}

export const getUrlSearchParamsFromCustomEventFilters = (filters: PageFilters) => {
  const { artist, venue, perfDate } = filters

  const urlSearchParams = new URLSearchParams()

  artist && urlSearchParams.append('artist', artist?.id)
  venue && urlSearchParams.append('venue', venue?.id)
  perfDate && urlSearchParams.append('perfdate', perfDate?.id)

  return urlSearchParams
}
