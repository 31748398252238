import cloneDeep from 'lodash/cloneDeep'

/**
 * Sort objects/arrays by keys and values, on all levels
 * Filter items with nullish values
 * When an array has a mix of objects, primitives and arrays, the order is:
 * - primitives (sorted)
 * - arrays (sorted)
 * - objects (sorted)
 */
export class Sorter {
  obj: any
  eraseNullish: boolean

  constructor(obj: any, eraseNullish = false) {
    this.obj = cloneDeep(obj)
    this.eraseNullish = eraseNullish
  }

  sort() {
    return Array.isArray(this.obj) ? this.sortArray(this.obj) : this.sortObject(this.obj)
  }

  sortArray(arr: any[]): any[] {
    arr = arr.filter((o) => o != null)

    for (const index in arr) {
      if (Array.isArray(arr[index])) {
        arr[index] = this.sortArray(arr[index] as any[]) as any
        if (this.eraseNullish && arr[index].length === 0) delete arr[index]
      } else if (typeof arr[index] === 'object') {
        arr[index] = this.sortObject(arr[index] as any) as any
        if (this.eraseNullish && Object.keys(arr[index] as any).length === 0) delete arr[index]
      } else {
        arr[index] = arr[index] as any
      }
    }

    return arr.sort(this.compareValues.bind(this))
  }

  sortObject<T extends object>(obj: T): T {
    if (!obj || typeof obj !== 'object') return obj
    obj = this.sortObjectKeys(obj)
    for (const key in obj) {
      if (obj[key] == null) {
        delete obj[key]
      } else if (Array.isArray(obj[key])) {
        obj[key] = this.sortArray(obj[key] as any[]) as any
        if (this.eraseNullish && (obj[key] as any[]).length === 0) delete obj[key]
      } else if (typeof obj[key] === 'object') {
        obj[key] = this.sortObject(obj[key] as any) as any
        if (this.eraseNullish && Object.keys(obj[key] as any).length === 0) delete obj[key]
      } else {
        obj[key] = obj[key] as any
      }
    }
    return obj
  }

  compareArrays(a: any[], b: any[]): number {
    a = this.sortArray(a)
    b = this.sortArray(b)
    for (let i = 0, j = 0, len = Math.min(a.length, b.length); i < len && j < len; i++, j++) {
      const aVal = a[i]
      const bVal = b[i]
      const comp = this.compareValues(aVal, bVal)
      if (comp !== 0) return comp
    }
    return a.length < b.length ? -1 : a.length > b.length ? 1 : 0
  }

  sortObjectKeys<T extends object>(obj: T): T {
    const keys = Object.keys(obj).sort() as (keyof T)[]
    const newObj = {} as T
    keys.forEach((key) => {
      newObj[key] = obj[key]
    })
    return newObj
  }

  compareObjects<T1 extends object, T2 extends object>(a: T1, b: T2, keyIndex = 0): number {
    a = this.sortObjectKeys(a)
    b = this.sortObjectKeys(b)
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)
    if (aKeys.length < bKeys.length && keyIndex >= aKeys.length) return -1
    if (aKeys.length > bKeys.length && keyIndex >= bKeys.length) return 1
    if (aKeys.length === bKeys.length && keyIndex >= aKeys.length) return 0

    if (aKeys[keyIndex] === bKeys[keyIndex]) {
      const key = aKeys[keyIndex] as keyof T1 & keyof T2
      const comp = this.compareValues(a[key], b[key])
      if (comp !== 0) return comp
      return this.compareObjects(a, b, keyIndex + 1)
    } else {
      return aKeys[keyIndex] > bKeys[keyIndex] ? 1 : -1
    }
  }

  compareValues(a: any, b: any): number {
    if (Array.isArray(a) && Array.isArray(b)) {
      return this.compareArrays(a, b)
    } else if (Array.isArray(a) || Array.isArray(b)) {
      if (Array.isArray(a)) return typeof b === 'object' ? -1 : 1
      return typeof a === 'object' ? 1 : -1
    } else if (typeof a === 'object' && typeof b === 'object') {
      return this.compareObjects(a, b)
    } else if (typeof a === 'object' || typeof b === 'object') {
      return typeof a === 'object' ? 1 : -1
    } else {
      return a === b ? 0 : a > b ? 1 : -1
    }
  }
}
