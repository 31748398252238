import React, { useEffect, useState } from 'react'
import { Button, TextInput } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ChatBotMessage } from '@components/InsightsChatBot/ChatBotMessage'
import { GetStartedCard } from '@components/InsightsChatBot/GetStartedMessage'
import { LoadingMessage } from '@components/InsightsChatBot/LoadingMessage'
import { useAuth } from '@components/Okta'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useApiFetch, useCancelableApiFetch } from '@services/api'
import { AI_USER_NAME, formatDollarNumber, formatMessageDates, highlightMessageKeyWord } from '@utils/insights'

interface APIResponse {
  id: string
  table_data: (string | number)[][]
  column_names: string[]
  natural_language_output: string
  status: string
}

interface MessagesData {
  user: string
  data: {
    message?: string
    answer?: APIResponse
  }
}

const validateMessageData = (messageData: MessagesData) => {
  return (
    messageData &&
    typeof messageData === 'object' &&
    typeof messageData.user === 'string' &&
    messageData.data &&
    typeof messageData.data === 'object' &&
    (messageData.data.message === undefined || typeof messageData.data.message === 'string') &&
    (messageData.data.answer === undefined ||
      (typeof messageData.data.answer === 'object' &&
        typeof messageData.data.answer.id === 'string' &&
        typeof messageData.data.answer.natural_language_output === 'string' &&
        typeof messageData.data.answer.status === 'string' &&
        Array.isArray(messageData.data.answer.column_names) &&
        messageData.data.answer.column_names.every((name) => typeof name === 'string') &&
        Array.isArray(messageData.data.answer.table_data) &&
        messageData.data.answer.table_data.every(
          (row) => Array.isArray(row) && row.every((cell) => typeof cell === 'string' || typeof cell === 'number'),
        )))
  )
}

interface ChatHistoryDBObject {
  id: string
  user_input: string
  status: string
  natural_language_output: string
  column_names: string[]
  table_data: string[][]
  created_at: string
}

const validateChatObject = (chat: ChatHistoryDBObject) => {
  return (
    chat &&
    typeof chat === 'object' &&
    typeof chat.id === 'string' &&
    typeof chat.user_input === 'string' &&
    typeof chat.status === 'string' &&
    typeof chat.natural_language_output === 'string' &&
    Array.isArray(chat.column_names) &&
    chat.column_names.every((name) => typeof name === 'string') &&
    Array.isArray(chat.table_data) &&
    chat.table_data.every(
      (row) => Array.isArray(row) && row.every((cell) => typeof cell === 'string' || typeof cell === 'number'),
    ) &&
    typeof chat.created_at === 'string'
  )
}

const BAD_DATA_MESSAGE: MessagesData = {
  user: AI_USER_NAME,
  data: {
    message: 'Sorry, something went wrong with your request. Please try again',
  },
}

function mapDatabaseChatHistoryToMessages(chatHistory: ChatHistoryDBObject[], user: string): MessagesData[] {
  const mappedMessageData = chatHistory.flatMap((chat) => {
    if (validateChatObject(chat) === false) {
      if (chat?.user_input) {
        return [
          {
            user,
            data: {
              message: chat.user_input,
            },
          },
          BAD_DATA_MESSAGE,
        ]
      }

      return BAD_DATA_MESSAGE
    }

    const aiAnswer = {
      user: AI_USER_NAME,
    } as MessagesData

    if (chat.status === 'success') {
      let aiMessage = highlightMessageKeyWord(chat.natural_language_output || '', chat.table_data)
      aiMessage = formatMessageDates(aiMessage)
      aiMessage = formatDollarNumber(aiMessage)
      aiAnswer.data = {
        message: aiMessage,
        answer: {
          id: chat.id,
          table_data: chat.table_data,
          column_names: chat.column_names,
          natural_language_output: chat.natural_language_output,
          status: chat.status,
        },
      }
    } else {
      aiAnswer.data = {
        message: 'Sorry, something went wrong with the request. Please try again',
      }
    }

    return [
      {
        user,
        data: {
          message: chat.user_input,
        },
      },
      aiAnswer,
    ]
  })

  return mappedMessageData.filter(validateMessageData)
}

export function ChatBot() {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const scrollFocusRef = React.useRef<HTMLLIElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [messages, setMessages] = useState<MessagesData[]>([])
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const [started, setStarted] = useState(false)
  const [wasUpperArrowPressed, setWasUpperArrowPressed] = useState(false)
  const { t } = useTranslation('chatbot')
  const { user: userInfo } = useAuth()
  const user = userInfo?.email || ''

  const [cancelableApiFetch, abortAPIFetch] = useCancelableApiFetch()
  const fetchApi = useApiFetch()

  useEffectOnce(() => {
    const fetchChats = async () => {
      try {
        const response = await fetchApi('/data/chat')

        if (response?.length) {
          setStarted(true)
          setMessages(() => {
            return mapDatabaseChatHistoryToMessages(response, user)
          })
        }
      } catch (error) {
        setStarted(true)
        addMessage({
          user: AI_USER_NAME,
          data: { message: t('errors.fetch-chat-history') },
        })
      }
    }
    fetchChats()
  })

  useEffect(() => {
    const { scrollTop = 0, offsetHeight = 0, scrollHeight = 0 } = containerRef.current || {}
    if (scrollTop + offsetHeight <= scrollHeight) {
      scrollFocusRef.current?.scrollIntoView({ behavior: 'instant' })
    }
  })

  useEffect(() => {
    setWasUpperArrowPressed(false)
    if (typeof input === 'string') {
      inputRef?.current?.setSelectionRange(input.length, input.length)
    }
  }, [wasUpperArrowPressed])

  function addMessage(newMessage: MessagesData) {
    if (validateMessageData(newMessage) === false) return

    setMessages((previousMessages) => [...previousMessages, newMessage])
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (typeof input !== 'string') return

    const question = input.trim()
    setMessages((previousMessages) => [...previousMessages, { user, data: { message: question } }])
    setInput('')
    setLoading(true)

    try {
      const apiData = await cancelableApiFetch<APIResponse>('/data/chat/experimental', {
        method: 'POST',
        body: JSON.stringify({ question }),
      })
      const { natural_language_output, table_data } = apiData
      let message = natural_language_output
      message = highlightMessageKeyWord(message, table_data)
      message = formatMessageDates(message)
      message = formatDollarNumber(message)
      addMessage({
        user: AI_USER_NAME,
        data: {
          message,
          answer: apiData,
        },
      })
    } catch (error: any) {
      const errorCode: string = error ? error.message || error.status : undefined

      if (error != undefined) {
        const errorMessage = t(`errors.${errorCode}`, {
          defaultValue: t('errors._unknown'),
        })
        addMessage({
          user: AI_USER_NAME,
          data: { message: errorMessage },
        })
      } else {
        addMessage({
          user: AI_USER_NAME,
          data: { message: 'Something went wrong, please try again.' },
        })
      }
    }

    setLoading(false)
  }

  return (
    <section className="w-full flex flex-col h-full">
      <div
        ref={containerRef}
        className={`p-8 bg-white dark:bg-gray-800 flex overflow-y-scroll h-full ${
          messages.length === 0 ? 'justify-center items-center' : 'justify-end'
        }`}
      >
        {!started ? (
          <GetStartedCard
            onGetStarted={() => {
              setStarted(true)
              setMessages([
                {
                  user: AI_USER_NAME,
                  data: { message: t('get-started-asking') },
                },
              ])
              inputRef.current?.focus()
            }}
          />
        ) : (
          <ul className="w-full flex flex-col gap-2">
            {messages.map(({ user, data }: MessagesData, i: number) => {
              if (data) {
                return (
                  <ChatBotMessage
                    key={i}
                    chatId={data.answer?.id}
                    user={user}
                    message={data.message || ''}
                    tableData={data.answer?.table_data}
                    tableColumns={data.answer?.column_names}
                    // fullResultCount={data.answer?.table_data.length} // FIXME: This is coming in the next PR
                  />
                )
              } else {
                return null
              }
            })}

            {loading && <LoadingMessage abortAPIFetch={() => abortAPIFetch()} />}
            <li ref={scrollFocusRef} className="py-4" />
          </ul>
        )}
      </div>
      <form className="flex p-3 bg-blue-50 dark:bg-gray-500 rounded-b-lg" onSubmit={handleSubmit}>
        <TextInput
          ref={inputRef}
          className="flex-1"
          placeholder={t('placeholder')}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'ArrowUp') {
              const lastUserInput = messages.findLast((d) => d.user == user)
              const message = lastUserInput?.data?.message || ''
              setInput(message)
              setWasUpperArrowPressed(true)
            } else if (e.key === 'ArrowDown') {
              setInput('')
            }
          }}
        />
        <Button type="submit" variant="ghost" className="peer" disabled={!started || !input || loading}>
          {/* Only visible for screenreaders */}
          <span className="absolute m-[-1px] h-[1px] w-[1px] overflow-hidden border-0 p-0">{t('send-questions')}</span>
          <PaperAirplaneIcon className="w-6 fill-blue-600 dark:fill-white peer-disabled:fill-gray-500" />
        </Button>
      </form>
    </section>
  )
}
