import { ChangeEvent, useCallback, useState } from 'react'
import { Checkbox, Label, Textarea } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '@components/Modal'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { downloadPDFInNewTab } from '@utils/pdf'
import { CheckboxOption } from './ExportButton'

const EXPORT_OPTIONS = {
  PRIMARY_MARKET_OPTION: 'pdf-report-hide-timeseries-chart',
} as const

const isTimeSeriesOption = (optionId: string) => optionId === EXPORT_OPTIONS.PRIMARY_MARKET_OPTION

export type TCustomConfirmMethod = (pdfReportHideParams: string[], comment: string) => void

interface ExportModalProps {
  show: boolean
  setShow: (show: boolean) => void
  pageId: string
  options: CheckboxOption[]
  multipleArtistsSelected?: boolean
  customConfirm?: TCustomConfirmMethod
}

export function ExportModal(props: ExportModalProps) {
  const { show, setShow, pageId, options = [], multipleArtistsSelected } = props
  const { t } = useTranslation('tour_marketing')
  const [optionsChecked, setOptionsChecked] = useState<Record<string, boolean> | null>(null)
  const [comment, setComment] = useState('')

  useEffectOnce(() => {
    const optionsChecked = options.reduce(
      (obj, option) => ({
        ...obj,
        [option.id]:
          !option.disabled && option.checked !== false && (!isTimeSeriesOption(option.id) || !multipleArtistsSelected),
      }),
      {},
    )

    setOptionsChecked(optionsChecked)
  })

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOptionsChecked((previousOptionsChecked) => ({
      ...previousOptionsChecked,
      [e.target.id]: e.target.checked,
    }))
  }

  const handleConfirm = useCallback(() => {
    const pdfReportHideParams = Object.entries(optionsChecked || {})
      .filter(([, checked]) => !checked)
      .map(([key]) => key)

    // if customConfirm is provided, skip opening in new tab
    if (props.customConfirm) {
      props.customConfirm(pdfReportHideParams, comment)
    } else {
      downloadPDFInNewTab(pageId, pdfReportHideParams, comment)
    }

    setShow(false)
  }, [optionsChecked, pageId, comment, props, setShow])

  return (
    <ConfirmationModal
      show={show}
      setShow={setShow}
      title={t('common:export.report')}
      confirmButton={{
        label: t('common:export.title'),
        onClick: handleConfirm,
        disabled: Object.values(optionsChecked || []).every((option) => !option),
      }}
      cancelButton={{ label: t('common:cancel'), variant: 'outline', onClick: () => setShow(false) }}
    >
      <div className="flex flex-col gap-4">
        <p className="leading-tight text-xs font-normal text-gray-500 dark:text-gray-200 mb-3">
          {t('common:export.description')}
        </p>

        <div className="flex flex-col gap-3">
          {optionsChecked &&
            options.map((option) => (
              <div key={option.id} className="flex items-center group">
                <Checkbox
                  id={option.id}
                  disabled={option.disabled || (isTimeSeriesOption(option.id) && multipleArtistsSelected)}
                  onChange={handleCheckboxChange}
                  checked={optionsChecked[option.id]}
                />
                <Label
                  htmlFor={option.id}
                  disabled={option.disabled || (isTimeSeriesOption(option.id) && multipleArtistsSelected)}
                  className="leading-none text-sm font-medium text-gray-900 ml-2 disabled:text-gray-400"
                >
                  {option.label}
                </Label>
                {isTimeSeriesOption(option.id) && multipleArtistsSelected && (
                  <div className="relative inline-block ml-2">
                    <InformationCircleIcon className="h-5 w-5 text-gray-400 cursor-help" />
                    <div className="absolute z-10 w-60 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700 invisible group-hover:visible group-hover:opacity-100 bottom-full left-1/2 -translate-x-1/2 -translate-y-2">
                      Disabled when multiple artists are selected
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        <Textarea
          rows={4}
          required
          maxLength={500}
          placeholder={`Please leave any comments or remarks here to be exported with your PDF`}
          onChange={(event) => {
            setComment(event.target.value)
          }}
        />
      </div>
    </ConfirmationModal>
  )
}
