import { Toast, useTheme } from 'carbonarc-ui'
import { Toaster as HotToaster, ToastBar, toast } from 'react-hot-toast'
import { cn } from '@utils/className'

export function Toaster() {
  const toastTheme = useTheme().theme.toast

  return (
    <HotToaster containerClassName="react-hot-toast" containerStyle={{ top: 100 }}>
      {(t) => {
        return (
          <ToastBar toast={t} style={{ padding: 0, boxShadow: 'none' }}>
            {({ message }) => (
              <Toast
                theme={{
                  root: {
                    base: cn(
                      toastTheme.root.base,
                      'text-sm font-normal items-start',
                      'w-44 md:w-96',
                      t.type === 'success' && 'bg-green-50 text-green-800',
                      t.type === 'error' && 'bg-red-50 text-red-800',
                    ),
                  },
                }}
              >
                {message}
                <Toast.Toggle
                  theme={{
                    base: cn(
                      toastTheme.toggle.base,
                      'bg-transparent hover:bg-transparent',
                      t.type === 'success' && 'text-green-700 hover:text-green-500',
                      t.type === 'error' && 'text-red-700 hover:text-red-500',
                    ),
                  }}
                  onClick={() => toast.dismiss(t.id)}
                />
              </Toast>
            )}
          </ToastBar>
        )
      }}
    </HotToaster>
  )
}
