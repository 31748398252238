import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { createControlPanelSlice } from '@stores/slices/controlPanelSlice'
import { FilterField, TourPlanningControlPanelStoreState } from './tourPlanningControlPanelStoreTypes'

export const defaultFilterValue = {
  artist: [],
  showType: [],
  region: [],
  venue: [],
  date: {
    start: null,
    end: null,
    option: null,
  },
}

const defaultDateRange = {
  min: undefined,
  max: undefined,
  loading: false,
}

export const useTourPlanningControlPanelStore = create<TourPlanningControlPanelStoreState>()(
  immer(
    devtools((set, get, store) => ({
      ...createControlPanelSlice<FilterField>(set, get, store),

      dateRange: defaultDateRange,
      setDateRange: (dateRange) => set({ dateRange }),
    })),
  ),
)
