import snakeCase from 'lodash/snakeCase'
import type { Item } from '@components/Inputs/Select'
import { ControlPanelSlice } from '@stores/slices/controlPanelSlice'

export type BackTestFilterKey = 'metric' | 'artist' | 'venue' | 'year'

export type GenerateFilterKey = 'season' | 'artist' | 'venue'

export interface FilterField<FilterKey extends string> {
  name: FilterKey
  data: any
  toParams(): Record<string, string | string[] | undefined>
}

export type GroupedFilterField = {
  backTestFilters: FilterField<BackTestFilterKey>[]
  generateFilters: FilterField<GenerateFilterKey>[]
}

export class FilterItem<FilterKey extends string> implements FilterField<FilterKey> {
  name: FilterKey
  data: Item[] | Item | null
  constructor(name: FilterKey, data: Item[] | Item | null) {
    this.name = name
    this.data = data
  }

  toParams() {
    const filterKey = snakeCase(this.name)
    return {
      [filterKey]: Array.isArray(this.data) ? this.data?.map((v) => v.id) : this.data?.id,
    }
  }
}

export type predictionAssessmentControlPanelStoreState = {
  backTest: ControlPanelSlice<FilterField<BackTestFilterKey>>
  generate: ControlPanelSlice<FilterField<GenerateFilterKey>>
}
