import { useTranslation } from 'react-i18next'
import { useVisualizationEvaluation } from '@contexts/VisualizationEvaluation/hook'
import { VisualizationEvaluationOptions } from '@contexts/VisualizationEvaluation/options'
import { VisualizationType } from '@contexts/VisualizationEvaluation/types'

export const VisualizationEvaluation = ({ type }: { type: VisualizationType }) => {
  const { openEvaluation } = useVisualizationEvaluation(type)
  const { t } = useTranslation('common')
  return (
    <div className="text-sm font-normal text-gray-500 dark:text-gray-400 flex items-center group-[.pdf-report]:hidden">
      {t('visualization_evaluation.question')}
      <VisualizationEvaluationOptions size="sm" onSelect={openEvaluation} />
    </div>
  )
}
