import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BookmarkButton } from '@components/Bookmarks/BookmarkButton'
import { CopyReportUrlButton } from '@components/Bookmarks/CopyReportUrlButton'
import { ExportComment } from '@components/Export'
import { ExportButton } from '@components/Export/ExportButton'
import { TCustomConfirmMethod } from '@components/Export/ExportModal'
import { SubNavItem } from '@components/SubNav'
import { TimeSeriesChartCard } from '@components/TourPlanning/CumulativeTicketsSoldChartCard/TimeSeriesChartCard'
import { HistoricalSummary } from '@components/TourPlanning/HistoricalSummary'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { useSingleEventStore } from '@stores/singleEventStore'
import { useTourPlanningStore } from '@stores/tourPlanningStore'
import { screenUtils } from '@utils'
import { cn } from '@utils/className'
import { exportComponentToPDF, hasDownloadPDFUrlParam } from '@utils/pdf'

export type EventsListRef = {
  subnavItems: SubNavItem[]
}

export const EventsList = forwardRef(
  (props: { events: any; customConfirm?: TCustomConfirmMethod }, ref: ForwardedRef<EventsListRef>) => {
    const contentRef = useRef<HTMLElement>(null)
    const mainRef = useRef<HTMLDivElement>(null)
    const historicalSummaryRef = useRef<HTMLDivElement>(null)
    const cumulativeTicketsSoldRef = useRef<HTMLDivElement>(null)
    const {
      isLoading,
      isLoadingEvents,
      pdfHeader,
      filterContext,
      stringfiedFilterContext,
      selectedEventIds,
      firstLoad,
      exportOptions: exportOptions,
      context,
      selectedEvents,
    } = useTourPlanningStore((state) => ({
      isLoading: state.isLoading,
      isLoadingEvents: state.isLoadingEvents,
      pdfHeader: state.pdfHeader,
      filterContext: state.filterContext,
      stringfiedFilterContext: state.stringfiedFilterContext,
      selectedEventIds: state.selectedEventIds,
      firstLoad: state.firstLoad,
      exportOptions: state.exportOptions,
      context: state.context,
      selectedEvents: state.selectedEvents,
    }))
    const { t } = useTranslation('tour_planning')

    useImperativeHandle(
      ref,
      () => ({
        get subnavItems() {
          return [
            { element: historicalSummaryRef.current as HTMLElement, label: t('historical_summary_table.title') },
            {
              element: cumulativeTicketsSoldRef.current as HTMLElement,
              label: t('cumulative_tickets_sold_chart.title'),
            },
          ].filter((v) => !!v) as SubNavItem[]
        },
      }),
      [t],
    )

    const searchParamsHandler = useSearchParamsObject()

    const scrollIntoView = useCallback(() => {
      if (screenUtils.isMobile(window)) {
        contentRef.current?.scrollIntoView({ behavior: 'smooth' })
      } else {
        mainRef.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [contentRef, mainRef])

    useEffect(() => {
      if (
        !isLoading &&
        !isLoadingEvents &&
        !!pdfHeader &&
        hasDownloadPDFUrlParam(BookmarkPageEnum.BRAND_VENUE_INSIGHTS)
      ) {
        exportComponentToPDF({
          component: contentRef.current,
          filename: `${t('title')}${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`,
          header: pdfHeader,
        })
      }
    }, [isLoading, isLoadingEvents, pdfHeader, t, contentRef, stringfiedFilterContext])

    useEffect(() => {
      if (!firstLoad && localStorage.tourPlanningJoyrideRunComplete) scrollIntoView()
    }, [firstLoad, scrollIntoView])
    const timeSeriesId = 'pdf-report-hide-timeseries-chart'

    const customEvents = useTourPlanningStore((state) => ({
      timeSeriesEntities: state.multipleCustomTimeSeriesEntities,
      fetch: state.fetchMultipleCustomTimeSeriesData,
      events: state.customEvents,
      setEvents: state.setCustomEvents,
    }))
    const main = useSingleEventStore((state) => ({
      events: state.events,
      loading: state.isLoadingEvents(),
      summaryData: state.eventSummaryData,
      eventFilter: state.context.singleEventFilter,
    }))

    const hasEventFilter = !!(
      main &&
      main.eventFilter &&
      main.eventFilter.artist &&
      main.eventFilter.venue &&
      main.eventFilter.date
    )

    const benchmark = useTourPlanningStore((state) => ({
      selected: state.selectedBenchmark,
      setSelected: state.setSelectedBenchmark,
    }))

    const timeSeriesProps = {
      loading: false,
      stringfiedFilterContext,
      pdfHeader,
      hasEventFilter,
      selectedEvents,
      customEvents,
      benchmark,
      exportOptions: exportOptions?.map((option) => {
        return { ...option, checked: option.id === timeSeriesId }
      }),
      additionalParamsForInternalChange: { searchParamsHandler },
    }

    return (
      <div className="px-4 py-6 md:p-8 !pb-0 group-[.pdf-report]:p-0">
        <div className="flex flex-col gap-2 mb-6 md:mb-8">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white">{t('title')}</h1>
            <div className="flex gap-2">
              <ExportButton
                isLoading={isLoading}
                pageId={BookmarkPageEnum.BRAND_VENUE_INSIGHTS}
                options={exportOptions}
                customConfirm={props.customConfirm}
              />
              <CopyReportUrlButton disabled={!filterContext} />
              <BookmarkButton
                page={BookmarkPageEnum.BRAND_VENUE_INSIGHTS}
                context={
                  filterContext
                    ? {
                        filters: filterContext,
                        selectedEvents: selectedEventIds,
                        singleEventFilter: context?.singleEventFilter,
                      }
                    : undefined
                }
              />
            </div>
          </div>
          <h2 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">{t('subtitle')}</h2>
        </div>
        <ExportComment />
        <div ref={historicalSummaryRef}>{props.events && <HistoricalSummary events={props.events} />}</div>
        <div
          className={cn('w-full mb-4', `group-[.pdf-report-hide-timeseries-chart]:hidden`)}
          ref={cumulativeTicketsSoldRef}
        >
          <TimeSeriesChartCard {...timeSeriesProps} />
        </div>
      </div>
    )
  },
)

export default EventsList
