import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Dropdown, TextInput, useTheme } from 'carbonarc-ui'
import { useQuery } from 'react-query'
import { Item, Select, SelectProps, SelectRef, Selected } from '@components/Inputs'
import { useApiFetch } from '@services/api'
import { cn } from '@utils/className'

const getQueryParams = (params: Record<string, string | string[]>): string => {
  const query = new URLSearchParams()

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => query.append(key, v))
    } else if (value !== undefined) {
      query.append(key, value)
    }
  })

  return query.toString()
}

export type Mode = 'select' | 'text'

type Placeholder = {
  search: string
  text: string
}

type RemoteSelectProps = {
  dataUrl: string
  extraParams?: Record<string, string | string[] | undefined>
  idField?: string
  nameField?: string | ((item: any) => string)
  disableRemoteSearch?: boolean
  isLastAvailableFilter?: boolean
  mode: Mode
  changeMode: (mode: Mode) => void
  value: Item | Item[] | string | null
  onChange: (value: Item | Item[] | string | null) => void
  placeholder?: Placeholder | string
} & Omit<SelectProps, 'items' | 'placeholder'>

export const RemoteSelectAndSearch = forwardRef<SelectRef, RemoteSelectProps>((props, ref) => {
  const {
    dataUrl,
    extraParams = {},
    idField = 'id',
    nameField = 'name',
    disableRemoteSearch = false,
    disabled = false,
    limitTo = 50,
    searchMinimumLength = 0,
    value,
    onChange,
    allOption,
    isLastAvailableFilter,
    mode,
    changeMode,
    placeholder,
    ...selectProps
  } = props
  const apiFetch = useApiFetch()
  const [searchQuery, setSearchQuery] = useState('')
  const [items, setItems] = useState<Item[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<SelectRef>(null)
  const inputTheme = useTheme().theme.textInput
  const dropdownTheme = useTheme().theme.dropdown
  const queryParams = getQueryParams({
    ...extraParams,
    ...(disableRemoteSearch ? {} : { search: searchQuery }),
  })
  const useQueryEnabled = !disabled && searchQuery.trim().length >= searchMinimumLength

  useImperativeHandle(ref, () => ({
    clearInput: () => {
      if (mode === 'text') onChange('')
      selectRef.current?.clearInput?.()
    },
    onRemove: selectRef.current?.onRemove,
  }))

  const { data, isLoading, isError } = useQuery({
    queryKey: [dataUrl, queryParams],
    queryFn: () => apiFetch(`${dataUrl}?${queryParams}`),
    enabled: useQueryEnabled,
  })

  useEffect(() => {
    let items = [
      ...(allOption?.id ? [allOption] : []),
      ...(data?.map?.((apiItem: any) => ({
        id: apiItem[idField],
        name: typeof nameField === 'function' ? nameField(apiItem) : apiItem[nameField],
      })) || []),
    ]
      .filter((i) => !!i?.id)
      .filter((i) => searchQuery.trim().length === 0 || i.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)

    if (limitTo && limitTo > 0) {
      items = items.slice(0, limitTo)
    }

    setItems(items)

    if (items?.length === 1 && isLastAvailableFilter) {
      onChange(items[0])
    }
  }, [data, searchQuery, idField, nameField, allOption, setItems, limitTo, onChange, isLastAvailableFilter])

  const options = [
    {
      mode: 'select',
      label: 'Search',
    },
    {
      mode: 'text',
      label: 'Text',
    },
  ]

  const placeholders: Placeholder =
    typeof placeholder === 'object'
      ? { search: placeholder.search, text: placeholder.text }
      : { search: placeholder as string, text: placeholder as string }

  return (
    <div>
      <div className="flex flex-row items-end" ref={containerRef}>
        <Dropdown
          inline
          label={<div>{options.find((option) => option.mode === mode)?.label}</div>}
          theme={{
            inlineWrapper: cn(
              dropdownTheme.inlineWrapper,
              'leading-tight text-sm font-medium py-3.5 sm:py-2.5',
              'border shadow-sm ring-0 ring-inset focus:ring-0 focus:ring-inset disabled:opacity-50 border-gray-300 bg-gray-100 text-gray-900 ring-gray-300 focus:ring-indigo-600 dark:border-gray-600 dark:bg-gray-900 dark:ring-gray-600 dark:text-white',
              'rounded-lg rounded-e-none border-r-0',
              'px-2 justify-between',
            ),
            arrowIcon: cn(dropdownTheme.arrowIcon, 'w-4 h-4'),
            content: cn(dropdownTheme.content, 'font-normal'),
          }}
        >
          {options.map((option) => (
            <Dropdown.Item key={option.mode} onClick={() => changeMode(option.mode as Mode)}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown>
        {mode === 'select' ? (
          <Select
            {...selectProps}
            value={value as any}
            onChange={onChange as any}
            items={items}
            isLoading={isLoading}
            isError={isError}
            onSearchInputValueChange={setSearchQuery}
            disabled={disabled}
            limitTo={limitTo}
            searchMinimumLength={searchMinimumLength}
            inputClassName="rounded-s-none"
            icon={null}
            hideSelectedPanel
            placeholder={placeholders.search}
            ref={selectRef}
            expandSelectionPanelTo={containerRef.current || undefined}
          />
        ) : (
          <TextInput
            value={value as string}
            placeholder={placeholders.text}
            onChange={(event) => {
              onChange(event.target.value)
            }}
            theme={{
              field: {
                input: {
                  withAddon: {
                    off: cn(inputTheme.field.input.withAddon.off, 'rounded-s-none font-normal'),
                  },
                },
              },
            }}
          />
        )}
      </div>
      {mode === 'select' && (
        <Selected
          items={value as any}
          onRemove={(item) => {
            selectRef.current?.onRemove?.(item)
          }}
        />
      )}
    </div>
  )
})
