import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Tooltip } from 'carbonarc-ui'
import { isMobile, isSafari } from 'react-device-detect'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useTranslation } from 'react-i18next'
import { CheckboxOption, ExportDropdown } from '@components/Export'
import { TCustomConfirmMethod } from '@components/Export/ExportModal'
import { VisualizationEvaluation } from '@components/VisualizationEvaluation'
import { VisualizationId, VisualizationType } from '@contexts/VisualizationEvaluation/types'
import { ArrowsPointingInIcon, ArrowsPointingOutIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { formatUtils } from '@utils'
import { cn } from '@utils/className'
import { PdfHeader, exportComponentToPDF, hasDownloadPDFUrlParam } from '@utils/pdf'

type ChartCardContextType = {
  setData: (rows: string[][]) => void
}

const ChartCardContext = createContext<ChartCardContextType>({
  setData: () => null,
})

export const useChartCardData = () => {
  const ctx = useContext(ChartCardContext)
  return ctx
}

type ChartCardProps = {
  id: VisualizationId
  visualization?: VisualizationType
  title: string | React.ReactNode
  subtitle?: string | React.ReactNode
  info?: string
  chart: React.ReactNode
  showChart: boolean
  lastUpdatedDate?: string | number | Date | null
  noDataIcon: React.ElementType
  isLoading: boolean
  hideDownload?: boolean
  noDataMessage?: string | React.ReactNode
  exportFilename?: string
  pdfHeader?: PdfHeader
  chartControlsComponent?: React.ReactNode
  controlPanelComponent?: React.ReactNode
  exportOptions?: CheckboxOption[]
  page?: string
  showLastUpdated?: boolean
}

export function ChartCard(props: ChartCardProps) {
  const { t } = useTranslation()
  const {
    id,
    visualization,
    title,
    subtitle,
    info,
    chart,
    showChart,
    lastUpdatedDate,
    noDataIcon: NoDataIcon,
    isLoading,
    hideDownload = false,
    noDataMessage = t('data_tips.no_data.title'),
    exportFilename,
    pdfHeader,
    chartControlsComponent,
    controlPanelComponent,
    exportOptions,
    page,
    showLastUpdated = true,
  } = props
  const [data, setData] = useState<string[][]>([[]])
  const handle = useFullScreenHandle()
  const isSafariMobile = isSafari && isMobile
  const chartRef = useRef<HTMLDivElement>(null)

  const handleCustomExportPDF: TCustomConfirmMethod = useCallback(
    (customPdfReportHide, customPdfComment) => {
      if (!isLoading && showChart) {
        exportComponentToPDF({
          component: chartRef.current,
          filename: exportFilename || 'table',
          header: pdfHeader,
          autoClose: false,
          customPdfReportHide,
          customPdfComment,
        })
      }
    },
    [isLoading, pdfHeader, chartRef, exportFilename, showChart],
  )

  useEffect(() => {
    if (!isLoading && showChart && hasDownloadPDFUrlParam(id)) {
      exportComponentToPDF({
        component: chartRef.current,
        filename: exportFilename || title?.toString() || 'chart',
        header: pdfHeader,
        htmlClassnames: ['pdf-report', 'pdf-report-chart'],
      })
    }
  }, [isLoading, showChart, id, exportFilename, title, pdfHeader])

  return (
    <ChartCardContext.Provider value={{ setData }}>
      <div
        className={cn(
          'flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col flex-1',
          isLoading && 'animate-pulse',
          'print:shadow-none print:break-inside-avoid-page',
          'group-[.pdf-report]:border-none group-[.pdf-report]:shadow-none',
          !showChart && 'group-[.pdf-report]:hidden',
        )}
        ref={chartRef}
      >
        <div className="flex h-full flex-col justify-center gap-4 py-6 divide-y divide-gray-200 dark:divide-gray-700 group-[.pdf-report-chart]:divide-none">
          {isLoading ? (
            <div className="mx-6 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-64 mb-2"></div>
          ) : (
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center px-6 group-[.pdf-report-chart]:justify-center group-[.pdf-report]:pl-0">
                <div className="flex flex-col w-full">
                  <div className="flex flex-row items-center gap-2 w-full">
                    <h4 className="text-base md:text-xl font-semibold text-gray-900 dark:text-white">{title}</h4>
                    {info && (
                      <Tooltip content={<div className="max-w-xs md:max-w-sm">{info}</div>}>
                        <InformationCircleIcon className="h-5 w-5 text-gray-500 dark:text-gray-400 cursor-pointer" />
                      </Tooltip>
                    )}
                    <div className={cn('flex items-end gap-2 flex-col-reverse md:flex-row md:items-center ml-auto')}>
                      <div className="flex items-end gap-2 pl-2 flex-col-reverse md:flex-row md:items-center">
                        {!isLoading && showChart && page && (
                          <ExportDropdown
                            pdfOptions={{ id: page, options: exportOptions }}
                            {...(!hideDownload && {
                              csvOptions: {
                                all: {
                                  rows: data,
                                  filename: exportFilename || title?.toString() || 'chart',
                                },
                              },
                            })}
                            pdfCustomConfirm={handleCustomExportPDF}
                          />
                        )}
                        {showChart && !isSafariMobile && (
                          <div className="h-6  group-[.pdf-report]:hidden">
                            <ArrowsPointingOutIcon
                              onClick={handle.enter}
                              title="Enter fullscreen"
                              className="w-6 text-gray-900 opacity-60 dark:text-white stroke-1 cursor-pointer hover:opacity-100 print:hidden"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {subtitle}
                </div>
              </div>
              {controlPanelComponent && <div className="block px-6 w-full relative">{controlPanelComponent}</div>}
            </div>
          )}
          {!isLoading && showChart ? (
            <FullScreen className="h-full bg-white dark:bg-gray-800" handle={handle}>
              {handle.active && (
                <ArrowsPointingInIcon
                  onClick={handle.exit}
                  title="Exit fullscreen"
                  className="w-6 text-gray-900 opacity-60 dark:text-white stroke-1 cursor-pointer hover:opacity-100 absolute top-2 right-2"
                />
              )}
              {chart}
              {chartControlsComponent && (
                <div className="hidden md:block w-full relative">{chartControlsComponent}</div>
              )}
            </FullScreen>
          ) : (
            <div className="h-full min-h-[35vh] text-sm text-gray-500 flex flex-col items-center justify-center dark:text-gray-400">
              <div className="w-24 h-24 bg-gray-50 dark:bg-gray-700 rounded-full gap-2 flex items-center justify-center mb-2">
                <NoDataIcon className="w-16 text-gray-300 dark:text-gray-500 stroke-1" />
              </div>
              {!isLoading && noDataMessage}
            </div>
          )}

          <div className="px-6 pt-6 text-sm font-normal text-gray-500 dark:text-gray-200 border-gray-200 dark:border-gray-700 group-[.pdf-report-chart]:!border-solid">
            {isLoading ? (
              <div className="h-1 bg-gray-200 rounded-full dark:bg-gray-700 w-48" />
            ) : (
              <div className="flex items-center justify-between group-[.pdf-report]:justify-center">
                {showLastUpdated && (
                  <span>
                    {t('last_updated')}{' '}
                    {showChart && lastUpdatedDate ? (
                      <span className="font-semibold text-gray-900 dark:text-white">
                        {formatUtils.formatDateTimeShort(new Date(lastUpdatedDate))}
                      </span>
                    ) : (
                      '-'
                    )}
                  </span>
                )}
                <div className="ml-auto flex flex-col sm:flex-row items-end sm:items-center gap-2 sm:divide-x divide-gray-200 dark:divide-gray-600">
                  {visualization && (
                    <div className="sm:pl-2 group-[.pdf-report]:hidden">
                      <VisualizationEvaluation type={visualization} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ChartCardContext.Provider>
  )
}
