import { MutableRefObject } from 'react'
import { Item } from '@components/RemoteSelect'
import { ApiFetch } from '@services/api'
import { PredictionAssessmentContext } from '@stores/bookmarkStore/utils/types'
import { PageSlice } from '@stores/slices/pageSlice'
import { EventPredictionBackTestEntity, EventPredictionEntity } from '@types'
import { StoreSetFunction } from '@utils/zustand'
import { createPredictionAssessmentStore } from './predictionAssessmentStore'

export type Filters = {
  backTest: BackTestFilters
  generate: GenerateFilters
}

export type BackTestFilters = {
  metric: Item | null
  artist: Item[]
  venue: Item[]
  year: Item[]
}

export type GenerateFilters = {
  season: Item | null
  artist: Item | null
  venue: Item | null
}

export const defaultBackTestFilters: BackTestFilters = {
  metric: null,
  artist: [],
  venue: [],
  year: [],
}

export const defaultGenerateFilters: GenerateFilters = {
  artist: null,
  venue: null,
  season: null,
}

export interface PredictionAssessmentStoreProps {
  getApiFetch: () => ApiFetch
}

export type PredictionAssessmentStore = ReturnType<typeof createPredictionAssessmentStore>

export interface PredictionAssessmentStoreState extends PageSlice<Filters> {
  getApiFetch: () => ApiFetch

  isLoadingBackTest: boolean
  predictionsBackTest: EventPredictionBackTestEntity[]
  setPredictionsBackTest: (predictionsBackTest: EventPredictionBackTestEntity[]) => void
  clearBackTest: (searchParamsHandler: SearchParamsObject) => void

  backTestFilterInputRef: MutableRefObject<HTMLInputElement>
  setBackTestFilterInputRef: (filterInputRef: HTMLInputElement | null) => void

  isLoadingGenerate: boolean
  generateData: EventPredictionEntity | null
  setGenerateData: (generateData: EventPredictionEntity | null) => void
  clearGenerate: (searchParamsHandler: SearchParamsObject) => void

  getContext: () => PredictionAssessmentContext
  setContext: (context: PredictionAssessmentContext, searchParamsHandler: SearchParamsObject) => void

  clearData: () => void
  urlChanged: (searchParamsHandler: SearchParamsObject) => void
  applyBackTestFilters: (filters: BackTestFilters, searchParamsHandler: SearchParamsObject) => Promise<void>
  applyGenerateFilters: (filters: GenerateFilters, searchParamsHandler: SearchParamsObject) => Promise<void>
  loadData: (searchParamsHandler: SearchParamsObject) => Promise<void>
  syncingUrl: boolean
}

export type PredictionAssessmentStoreSetFunction = StoreSetFunction<PredictionAssessmentStoreState>
export type PredictionAssessmentStoreGetFunction = () => PredictionAssessmentStoreState
