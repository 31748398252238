import { Button, Card } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

export function GetStartedCard({ onGetStarted }: { onGetStarted?: () => void }) {
  const { t } = useTranslation('chatbot')

  return (
    <Card className="mx-auto max-w-[555px]">
      <div>
        <h2 className="text-2xl font-bold">AI-Powered Insights</h2>
        <p className="mb-1 mt-2">{t('chatbot:welcome')}</p>
        <p>{t('welcome-example')}</p>
        <Button className="w-fit text-sm font-medium mt-3" onClick={onGetStarted}>
          {t('get-started')} <ArrowRightIcon className="ml-2 w-4" />
        </Button>
      </div>
    </Card>
  )
}
