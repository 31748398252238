import { MutableRefObject, createRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { createStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { ApiFetch } from '@services/api'
import { PredictionAssessmentContext } from '@stores/bookmarkStore/utils/types'
import { createPageSlice } from '@stores/slices/pageSlice'
import { setRef } from '@utils/zustand'
import { getLocalFilters, saveLocalFilters } from './localFilters'
import * as api from './predictionAssessmentApi'
import { usePredictionAssessmentControlPanelStore } from './predictionAssessmentControlPanelStore'
import { GroupedFilterField } from './predictionAssessmentControlPanelStoreTypes'
import {
  Filters,
  PredictionAssessmentStoreGetFunction,
  PredictionAssessmentStoreProps,
  PredictionAssessmentStoreSetFunction,
  PredictionAssessmentStoreState,
} from './predictionAssessmentStoreTypes'
import {
  filterFieldsToPageFilters,
  getPdfHeader,
  pageFilterToFilterFields,
  stringifyFilters,
} from './predictionAssessmentStoreUtils'
import { contextToQueryParams, queryParamsToContext } from './queryParams'

export const createPredictionAssessmentStore = (initialProps: PredictionAssessmentStoreProps) => {
  return createStore<PredictionAssessmentStoreState>()(
    immer(
      (set, get, store) =>
        ({
          ...initialProps,

          ...createPageSlice<Filters>({ stringifyFilters, getPdfHeader }, set, get, store),

          syncingUrl: false,

          predictionsBackTest: [],
          setPredictionsBackTest: (predictionsBackTest) => set({ predictionsBackTest }),

          isLoadingBackTest: false,
          backTestFilterInputRef: createRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>,
          setBackTestFilterInputRef: (ref) => setRef(set, get, 'backTestFilterInputRef', ref),

          isLoadingGenerate: false,
          generateData: null,
          setGenerateData: (generateData) => set({ generateData }),

          clearBackTest: (searchParamsHandler) => {
            const filterContext = get().filterContext
            const isLoading = get().isLoadingGenerate
            set({
              isLoading,
              isLoadingBackTest: false,
              predictionsBackTest: [],
            })
            get().setContext(
              {
                filters: {
                  backTest: {
                    artist: [],
                    venue: [],
                    year: [],
                    metric: null,
                  },
                  generate: filterContext?.generate || {
                    artist: null,
                    venue: null,
                    season: null,
                  },
                },
              },
              searchParamsHandler,
            )
          },
          clearGenerate: (searchParamsHandler) => {
            const filterContext = get().filterContext
            const isLoading = get().isLoadingBackTest
            set({
              isLoading,
              isLoadingGenerate: false,
              generateData: null,
            })
            get().setContext(
              {
                filters: {
                  backTest: filterContext?.backTest || {
                    artist: [],
                    venue: [],
                    year: [],
                    metric: null,
                  },
                  generate: {
                    artist: null,
                    venue: null,
                    season: null,
                  },
                },
              },
              searchParamsHandler,
            )
          },
          clearData: () => {
            set({
              isLoading: false,
              syncingUrl: false,
              isLoadingBackTest: false,
              isLoadingGenerate: false,
              filterContext: undefined,
              stringfiedFilterContext: '',
              predictionsBackTest: [],
              generateData: null,
            })
          },

          getContext: () => buildContext(get),
          setContext: (context: PredictionAssessmentContext, searchParamsHandler) => {
            const { searchParams, replaceSearchParams } = searchParamsHandler

            get().setFilterContext(context.filters)
            saveLocalFilters(context.filters)

            const urlContext = queryParamsToContext(searchParams)
            if (!isEqual(urlContext, context)) {
              set({ syncingUrl: true })
              replaceSearchParams(contextToQueryParams(context))
            }
          },

          applyBackTestFilters: async (filters, searchParamsHandler) => {
            const apiFetch = get().getApiFetch()
            set({ predictionsBackTest: [] })
            const currentContext = get().getContext()
            await applyFilters(set, apiFetch, { backTest: filters })
            get().setContext(
              {
                ...currentContext,
                filters: {
                  ...currentContext.filters,
                  backTest: filters,
                },
              },
              searchParamsHandler,
            )
          },
          applyGenerateFilters: async (filters, searchParamsHandler) => {
            const apiFetch = get().getApiFetch()
            set({ generateData: null })
            const currentContext = get().getContext()
            await applyFilters(set, apiFetch, { generate: filters })
            get().setContext(
              {
                ...currentContext,
                filters: {
                  ...currentContext.filters,
                  generate: filters,
                },
              },
              searchParamsHandler,
            )
          },
          urlChanged: (searchParamsHandler: SearchParamsObject) => {
            if (get().syncingUrl) {
              set({ syncingUrl: false })
              return
            }
            get()
              .loadData(searchParamsHandler)
              .then(() => {
                set({ syncingUrl: false })
              })
          },
          loadData: async (searchParamsHandler: SearchParamsObject) => {
            const firstLoad = get().firstLoad
            const currentContext = buildContext(get)
            const { searchParams, replaceSearchParams } = searchParamsHandler
            const apiFetch = get().getApiFetch()

            let newFilterFields: GroupedFilterField = {} as GroupedFilterField
            let newContext: PredictionAssessmentContext = {} as PredictionAssessmentContext

            if (searchParams && searchParams.size > 0) {
              newContext = queryParamsToContext(searchParams)
              newFilterFields = pageFilterToFilterFields(newContext.filters)
            } else if (firstLoad) {
              const localSavedObject = getLocalFilters() as GroupedFilterField
              if (localSavedObject?.backTestFilters?.length || localSavedObject?.generateFilters?.length) {
                newFilterFields = localSavedObject
                newContext = {
                  filters: filterFieldsToPageFilters(newFilterFields.generateFilters, newFilterFields.backTestFilters),
                }
              }
            }

            const contextChanged = !isEqual(currentContext, newContext) && !isEmpty(newContext)

            if (contextChanged) {
              usePredictionAssessmentControlPanelStore.setState((state) => {
                state.backTest.filters = newFilterFields.backTestFilters
                state.generate.filters = newFilterFields.generateFilters
              })
              set({ generateData: null, predictionsBackTest: [] })
              await applyFilters(set, apiFetch, newContext.filters)
              get().setContext(newContext, searchParamsHandler)
            } else if (!contextChanged) {
              set({ syncingUrl: true })
              replaceSearchParams(contextToQueryParams(currentContext))
            }

            set({ firstLoad: false })
          },
        } as PredictionAssessmentStoreState),
    ),
  )
}

const applyFilters = async (
  set: PredictionAssessmentStoreSetFunction,
  apiFetch: ApiFetch,
  filters: Partial<Filters>,
) => {
  const newLoadingState: { isLoading?: boolean; isLoadingBackTest?: boolean; isLoadingGenerate?: boolean } = {}
  try {
    const fetches = []

    if (filters.backTest && filters.backTest.artist?.length > 0) {
      newLoadingState.isLoadingBackTest = true
      fetches.push(api.fetchPredictionsBackTest(set, apiFetch, filters.backTest))
    }

    if (filters.generate && filters.generate.artist != null) {
      newLoadingState.isLoadingGenerate = true
      fetches.push(api.fetchGenerate(set, apiFetch, filters.generate))
    }

    newLoadingState.isLoading = newLoadingState.isLoadingBackTest && newLoadingState.isLoadingGenerate

    set(newLoadingState)
    await Promise.all(fetches)
  } finally {
    const keys = Object.keys(newLoadingState) as (keyof typeof newLoadingState)[]
    keys.forEach((key) => (newLoadingState[key] = false))
    set(newLoadingState)
  }
}

const buildContext = (get: PredictionAssessmentStoreGetFunction): PredictionAssessmentContext => {
  const filterContext = get().filterContext

  return {
    filters: filterContext as Filters,
  }
}
