import { Suspense } from 'react'
import { LoadingIndicator, NotFoundPage } from 'carbonarc-ui'
import { Outlet } from 'react-router-dom'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useAuth } from './AuthContext'

export function AuthRoute() {
  const { onAuthRequired, okta } = useAuth()
  const restoreOriginalUri = async (_: unknown, originalUri: string) => {
    const url = toRelativeUrl(originalUri, window.location.origin)
    window.location.href = url
  }
  if (!okta) return <NotFoundPage showUserMenu={true} />

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Security oktaAuth={okta} onAuthRequired={onAuthRequired} restoreOriginalUri={restoreOriginalUri}>
        <Outlet />
      </Security>
    </Suspense>
  )
}
