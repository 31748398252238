import { ReactElement, useState } from 'react'
import { Button } from 'carbonarc-ui'
import { useSearchParams } from 'react-router-dom'
import { ChatBotTable } from '@components/InsightsChatBot/ChatBotTable'
import { LoadingIcon } from '@components/LoadingIcon'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useApiFetch } from '@services/api'
import { cn } from '@utils/className'
import { isAIMessage } from '@utils/insights'

interface ChatBotMessageProps {
  chatId?: string
  user: string
  message: string | ReactElement
  tableData?: (string | number)[][]
  tableColumns?: string[]
  onShowTable?: () => void
}

const shouldShowTable = (tableColumns: string[], tableData: (string | number)[][]) => {
  return tableColumns?.length > 1 && tableData?.length > 0
}

export function ChatBotMessage({ chatId, user, message, tableData = [], tableColumns = [] }: ChatBotMessageProps) {
  const [searchParams] = useSearchParams()
  const [tableDataState, setTableDataState] = useState<(string | number)[][]>(tableData)
  const [isLoading, setIsLoading] = useState(false)
  const [entriesAlreadyRequested, setEntriesAlreadyRequested] = useState(0)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const fetchApi = useApiFetch()

  // FIXME: This doesn't feel correct, I'm sure there is a reason behind this but this seems like a hacky solution, and if anything it should be dealt with on the server
  function getMessage(messageText: string) {
    if (!searchParams.get('whole-ai-answer') && tableData?.length > 3) {
      return messageText && typeof messageText === 'string' ? messageText?.split('\n')[0] : ''
    }
    return messageText || ''
  }
  // END

  const fetchMoreData = async () => {
    setIsLoading(true)
    const newTableData = await fetchApi(`/data/chat/${chatId}/data?offset=${entriesAlreadyRequested}`)

    // FIX ME: chatAPI needs it own hook for validation and error handling, shouldn't be inside a component.. but I'm trying to fix a prod bug rn so I'll wrap it here
    const validateTableData = (data: any): data is string[][] => {
      if (!Array.isArray(data)) {
        return false
      }

      return data.every((row) => Array.isArray(row) && row.every((cell) => typeof cell === 'string'))
    }
    // END

    if (validateTableData(newTableData)) {
      setTableDataState((prevTableData) => [...prevTableData, ...newTableData])
      setEntriesAlreadyRequested((prevAmount) => prevAmount + newTableData.length)
    } else {
      setHasMoreResults(false)
    }

    setIsLoading(false)
  }

  const showMoreResultsButtonIsVisible = true //FIXME: Next PR coming will include fullResultCount/pagination in API so we know when to request more.. -> fullResultCount > tableDataState.length

  return (
    <li className={`w-full min-[1040px]:w-3/5 ${isAIMessage(user) ? 'self-start' : 'self-end'}`}>
      <h6 className={`font-semibold mb-1 ${!isAIMessage(user) && 'text-right'}`}>{user}</h6>
      <div className={cn('shadow-md rounded-lg dark:bg-gray-600', !isAIMessage(user) && 'bg-blue-50 dark:bg-gray-400')}>
        <div className="p-8 space-y-4">
          {typeof message === 'string' ? (
            <p
              className="text-gray-500 dark:text-gray-50 whitespace-pre-line"
              dangerouslySetInnerHTML={{ __html: getMessage(message) }} // FIXME: I dont know what this is, but it shouldn't be handled like this
            />
          ) : (
            message
          )}
          {shouldShowTable(tableColumns, tableDataState) && (
            <>
              <ChatBotTable tableData={tableDataState} tableColumns={tableColumns} />

              {showMoreResultsButtonIsVisible && (
                <p className="text-gray-700 dark:text-gray-50">
                  {`Please note that I can only show 5 entries at a time, however, you can request more results by clicking the “+” button below.`}
                </p>
              )}
            </>
          )}
        </div>
        {shouldShowTable(tableColumns, tableDataState) && showMoreResultsButtonIsVisible && (
          <div className="px-6 py-3 border-t-2 border-gray-200 dark:border-gray-700 flex">
            <Button
              variant="ghost"
              disabled={isLoading || !hasMoreResults}
              className="gap-1 text-gray-500"
              onClick={fetchMoreData}
            >
              {isLoading ? <LoadingIcon className="w-4 text-current" /> : <PlusIcon width={24} />} Show More Results
            </Button>
          </div>
        )}
      </div>
    </li>
  )
}
