import { useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { StoresContext } from '@stores/StoresContext'
import { SingleEventStoreState } from '@stores/singleEventStore/singleEventStoreTypes'

export function useSingleEventStore<T>(
  selector: (state: SingleEventStoreState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(StoresContext)
  if (!store) throw new Error('Missing Provider in the tree')

  return useStoreWithEqualityFn(store.singleEventStore, selector, equalityFn)
}
