import { datetimeUtils } from '@utils'

export const formatNumber = (value: number | string | undefined | null, options?: Intl.NumberFormatOptions): string => {
  if (value === undefined || value === null) value = 0
  return new Intl.NumberFormat(undefined, options).format(Number(value))
}

export const formatPercentage = (value: number | string | undefined | null, decimalCases = 2): string => {
  if (value === undefined || value === null) value = 0
  return new Intl.NumberFormat(undefined, {
    style: 'percent',
    minimumFractionDigits: decimalCases,
  }).format(Number(value))
}

export const formatCurrency = (value: number | string | undefined | null, decimalCases = 2): string => {
  if (value === undefined || value === null) value = 0
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalCases,
  }).format(Number(value))
}

export const formatDate = (value: Date | undefined | null): string => {
  if (value === undefined || value === null) return 'N/A'
  return value.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

export function formatDateNumbersOnly<
  T extends Intl.DateTimeFormatOptions & { useTime?: boolean; returnDate?: boolean },
>(
  value: Date | string | undefined | null,
  options?: T,
): T extends Intl.DateTimeFormatOptions & { useTime?: boolean; returnDate: true }
  ? { toString: () => string; date: Date }
  : string
export function formatDateNumbersOnly(
  value: Date | string | undefined | null,
  options?: Intl.DateTimeFormatOptions & { useTime?: boolean; returnDate?: boolean },
) {
  if (value === undefined || value === null) return '-'
  if (typeof value === 'string') {
    value = new Date(value)
    if (!options?.useTime) {
      const userTimezoneOffset = value.getTimezoneOffset() * 60000
      value = new Date(value.getTime() + userTimezoneOffset)
    }
    if (isNaN(value.getTime())) return '-'
  }
  const dateStr = value.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...options,
  })
  return options?.returnDate
    ? {
        toString: () => dateStr,
        date: value,
      }
    : dateStr
}

export const formatTime = (value: Date | undefined | null): string => {
  if (value === undefined || value === null) return 'N/A'
  return value.toLocaleTimeString(undefined, {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  })
}

type FormatDateTimeOptions = {
  hideDayIfInLast24Hours?: boolean
}

const defaultFormatDateTimeOptions: FormatDateTimeOptions = {
  hideDayIfInLast24Hours: false,
}

export const formatDateTime = (value: Date | undefined | null, options: FormatDateTimeOptions = {}): string => {
  if (value === undefined || value === null) return 'N/A'

  const finalOptions = { ...defaultFormatDateTimeOptions, ...options }
  if (finalOptions.hideDayIfInLast24Hours && datetimeUtils.diffInHours(new Date(), value) < 24) {
    return formatTime(value)
  }

  return `${formatDate(value)} ${formatTime(value)}`
}

export const formatDateTimeShort = (value: Date | undefined | null, options: FormatDateTimeOptions = {}): string => {
  if (value === undefined || value === null) return 'N/A'

  const finalOptions = { ...defaultFormatDateTimeOptions, ...options }
  if (finalOptions.hideDayIfInLast24Hours && datetimeUtils.diffInHours(new Date(), value) < 24) {
    return formatTime(value)
  }

  return `${formatDateNumbersOnly(value)}, ${formatTime(value)}`
}

export const formatCityState = (city: string | undefined, state: string | undefined) => {
  let cityState = ''

  if (city && state) {
    cityState = `${city}, ${state}`
  } else if (city) {
    cityState = `${city}`
  } else if (state) {
    cityState = `${state}`
  }

  return cityState
}
