import { ControlPanelSliceStateCreator } from './controlPanelSliceTypes'

export function createControlPanelSlice<FilterField>(
  ...[set]: Parameters<ControlPanelSliceStateCreator<FilterField>>
): ReturnType<ControlPanelSliceStateCreator<FilterField>> {
  return {
    applied: false,
    setApplied: (applied) => set({ applied }),

    filters: [],
    setFilters: (filters) => set({ filters }),
  }
}
