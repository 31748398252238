import { useCallback, useState } from 'react'
import { Button } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ChartCard } from '@components/ChartCard'
import { EventContentProps } from '@components/EventContent'
import { TicketSoldPieChart } from '@components/EventContent/Summary/TicketSoldPieChart'
import { FourGrid, TwoGrid } from '@components/Grid'
import { LoadingIcon } from '@components/LoadingIcon'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { ChartPieIcon, DocumentChartBarIcon } from '@heroicons/react/24/outline'
import { useApiDownload } from '@services/api'
import { formatUtils } from '@utils'
import { formatCityState } from '@utils/format'

const mediaTypesByType: Record<string, string> = {
  pdf: 'application/pdf',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}
const Stat = ({ text, subtext }: { text: string; subtext: string }) => {
  return (
    <div className="flex items-center gap-2">
      <span className="text-sm font-bold md:text-lg md:font-semibold dark:text-gray-100">{text}</span>
      <span className="text-xs font-normal dark:text-gray-400">{subtext}</span>
    </div>
  )
}
type SummaryProps<AdditionalParamsOnInternalChange> = Pick<
  EventContentProps<AdditionalParamsOnInternalChange>,
  'loading' | 'summaryData' | 'stringfiedFilterContext' | 'pdfHeader' | 'exportOptions' | 'page' | 'secondaryMarket'
>
//TODO: get averagePrice and secondaryAveragePrice from api
export function Summary<AdditionalParamsOnInternalChange>(props: SummaryProps<AdditionalParamsOnInternalChange>) {
  const { loading, summaryData: eventSummaryData, stringfiedFilterContext, pdfHeader, secondaryMarket } = props
  const { t } = useTranslation('tour_marketing')
  const apiDownload = useApiDownload()
  const [downloading, setDownloading] = useState(false)

  const event = eventSummaryData?.event
  const priceTiers = eventSummaryData?.price_tiers
  const secondaryMarketTimeSeriesInventoryData = secondaryMarket?.timeSeriesInventoryData

  const performanceDate = formatUtils.formatDateNumbersOnly(event?.performance_date, {
    returnDate: true,
  })
  const weekday = performanceDate.date?.toLocaleDateString(undefined, { weekday: 'long' })
  const isPastEvent = performanceDate.date?.getTime() < new Date().setHours(0, 0, 0, 0)
  const audienceReport = event?.upcoming_summary?.audience_report
  const hasAudienceReport = audienceReport?.pdf || audienceReport?.pptx
  const id = 'pdf-report-hide-summary'
  const downloadAudienceReport = useCallback(() => {
    if (!hasAudienceReport) {
      return
    }

    setDownloading(true)
    const type = audienceReport.pdf ? 'pdf' : 'pptx'
    const url = audienceReport.pdf || audienceReport.pptx || ''
    const mediaType = mediaTypesByType[type]
    const filename = `audience-report${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}.${type}`

    const urlSearchParams = new URLSearchParams()

    urlSearchParams.append('url', url)
    urlSearchParams.append('media_type', mediaType)
    urlSearchParams.append('filename', filename)

    apiDownload(`/audience_report?${urlSearchParams.toString()}`, filename).finally(() => setDownloading(false))
  }, [apiDownload, audienceReport, hasAudienceReport, stringfiedFilterContext])
  const secondaryMarketLatestDay =
    secondaryMarketTimeSeriesInventoryData.length > 0
      ? secondaryMarketTimeSeriesInventoryData[secondaryMarketTimeSeriesInventoryData.length - 1]
      : undefined
  const averagePriceData = priceTiers?.reduce(
    (acc, tier) => {
      acc.totalQuantity += tier.quantity
      acc.totalRevenue += tier.quantity * tier.price_total
      return acc
    },
    { totalQuantity: 0, totalRevenue: 0 },
  )
  const averagePrice =
    priceTiers && priceTiers?.length > 0 && averagePriceData
      ? formatUtils.formatCurrency(averagePriceData.totalRevenue / averagePriceData.totalQuantity)
      : '-'

  const secondaryAverageListingPrice =
    secondaryMarketLatestDay && secondaryMarketLatestDay.avg_listing_price
      ? formatUtils.formatCurrency(secondaryMarketLatestDay.avg_listing_price)
      : '-'

  const currentQuantitySold =
    event?.sales_summary?.current_quantity_sold == null
      ? '-'
      : formatUtils.formatNumber(event.sales_summary.current_quantity_sold)
  const sellableCap = event?.sellable_cap == null ? '-' : formatUtils.formatNumber(event.sellable_cap)
  const currentPercentSold =
    event?.sales_summary?.current_percent_sold == null
      ? '-'
      : formatUtils.formatPercentage(event.sales_summary.current_percent_sold / 100)
  const secondaryTotalListed =
    secondaryMarketLatestDay && secondaryMarketLatestDay.total_tickets_listed
      ? formatUtils.formatNumber(secondaryMarketLatestDay.total_tickets_listed)
      : '-'

  return (
    <div className={`group-[.pdf-report-hide-summary]:hidden`}>
      <div className="flex justify-between mb-5 md:mb-6">
        <div className="flex flex-col gap-2">
          <h2 className="text-xl md:text-3xl font-semibold dark:text-white">{t('summary.title')}</h2>
          <h3 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">{t('summary.subtitle')}</h3>
        </div>

        {hasAudienceReport && (
          <Button
            disabled={loading || downloading}
            variant="outline"
            className="bg-white dark:bg-transparent dark:border-gray-800 dark:text-gray-300 group-[.pdf-report]:hidden rounded-lg"
            onClick={downloadAudienceReport}
          >
            <DocumentChartBarIcon className="w-5 h-5 mr-2 text-gray-400" />
            {t('common:download_audience_report')}
            {downloading && <LoadingIcon className="inline-block w-4 h-4 ml-2" />}
          </Button>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-4 mb-6 md:mb-8 pdf:hidden">
        <div className="flex-1 flex flex-col">
          <FourGrid
            metrics={[
              { title: t('summary.days_until_show'), value: isPastEvent ? '-' : event?.days_from_event },
              {
                title: t('summary.performance_date'),
                value: weekday ? `${weekday}, ${performanceDate.toString()}` : performanceDate.toString(),
              },
              {
                title: t('summary.days_since_ticket_sales_opened'),
                value: event?.days_since_sales_opened,
              },
              {
                title: t('summary.ticket_sales_open_date'),
                value: formatUtils.formatDateNumbersOnly(event?.on_sale_date),
              },
            ]}
            isLoading={loading}
          />

          <FourGrid
            metrics={[
              { title: t('summary.artist_name'), value: event?.brand?.name },
              { title: t('summary.supporting_artists'), value: event?.support },
              { title: t('summary.venue_name'), value: event?.venue?.name },
              {
                title: t('summary.venue_location'),
                value: formatCityState(event?.venue?.location?.city, event?.venue?.location?.state),
              },
            ]}
            isLoading={loading}
          />

          <TwoGrid
            metrics={[
              { title: t('summary.sub_category'), value: event?.sub_category?.name },
              { title: t('summary.promoter'), value: event?.promoter_name },
            ]}
            isLoading={loading}
          />

          <TwoGrid
            metrics={[
              { title: t('summary.status'), value: event?.status },
              {
                title: t('summary.last_data_entry'),
                value: event?.last_entered_date
                  ? formatUtils.formatDateTimeShort(new Date(event.last_entered_date))
                  : '',
              },
            ]}
            isLoading={loading}
            className="mb-0"
          />
        </div>
        <div className="flex flex-col">
          <TwoGrid
            metrics={[
              {
                title: t('tickets_sold'),
                value: (
                  <div className="flex flex-col mt-1">
                    <Stat text={currentQuantitySold} subtext={`of ${sellableCap} (${currentPercentSold})`} />
                    {secondaryMarketLatestDay !== undefined && (
                      <Stat text={secondaryTotalListed} subtext={t('summary.on_secondary_market')} />
                    )}
                  </div>
                ),
              },
              {
                title: t('summary.average_ticket_price'),
                value: (
                  <div className="flex flex-col mt-1">
                    <Stat text={averagePrice} subtext={t('summary.on_primary_market')} />
                    {secondaryMarketLatestDay !== undefined && (
                      <Stat text={secondaryAverageListingPrice} subtext={t('summary.on_secondary_market')} />
                    )}
                  </div>
                ),
              },
            ]}
            isLoading={loading}
          />
          <ChartCard
            exportOptions={props.exportOptions?.map((option) => {
              return { ...option, checked: option.id === id }
            })}
            page={props.page}
            id={VisualizationId.TicketsSoldVSTicketsRemaining}
            visualization={VisualizationId.TicketsSoldVSTicketsRemaining}
            title={t('tickets_sold_vs_tickets_remaining_chart.title')}
            lastUpdatedDate={event?.last_entered_date}
            chart={
              <TicketSoldPieChart
                quantitySold={event?.sales_summary?.current_quantity_sold}
                quantityRemaining={event?.sales_summary?.current_quantity_remaining}
              />
            }
            showChart={
              event?.sales_summary?.current_quantity_sold != null &&
              event?.sales_summary.current_quantity_remaining != null
            }
            noDataIcon={ChartPieIcon}
            isLoading={loading}
            hideDownload
            exportFilename={`tickets_sold_vs_tickets_remaining${
              stringfiedFilterContext ? '-' + stringfiedFilterContext : ''
            }`}
            pdfHeader={pdfHeader}
          />
        </div>
      </div>
    </div>
  )
}
