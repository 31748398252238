import { Reducer, useEffect, useReducer, useRef } from 'react'
import { reducer } from '@hooks/usePaginatedData/reducer'
import { Action, State } from '@hooks/usePaginatedData/types'
import { useApiFetch } from '@services/api'
import { StarEventEntity } from '@types'
import { createTourPlanningStore } from './tourPlanningStore'
import { TourPlanningContext } from './tourPlanningStoreContext'
import { Filters, TourPlanningStore } from './tourPlanningStoreTypes'

export function TourPlanningStoreProvider({ children }: { children: React.ReactNode }) {
  const apiFetch = useApiFetch()

  const eventsReducer = useReducer<Reducer<State<StarEventEntity, Filters>, Action<StarEventEntity, Filters>>>(
    reducer,
    {
      prefix: 'events',
      items: [],
      page: 1,
      pages: 1,
      size: 10,
      total: 0,
      fetching: false,
      filter: null as unknown as Filters,
    },
  )
  const storeRef = useRef<TourPlanningStore>(
    createTourPlanningStore({
      getApiFetch: () => apiFetch,
      events: eventsReducer,
    }),
  )

  useEffect(() => {
    const getApiFetch = () => apiFetch
    if (storeRef.current) {
      storeRef.current.setState({
        getApiFetch,
        events: eventsReducer,
      })
    } else {
      storeRef.current = createTourPlanningStore({
        getApiFetch,
        events: eventsReducer,
      })
    }
  }, [apiFetch, eventsReducer])

  return <TourPlanningContext.Provider value={storeRef.current}>{children}</TourPlanningContext.Provider>
}
