import { Fragment } from 'react'
import { Button } from 'carbonarc-ui'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ReactComponent as ArtistVenueInsightsIcon } from '@assets/music/actions/artist-venue-insights-icon.svg'
import { ReactComponent as EventsInsightsIcon } from '@assets/music/actions/events-insights-icon.svg'
import { Footer } from '@components/Footer'
import { UpcomingEvents } from '@components/OverviewPage'
import { Table } from '@components/Table'
import { useFlag, usePages } from '@config/site/app'
import { Flags } from '@config/site/app/flags'
import { Page } from '@config/site/app/pages'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { ArrowRightIcon, Square2StackIcon } from '@heroicons/react/24/outline'
import { useBookmarkStore } from '@stores/bookmarkStore'
import { generateReportPath } from '@stores/bookmarkStore/utils'
import { copyToClipboard } from '@utils/string'

type QuickActionCardProps = {
  title: string
  description: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  page: Page
}

const QuickActionCard = ({ title, description, icon: Icon, page }: QuickActionCardProps) => {
  const { getPageData } = usePages()
  const pageData = getPageData(page)
  if (!pageData) return null
  return (
    <Link
      to={pageData?.path}
      className="w-full  flex flex-col items-start gap-6 rounded-lg border border-gray-200 dark:border-gray-700 shadow-md p-6 bg-white dark:bg-gray-800"
    >
      <div className="flex items-center flex-1">
        <div className="rounded-full w-20 h-20 md:w-24 md:h-24 bg-blue-100 text-blue-600 flex items-center justify-center mr-3 md:mr-6 shrink-0">
          <Icon className="mx-auto h-12 w-12" />
        </div>
        <div className="">
          <div className="text-xl font-semibold text-gray-900 dark:text-white">{title}</div>
          <div className="text-base font-normal text-gray-500 dark:text-gray-400">{description}</div>
        </div>
      </div>
    </Link>
  )
}

type Action = {
  titleKey: string
  descriptionKey: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  page: Page
  canBeFavorite?: boolean
}

const quickActions: Action[] = [
  {
    titleKey: 'tour_marketing:title',
    descriptionKey: 'tour_marketing:short_description',
    icon: EventsInsightsIcon,
    page: Page.EVENT_INSIGHTS,
    canBeFavorite: true,
  },
  {
    titleKey: 'tour_planning:title',
    descriptionKey: 'tour_planning:short_description',
    icon: ArtistVenueInsightsIcon,
    page: Page.ARTIST_VENUE_INSIGHTS,
    canBeFavorite: true,
  },
]

const QuickActions = () => {
  const { t } = useTranslation()
  const { canSeePage } = usePages()

  const actions = quickActions.filter((action) => canSeePage(action.page))

  return actions.length === 0 ? null : (
    <div className="flex gap-6">
      {actions.map((action) => {
        return (
          <QuickActionCard
            key={action.page}
            title={t(action.titleKey)}
            description={t(action.descriptionKey)}
            icon={action.icon}
            page={action.page}
          />
        )
      })}
    </div>
  )
}

const NoDataMessage = () => {
  const { t } = useTranslation()
  const { canSeePage, getPageData } = usePages()

  const canBeFavoriteActions = quickActions.filter((action) => action.canBeFavorite && canSeePage(action.page))

  return (
    <div className="text-sm font-normal text-gray-500 dark:text-gray-400 flex flex-col items-center gap-2">
      <div>{t('overview:favorites.empty_message')}</div>
      <div>
        {t('overview:favorites.empty_links_message')}
        {canBeFavoriteActions.map((action, index) => {
          const pageData = getPageData(action.page)
          if (!pageData) return null
          return (
            <Fragment key={action.titleKey}>
              {index === canBeFavoriteActions.length - 1 ? ' or ' : index > 0 ? ', ' : ' '}
              <Link to={pageData.path} className="text-blue-600">
                {t(action.titleKey)}
              </Link>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

const Favorites = () => {
  const enabled = useFlag(Flags.ENABLE_FAVORITES)
  const { t } = useTranslation()
  const { allBookmarks, isLoading, setShowFavoritesPanel } = useBookmarkStore((state) => ({
    isLoading: state.isLoading,
    allBookmarks: state.bookmarks,
    setShowFavoritesPanel: state.setShow,
  }))

  const showAllButton = allBookmarks.length > 5
  const bookmarks = allBookmarks.slice(0, 5)

  const copyPathToClipboard = (path: string) => {
    const currentUrl = new URL(window.location.href)
    copyToClipboard(`${currentUrl.origin}${path}`)
    toast.success(t('bookmark.copied_report_to_clipboard'))
  }
  if (!enabled) return null

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md flex h-full flex-col justify-center divide-y divide-gray-200 dark:divide-gray-700">
      <div className="p-6 text-xl font-semibold text-gray-900 dark:text-white">{t('bookmark.title')}</div>
      <div className="p-6">
        <Table
          id={VisualizationId.OverviewBookmarks}
          header={[{ column: t('bookmark.report_name') }]}
          body={bookmarks.map((bookmark) => {
            const path = generateReportPath(bookmark)
            return {
              row: [
                <div className="flex justify-between items-center">
                  <Link
                    to={path}
                    className="hover:text-blue-600 hover:dark:text-blue-500 underline underline-offset-4
"
                  >
                    {bookmark.name}
                  </Link>
                  <div className="flex divide-x gap-2 items-center dark:divide-gray-600">
                    <Button
                      variant="ghost"
                      size="sm"
                      className="!ring-0 focus:ring-offset-0 hover:bg-gray-200"
                      onClick={() => copyPathToClipboard(path)}
                    >
                      <Square2StackIcon className="w-4 h-4 stroke-2 text-gray-500 dark:text-gray-400" />
                    </Button>

                    <Link to={path} className="">
                      <div className="flex ml-2 items-center text-blue-600 dark:text-blue-500 text-sm font-medium hover:bg-gray-200 dark:hover:bg-slate-800 h-9 px-2 rounded-md">
                        {t('overview:favorites.view')} <ArrowRightIcon className="w-4 h-4 stroke-2 ml-1.5" />
                      </div>
                    </Link>
                  </div>
                </div>,
              ],
            }
          })}
          isLoading={isLoading}
          showTable={bookmarks.length > 0}
          showTotal={false}
          hideDownload
          ignoreMaxHeight
          noDataMessage={<NoDataMessage />}
          noDataClassName="border-0 shadow-none"
          footer={
            showAllButton && (
              <div className="cursor-pointer" onClick={() => setShowFavoritesPanel(true)}>
                {t('bookmark.show_all')}
              </div>
            )
          }
        />
      </div>
    </div>
  )
}

const PageContent = () => {
  const { t } = useTranslation('overview')
  const showUpcomingEvents = useFlag(Flags.SHOW_UPCOMING_EVENTS)
  return (
    <section className="px-4 pt-6 md:p-8 !pb-0 h-full w-full max-w-[1232px] 2xl:max-w-[1500px] flex-1 flex flex-col gap-8 justify-center items-center">
      <div className="flex flex-col gap-5 w-2/3">
        <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white">{t('title')}</h1>
        <div className="h-full  flex-1 flex gap-6 md:flex-row">
          <div className="h-full flex flex-col gap-6 w-full">
            <QuickActions />
            <Favorites />
          </div>
        </div>
        {showUpcomingEvents && <UpcomingEvents />}
      </div>
    </section>
  )
}

export default function OverviewPage() {
  const { t } = useTranslation('overview')
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('title')} />
      </Helmet>
      <div className="flex w-full flex-col items-center bg-transparent max-h-full">
        <PageContent />
        <Footer />
      </div>
    </>
  )
}
