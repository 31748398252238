import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Theme, ThemeContext } from '@contexts/Theme/context'
import { isExportingPdf } from '@utils/pdf'

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const getOsTheme = useCallback(() => {
    const isOsDark = window.matchMedia('(prefers-color-scheme: dark)').matches

    return isOsDark ? 'dark' : 'light'
  }, [])

  const getInitTheme = useCallback(() => {
    const isOsDark = getOsTheme() === 'dark'
    const localTheme = localStorage.theme

    if (isExportingPdf()) return 'light'

    return localTheme === 'dark' || (!('theme' in localStorage) && isOsDark) ? 'dark' : 'light'
  }, [getOsTheme])

  const [theme, setTheme] = useState<Theme>(getInitTheme())

  const setDocumentTheme = useCallback((theme: string) => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [])

  useEffect(() => {
    localStorage.theme = theme || getOsTheme()
    setDocumentTheme(theme || getOsTheme())
  }, [getOsTheme, theme, setDocumentTheme])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        getOsTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
