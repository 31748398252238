import { useContext } from 'react'
import { AppConfigContext } from '@config/site/app/context'

export const usePages = () => {
  const { canSeePage, navBar, docsMenu, initialPath, getPageData, getMinimumRoleForPage, isLoadingPageFlag, appLinks } =
    useContext(AppConfigContext)

  return {
    canSeePage,
    navBar,
    docsMenu,
    initialPath,
    getPageData,
    getMinimumRoleForPage,
    isLoadingPageFlag,
    appLinks,
  }
}
