import { useContext } from 'react'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { StoresContext } from '@stores/StoresContext'
import { PredictionAssessmentStoreState } from '@stores/predictionAssessmentStore'

export function usePredictionAssessmentStore<T>(
  selector: (state: PredictionAssessmentStoreState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(StoresContext)
  if (!store) throw new Error('Missing Provider in the tree')

  return useStoreWithEqualityFn(store.predictionAssessmentStore, selector, equalityFn)
}
