import { forwardRef, useState } from 'react'
import { Breadcrumb, useTheme } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { BookmarkButton } from '@components/Bookmarks/BookmarkButton'
import { CopyReportUrlButton } from '@components/Bookmarks/CopyReportUrlButton'
import { EventContent, EventContentRef } from '@components/EventContent'
import { useEventContentExportOptions } from '@components/EventContent/useExportOptions'
import { ExportComment } from '@components/Export'
import { ExportButton } from '@components/Export/ExportButton'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { AdditionalParamsOnInternalChangeForEventSearch, useEventSearchStore } from '@stores/eventSearchStore'
import { cn } from '@utils/className'
import { toISOStringDatePart } from '@utils/date'

export const SingleEvent = forwardRef<EventContentRef, any>((_, ref) => {
  const { t } = useTranslation('event_search_advanced')
  const breadcrumbTheme = useTheme().theme.breadcrumb
  const searchParamsHandler = useSearchParamsObject()
  const navigate = useNavigate()
  const locationState = useLocation().state as { showBackButton?: boolean }
  const [showBackButton] = useState(locationState?.showBackButton || false)
  const { context, getEventName, stringifyEvent, getPdfHeader } = useEventSearchStore((state) => ({
    context: state.context,
    getEventName: state.getEventName,
    stringifyEvent: state.stringifyEvent,
    getPdfHeader: state.getPdfHeader,
  }))

  const main = useEventSearchStore((state) => ({
    events: state.events,
    loading: state.isLoadingEvents(),
    summaryData: state.eventSummaryData,
    eventFilter: state.context.singleEventFilter,
  }))

  const hasEventFilter = !!(
    main &&
    main.eventFilter &&
    main.eventFilter.artist &&
    main.eventFilter.venue &&
    main.eventFilter.date
  )

  const event = hasEventFilter
    ? main.events.items.find(
        (event) =>
          event.brand?.id?.toString() === main.eventFilter?.artist &&
          event.venue?.id?.toString() === main.eventFilter?.venue &&
          event.performance_date &&
          toISOStringDatePart(event.performance_date) === main.eventFilter?.date,
      )
    : null

  const customEvents = useEventSearchStore((state) => ({
    timeSeriesEntities: state.multipleCustomTimeSeriesEntities,
    fetch: state.fetchMultipleCustomTimeSeriesData,
    events: state.customEvents,
    setEvents: state.setCustomEvents,
  }))

  const benchmark = useEventSearchStore((state) => ({
    selected: state.selectedBenchmark,
    setSelected: state.setSelectedBenchmark,
  }))

  const primaryMarket = useEventSearchStore((state) => ({
    timeSeriesEntity: state.timeSeriesEntity,
  }))

  const secondaryMarket = useEventSearchStore((state) => ({
    timeSeriesData: state.secondaryMarketTimeSeriesData,
    timeSeriesInventoryData: state.secondaryMarketTimeSeriesInventoryData,
    priceAndTierData: state.secondaryMarketPriceAndTierData,
    chartType: state.secondaryMarketChartType,
    setChartType: state.setSecondaryMarketChartType,
  }))

  const competitors = useEventSearchStore((state) => ({
    summaryData: state.competitorsSummaryData,
    summaryDataLoading: state.competitorsSummaryLoading || main.loading,
    data: state.competitors,
    filters: state.competitorsFilters,
    setFilters: state.setCompetitorsFilters,
    getFilterContext: state.getCompetitorsFilterContext,
  }))

  const { backToList } = useEventSearchStore((state) => ({
    backToList: state.backToList,
  }))

  const back = () => {
    showBackButton ? navigate('/upcoming-events') : backToList(searchParamsHandler)
  }

  const artist = event?.brand ? event.brand.name : main.summaryData?.event?.brand?.name || null
  const venue = event?.venue ? event.venue.name : main.summaryData?.event?.venue?.name || null
  const date = event?.performance_date ? event.performance_date : main.summaryData?.event?.performance_date || null

  const title = getEventName()
  const pdfHeader = getPdfHeader()
  const stringfiedFilterContext = stringifyEvent()

  const eventContentProps = {
    ...main,
    hasEventFilter,
    pdfHeader,
    stringfiedFilterContext,
    customEvents,
    benchmark,
    primaryMarket,
    secondaryMarket,
    competitors,
    additionalParamsForInternalChange: {
      searchParamsHandler,
    },
  }

  const exportOptions = useEventContentExportOptions<AdditionalParamsOnInternalChangeForEventSearch>(eventContentProps)

  return (
    <div className="px-4 py-6 md:p-8 !pb-0 group-[.pdf-report]:p-0 w-full">
      <div className="flex flex-row justify-between gap-2 mb-6 md:mb-8 w-full">
        <div className="min-w-0">
          <div className="pb-4 group-[.pdf-report]:hidden">
            <Breadcrumb
              theme={{
                list: cn(breadcrumbTheme.root.list, 'flex items-center gap-2'),
              }}
            >
              <Breadcrumb.Item
                theme={{
                  base: cn(breadcrumbTheme.item.base, 'min-w-0'),
                  href: {
                    off: cn(
                      breadcrumbTheme.item.href.off,
                      'cursor-pointer text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white',
                      'inline truncate',
                    ),
                  },
                }}
                onClick={back}
              >
                {t('title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                theme={{
                  base: cn(breadcrumbTheme.item.base, 'min-w-0'),
                  href: {
                    off: cn(breadcrumbTheme.item.href.off, 'inline truncate'),
                  },
                }}
              >
                {title}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="flex flex-row items-center w-full min-w-0">
            <div
              onClick={back}
              className="w-10 h-10 shrink-0 rounded-full flex items-center justify-center border border-gray-200 cursor-pointer mr-4 dark:border-gray-500 group-[.pdf-report]:hidden"
            >
              <ArrowLeftIcon className="w-5 h-5 text-gray-900 dark:text-white" />
            </div>

            <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white truncate">{title}</h1>
          </div>
          <h2 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">
            {t('single_page.subtitle')}
          </h2>
        </div>
        <div className="flex gap-2">
          <ExportButton
            isLoading={main.loading}
            pageId={BookmarkPageEnum.EVENT_ADVANCED_SINGLE}
            options={exportOptions}
          />
          <CopyReportUrlButton disabled={!hasEventFilter} />
          <BookmarkButton
            page={BookmarkPageEnum.EVENT_ADVANCED_SINGLE}
            context={context}
            extraData={{
              nameCreator: { artist, venue, date },
            }}
          />
        </div>
      </div>
      <ExportComment />
      <EventContent<AdditionalParamsOnInternalChangeForEventSearch>
        {...eventContentProps}
        ref={ref}
        exportOptions={exportOptions}
        page={BookmarkPageEnum.EVENT_ADVANCED_SINGLE}
      />
    </div>
  )
})
