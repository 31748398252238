import { Draft } from 'immer'

export const setRef = (set: any, get: any, refName: string, ref: Element | null) => {
  if (!ref) return

  const currentRef = get()[refName].current
  const isCurrentRefInDOM = document.body.contains(currentRef)

  if (!currentRef || !isCurrentRefInDOM) {
    set({ [refName]: { current: ref } })
  }
}

export type StoreSetFunctionParams<StoreState> = StoreState | Partial<StoreState> | ((state: Draft<StoreState>) => void)

export type StoreSetFunction<StoreState> = (
  nextStateOrUpdater: StoreSetFunctionParams<StoreState>,
  shouldReplace?: boolean | undefined,
) => void
