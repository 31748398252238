import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChartCard } from '@components/ChartCard'
import { BenchmarkOption, BenchmarkOptionId, SingleEventContentProps } from '@components/EventContent/types'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { PresentationChartLineIcon } from '@heroicons/react/24/outline'
import { useSingleEventStore } from '@stores/singleEventStore'
import { objectUtils } from '@utils'
import { CustomEventFilterGroups, CustomEventsBenchmark } from './CustomEventsBenchmark'
import { AxisType, SelectXAxis } from './SelectXAxis'
import { TimeSeriesChart } from './TimeSeriesChart'

type TimeSeriesChartCardProps<AdditionalParamsOnInternalChange> = Pick<
  SingleEventContentProps<AdditionalParamsOnInternalChange>,
  | 'loading'
  | 'stringfiedFilterContext'
  | 'pdfHeader'
  | 'customEvents'
  | 'benchmark'
  | 'additionalParamsForInternalChange'
  | 'exportOptions'
  | 'page'
>

export function TimeSeriesChartCard<AdditionalParamsOnInternalChange>(
  props: TimeSeriesChartCardProps<AdditionalParamsOnInternalChange>,
) {
  const { loading, stringfiedFilterContext, pdfHeader, customEvents, benchmark, exportOptions, page } = props

  const { event } = useSingleEventStore((state) => ({
    event: state.event,
  }))
  const [isLoading, setLoading] = useState(loading)
  const { t } = useTranslation('tour_marketing')
  const [xAxis, setXAxis] = useState(AxisType.DaysUntilShow)

  const customEventsBenchmarkOption: BenchmarkOption = useMemo(() => {
    return {
      id: BenchmarkOptionId.CustomEvents,
      label: t('cumulative_tickets_sold_chart.custom_events'),
    }
  }, [t])

  const applyFilters = useCallback(
    async (newFilters: CustomEventFilterGroups) => {
      try {
        setLoading(true)
        benchmark.setSelected(customEventsBenchmarkOption, props.additionalParamsForInternalChange)
        customEvents.setEvents(newFilters, props.additionalParamsForInternalChange)
        await customEvents.fetch()
      } catch (error) {
        objectUtils.logErrorMessage(error)
      } finally {
        setLoading(false)
      }
    },
    [customEvents, props.additionalParamsForInternalChange, customEventsBenchmarkOption, benchmark],
  )

  useEffect(() => {
    setLoading(loading)
  }, [loading])
  const formattedSelectedEvent = useMemo(() => {
    return [event].map((event) => {
      return event.time_series?.map((timeSeries) => ({ ...timeSeries, event })) || []
    })
  }, [event])

  const events = useMemo(
    () => [...[...formattedSelectedEvent, ...customEvents.timeSeriesEntities].filter((event) => event.length)],
    [customEvents, formattedSelectedEvent],
  )

  return (
    <ChartCard
      id={VisualizationId.CumulativeTicketsSold}
      visualization={VisualizationId.CumulativeTicketsSold}
      title={t('cumulative_tickets_sold_chart.title')}
      info={t('cumulative_tickets_sold_chart.info')}
      subtitle={
        <h5 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400 mt-2">
          {t('cumulative_tickets_sold_chart.subtitle')}
        </h5>
      }
      showLastUpdated={false}
      chart={<TimeSeriesChart multipleCustomTimeSeriesEntity={events} xAxis={xAxis} />}
      showChart={!!events?.length}
      noDataIcon={PresentationChartLineIcon}
      isLoading={isLoading}
      exportFilename={`cumulative_tickets_sold${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`}
      chartControlsComponent={
        <div className="flex gap-2 flex-col md:flex-row md:flex-wrap md:justify-center">
          <SelectXAxis
            xAxis={xAxis}
            setXAxis={setXAxis}
            selectedBenchmark={benchmark.selected}
            multipleCustomTimeSeriesEntity={events}
            disabled={isLoading}
          />
        </div>
      }
      controlPanelComponent={<CustomEventsBenchmark handleSelected={applyFilters} customEvents={customEvents.events} />}
      pdfHeader={pdfHeader}
      exportOptions={exportOptions}
      page={page}
    />
  )
}
