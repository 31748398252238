import { Filters } from '@stores/eventSearchStore'
import { FilterDateRange, FilterKey, filterNameToClass } from '@stores/eventSearchStore/filterTypes'
import { formatUtils } from '@utils'
import { formatCityState } from '@utils/format'

export const buildEventName = (
  brandName: string,
  venueName: string,
  date: string | Date,
  city?: string,
  state?: string,
) => {
  const cityState = formatCityState(city, state)
  const venue = `${venueName} ${cityState}`.trim()
  return `${brandName} | ${venue} | ${formatUtils.formatDateNumbersOnly(date)}`
}

export const createFilterInstances = (data: any[]) => {
  const filters: Filters = []
  data.forEach((filter: any) => {
    const filterClass = filterNameToClass[filter.name as FilterKey]
    const filterInstance = new filterClass(filter.name, null)
    filterInstance.data = filter.data
    if (filterClass === FilterDateRange) {
      filterInstance.data.start = filter.data.start ? new Date(filter.data.start) : null
      filterInstance.data.end = filter.data.end ? new Date(filter.data.end) : null
      if (!isNaN(filter.data.option?.value)) {
        filterInstance.data.option.value = +filter.data.option.value
      }
    }
    filters.push(filterInstance)
  })
  return filters
}
