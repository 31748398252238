import { Table } from '@components/Table'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { SecondaryMarketTimeSeriesInventoryBySectionData } from '@types'
import { formatUtils } from '@utils'

interface SecondaryMarketBySectionTableProps {
  id: VisualizationId
  seriesData?: SecondaryMarketTimeSeriesInventoryBySectionData[]
  showAvgPrice?: boolean
}

export const SecondaryMarketBySectionTable = ({
  id,
  seriesData = [],
  showAvgPrice,
}: SecondaryMarketBySectionTableProps) => {
  return (
    <div className="px-6 pb-4">
      <Table
        id={id}
        header={[{ column: 'Section' }, showAvgPrice ? { column: 'Avg Price' } : { column: 'Total Tickets sold' }]}
        body={seriesData.map((data) => ({
          row: [
            <span>
              <strong>{data.section}</strong>
            </span>,
            showAvgPrice
              ? data.avg_listing_price == null
                ? '-'
                : `$${formatUtils.formatNumber(data.avg_listing_price)}`
              : data.total_tickets_listed == null
              ? '-'
              : data.total_tickets_listed,
          ],
        }))}
        showTable={seriesData.length > 0}
        isLoading={false}
        showTotal={false}
        hideDownload
      />
    </div>
  )
}
