import { useCallback, useEffect, useRef, useState } from 'react'
import qs from 'qs'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import Joyride, { LIFECYCLE } from 'react-joyride'
import { EventContentRef } from '@components/EventContent'
import { TCustomConfirmMethod } from '@components/Export/ExportModal'
import { Footer } from '@components/Footer'
import { SubNav } from '@components/SubNav'
import { selectArtistTooltipStep } from '@components/TooltipSteps/SelectArtistTooltipStep'
import { ControlPanel } from '@components/TourPlanning/ControlPanel'
import EventsList, { EventsListRef } from '@components/TourPlanning/EventsList'
import { SingleEvent } from '@components/TourPlanning/SingleEvent'
import { defaultJoyrideProps } from '@config/react-joyride'
import { usePrevious } from '@hooks'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { usePaginatedData } from '@hooks/usePaginatedData'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { clearParams, useSingleEventStore } from '@stores/singleEventStore'
import { Filters, useTourPlanningStore } from '@stores/tourPlanningStore'
import { filtersToQueryParams } from '@stores/tourPlanningStore/queryParams'
import { StarEventEntity } from '@types'
import { objectUtils, screenUtils } from '@utils'
import { exportComponentToPDF, hasDownloadPDFUrlParam } from '@utils/pdf'

export default function TourPlanningPage() {
  const contentRef = useRef<HTMLElement>(null)
  const mainRef = useRef<HTMLDivElement>(null)
  const [singleEventRef, setSingleEventRef] = useState<EventContentRef | null>(null)
  const [eventsListRef, setEventsListRef] = useState<EventsListRef | null>(null)
  const {
    isLoading,
    isLoadingEvents,
    pdfHeader,
    stringfiedFilterContext,
    events: stateEvents,
    selectedEvents,
    firstLoad,
    loadData,
    applyFilters,
    setExportOptions,
    isShowingSingleEvent,
    context,
  } = useTourPlanningStore((state) => ({
    isLoading: state.isLoading,
    isLoadingEvents: state.isLoadingEvents,
    pdfHeader: state.pdfHeader,
    filterContext: state.filterContext,
    stringfiedFilterContext: state.stringfiedFilterContext,
    events: state.events,
    selectedEvents: state.selectedEvents,
    selectedEventIds: state.selectedEventIds,
    loadData: state.loadData,
    firstLoad: state.firstLoad,
    applyFilters: state.applyFilters,
    page: state.page,
    exportOptions: state.exportOptions,
    setExportOptions: state.setExportOptions,
    isShowingSingleEvent: state.isShowingSingleEvent,
    context: state.context,
  }))

  const events = usePaginatedData<StarEventEntity, Filters>(
    {
      fetchParams: filtersToQueryParams,
      fetchUrl: '/data/eventsummarylist',
    },
    stateEvents,
  )

  const { t } = useTranslation('tour_planning')
  const searchParamsHandler = useSearchParamsObject()
  const previousSearchParams = usePrevious(searchParamsHandler.searchParams)

  const scrollIntoView = useCallback(() => {
    if (screenUtils.isMobile(window)) {
      contentRef.current?.scrollIntoView({ behavior: 'smooth' })
    } else {
      mainRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [contentRef, mainRef])

  const _applyFilters = useCallback(
    async (newFilters: Filters) => {
      try {
        await applyFilters(newFilters, { events }, searchParamsHandler)
        scrollIntoView()
      } catch (error) {
        objectUtils.logErrorMessage(error)
      }
    },
    [applyFilters, events, scrollIntoView, searchParamsHandler],
  )

  useEffectOnce(() => {
    events.finishPendingFetch()
  })

  const { loadFromUrl, initLoad, isInitiated } = useSingleEventStore((state) => ({
    loadFromUrl: state.loadFromUrl,
    initLoad: state.initLoad,
    isInitiated: state.isInitiated,
  }))
  const initiated = isInitiated()
  useEffect(() => {
    const clearPrevious = clearParams(previousSearchParams)
    const clearCurrent = clearParams(searchParamsHandler.searchParams)
    if (clearPrevious !== clearCurrent && clearCurrent && clearCurrent.size > 0 && initiated) {
      loadFromUrl(searchParamsHandler).catch(() => {
        /* empty */
      })
    }
  }, [initiated, loadFromUrl, previousSearchParams, searchParamsHandler])
  useEffectOnce(() => {
    initLoad(searchParamsHandler)
  })

  const handleCustomExportPDF: TCustomConfirmMethod = useCallback(
    (customPdfReportHide, customPdfComment) => {
      if (!isLoading && !isLoadingEvents && !!pdfHeader) {
        exportComponentToPDF({
          component: contentRef.current,
          filename: `${t('title')}${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`,
          header: pdfHeader,
          autoClose: false,
          customPdfReportHide,
          customPdfComment,
        })
      }
    },
    [isLoading, isLoadingEvents, pdfHeader, t, contentRef, stringfiedFilterContext],
  )

  useEffect(() => {
    if (
      !isLoading &&
      !isLoadingEvents &&
      !!pdfHeader &&
      hasDownloadPDFUrlParam(BookmarkPageEnum.BRAND_VENUE_INSIGHTS)
    ) {
      exportComponentToPDF({
        component: contentRef.current,
        filename: `${t('title')}${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`,
        header: pdfHeader,
      })
    }
  }, [isLoading, isLoadingEvents, pdfHeader, t, contentRef, stringfiedFilterContext])
  useEffectOnce(() => {
    loadData({ events }, searchParamsHandler)
  })

  useEffect(() => {
    if (previousSearchParams) {
      const previous = qs.parse(previousSearchParams.toString())
      const current = qs.parse(searchParamsHandler.searchParams.toString())
      if ('selectedEvents' in previous && !('selectedEvents' in current)) return
    }
    if (
      !firstLoad &&
      previousSearchParams !== searchParamsHandler.searchParams &&
      searchParamsHandler.searchParams.size > 0
    ) {
      loadData({ events }, searchParamsHandler, { enableReload: true })
    }
  }, [searchParamsHandler.searchParams, firstLoad, previousSearchParams, loadData, events, searchParamsHandler])

  useEffect(() => {
    if (!firstLoad && localStorage.tourPlanningJoyrideRunComplete) scrollIntoView()
  }, [firstLoad, scrollIntoView])

  const showingSingleEvent = isShowingSingleEvent()

  useEffect(() => {
    setExportOptions([
      {
        id: 'pdf-report-hide-historical-summary',
        label: t('historical_summary_table.title'),
        disabled: events?.total === 0,
      },
      {
        id: 'pdf-report-hide-timeseries-chart',
        label: t('cumulative_tickets_sold_chart.title'),
        disabled: selectedEvents.length === 0,
      },
    ])
  }, [selectedEvents, t, events?.total, setExportOptions])

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('title')} />
      </Helmet>

      <Joyride
        {...defaultJoyrideProps}
        run={!localStorage.tourPlanningJoyrideRunComplete}
        steps={[selectArtistTooltipStep]}
        callback={(data) => {
          if (data.lifecycle === LIFECYCLE.COMPLETE) {
            localStorage.tourPlanningJoyrideRunComplete = true
          }
        }}
      />

      <div className="" ref={mainRef}>
        <SubNav
          container={mainRef.current?.parentElement as HTMLElement}
          items={
            showingSingleEvent && singleEventRef?.subnavItems && singleEventRef.subnavItems.length > 0
              ? singleEventRef.subnavItems
              : eventsListRef?.subnavItems || []
          }
        />
        <div className="flex flex-col lg:flex-row">
          <ControlPanel handleSelected={_applyFilters} />
          <section
            className="flex-1 w-full lg:w-[calc(100%-256px)] group-[.pdf-report]:bg-white group-[.pdf-report]:dark:bg-gray-700"
            ref={contentRef}
          >
            {showingSingleEvent ? (
              <SingleEvent
                events={events}
                customConfirm={handleCustomExportPDF}
                context={context}
                ref={setSingleEventRef}
              />
            ) : (
              <EventsList events={events} ref={setEventsListRef} customConfirm={handleCustomExportPDF} />
            )}
            <Footer />
          </section>
        </div>
      </div>
    </>
  )
}
