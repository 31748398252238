import { useCallback, useEffect, useRef, useState } from 'react'
import { EChartsOption } from 'echarts'
import { useTranslation } from 'react-i18next'
import { useChartCardData } from '@components/ChartCard'
import { EChartsComponent } from '@components/EChartsComponent'
import { SecondaryMarketTimeSeriesInventoryData } from '@types'
import { formatUtils } from '@utils'
import { getChartStyle, getXAxisDaysFromEventWithinEventData, showAnimation, showSlider } from '@utils/charts'
import { pivotMatrixFromDays } from '@utils/number'

interface SecondaryMarketTimeSeriesInventoryChartProps {
  secondaryMarketTimeSeriesInventoryData?: SecondaryMarketTimeSeriesInventoryData[]
}

export const SecondaryMarketTimeSeriesInventoryChart = ({
  secondaryMarketTimeSeriesInventoryData = [],
}: SecondaryMarketTimeSeriesInventoryChartProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const { setData: setDownloadableData } = useChartCardData()
  const [chartWidth, setChartWidth] = useState<number | null>(null)
  const observer = useRef<ResizeObserver | null>(null)
  const { t } = useTranslation('tour_marketing')

  const handleRef = useCallback((node: HTMLDivElement) => {
    if (node && !observer.current) {
      observer.current = new ResizeObserver((entries) => {
        setChartWidth(entries[0].contentBoxSize?.[0].inlineSize)
      })
      observer.current.observe(node)
    }
  }, [])

  useEffect(() => {
    return () => {
      observer?.current?.disconnect()
      observer.current = null
    }
  }, [])

  const getChartOption = useCallback(async () => {
    const xAxisData = getXAxisDaysFromEventWithinEventData([secondaryMarketTimeSeriesInventoryData])

    const totalTicketsData = secondaryMarketTimeSeriesInventoryData
      .filter((data) => data.event.days_from_event)
      .map((data) => [data.event.days_from_event, data.total_tickets_listed])
      .sort((a, b) => b[0] - a[0])

    const avgListingPriceData = secondaryMarketTimeSeriesInventoryData
      .filter((data) => data.event.days_from_event)
      .map((data) => [data.event.days_from_event, data.avg_listing_price])
      .sort((a, b) => b[0] - a[0])

    const fileDate = secondaryMarketTimeSeriesInventoryData
      .filter((data) => data.event.days_from_event)
      .map((data) => [data.event.days_from_event, data.file_date] as [number, string])
      .sort((a, b) => b[0] - a[0])

    const maxWidth = chartWidth ? chartWidth / 2 - 40 : null
    const nameTruncate = maxWidth ? { maxWidth } : {}

    setChartOption({
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const date = fileDate.find((d) => d[0] === params.data[0])?.[1]
          const avgListingPrice = avgListingPriceData.find((d) => d[0] === params.data[0])?.[1]
          const totalTickets = totalTicketsData.find((d) => d[0] === params.data[0])?.[1]

          return `${t('secondary_market_chart.days_from_event')}: ${params.data[0]}<br />
            ${t('secondary_market_chart.average_listing_price')}: ${formatUtils.formatNumber(avgListingPrice || 0, {
            maximumFractionDigits: 2,
          })}<br />
            ${t('secondary_market_chart.total_ticket_listed')}: ${formatUtils.formatNumber(totalTickets || 0)}<br />
            ${t('secondary_market_chart.inventory_item_date')}: ${formatUtils.formatDateNumbersOnly(date)}`
        },
      },
      legend: {
        data: [t('secondary_market_chart.total_ticket_listed'), t('secondary_market_chart.average_listing_price')],
        top: '0',
        type: 'scroll',
      },
      grid: {
        left: '3%',
        right: '4%',
        containLabel: true,
      },
      xAxis: {
        interval: 5,
        name: t('secondary_market_chart.days_from_event'),
        nameGap: 24,
        nameLocation: 'middle',
        inverse: true,
        min: 'dataMin',
        max: 'dataMax',
      },
      yAxis: [
        {
          name: t('secondary_market_chart.total_ticket_listed'),
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          nameLocation: 'end',
          nameTruncate,
        },
        {
          name: t('secondary_market_chart.average_listing_price'),
          type: 'value',
          nameTextStyle: {
            align: 'right',
          },
          nameLocation: 'end',
          nameTruncate,
        },
      ],
      series: [
        {
          name: t('secondary_market_chart.total_ticket_listed'),
          type: 'bar',
          stack: '',
          data: totalTicketsData,
        },
        {
          name: t('secondary_market_chart.average_listing_price'),
          type: 'line',
          stack: '',
          yAxisIndex: 1,
          data: avgListingPriceData,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
        },
      ],
      animation: showAnimation(),
    })
    setDownloadableData([
      [
        t('secondary_market_chart.days_from_event'),
        t('secondary_market_chart.total_ticket_listed'),
        t('secondary_market_chart.average_listing_price'),
        t('secondary_market_chart.inventory_item_date'),
      ],
      ...pivotMatrixFromDays(xAxisData, [totalTicketsData, avgListingPriceData, fileDate], 2),
    ])
  }, [secondaryMarketTimeSeriesInventoryData, chartWidth, setDownloadableData, t])

  useEffect(() => {
    getChartOption()
  }, [getChartOption])

  if (!chartOption) return null

  return <EChartsComponent option={chartOption} style={getChartStyle()} ref={handleRef} />
}
