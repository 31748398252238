import { useState } from 'react'
import { Button, Modal, useTheme } from 'carbonarc-ui'
import { toast } from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { UseQueryResult } from 'react-query'
import { useApi } from '@services/api'
import { useBookmarkStore } from '@stores/bookmarkStore'
import { Bookmark } from '@stores/bookmarkStore/utils'
import { cn } from '@utils/className'

type BookmarkModalDeleteProps = {
  remoteBookmark: UseQueryResult<Bookmark, unknown>
  show: boolean
  setShow: (show: boolean) => void
  onClose: () => void
  setAlreadyFavorite?: (alreadyFavorite: boolean) => void
}

export const BookmarkModalDelete = ({
  remoteBookmark,
  show,
  setShow,
  onClose,
  setAlreadyFavorite,
}: BookmarkModalDeleteProps) => {
  const modalTheme = useTheme().theme.modal
  const { t } = useTranslation()
  const [deleting, setDeleting] = useState(false)
  const { fetch: apiFetch, remove: apiRemove } = useApi()
  const { refetchAll } = useBookmarkStore((state) => ({ refetchAll: state.fetchBookmarks }))

  const deleteFavorite = async () => {
    try {
      setDeleting(true)
      await apiRemove(`/app/userbookmarks/${remoteBookmark.data?.uid}`)
      toast.success(t('bookmark.remove.success_message'))
      remoteBookmark.refetch()
      if (setAlreadyFavorite) setAlreadyFavorite(false)
      refetchAll(apiFetch)
    } catch (e) {
      toast.error(t('bookmark.remove.error_message'))
    } finally {
      setShow(false)
      setDeleting(false)
    }
  }

  return (
    <Modal
      show={show}
      position="top-center"
      onClose={() => onClose()}
      theme={{
        root: {
          positions: {
            'top-center': cn(modalTheme.root.positions['top-center'], 'pt-[100px]'),
          },
        },
      }}
    >
      <Modal.Header
        theme={{
          title: cn(modalTheme.header.title, 'text-lg font-semibold'),
        }}
      >
        {t('bookmark.remove.modal_title')}
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="bookmark.remove.modal_message"
          values={{
            reportName: remoteBookmark.data?.name,
          }}
          components={[
            <div className="text-xs font-normal text-gray-500 dark:text-gray-400 mb-3"></div>,
            <div className="text-sm font-semibold text-gray-700 dark:text-gray-300"></div>,
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="destructive" onClick={deleteFavorite} isLoading={deleting}>
          {t('bookmark.remove.modal_confirm')}
        </Button>
        <Button variant="outline" onClick={onClose} disabled={deleting}>
          {t('bookmark.remove.modal_cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
