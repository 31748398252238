import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BookmarkButton } from '@components/Bookmarks/BookmarkButton'
import { CopyReportUrlButton } from '@components/Bookmarks/CopyReportUrlButton'
import { EventContent, EventContentRef } from '@components/EventContent'
import { useEventContentExportOptions } from '@components/EventContent/useExportOptions'
import { ExportButton } from '@components/Export/ExportButton'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { EventFilter } from '@stores/slices/eventDataSlice'
import { AdditionalParamsOnInternalChangeForTourMarketing, useTourMarketingStore } from '@stores/tourMarketingStore'

type TourMarketingEventProps = {
  showBackButton?: boolean
}

export const TourMarketingEvent = forwardRef<EventContentRef, TourMarketingEventProps>((props, ref) => {
  const { t } = useTranslation('tour_marketing')
  const searchParamsHandler = useSearchParamsObject()
  const { showBackButton = false } = props
  const { isLoading, getContext } = useTourMarketingStore((state) => ({
    isLoading: state.isLoading,
    getContext: state.getContext,
  }))

  const main = useTourMarketingStore((state) => ({
    loading: state.isLoading,
    pdfHeader: state.pdfHeader,
    stringfiedFilterContext: state.stringfiedFilterContext,
    summaryData: state.eventSummaryData,
    filterContext: state.filterContext,
  }))

  const hasEventFilter = !!(
    main &&
    main.filterContext &&
    main.filterContext.artist?.id &&
    main.filterContext.venue?.id &&
    main.filterContext.perfDate?.id
  )
  const eventFilter: EventFilter | null = hasEventFilter
    ? {
        artist: main?.filterContext?.artist?.id || '',
        venue: main?.filterContext?.venue?.id || '',
        date: main?.filterContext?.perfDate?.id || '',
      }
    : null

  const customEvents = useTourMarketingStore((state) => ({
    timeSeriesEntities: state.multipleCustomTimeSeriesEntities,
    fetch: state.fetchMultipleCustomTimeSeriesData,
    events: state.customEvents,
    setEvents: state.setCustomEvents,
  }))

  const benchmark = useTourMarketingStore((state) => ({
    selected: state.selectedBenchmark,
    setSelected: state.setSelectedBenchmark,
  }))

  const primaryMarket = useTourMarketingStore((state) => ({
    timeSeriesEntity: state.timeSeriesEntity,
  }))

  const secondaryMarket = useTourMarketingStore((state) => ({
    timeSeriesData: state.secondaryMarketTimeSeriesData,
    timeSeriesInventoryData: state.secondaryMarketTimeSeriesInventoryData,
    priceAndTierData: state.secondaryMarketPriceAndTierData,
    chartType: state.secondaryMarketChartType,
    setChartType: state.setSecondaryMarketChartType,
  }))

  const competitors = useTourMarketingStore((state) => ({
    summaryData: state.competitorsSummaryData,
    summaryDataLoading: state.competitorsSummaryLoading || main.loading,
    data: state.competitors,
    filters: state.competitorsFilters,
    setFilters: state.setCompetitorsFilters,
    getFilterContext: state.getCompetitorsFilterContext,
  }))

  const context = getContext()

  const eventContentProps = {
    ...main,
    eventFilter,
    hasEventFilter,
    customEvents,
    benchmark,
    primaryMarket,
    secondaryMarket,
    competitors,
    additionalParamsForInternalChange: {
      searchParamsHandler,
    },
  }
  const exportOptions = useEventContentExportOptions(eventContentProps)
  return (
    <div className="px-4 py-6 md:p-8 !pb-0 group-[.pdf-report]:p-0">
      <div className="flex flex-col gap-2 mb-6 md:mb-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {showBackButton && (
              <Link
                to="/upcoming-events"
                className="w-10 h-10 rounded-full flex items-center justify-center border border-gray-200 cursor-pointer mr-4 dark:border-gray-500 group-[.pdf-report]:hidden"
              >
                <ArrowLeftIcon className="w-5 h-5 text-gray-900 dark:text-white" />
              </Link>
            )}

            <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white">{t('title')}</h1>
          </div>
          <div className="flex gap-2">
            <ExportButton isLoading={isLoading} pageId={BookmarkPageEnum.EVENT_INSIGHTS} options={exportOptions} />
            <CopyReportUrlButton disabled={!main.filterContext} />
            <BookmarkButton page={BookmarkPageEnum.EVENT_INSIGHTS} context={context} />
          </div>
        </div>
        <h2 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">{t('subtitle')}</h2>
      </div>
      <EventContent<AdditionalParamsOnInternalChangeForTourMarketing>
        ref={ref}
        {...eventContentProps}
        exportOptions={exportOptions}
      />
    </div>
  )
})
