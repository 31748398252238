import { cn } from '@utils/className'

export function WandFireworks({ size = 30 }) {
  return (
    <>
      <svg
        className="fill-gray-300"
        width={size}
        height={size}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1511 8.52467C15.9487 8.32236 15.6797 8.21094 15.3936 8.21094C15.1074 8.21094 14.8384 8.32236 14.6361 8.52467L1.31373 21.8471C1.11142 22.0494 1 22.3184 1 22.6046C1 22.8907 1.11142 23.1597 1.31373 23.362L2.63805 24.6863C2.84687 24.8951 3.12114 24.9995 3.39545 24.9995C3.66977 24.9995 3.94408 24.8951 4.15295 24.6863L7.16748 21.6718C7.35086 21.4884 7.35086 21.191 7.16748 21.0076C6.98406 20.8242 6.68669 20.8242 6.50327 21.0076L3.48878 24.0221C3.43736 24.0735 3.35364 24.0735 3.30227 24.0221L1.97795 22.6978C1.94603 22.6659 1.93938 22.6295 1.93938 22.6046C1.93938 22.5797 1.94608 22.5433 1.978 22.5114L11.6206 12.8688L13.1313 14.3795L9.49708 18.0138C9.3137 18.1972 9.3137 18.4946 9.49708 18.678C9.6805 18.8614 9.97787 18.8614 10.1613 18.678L17.4753 11.3639C17.6777 11.1616 17.7891 10.8926 17.7891 10.6064C17.7891 10.3203 17.6777 10.0513 17.4753 9.84894L16.1511 8.52467ZM16.8111 10.6996L13.7955 13.7152L12.2848 12.2045L15.3004 9.18889C15.3323 9.15697 15.3687 9.15031 15.3936 9.15031C15.4185 9.15031 15.4549 9.15702 15.4868 9.18894L16.8111 10.5132C16.843 10.5451 16.8497 10.5815 16.8497 10.6064C16.8497 10.6313 16.843 10.6677 16.8111 10.6996Z"
          className="fill-blue-700 stroke-blue-700 dark:fill-gray-300 dark:stroke-gray-300"
          strokeWidth="0.5"
        />
        <path
          d="M8.33346 20.3105C8.59286 20.3105 8.80314 20.1002 8.80314 19.8408C8.80314 19.5814 8.59286 19.3711 8.33346 19.3711C8.07406 19.3711 7.86377 19.5814 7.86377 19.8408C7.86377 20.1002 8.07406 20.3105 8.33346 20.3105Z"
          className="fill-blue-700 stroke-blue-700 dark:fill-gray-300 dark:stroke-gray-300"
          strokeWidth="0.5"
        />
      </svg>
      {/* top */}
      <svg
        className={cn(
          'fill-gray-300 absolute group-hover:animate-fireworks-t-sparkle group-hover:animate-fireworks-sparkle-back',
        )}
        width={size}
        height={size}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.14295 6.31262C9.34395 6.51367 9.63832 6.58886 9.91104 6.50894L11.6854 5.98928L13.4598 6.50894C13.5319 6.53003 13.6054 6.5403 13.6783 6.5403C13.8815 6.5403 14.08 6.46051 14.2279 6.31262C14.4289 6.11162 14.504 5.81734 14.4242 5.54458L13.9045 3.77017L14.4242 1.99581C14.5041 1.723 14.4289 1.42867 14.2279 1.22767C14.0268 1.02667 13.7324 0.951484 13.4598 1.03141L11.6854 1.55111L9.91109 1.03145C9.63832 0.951578 9.34399 1.02681 9.14299 1.22777C8.94204 1.42877 8.86681 1.72305 8.94668 1.99586L9.46638 3.77022L8.94668 5.54462C8.86676 5.8173 8.94199 6.11162 9.14295 6.31262ZM10.4065 3.63817L9.93148 2.0162L11.5534 2.49128C11.6396 2.51645 11.7312 2.51655 11.8174 2.49128L13.4394 2.01625L12.9644 3.63817C12.9391 3.72437 12.9391 3.81601 12.9644 3.90217L13.4394 5.52414L11.8174 5.04911C11.7312 5.02384 11.6396 5.02384 11.5534 5.04911L9.93148 5.52414L10.4065 3.90217C10.4317 3.81601 10.4317 3.72437 10.4065 3.63817Z"
          className="fill-blue-700 stroke-blue-700 dark:fill-gray-300 dark:stroke-gray-300"
          strokeWidth="0.5"
        />
      </svg>
      {/* Middle */}
      <svg
        className={cn(
          'fill-gray-300 absolute group-hover:animate-fireworks-m-sparkle group-hover:animate-fireworks-sparkle-back',
        )}
        width={size}
        height={size}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.7713 7.77063C22.8434 7.79172 22.9169 7.80199 22.9899 7.80199C23.1931 7.80199 23.3916 7.72216 23.5394 7.57432C23.7404 7.37332 23.8156 7.07903 23.7357 6.80622L23.2161 5.03186L23.7357 3.25746C23.8157 2.98474 23.7404 2.69041 23.5395 2.48941C23.3384 2.28841 23.044 2.21322 22.7713 2.2931L20.997 2.81275L19.2226 2.29314C18.95 2.21322 18.6555 2.28846 18.4545 2.48946C18.2535 2.69046 18.1783 2.98478 18.2582 3.2575L18.7779 5.03191L18.2582 6.80627C18.1783 7.07908 18.2535 7.37336 18.4545 7.57436C18.6556 7.77541 18.9501 7.8505 19.2226 7.77067L20.997 7.25097L22.7713 7.77063ZM19.243 6.78583L19.7181 5.16386C19.7433 5.07766 19.7433 4.98602 19.7181 4.89986L19.243 3.27789L20.865 3.75292C20.9511 3.77814 21.0429 3.77814 21.129 3.75292L22.7509 3.27789L22.2759 4.89986C22.2506 4.98607 22.2506 5.07771 22.2759 5.16386L22.7509 6.78583L21.129 6.31075C21.0859 6.29814 21.0414 6.29182 20.997 6.29182C20.9525 6.29182 20.908 6.29814 20.865 6.31075L19.243 6.78583Z"
          className="fill-blue-700 stroke-blue-700 dark:fill-gray-300 dark:stroke-gray-300"
          strokeWidth="0.5"
        />
      </svg>
      {/* bottom */}
      <svg
        className={cn(
          'fill-gray-300 absolute group-hover:animate-fireworks-b-sparkle group-hover:animate-fireworks-sparkle-back',
        )}
        width={size}
        height={size}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.4489 14.3131L24.9686 12.5387C25.0486 12.266 24.9733 11.9717 24.7724 11.7707C24.5713 11.5697 24.2769 11.4945 24.0042 11.5744L22.2299 12.094L20.4555 11.5744C20.1827 11.4945 19.8884 11.5696 19.6874 11.7707C19.4864 11.9717 19.4112 12.266 19.4911 12.5387L20.0108 14.3131L19.4911 16.0875C19.4112 16.3603 19.4864 16.6546 19.6874 16.8556C19.8354 17.0035 20.0338 17.0833 20.2369 17.0833C20.3099 17.0833 20.3834 17.073 20.4554 17.0519L22.2298 16.5322L24.0042 17.0519C24.2771 17.1318 24.5713 17.0565 24.7723 16.8556C24.9733 16.6546 25.0485 16.3603 24.9686 16.0875L24.4489 14.3131ZM22.3619 15.592C22.3188 15.5794 22.2744 15.5731 22.2299 15.5731C22.1855 15.5731 22.141 15.5794 22.0979 15.592L20.476 16.0671L20.951 14.4451C20.9763 14.3589 20.9763 14.2673 20.951 14.1811L20.476 12.5592L22.0979 13.0342C22.1842 13.0594 22.2758 13.0594 22.3619 13.0342L23.9838 12.5592L23.5088 14.1811C23.4836 14.2673 23.4836 14.359 23.5088 14.4451L23.9839 16.0671L22.3619 15.592Z"
          className="fill-blue-700 stroke-blue-700 dark:fill-gray-300 dark:stroke-gray-300"
          strokeWidth="0.5"
        />
      </svg>
    </>
  )
}
