import { useState } from 'react'
import { Button, Modal, RoleName } from 'carbonarc-ui'
import { ChatBot } from '@components/InsightsChatBot'
import { WandFireworks } from '@components/InsightsChatBot/WandFireworks'
import { useAuth } from '@components/Okta'

const enableExperimentalChat = import.meta.env.VITE_ENABLE_EXPERIMENTAL_CHAT === 'true'
const requiresAtLeastRole = RoleName.CLIENT_ADMIN

export function ChatBotButton() {
  const { isAtLeast } = useAuth()
  const [show, setShow] = useState(false)

  if (!enableExperimentalChat || !isAtLeast(requiresAtLeastRole)) {
    return null
  }

  return (
    <>
      <div className="fixed bottom-4 right-4 group-[.pdf-report]:hidden">
        <Button
          variant="outline"
          onClick={() => setShow(true)}
          className="relative group bg-white border-2 border-blue-700 dark:border-gray-300 dark:bg-gray-600 rounded-full h-16 shadow-lg"
        >
          <WandFireworks />
        </Button>
      </div>
      <Modal
        dismissible
        show={show}
        size="full"
        onClose={() => setShow(false)}
        theme={{
          root: {
            base: 'fixed backdrop-blur-[6px] top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full',
            sizes: {
              full: 'relative mx-5 my-10 w-full p-4 h-[calc(100%_-_80px)] md:mx-10 md:my-20 md:!h-[calc(100%_-_160px)] lg:m-10 lg:mt-[160px] lg:h-[calc(100%_-_200px)]',
            },
          },
          content: {
            inner: 'relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col h-full',
          },
        }}
      >
        <Modal.Header>Insights</Modal.Header>
        <Modal.Body
          theme={{
            base: 'p-0 flex-1 overflow-auto',
          }}
        >
          <ChatBot />
        </Modal.Body>
      </Modal>
    </>
  )
}
