import { useCallback, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { ReactComponent as UpcomingIcon } from '@assets/music/actions/upcoming-events-icon.svg'
import { ReactComponent as TicketIcon } from '@assets/ticket-icon.svg'
import { Footer } from '@components/Footer'
import { ControlPanel, EventCard, NoUpcomingEventsCard, Pagination, StatCard } from '@components/UpcomingEventsPage'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useApiFetch } from '@services/api'
import { Filters, useUpcomingEventsStore } from '@stores/upcomingEventsStore'

export default function UpcomingEvents() {
  const mainRef = useRef<HTMLDivElement>(null)

  const {
    isLoading,
    isLoadingStats,
    currentPage,
    eventsData,
    eventsStatsData,
    previousEventsStatsData,
    loadData,
    applyFilters,
    setPage,
  } = useUpcomingEventsStore((state) => ({
    isLoading: state.isLoading,
    isLoadingStats: state.isLoadingStats,
    currentPage: state.currentPage,
    eventsData: state.eventsData,
    eventsStatsData: state.eventsStatsData,
    previousEventsStatsData: state.previousEventsStatsData,
    loadData: state.loadData,
    applyFilters: state.applyFilters,
    setPage: state.setPage,
  }))

  const apiFetch = useApiFetch()
  const { t } = useTranslation('upcoming_events')

  const _applyFilters = useCallback(
    (newFilters: Filters) => {
      applyFilters(apiFetch, newFilters)
    },
    [apiFetch, applyFilters],
  )

  const goToPage = useCallback(
    (page: number) => {
      setPage(apiFetch, page)
    },
    [apiFetch, setPage],
  )

  useEffectOnce(() => {
    mainRef.current?.scrollIntoView()
  })

  useEffectOnce(() => {
    goToPage(1)
    loadData(apiFetch)
  })

  const notLoadingAndData = !isLoading && !!eventsData?.items?.length
  const notLoadingAndNoData = !isLoading && !eventsData?.items?.length

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('title')} />
      </Helmet>

      <div className="flex flex-col lg:flex-row">
        <ControlPanel handleSelected={_applyFilters} />
        <section className="w-full lg:w-[calc(100%-256px)]">
          <div className="px-4 py-6 md:p-8 !pb-0 flex-1 flex flex-col">
            <div className="flex flex-col gap-2 mb-6 md:mb-8">
              <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white">{t('title')}</h1>
              <h2 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">{t('subtitle')}</h2>
            </div>
            <div className="mb-6 md:mb-8 flex flex-col md:flex-row gap-8">
              <StatCard
                stat={{
                  name: t('total_upcoming_events'),
                  value: eventsStatsData?.total_upcoming_events,
                  previousValue: previousEventsStatsData?.total_upcoming_events,
                }}
                isLoading={isLoadingStats}
                icon={UpcomingIcon}
                iconColor="text-orange-500"
                iconContainerColor="bg-orange-100"
              />
              <StatCard
                stat={{
                  name: t('total_tickets_sold'),
                  value: eventsStatsData?.total_tickets_sold,
                  previousValue: previousEventsStatsData?.total_tickets_sold,
                }}
                isLoading={isLoadingStats}
                icon={TicketIcon}
                iconColor="text-blue-500"
                iconContainerColor="bg-blue-100"
              />
            </div>

            <h2 className="text-xl md:text-3xl font-semibold leading-tight tracking-tighter sm:text-xl lg:text-3xl dark:text-white mb-5 md:mb-6">
              {t('up_next')}
            </h2>

            {isLoading &&
              Array(5)
                .fill(1)
                .map((_, index) => <EventCard key={index} isLoading />)}

            {notLoadingAndData &&
              eventsData?.items?.map((event, index) => (
                <EventCard
                  key={`${index}-${event.brand?.id}-${event.venue?.id}-${event.performance_date}`}
                  event={event}
                />
              ))}

            {notLoadingAndNoData && <NoUpcomingEventsCard />}

            <Pagination isLoading={isLoading} currentPage={currentPage} eventsData={eventsData} goToPage={goToPage} />
          </div>
          <Footer />
        </section>
      </div>
    </>
  )
}
