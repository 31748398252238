import { Pagination as CAPagination, Select } from 'carbonarc-ui'
import { Trans, useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ApiFetch, useApiFetch } from '@services/api'
import { formatUtils } from '@utils'
import { updateSearchParam } from '@utils/url'

export interface TablePagination {
  prefix: string
  page: number
  pages: number
  size: number
  total: number
  goToPage: (p: number, apiFetch: ApiFetch) => void
  setPageSize: (s: number, apiFetch: ApiFetch) => void
}

type TablePaginationProps = {
  pagination?: TablePagination
  loading?: boolean
}

export const TablePagination = ({ pagination, loading = false }: TablePaginationProps) => {
  const { t } = useTranslation()
  const setSearchParams = useSearchParams()[1]
  const apiFetch = useApiFetch()
  if (!pagination) return null

  if (loading) {
    return (
      <div className="rounded-b-lg bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400 flex items-center gap-4 sm:gap-8 sm:justify-between p-4 flex-col sm:flex-row border-t dark:border-gray-700">
        <div className="flex items-center gap-4 sm:gap-8 flex-col sm:flex-row text-gray-500 dark:text-gray-400">
          <div className="flex items-center gap-2">
            <div className="animate-pulse h-3 w-24 inline-block rounded-lg bg-gray-200 dark:bg-gray-700"></div>
          </div>
          <div className="text-sm font-normal">
            <div className="animate-pulse h-3 w-24 inline-block rounded-lg bg-gray-200 dark:bg-gray-700"></div>
          </div>
        </div>
        <div className="animate-pulse h-3 w-48 inline-block rounded-lg bg-gray-200 dark:bg-gray-700"></div>
      </div>
    )
  }

  return (
    <div className="bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400 flex items-center gap-4 sm:gap-8 sm:justify-between p-4 flex-col sm:flex-row border-t dark:border-gray-700 group-[.pdf-report]:hidden">
      <div className="flex items-center gap-4 sm:gap-8 flex-col sm:flex-row text-gray-500 dark:text-gray-400">
        <div className="flex items-center gap-2">
          <label className="text-sm font-normal ">{t('pagination.rows_per_page')}</label>
          <Select
            onChange={(event) => {
              const pageSize = parseInt(event.target.value)
              const maxPages = Math.ceil(pagination.total / pageSize)

              setSearchParams(updateSearchParam(`${pagination.prefix}Size`, pageSize))
              pagination.setPageSize(pageSize, apiFetch)

              if (pagination.page > maxPages) {
                setSearchParams(updateSearchParam(`${pagination.prefix}Page`, maxPages.toString()))
                pagination.goToPage(maxPages, apiFetch)
              }
            }}
            value={pagination.size}
            theme={{
              field: {
                select: {
                  base: 'block w-full min-w-[5rem] border disabled:cursor-not-allowed disabled:opacity-50 text-sm',
                },
              },
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Select>
        </div>
        <div className="text-sm font-normal">
          <Trans
            i18nKey="pagination.showing_items"
            values={{
              from: formatUtils.formatNumber((pagination.page - 1) * pagination.size + 1),
              to: formatUtils.formatNumber(Math.min(pagination.page * pagination.size, pagination.total)),
              total: formatUtils.formatNumber(pagination.total),
            }}
            components={[
              <span className="font-semibold text-gray-900 dark:text-gray-100"></span>,
              <span className="font-semibold text-gray-900 dark:text-gray-100"></span>,
            ]}
          />
        </div>
      </div>
      {pagination.pages > 1 && (
        <CAPagination
          currentPage={pagination.page}
          onPageChange={(page: number) => {
            setSearchParams(updateSearchParam(`${pagination.prefix}Page`, page.toString()))
            pagination.goToPage(page, apiFetch)
          }}
          totalPages={pagination.pages}
          showIcons
          nextLabel=""
          previousLabel=""
          theme={{
            base: 'font-medium text-center inline-block',
            pages: {
              base: 'mt-0 inline-flex items-center -space-x-px',
              selector: {
                base: 'w-9 md:w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white',
                active: '!text-blue-600 !bg-blue-100 dark:!bg-blue-300',
              },
            },
          }}
        />
      )}
    </div>
  )
}
