import { useCallback } from 'react'
import { Button } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LightBulbIcon } from '@assets/light-bulb.svg'
import { ClearAllButton } from '@components/ClearAllButton'
import { Filter, SingleEventFilterFields } from '@components/EventContent/SingleEventFilterFields'
import { EventPageToggle } from '@components/EventsPages/EventPageToggle'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import {
  FilterField,
  Filters as PageFilters,
  filterFieldsToPageFilters,
  useTourMarketingControlPanelStore,
  useTourMarketingStore,
} from '@stores/tourMarketingStore'
import { clearLocalFilters } from '@stores/tourMarketingStore/localFilters'
import { cn } from '@utils/className'

const isValidFilters = (filters: FilterField[]) => {
  const countByType = filters.reduce((acc: Record<string, number>, filter: FilterField) => {
    acc[filter.name] = (acc?.[filter?.name] || 0) + 1
    return acc
  }, {})

  return (
    countByType?.artist === 1 && countByType?.showType === 1 && countByType?.venue === 1 && countByType?.perfDate === 1
  )
}

interface ControlPanelProps {
  handleSelected: (filters: PageFilters) => void
}

export const ControlPanel = ({ handleSelected }: ControlPanelProps) => {
  const searchParamsHandler = useSearchParamsObject()
  const { isLoading, filterContext, clearState } = useTourMarketingStore((state) => ({
    isLoading: state.isLoading,
    filterContext: state.filterContext,
    clearState: state.clearData,
  }))

  const { applied, setApplied, lastTouchedFilterKey, setLastTouchedFilterKey, filters, setFilters } =
    useTourMarketingControlPanelStore((state) => ({
      applied: state.applied,
      setApplied: state.setApplied,
      lastTouchedFilterKey: state.lastTouchedFilterKey,
      setLastTouchedFilterKey: state.setLastTouchedFilterKey,
      filters: state.filters,
      setFilters: state.setFilters,
    }))

  const { t } = useTranslation('tour_marketing')

  const apply = useCallback(() => {
    if (!isValidFilters(filters)) return

    handleSelected(filterFieldsToPageFilters(filters))
    setApplied(true)
  }, [filters, handleSelected, setApplied])

  const canApply = isValidFilters(filters) && !applied

  return (
    <section
      className={cn(
        'bg-white border-gray-200 dark:border-gray-700 dark:bg-gray-800 border-r',
        'subnav-control-panel',
        'w-full lg:w-[250px] lg:shrink-0 lg:!overflow-y-auto group-[.pdf-report]:hidden',
      )}
      id="control_panel"
    >
      <div
        className={cn(
          'w-full text-center font-bold flex flex-col md:flex-row lg:flex-col divide-y divide-gray-200 dark:divide-gray-700 md:justify-between',
        )}
      >
        <Filter showLabel label={t('control_panel.search_type')} labelIcon={LightBulbIcon}>
          <EventPageToggle />
        </Filter>
        <SingleEventFilterFields
          isLoading={isLoading}
          filterContext={filterContext}
          filters={filters}
          setFilters={setFilters}
          lastTouchedFilterKey={lastTouchedFilterKey}
          setLastTouchedFilterKey={setLastTouchedFilterKey}
          applied={applied}
          setApplied={setApplied}
          onEmptyFilters={clearLocalFilters}
        />
      </div>

      <div className="px-3 py-4 lg:pb-[80px] w-full border-t border-gray-200 dark:border-gray-700 print:hidden grid grid-rows-2 sm:grid-rows-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-2">
        <Button
          variant="default"
          onClick={() => apply()}
          disabled={!canApply || isLoading}
          className="w-full sm:max-w-[250px] disabled:opacity-25"
          tabIndex={5}
          isLoading={isLoading}
        >
          {t('common:apply')}
        </Button>
        <ClearAllButton
          disabled={filters.length === 0 || isLoading}
          onClick={() => {
            clearLocalFilters()
            setFilters([])
            clearState(searchParamsHandler)
          }}
          tabIndex={6}
        />
      </div>
    </section>
  )
}
