import { useContext } from 'react'
import { AppConfigContext } from '@config/site/app/context'
import { Flags } from '@config/site/app/flags'

export const useFlag = (flag: Flags) => {
  const ctx = useContext(AppConfigContext)

  return ctx.flags[flag]?.value
}

export const useFlagComplete = (flag: Flags) => {
  const ctx = useContext(AppConfigContext)

  return ctx.flags[flag]
}
