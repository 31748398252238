import { CSSProperties, ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'
import { EChartsOption, init } from 'echarts'
import { darkThemeConfig } from '@config/echarts'
import { useTheme } from '@contexts/Theme'
import { cn } from '@utils/className'

interface EChartsComponentProps {
  option: EChartsOption
  style?: CSSProperties
}

export const getDefaultChartColors = (theme = 'light') => {
  return theme === 'dark'
    ? darkThemeConfig.color
    : [
        '#37A2DA',
        '#32C5E9',
        '#67E0E3',
        '#9FE6B8',
        '#FFDB5C',
        '#ff9f7f',
        '#fb7293',
        '#E062AE',
        '#E690D1',
        '#e7bcf3',
        '#9d96f5',
        '#8378EA',
        '#96BFFF',
      ]
}

const useCombinedRefs = <T,>(...refs: ForwardedRef<T>[]) => {
  const targetRef = useRef<T | null>(null)
  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return
      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export const EChartsComponent = forwardRef<HTMLDivElement, EChartsComponentProps>(({ option, style }, ref) => {
  const innerRef = useRef<HTMLDivElement | null>(null)
  const chartRef = useCombinedRefs<HTMLDivElement>(ref, innerRef)
  const { theme } = useTheme()
  const [printMode, setPrintMode] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = init(chartRef.current, theme)
      chartInstance.setOption(option)

      const observer = new ResizeObserver(() => chartInstance.resize())
      observer.observe(chartRef.current.parentElement as HTMLElement)
      observer.observe(window.document.body)

      const printOptions = {
        ...option,
      }
      delete printOptions['dataZoom']

      const beforePrint = () => {
        chartInstance.setOption(printOptions)
        setImageUrl(chartInstance.getDataURL())
        setPrintMode(true)
      }

      const afterPrint = () => {
        chartInstance.setOption(option)
        setImageUrl('')
        setPrintMode(false)
      }

      window.addEventListener('beforeprint', beforePrint)
      window.addEventListener('afterprint', afterPrint)

      return () => {
        observer.disconnect()
        chartInstance.dispose()
        window.removeEventListener('beforeprint', beforePrint)
        window.removeEventListener('afterprint', afterPrint)
      }
    }
  }, [chartRef, option, theme])

  return (
    <>
      <div
        ref={chartRef}
        style={style}
        className={cn({
          hidden: printMode,
        })}
      ></div>
      {printMode && <img src={imageUrl} />}
    </>
  )
})
