import { useCallback, useEffect, useState } from 'react'
import { EChartsOption, SeriesOption } from 'echarts'
import { useTranslation } from 'react-i18next'
import { useChartCardData } from '@components/ChartCard'
import { EChartsComponent } from '@components/EChartsComponent'
import { AxisType, axisConfig } from '@components/EventContent/InsightsAndTrends/TimeSeriesChartCard/SelectXAxis'
import { BenchmarkOption, BenchmarkOptionId } from '@components/EventContent/types'
import { TimeSeriesEntity } from '@types'
import { formatUtils } from '@utils'
import { getChartStyle, getXAxisInterval, showAnimation, showSlider } from '@utils/charts'
import { pivotMatrixFromDays } from '@utils/number'

interface TimeSeriesChartProps {
  timeSeriesEntity?: TimeSeriesEntity[]
  multipleCustomTimeSeriesEntity?: TimeSeriesEntity[][]
  selectedBenchmark?: BenchmarkOption | null
  xAxis?: AxisType
}

const getYAxisData = (data: TimeSeriesEntity[], xAxis = AxisType.DaysUntilShow) => {
  const getItemValue = axisConfig[xAxis].getItemValue
  return data
    .map((item: TimeSeriesEntity) => [getItemValue(item), item.cumulative_percent_sold, item.cumulative_quantity_sold])
    .sort((a, b) => b[0] - a[0])
}

const getEventName = (timeSeriesEntity: TimeSeriesEntity) =>
  `${timeSeriesEntity?.event?.brand?.name} - ${timeSeriesEntity?.event?.venue?.name}`

export const TimeSeriesChart = ({
  timeSeriesEntity = [],
  multipleCustomTimeSeriesEntity = [],
  selectedBenchmark,
  xAxis = AxisType.DaysUntilShow,
}: TimeSeriesChartProps) => {
  const { setData: setDownloadableData } = useChartCardData()
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const { t } = useTranslation('tour_marketing')

  const getChartOption = useCallback(async () => {
    const xAxisConfig = axisConfig[xAxis]

    const xAxisDataSets = [timeSeriesEntity]
    if (!xAxisConfig?.unsupportedBenchmarks?.includes(BenchmarkOptionId.CustomEvents)) {
      xAxisDataSets.push(...multipleCustomTimeSeriesEntity)
    }

    const xAxisData = xAxisConfig.dataParser(xAxisDataSets)
    const xAxisInterval = getXAxisInterval(xAxisData)
    const currentEventData = getYAxisData(timeSeriesEntity, xAxis)

    const currentEventTitle = t('cumulative_tickets_sold_chart.current_event', {
      eventName: getEventName(timeSeriesEntity[0]),
    })

    const seriesEvents = [timeSeriesEntity]
    const legendData = [currentEventTitle]
    const series: SeriesOption[] = [
      {
        name: currentEventTitle,
        type: 'line',
        stack: '',
        data: currentEventData,
      },
    ]
    const xAxisLabel = t(xAxisConfig.labelKey)
    const csvHeaders = [
      xAxisLabel,
      `${currentEventTitle} - ${t('cumulative_tickets_sold_chart.percent_sold')}`,
      `${currentEventTitle} - ${t('cumulative_tickets_sold_chart.quantity_sold')}`,
    ]
    const csvData = [currentEventData]

    if (selectedBenchmark?.id === BenchmarkOptionId.CustomEvents && multipleCustomTimeSeriesEntity.length > 0) {
      multipleCustomTimeSeriesEntity.forEach((customTimeSeriesEntity, index) => {
        if (customTimeSeriesEntity.length === 0) return

        const customEventNumber = index + 1
        const customEventData = getYAxisData(customTimeSeriesEntity, xAxis)
        const customEventName = getEventName(customTimeSeriesEntity[0])

        seriesEvents.push(customTimeSeriesEntity)
        legendData.push(customEventName)
        series.push({
          name: customEventName,
          type: 'line',
          stack: '',
          data: customEventData,
        })

        csvHeaders.push(
          ...[
            `${t('cumulative_tickets_sold_chart.custom_event_n', { number: customEventNumber })} - ${t(
              'cumulative_tickets_sold_chart.percent_sold',
            )}`,
            `${t('cumulative_tickets_sold_chart.custom_event_n', { number: customEventNumber })} - ${t(
              'cumulative_tickets_sold_chart.quantity_sold',
            )}`,
          ],
        )
        csvData.push(customEventData)
      })
    }

    setChartOption({
      tooltip: {
        trigger: 'axis',
        extraCssText: 'padding: 0px;border-width: 0px;',
        formatter: (params: any) => {
          const timeSeriesEntity = seriesEvents[params?.[0]?.seriesIndex][params?.[0]?.dataIndex]

          return `
            <div class='cumulative-tooltip-header'>
              <div class='font-bold'>${xAxisLabel}: ${params?.[0]?.data[0]}</div>
            </div>
            ${params
              .map((param: any) => {
                const isCustomEvent = param.seriesName.includes('-') && !param.seriesName.includes('Current')

                const artist = isCustomEvent
                  ? multipleCustomTimeSeriesEntity[param.componentIndex - 1][param.dataIndex].event.brand?.name
                  : timeSeriesEntity.event.brand?.name

                const venue = isCustomEvent
                  ? multipleCustomTimeSeriesEntity[param.componentIndex - 1][param.dataIndex].event.venue?.name
                  : timeSeriesEntity.event.venue?.name

                const perfDate = isCustomEvent
                  ? multipleCustomTimeSeriesEntity[param.componentIndex - 1][param.dataIndex].event.performance_date
                  : timeSeriesEntity.event.performance_date

                const eventHtml = `
                      <div>${t('cumulative_tickets_sold_chart.artist')}: ${artist}</div>
                      <div>${t('cumulative_tickets_sold_chart.venue')}: ${venue}</div>
                      <div>${t('cumulative_tickets_sold_chart.performance_date')}: ${formatUtils.formatDateNumbersOnly(
                  perfDate,
                )}</div>
                    `

                return `
                  <div class='cumulative-tooltip-item'>
                    <div class='font-semibold'>${param.marker}${param.seriesName}</div>
                    ${eventHtml}
                    <div>${t('cumulative_tickets_sold_chart.total')}: ${
                  param.data[2] == null ? 'N/A' : formatUtils.formatNumber(param.data[2])
                } (${formatUtils.formatPercentage(param.data[1] / 100)})</div>
                 </div>
                `
              })
              .join('')}`
        },
      },
      legend: {
        data: legendData,
        top: '0',
        type: 'scroll',
      },
      grid: {
        left: '1%',
        right: '2%',
        containLabel: true,
      },
      xAxis: {
        interval: xAxisInterval,
        name: xAxisLabel,
        nameGap: 24,
        nameLocation: 'middle',
        inverse: xAxisConfig.showReverse,
        min: 'dataMin',
        max: 'dataMax',
      },
      yAxis: {
        type: 'value',
        min: 0,
        axisLabel: { formatter: '{value} %' },
        name: t('cumulative_tickets_sold_chart.tickets_sold'),
        nameLocation: 'end',
        nameTextStyle: {
          align: 'left',
        },
        axisLine: {
          onZero: false,
        },
      },
      series,
      dataZoom: [
        {
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
        },
      ],
      animation: showAnimation(),
    })
    setDownloadableData([csvHeaders, ...pivotMatrixFromDays(xAxisData, csvData, 3)])
  }, [timeSeriesEntity, multipleCustomTimeSeriesEntity, selectedBenchmark, setDownloadableData, t, xAxis])

  useEffect(() => {
    getChartOption()
  }, [getChartOption])

  if (!chartOption) return null

  return <EChartsComponent option={chartOption} style={getChartStyle()} />
}
