import { Route } from 'react-router-dom'
import { AuthRoute } from '@components/Okta'
import { SentryRoutes } from '@config/sentry'
import { LoginCallback } from '@okta/okta-react'
import { ProtectedRouter } from '@routes/protected'

export const Router = () => {
  return (
    <SentryRoutes>
      <Route element={<AuthRoute />}>
        <Route path={`/login/callback`} element={<LoginCallback />} />
        <Route path="/*" element={<ProtectedRouter />} />
      </Route>
    </SentryRoutes>
  )
}
