import { queryClient } from '@config/react-query'
import { ApiFetch } from '@services/api'
import { EventEntityWithSeries } from '@types'

export const fetchEventsWithSeries = async (
  apiFetch: ApiFetch,
  currentEvents: EventEntityWithSeries[],
  newSelectedEventIds: number[],
) => {
  const currentEventsIds = currentEvents.map((event) => event.id)
  const keepEvents = currentEvents.filter((event) => newSelectedEventIds.includes(event.id))
  const newIds = newSelectedEventIds.filter((id) => !currentEventsIds.includes(id))

  const newEvents = await Promise.all(
    newIds.map(async (eventId) =>
      queryClient.fetchQuery({
        queryKey: ['event', eventId, 'series'],
        queryFn: () => apiFetch(`/data/event/${eventId}/series`) as Promise<EventEntityWithSeries>,
      }),
    ),
  )

  return [...keepEvents, ...newEvents]
}
