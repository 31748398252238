import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import EventSearchPage from '@components/EventsPages/EventSearch'
import TourMarketingPage from '@components/TourMarketing/Page'
import { usePrevious } from '@hooks'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { useEventSearchStore } from '@stores/eventSearchStore'

export default function EventsInsightDesambiguator() {
  const { extended, setExtended } = useEventSearchStore((state) => ({
    extended: state.showExtendedVersion,
    setExtended: state.setShowExtendedVersion,
  }))
  const searchParamsHandler = useSearchParamsObject()
  const location = useLocation()

  const { searchParams, replaceSearchParams } = searchParamsHandler

  const previousSearchParams = usePrevious(searchParams)
  const [initiated, setInitiated] = useState(false)

  const onEventPage = location.pathname === '/event-insights'

  useEffectOnce(() => {
    if (!onEventPage) return
    if (searchParams.size > 0) {
      const searchExtended = searchParams?.get('extended')
      setExtended(searchExtended === 'true', searchParamsHandler, true)
    } else if (extended === null || extended) {
      replaceSearchParams(new URLSearchParams({ extended: 'true' }))
    }
    setInitiated(true)
  })

  useEffect(() => {
    if (!initiated || !onEventPage) return
    const searchExtended = searchParams?.get('extended')
    const searchPreviousExtended = previousSearchParams?.get('extended')
    if (searchParams.size === 0 && !extended) {
      setExtended(true, searchParamsHandler)
    } else if (searchExtended !== searchPreviousExtended || extended === null)
      setExtended(searchExtended === 'true', searchParamsHandler, true)
  }, [initiated, previousSearchParams, searchParams, setExtended, extended, onEventPage, searchParamsHandler])

  if (!initiated || extended === null || !onEventPage) {
    return null
  }

  return extended || searchParams.size === 0 ? <EventSearchPage /> : <TourMarketingPage />
}
