import { PageSliceProps, PageSliceStateCreator } from './pageSliceTypes'

export function createPageSlice<Filters>(
  { stringifyFilters, getPdfHeader }: PageSliceProps<Filters>,
  ...[set]: Parameters<PageSliceStateCreator<Filters>>
): ReturnType<PageSliceStateCreator<Filters>> {
  return {
    isLoading: false,
    setLoading: (isLoading) => set({ isLoading }),

    firstLoad: true,
    setFirstLoad: (firstLoad) => set({ firstLoad }),

    filterContext: undefined,
    setFilterContext: (filters) =>
      set({
        filterContext: filters,
        stringfiedFilterContext: stringifyFilters(filters),
        pdfHeader: getPdfHeader(filters),
      }),
    stringfiedFilterContext: '',
    pdfHeader: null,
  }
}
