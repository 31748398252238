import { useCallback, useEffect, useState } from 'react'
import { LoadingIcon } from '@components/LoadingIcon'
import { Listbox } from '@headlessui/react'
import { useApiDownload } from '@services/api'
import { cn } from '@utils/className'
import { ExportOption } from './ExportDropdown'

type QuoteChar = '"' | "'"

type CsvConfig = {
  quoteChar: QuoteChar
  separator: string
  lineFeedChar: string
}

type CsvOptions = Partial<CsvConfig>

const defaultCsvOptions: CsvConfig = {
  quoteChar: '"',
  separator: ',',
  lineFeedChar: '\n',
}

const escapeCsvChar = (str: string, quoteChar: QuoteChar) => {
  const quoteCharRegex = new RegExp(quoteChar, 'g')
  const quoteCharEscape = quoteChar + quoteChar

  return (str?.toString() || '').replace(quoteCharRegex, quoteCharEscape)
}

const toCsvRow = (row: string[], config: CsvConfig) => {
  const escapedValues = row.map((value) => escapeCsvChar(value, config.quoteChar))
  const joiner = `${config.quoteChar}${config.separator}${config.quoteChar}`
  const joinedValuesWitInnerQuotes = escapedValues.join(joiner)

  return `${config.quoteChar}${joinedValuesWitInnerQuotes}${config.quoteChar}`
}

const generateCsvFile = (rows: string[][], options: CsvOptions = {}) => {
  const _opt = { ...defaultCsvOptions, ...options }
  const content = rows.map((row) => toCsvRow(row, _opt)).join(_opt.lineFeedChar)
  const file = new Blob([content], { type: 'data:text/csv;charset=utf-8' })

  return file
}

// If there's an apiUrl prop, it will download from the API
// Else, it will generate a csv on the frontend using the rows prop
type GeneratorProps =
  | {
      apiUrl: string
      rows?: never
    }
  | {
      apiUrl?: never
      rows: string[][]
    }

export type ExportCsvParams = {
  filename: string
} & GeneratorProps

export type ExportCsvProps = {
  disabled?: boolean
} & ExportCsvParams

type ExportCsvOptionProps = ExportCsvProps & {
  option: ExportOption
  setOpen(open: boolean): void
}

export const ExportCsvOption = ({
  option,
  setOpen,
  rows,
  disabled = false,
  filename = 'data',
  apiUrl,
}: ExportCsvOptionProps) => {
  const [url, setUrl] = useState<string | null>(null)
  const now = new Date()
  const timestamp = `${now.toISOString().split('T')[0].replaceAll('-', '')}_${Math.round(now.getTime() / 1000)}`
  const apiDownload = useApiDownload()
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    if (!rows) return

    const file = generateCsvFile(rows)
    const fileUrl = URL.createObjectURL(file)
    setUrl(fileUrl)

    return () => {
      URL.revokeObjectURL(fileUrl)
    }
  }, [rows, setUrl])

  const download = useCallback(() => {
    if (!apiUrl) return

    setDownloading(true)
    apiDownload(apiUrl, `${filename}-${timestamp}.csv`).finally(() => {
      setDownloading(false)
      setOpen(false)
    })
  }, [apiDownload, apiUrl, filename, timestamp, setOpen])

  return (
    <Listbox.Option
      as={apiUrl ? 'button' : 'a'}
      key={option.id}
      value={option}
      disabled={disabled || downloading}
      onClick={download}
      className={({ active, selected }) =>
        cn(
          { 'opacity-50': disabled || downloading },
          'relative cursor-pointer w-full select-none py-2 px-3 rounded text-gray-700 dark:text-gray-300 text-xs flex items-center justify-start',
          selected
            ? 'font-medium bg-gray-100 dark:bg-gray-800'
            : active
            ? 'bg-gray-50 dark:bg-gray-800/50'
            : 'bg-white dark:bg-gray-700',
        )
      }
      {...(!apiUrl && {
        href: disabled ? '' : url || '',
        target: '_blank',
        download: `${filename}-${timestamp}.csv`,
        onClick: () => setOpen(false),
      })}
    >
      {<option.icon className="w-5 h-5 mr-2" />}
      {option.label}
      {downloading && <LoadingIcon className="inline-block w-4 h-4 ml-2" />}
    </Listbox.Option>
  )
}
