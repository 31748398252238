import { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { EventContentRef } from '@components/EventContent'
import { Footer } from '@components/Footer'
import { SubNav } from '@components/SubNav'
import { ControlPanel } from '@components/TourMarketing/ControlPanel'
import { TourMarketingEvent } from '@components/TourMarketing/Event'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { usePrevious } from '@hooks/usePrevious'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { Filters, useTourMarketingStore } from '@stores/tourMarketingStore'
import { objectUtils, screenUtils } from '@utils'
import { exportComponentToPDF, hasDownloadPDFUrlParam } from '@utils/pdf'

export default function TourMarketingPage() {
  const contentRef = useRef<HTMLElement>(null)
  const mainRef = useRef<HTMLDivElement>(null)
  const locationState = useLocation().state as { showBackButton?: boolean }
  const [showBackButton] = useState(locationState?.showBackButton || false)
  const { t } = useTranslation('tour_marketing')
  const searchParamsHandler = useSearchParamsObject()
  const { searchParams } = searchParamsHandler
  const previousSearchParams = usePrevious(searchParams)
  const { pdfHeader, stringfiedFilterContext, isLoading, firstLoad, loadData, applyFilters } = useTourMarketingStore(
    (state) => ({
      pdfHeader: state.pdfHeader,
      stringfiedFilterContext: state.stringfiedFilterContext,
      isLoading: state.isLoading,
      loadData: state.loadData,
      firstLoad: state.firstLoad,
      applyFilters: state.applyFilters,
    }),
  )

  const [sectionNavRef, setSectionNavRef] = useState<EventContentRef | null>(null)

  const scrollIntoView = useCallback(() => {
    if (screenUtils.isMobile(window)) {
      contentRef.current?.scrollIntoView({ behavior: 'smooth' })
    } else {
      mainRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [contentRef, mainRef])

  const _applyFilters = useCallback(
    async (newFilters: Filters) => {
      try {
        await applyFilters(newFilters, searchParamsHandler)
        scrollIntoView()
      } catch (error) {
        objectUtils.logErrorMessage(error)
      }
    },
    [applyFilters, scrollIntoView, searchParamsHandler],
  )

  useEffect(() => {
    if (!isLoading && !!pdfHeader && hasDownloadPDFUrlParam(BookmarkPageEnum.EVENT_INSIGHTS)) {
      exportComponentToPDF({
        component: contentRef.current,
        filename: `${t('title')}${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`,
        header: pdfHeader,
      })
    }
  }, [isLoading, stringfiedFilterContext, pdfHeader, t, contentRef])

  useEffectOnce(() => {
    loadData(searchParamsHandler, { enableReload: showBackButton })
  })

  useEffect(() => {
    const extended = searchParams.get('extended') === 'true'
    if (!firstLoad && previousSearchParams !== searchParams && searchParams.size > 0 && !extended) {
      loadData(searchParamsHandler, { enableReload: true })
    }
  }, [firstLoad, loadData, previousSearchParams, searchParams, searchParamsHandler])

  useEffect(() => {
    if (!firstLoad) scrollIntoView()
  }, [firstLoad, scrollIntoView])

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('title')} />
      </Helmet>

      <div className="" ref={mainRef}>
        <SubNav container={mainRef.current?.parentElement as HTMLElement} items={sectionNavRef?.subnavItems || []} />

        <div className="flex flex-col lg:flex-row">
          <ControlPanel handleSelected={_applyFilters} />
          <section
            className="flex-1 w-full lg:w-[calc(100%-250px)] group-[.pdf-report]:bg-white group-[.pdf-report]:dark:bg-gray-700"
            ref={contentRef}
          >
            <TourMarketingEvent ref={setSectionNavRef} showBackButton={showBackButton} />
            <Footer />
          </section>
        </div>
      </div>
    </>
  )
}
