import { Badge } from '@components/Badge'
import { Item } from '@components/Inputs'
import { cn } from '@utils/className'

interface SelectedProps {
  items: Item[]
  onRemove?: (item: Item) => void
  className?: string
}

export const Selected = (props: SelectedProps) => {
  const { items = [], onRemove } = props

  if (!items || items?.length < 1) {
    return null
  }

  return (
    <div
      className={cn(
        'ml-1 mt-5 text-left relative max-w-[200px] flex flex-wrap gap-2 md:max-h-[63px] md:overflow-y-auto lg:max-h-none print:max-w-none print:gap-0',
        props.className,
      )}
    >
      {items.map((item) => (
        <Badge key={item.id} text={item.name} onRemove={onRemove ? () => onRemove?.(item) : undefined} />
      ))}
    </div>
  )
}
