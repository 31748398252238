import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { EventContentProps, EventContentRef } from '@components/EventContent'
import { CompetingEvents } from '@components/EventContent/CompetingEvents'
import { InsightsAndTrends } from '@components/EventContent/InsightsAndTrends'
import { Predictions } from '@components/EventContent/Predictions'
import { Summary } from '@components/EventContent/Summary'
import { SubNavItem } from '@components/SubNav'

export const EventContent = forwardRef(<T,>(props: EventContentProps<T>, ref: ForwardedRef<EventContentRef>) => {
  const { t } = useTranslation('tour_marketing')
  const showPredictions = false
  const summaryRef = useRef<HTMLDivElement>(null)
  const insightsAndTrendsRef = useRef<HTMLDivElement>(null)
  const predictionsRef = useRef<HTMLDivElement>(null)
  const competitorsRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(
    ref,
    () => ({
      get subnavItems() {
        const predictionSubnavItem = showPredictions
          ? { element: predictionsRef.current as HTMLElement, label: t('predictions.title') }
          : null
        return [
          { element: summaryRef.current as HTMLElement, label: t('summary.title') },
          { element: insightsAndTrendsRef.current as HTMLElement, label: t('insights_and_trends') },
          ...[predictionSubnavItem],
          { element: competitorsRef.current as HTMLElement, label: t('competing_events.title') },
        ].filter((v) => !!v) as SubNavItem[]
      },
    }),
    [showPredictions, t],
  )

  const summaryProps = {
    loading: props.loading,
    summaryData: props.summaryData,
    secondaryMarket: props.secondaryMarket,
    stringfiedFilterContext: props.stringfiedFilterContext,
    exportOptions: props.exportOptions,
    page: props.page,
  }

  const insightsAndTrendsProps = {
    loading: props.loading,
    pdfHeader: props.pdfHeader,
    hasEventFilter: props.hasEventFilter,
    stringfiedFilterContext: props.stringfiedFilterContext,
    primaryMarket: props.primaryMarket,
    secondaryMarket: props.secondaryMarket,
    summaryData: props.summaryData,
    customEvents: props.customEvents,
    benchmark: props.benchmark,
    additionalParamsForInternalChange: props.additionalParamsForInternalChange,
    exportOptions: props.exportOptions,
    page: props.page,
  }

  const predictionsProps = {
    loading: props.loading,
    summaryData: props.summaryData,
  }

  const competitorsProps = {
    loading: props.loading,
    stringfiedFilterContext: props.stringfiedFilterContext,
    eventFilter: props.eventFilter,
    hasEventFilter: props.hasEventFilter,
    competitors: props.competitors,
    exportOptions: props.exportOptions,
    page: props.page,
    additionalParamsForInternalChange: props.additionalParamsForInternalChange,
  }

  return (
    <>
      <div ref={summaryRef}>
        <Summary {...summaryProps} />
      </div>
      <div ref={insightsAndTrendsRef}>
        <InsightsAndTrends {...insightsAndTrendsProps} />
      </div>
      {showPredictions && (
        <div ref={predictionsRef}>
          <Predictions {...predictionsProps} />
        </div>
      )}
      <div ref={competitorsRef}>
        <CompetingEvents {...competitorsProps} />
      </div>
    </>
  )
}) as <T>(props: EventContentProps<T> & { ref: ForwardedRef<EventContentRef> }) => JSX.Element
