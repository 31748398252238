import { useCallback, useEffect, useRef, useState } from 'react'
import { EChartsOption } from 'echarts'
import { useTranslation } from 'react-i18next'
import { useChartCardData } from '@components/ChartCard'
import { EChartsComponent } from '@components/EChartsComponent'
import { SecondaryMarketTimeSeriesData } from '@types'
import { getChartStyle, getXAxisDaysFromEventWithinEventData, showAnimation, showSlider } from '@utils/charts'
import { pivotMatrixFromDays } from '@utils/number'

interface SecondaryMarketTimeSeriesChartProps {
  secondaryMarketTimeSeriesData?: SecondaryMarketTimeSeriesData[]
}

export const SecondaryMarketTimeSeriesChart = ({
  secondaryMarketTimeSeriesData = [],
}: SecondaryMarketTimeSeriesChartProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const { setData: setDownloadableData } = useChartCardData()
  const [chartWidth, setChartWidth] = useState<number | null>(null)
  const observer = useRef<ResizeObserver | null>(null)
  const { t } = useTranslation('tour_marketing')

  const handleRef = useCallback((node: HTMLDivElement) => {
    if (node && !observer.current) {
      observer.current = new ResizeObserver((entries) => {
        setChartWidth(entries[0].contentBoxSize?.[0].inlineSize)
      })
      observer.current.observe(node)
    }
  }, [])

  useEffect(() => {
    return () => {
      observer?.current?.disconnect()
      observer.current = null
    }
  }, [])

  const getChartOption = useCallback(async () => {
    const xAxisData = getXAxisDaysFromEventWithinEventData([secondaryMarketTimeSeriesData])

    const secondaryMarketSalesData = secondaryMarketTimeSeriesData
      .filter((data) => data.event.days_from_event)
      .map((data) => [data.event.days_from_event, data.quantity_sold])
      .sort((a, b) => b[0] - a[0])

    const averageTicketPriceData = secondaryMarketTimeSeriesData
      .filter((data) => data.event.days_from_event)
      .map((data) => [data.event.days_from_event, data.average_price])
      .sort((a, b) => b[0] - a[0])

    const maxWidth = chartWidth ? chartWidth / 2 - 40 : null
    const nameTruncate = maxWidth ? { maxWidth } : {}

    setChartOption({
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          return `${t('secondary_market_chart.days_from_event')}: ${params.data[0]}<br />
            ${params.seriesName}: ${params.data[1]}`
        },
      },
      legend: {
        data: [t('secondary_market_chart.secondary_market_sales'), t('secondary_market_chart.average_ticket_price')],
        top: '0',
        type: 'scroll',
      },
      grid: {
        left: '3%',
        right: '4%',
        containLabel: true,
      },
      xAxis: {
        interval: 5,
        name: t('secondary_market_chart.days_from_event'),
        nameGap: 24,
        nameLocation: 'middle',
        inverse: true,
        min: 'dataMin',
        max: 'dataMax',
      },
      yAxis: [
        {
          name: t('secondary_market_chart.secondary_market_sales'),
          type: 'value',
          nameTextStyle: {
            align: 'left',
          },
          nameLocation: 'end',
          nameTruncate,
        },
        {
          name: t('secondary_market_chart.average_ticket_price'),
          type: 'value',
          nameTextStyle: {
            align: 'right',
          },
          nameLocation: 'end',
          nameTruncate,
        },
      ],
      series: [
        {
          name: t('secondary_market_chart.secondary_market_sales'),
          type: 'bar',
          stack: '',
          data: secondaryMarketSalesData,
        },
        {
          name: t('secondary_market_chart.average_ticket_price'),
          type: 'line',
          stack: '',
          yAxisIndex: 1,
          data: averageTicketPriceData,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
        },
      ],
      animation: showAnimation(),
    })
    setDownloadableData([
      [
        t('secondary_market_chart.days_from_event'),
        `${t('secondary_market_chart.secondary_market_sales')} - ${t('secondary_market_chart.quantity_sold')}`,
        `${t('secondary_market_chart.average_ticket_price')} - ${t('secondary_market_chart.average_price')}`,
      ],
      ...pivotMatrixFromDays(xAxisData, [secondaryMarketSalesData, averageTicketPriceData], 2),
    ])
  }, [secondaryMarketTimeSeriesData, chartWidth, setDownloadableData, t])

  useEffect(() => {
    getChartOption()
  }, [getChartOption])

  if (!chartOption) return null

  return <EChartsComponent option={chartOption} style={getChartStyle()} ref={handleRef} />
}
