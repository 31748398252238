import { Card, Tooltip } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline'
import { formatUtils, numberUtils } from '@utils'
import { cn } from '@utils/className'

export type Stat = {
  name: string
  value?: number
  previousValue?: number
}

interface StatCardProps {
  stat: Stat
  isLoading: boolean
  icon: React.ElementType
  iconColor: string
  iconContainerColor: string
  size?: 'sm' | 'normal'
}

export function StatCard({
  stat,
  isLoading,
  icon: Icon,
  iconColor,
  iconContainerColor,
  size = 'normal',
}: StatCardProps) {
  const { change, changeType } = numberUtils.getChangeBetweenNumbers(stat?.previousValue, stat?.value)
  const { t } = useTranslation('upcoming_events')

  return (
    <div className={cn('flex-1', isLoading && 'animate-pulse')}>
      <Card
        theme={{
          root: {
            children: size === 'sm' ? 'p-4' : 'p-4 md:p-8',
          },
        }}
      >
        <div className="flex items-center flex-1">
          {isLoading ? (
            <div className="rounded-full w-20 h-20 md:w-24 md:h-24 bg-gray-200 dark:bg-gray-700 mr-3 md:mr-6 shrink-0" />
          ) : (
            <div
              className={cn(
                'rounded-full  bg-orange-100 flex items-center justify-center mr-3 md:mr-6 shrink-0',
                iconContainerColor,
                size === 'sm' ? 'w-16 h-16' : 'w-20 h-20 md:w-24 md:h-24',
              )}
            >
              <Icon className={cn(' text-orange-500', iconColor, size === 'sm' ? 'w-10 h-10' : 'w-12 h-12')} />
            </div>
          )}
          <div className="flex flex-col w-full">
            <dt className="text-base font-normal leading-6 text-gray-900 dark:text-white">{stat.name}</dt>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col flex-1">
                {isLoading ? (
                  <>
                    <div className="rounded-full w-4/5 h-3.5 bg-gray-200 dark:bg-gray-700 mb-2" />
                    <div className="rounded-full w-1/2 h-6 bg-gray-200 dark:bg-gray-700 mb-3" />
                    <div className="rounded-full w-1/5 h-3 bg-gray-200 dark:bg-gray-700" />
                  </>
                ) : (
                  <>
                    <dd className="mt-1 flex items-baseline justify-between gap-2">
                      <div className="items-baseline text-3xl font-bold leading-8 relative inline text-gray-900 dark:text-white">
                        {stat.value ? formatUtils.formatNumber(stat.value) : '-'}

                        <span className="block text-sm font-normal text-gray-500 dark:text-gray-200">
                          {t('common:from_value', {
                            value: stat.previousValue ? formatUtils.formatNumber(stat.previousValue) : '-',
                          }).toLowerCase()}
                        </span>
                      </div>
                    </dd>
                  </>
                )}
              </div>
              <div
                className={cn(
                  changeType === 'increase'
                    ? 'text-green-500'
                    : changeType === 'decrease'
                    ? 'text-red-500'
                    : 'text-gray-800 dark:text-gray-200',
                  'inline-flex items-baseline rounded-full py-0.5 text-base font-bold md:mt-2 lg:mt-0',
                )}
              >
                <span className="sr-only">
                  {changeType === 'increase' ? 'Increased' : changeType === 'decrease' ? 'Decreased' : 'Unchanged'} by{' '}
                </span>

                {!isLoading && (
                  <Tooltip
                    content={
                      <div className="leading-tight text-xs font-normal text-white max-w-[170px]">
                        {t('comparison_tooltip')}
                      </div>
                    }
                  >
                    <div
                      className={cn(
                        change !== 'N/A' && 'inline-flex border-b border-dashed',
                        changeType === 'increase' && 'border-b-green-500',
                        changeType === 'decrease' && 'border-b-red-500',
                      )}
                    >
                      {change !== 'N/A' ? change : '-'}
                      {changeType === 'increase' ? (
                        <ArrowUpIcon
                          className="h-4 w-4 flex-shrink-0 self-center text-green-500 ml-2 stroke-2"
                          aria-hidden="true"
                        />
                      ) : changeType === 'decrease' ? (
                        <ArrowDownIcon
                          className="h-4 w-4 flex-shrink-0 self-center text-red-500 ml-2 stroke-2"
                          aria-hidden="true"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}
