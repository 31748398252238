import { useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { Item, RemoteSelect } from '@components/RemoteSelect'
import { CalendarIcon, MapPinIcon, TicketIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { usePrevious } from '@hooks'
import { FilterDate, FilterField, FilterItem, FilterKey, filterFieldsToPageFilters } from '@stores/tourMarketingStore'
import { formatUtils } from '@utils'
import { cn } from '@utils/className'

interface FilterProps {
  showLabel?: boolean
  label: string
  labelIcon: React.ElementType
  containerClassName?: string
  children: React.ReactNode
}

export const Filter = (props: FilterProps) => {
  const { showLabel = true, label, labelIcon: LabelIcon, containerClassName, children } = props

  return (
    <div className={cn('px-3 py-4', containerClassName)}>
      <label
        className={cn(
          'block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1',
          !showLabel && 'hidden',
        )}
        htmlFor="artists"
      >
        <LabelIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
        {label}
      </label>
      <div className="mt-2">{children}</div>
    </div>
  )
}

interface SingleEventFiltersFieldsProps {
  isLoading: boolean
  showLabel?: boolean
  labelContainerClassName?: string
  filterContext?: any
  filters: FilterField[]
  setFilters: (filters: FilterField[]) => void
  lastTouchedFilterKey?: FilterKey | null
  setLastTouchedFilterKey?: (filterKey: FilterKey) => void
  applied?: boolean
  setApplied?: (applied: boolean) => void
  defaultParams?: Record<string, string | string[] | undefined>
  onEmptyFilters?: () => void
}

export function SingleEventFilterFields(props: SingleEventFiltersFieldsProps) {
  const {
    isLoading,
    showLabel = true,
    labelContainerClassName = '',
    filterContext,
    filters,
    setFilters,
    lastTouchedFilterKey,
    setLastTouchedFilterKey,
    applied,
    setApplied,
    defaultParams = {},
    onEmptyFilters,
  } = props
  const { t } = useTranslation('tour_marketing')
  const previousFilters = usePrevious(filters)

  const performanceDateFormatter = useCallback(
    (apiItem: any) => formatUtils.formatDateNumbersOnly(apiItem.performance_date),
    [],
  )

  const getFiltersFor = useCallback(
    (key: FilterKey) => {
      const preFilters: FilterField[] = []
      for (const filter of filters) {
        if (filter.name === key) break
        preFilters.push(filter)
      }
      const params: Record<string, string | string[] | undefined> = defaultParams
      for (const filter of preFilters) {
        Object.assign(params, filter.toParams())
      }
      return params
    },
    [filters, defaultParams],
  )

  const getValueFor = useCallback(
    (key: FilterKey) => {
      const idx = filters.findIndex((filter) => filter.name === key)
      return idx > -1 ? filters[idx].data : null
    },
    [filters],
  )

  const isFirstFilter = useCallback(
    (key: FilterKey) => {
      return filters.length === 0 || filters.findIndex((filter) => filter.name === key) === 0
    },
    [filters],
  )

  const isLastAvailableFilter = useCallback(
    (key: FilterKey) => {
      const isFilterSelected = filters.findIndex((filter) => filter.name === key) >= 0
      const isFilterLastTouched = key === lastTouchedFilterKey

      return filters.length === 3 && !isFilterSelected && !isFilterLastTouched
    },
    [filters, lastTouchedFilterKey],
  )

  const handleSelectItem = (key: FilterKey, value?: Item | null) => {
    if (!value) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters)
    } else {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters([...newFilters, new FilterItem(key, { ...value, id: String(value.id) })])
    }

    setLastTouchedFilterKey?.(key)
    setApplied?.(false)
  }

  const handleSelectDate = (key: FilterKey, value?: Item | null) => {
    if (!value) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters)
    } else {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters([...newFilters, new FilterDate(key, value)])
    }

    setLastTouchedFilterKey?.(key)
    setApplied?.(false)
  }

  useEffect(() => {
    const filterApplied = isEqual(filterFieldsToPageFilters(filters), filterContext)
    if (filterApplied && !applied) {
      setApplied?.(true)
    } else if (!filterApplied && applied) {
      setApplied?.(false)
    }
  }, [filters, setApplied, filterContext, applied])

  useEffect(() => {
    if (previousFilters && previousFilters.length > 0 && filters.length === 0) onEmptyFilters?.()
    else if (filters.length === 1 && isFirstFilter('perfDate')) setFilters([])
  }, [filters, previousFilters, isFirstFilter, setFilters, onEmptyFilters])

  return (
    <>
      <Filter
        showLabel={showLabel}
        label={t('control_panel.artists')}
        labelIcon={UserGroupIcon}
        containerClassName={labelContainerClassName}
      >
        <RemoteSelect
          id="artists"
          onChange={(item) => handleSelectItem('artist', item)}
          extraParams={getFiltersFor('artist')}
          value={getValueFor('artist')}
          dataUrl="/data/artist"
          placeholder={t('common:search_type', { type: t('control_panel.artists') })}
          nameField="artist_name"
          idField="artistid"
          openOnClick={!isFirstFilter('artist') && !isFirstFilter('showType')}
          disableRemoteSearch={!isFirstFilter('artist') && !isFirstFilter('showType')}
          searchMinimumLength={isFirstFilter('artist') ? 2 : 0}
          tabIndex={1}
          disabled={isLoading}
          isLastAvailableFilter={isLastAvailableFilter('artist')}
          {...(!showLabel && { icon: UserGroupIcon })}
        />
      </Filter>

      <Filter
        showLabel={showLabel}
        label={t('control_panel.sub_categories')}
        labelIcon={TicketIcon}
        containerClassName={labelContainerClassName}
      >
        <RemoteSelect
          id="eventSubCategories"
          onChange={(item) => handleSelectItem('showType', item)}
          value={getValueFor('showType')}
          dataUrl="/data/events/sub_categories"
          extraParams={getFiltersFor('showType')}
          placeholder={t('common:search_type', { type: t('control_panel.sub_categories') })}
          openOnClick
          nameField="name"
          idField="name"
          disableRemoteSearch
          tabIndex={2}
          disabled={isLoading}
          isLastAvailableFilter={isLastAvailableFilter('showType')}
          {...(!showLabel && { icon: TicketIcon })}
        />
      </Filter>

      <Filter
        showLabel={showLabel}
        label={t('control_panel.venues')}
        labelIcon={MapPinIcon}
        containerClassName={labelContainerClassName}
      >
        <RemoteSelect
          id="venues"
          onChange={(item) => handleSelectItem('venue', item)}
          extraParams={getFiltersFor('venue')}
          value={getValueFor('venue')}
          dataUrl="/data/venue"
          placeholder={t('common:search_type', { type: t('control_panel.venues') })}
          nameField="venue_name"
          idField="venueid"
          openOnClick={!isFirstFilter('venue') && !isFirstFilter('showType')}
          disableRemoteSearch={!isFirstFilter('venue') && !isFirstFilter('showType')}
          searchMinimumLength={isFirstFilter('venue') ? 2 : 0}
          tabIndex={3}
          disabled={isLoading}
          isLastAvailableFilter={isLastAvailableFilter('venue')}
          {...(!showLabel && { icon: MapPinIcon })}
        />
      </Filter>

      <Filter
        showLabel={showLabel}
        label={t('control_panel.dates')}
        labelIcon={CalendarIcon}
        containerClassName={labelContainerClassName}
      >
        <RemoteSelect
          id="dates"
          onChange={(item) => handleSelectDate('perfDate', item)}
          value={getValueFor('perfDate')}
          dataUrl="/data/perfdate"
          extraParams={getFiltersFor('perfDate')}
          placeholder={t('common:search_type', { type: t('control_panel.dates') })}
          nameField={performanceDateFormatter}
          idField="performance_date"
          openOnClick
          disableRemoteSearch
          disabled={filters.length === 0 || isLoading}
          tabIndex={4}
          isLastAvailableFilter={isLastAvailableFilter('perfDate')}
          {...(!showLabel && { icon: CalendarIcon })}
        />
      </Filter>
    </>
  )
}
