import { useCallback, useEffect, useMemo } from 'react'
import type { DateRangeInputOption, DateRangeInputValue } from 'carbonarc-ui'
import { Button, DateRangeInput } from 'carbonarc-ui'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { Badge } from '@components/Badge'
import { ClearAllButton } from '@components/ClearAllButton'
import { LoadingIcon } from '@components/LoadingIcon'
import { Item, RemoteSelect } from '@components/RemoteSelect'
import { CalendarIcon, GlobeAltIcon, MapPinIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import {
  Filters,
  getDefaultFilters,
  useUpcomingEventsControlPanelStore,
  useUpcomingEventsStore,
} from '@stores/upcomingEventsStore'
import { clearLocalFilters } from '@stores/upcomingEventsStore/localFilters'
import { MY_BRAND_ID, MY_BRAND_QUERY_PARAM } from '@stores/upcomingEventsStore/queryParams'
import { formatUtils } from '@utils'
import { cn } from '@utils/className'

type MultipleFilterKeys = keyof Omit<Filters, 'since' | 'until' | 'dateRangeOption'>
type FiltersValueTypes = Filters['artist'] & Filters['since'] & Filters['dateRangeOption']
interface Props {
  handleSelected: (filters: Filters) => void
}

const emptyFilters: Filters = {
  artist: [],
  venue: [],
  region: [],
  since: null,
  until: null,
  dateRangeOption: null,
}

const isValidFilters = (filters: Filters) => {
  return filters.artist && filters.region.length > 0 && filters.venue.length > 0 && filters.since && filters.until
}

const dateInputLabel = (filter: Filters) => {
  return filter?.dateRangeOption?.value === 'custom'
    ? `${filter.since ? formatUtils.formatDateNumbersOnly(filter.since) : '...'} - ${
        filter.until ? formatUtils.formatDateNumbersOnly(filter.until) : '...'
      }`
    : DateRangeInput.defaultOptions.find((o: DateRangeInputOption) => o.value === filter?.dateRangeOption?.value)
        ?.label || ''
}

export const ControlPanel = (props: Props) => {
  const { handleSelected } = props
  const { t } = useTranslation('upcoming_events')
  const { isLoading, showMyBrands } = useUpcomingEventsStore((state) => ({
    isLoading: state.isLoading,
    showMyBrands: state.hasPrivateArtist,
  }))

  const { applied, setApplied, filtersArray, setFiltersArray } = useUpcomingEventsControlPanelStore((state) => ({
    applied: state.applied,
    setApplied: state.setApplied,
    filtersArray: state.filters,
    setFiltersArray: state.setFilters,
  }))
  const allArtists = { id: '0', name: t('filters.all_brands') }
  const allRegions = { id: '0', name: t('filters.all_regions') }
  const allVenues = { id: '0', name: t('filters.all_venues') }
  const currentDate = new Date()
  const nextYear = new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDate())
  const defaultValues: Filters = {
    artist: [allArtists],
    region: [allRegions],
    venue: [allVenues],
    since: currentDate,
    until: nextYear,
    dateRangeOption: DateRangeInput.defaultOptions.find((o: DateRangeInputOption) => o.value === 365) || null,
  }
  const filters = useMemo(() => {
    return filtersArray?.[0] || getDefaultFilters(showMyBrands)
  }, [filtersArray, showMyBrands])

  const setFilters = useCallback((filters: Filters) => setFiltersArray([filters]), [setFiltersArray])

  const getUniqueItems = (items: Item[], allOption?: boolean) => {
    const allOptionItem = items?.find((item) => item.id === '0')

    if (allOption && allOptionItem) {
      return [allOptionItem]
    }

    const itemsMap = new Map(items.map((pos) => [pos.id, pos]))
    const uniqueItems = [...itemsMap.values()]

    return uniqueItems
  }

  const handleMultiSelect = ({
    key,
    value,
    eraseKeys,
    resetKeys,
    allOption,
  }: {
    key: MultipleFilterKeys
    value: Item[]
    eraseKeys?: (keyof Filters)[]
    resetKeys?: (keyof Filters)[]
    allOption?: boolean
  }) => {
    const newFilters = { ...filters }

    newFilters[key] = getUniqueItems(value, allOption)
    if (isEqual(filters[key].map((i) => i.id).sort(), newFilters[key].map((i) => i.id).sort())) return

    if (value.length === 0)
      eraseKeys?.forEach((k) => {
        newFilters[k] = emptyFilters[k] as FiltersValueTypes
      })
    else
      resetKeys?.forEach((k) => {
        newFilters[k] = defaultValues[k] as FiltersValueTypes
      })
    setFilters(newFilters)
  }

  const apply = useCallback(() => {
    if (isValidFilters(filters) && !applied) {
      handleSelected(filters)
      setApplied(true)
    }
  }, [filters, handleSelected, applied, setApplied])

  useEffect(() => {
    setApplied(false)
  }, [filters, setApplied])

  const handleDateRangeInputSelect = useCallback(
    ({ start, end, option }: DateRangeInputValue) => {
      const newFilters = { ...filters }
      newFilters.since = start
      newFilters.until = end
      newFilters.dateRangeOption = option
      setApplied(true)
      setFilters(newFilters)
    },
    [filters, setFilters, setApplied],
  )

  const canApply = isValidFilters(filters) && !applied

  const includeMyBrands = filters.artist.some((opt) => opt.id === MY_BRAND_ID).toString()

  return (
    <section
      className={cn(
        'bg-white border-gray-200 dark:border-gray-700 dark:bg-gray-800 border-r',
        'control-panel',
        'w-full lg:w-[250px] lg:shrink-0 lg:!overflow-y-auto',
      )}
      id="control_panel"
    >
      <div>
        <div className="w-full text-center font-bold flex flex-col divide-y divide-gray-200 dark:divide-gray-700">
          <div className="px-3 py-4">
            <label
              className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
              htmlFor="artists"
            >
              <UserGroupIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
              {t('control_panel.artists')}
            </label>
            <div className="mt-2">
              <RemoteSelect
                id="artists"
                onChange={(item: Item[]) => {
                  handleMultiSelect({
                    key: 'artist',
                    value: item,
                    eraseKeys: ['region', 'venue', 'since', 'until', 'dateRangeOption'],
                    resetKeys: ['region', 'venue', 'since', 'until', 'dateRangeOption'],
                    allOption: true,
                  })
                  if (filters.artist.length == 0) {
                    clearLocalFilters()
                  }
                }}
                value={filters.artist}
                dataUrl="/data/artist"
                extraParams={{ include_past: 'true' }}
                placeholder={t('common:search_type', { type: t('control_panel.artists') })}
                nameField="artist_name"
                idField="artistid"
                searchMinimumLength={2}
                customOptions={[
                  {
                    ...allArtists,
                    preventOthers: true,
                  },
                  ...(showMyBrands
                    ? [
                        {
                          id: MY_BRAND_ID,
                          name: t('filters.my_brands'),
                        },
                      ]
                    : []),
                ]}
                openOnClick
                multiple
                tabIndex={1}
              />
            </div>
          </div>

          <div className="px-3 py-4">
            <label
              className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
              htmlFor="venues"
            >
              <GlobeAltIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
              {t('control_panel.regions')}
            </label>
            <div className="mt-2 relative">
              <RemoteSelect
                id="regions"
                onChange={(item: Item[]) =>
                  handleMultiSelect({
                    key: 'region',
                    value: item,
                    eraseKeys: ['venue', 'since', 'until', 'dateRangeOption'],
                    resetKeys: ['venue', 'since', 'until', 'dateRangeOption'],
                    allOption: true,
                  })
                }
                value={filters.region}
                disabled={!filters.artist}
                dataUrl="/data/region"
                extraParams={{
                  artist: filters.artist.map((v) => v.id).filter((v) => v !== '0' && v !== MY_BRAND_ID),
                  [MY_BRAND_QUERY_PARAM]: includeMyBrands,
                }}
                placeholder={t('common:search_type', { type: t('control_panel.regions') })}
                searchMinimumLength={filters.artist?.map((v) => v.id).filter((v) => v !== '0').length > 0 ? 0 : 2}
                customOptions={[
                  {
                    ...allRegions,
                    preventOthers: true,
                  },
                ]}
                nameField="region_name"
                idField="regionid"
                openOnClick
                multiple
                disableRemoteSearch={
                  filters.artist?.map((v) => v.id).filter((v) => v !== '0' && v !== MY_BRAND_ID).length > 0
                }
                tabIndex={2}
              />
            </div>
          </div>

          <div className="px-3 py-4">
            <label
              className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
              htmlFor="venues"
            >
              <MapPinIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
              {t('control_panel.venues')}
            </label>
            <div className="mt-2 relative">
              <RemoteSelect
                id="venues"
                onChange={(item: Item[]) =>
                  handleMultiSelect({
                    key: 'venue',
                    value: item,
                    eraseKeys: ['since', 'until', 'dateRangeOption'],
                    resetKeys: ['since', 'until', 'dateRangeOption'],
                    allOption: true,
                  })
                }
                value={filters.venue}
                disabled={!filters.artist}
                dataUrl="/data/venue"
                extraParams={{
                  artist: filters.artist.map((v) => v.id).filter((v) => v !== '0' && v !== MY_BRAND_ID),
                  region: filters.region.map((v) => v.id).filter((v) => v !== '0'),
                  [MY_BRAND_QUERY_PARAM]: includeMyBrands,
                }}
                placeholder={t('common:search_type', { type: t('control_panel.venues') })}
                searchMinimumLength={
                  filters.artist?.map((v) => v.id).filter((v) => v !== '0' && v !== MY_BRAND_ID).length > 0 ||
                  filters.region?.map((v) => v.id).filter((v) => v !== '0').length > 0
                    ? 0
                    : 2
                }
                customOptions={[
                  {
                    ...allVenues,
                    preventOthers: true,
                  },
                ]}
                nameField="venue_name"
                idField="venueid"
                openOnClick
                multiple
                disableRemoteSearch={
                  filters.artist?.map((v) => v.id).filter((v) => v !== '0' && v !== MY_BRAND_ID).length > 0 ||
                  filters.region?.map((v) => v.id).filter((v) => v !== '0').length > 0
                }
                tabIndex={3}
              />
            </div>
          </div>
          <div className="px-3 py-4">
            <label
              className="block text-sm font-medium leading-6 text-gray-500 dark:text-gray-200 text-left ml-1"
              htmlFor="dates"
            >
              <CalendarIcon className="inline-block w-5 h-5 mr-3 text-gray-700 dark:text-gray-100 stroke-2" />
              {t('control_panel.time_period')}
            </label>
            <div className="mt-2">
              <DateRangeInput
                value={{
                  start: filters.since,
                  end: filters.until,
                  option: filters.dateRangeOption,
                }}
                onChange={handleDateRangeInputSelect}
                disabled={!filters.artist || filters.region.length === 0 || filters.venue.length === 0}
                placeholder={t('common:select_type', { type: t('control_panel.time_period') })}
                allowPastDates={false}
                tabIndex={4}
                hideInputValue
              />
              {filters.dateRangeOption && (
                <div className="mt-5 text-left relative max-w-[230px] flex flex-wrap gap-2 md:max-h-[63px] md:overflow-y-auto lg:max-h-none print:max-w-none print:gap-0">
                  <Badge
                    text={dateInputLabel(filters)}
                    onRemove={() =>
                      handleDateRangeInputSelect({
                        start: null,
                        end: null,
                        option: null,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 py-4 w-full bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 print:hidden grid grid-rows-2 sm:grid-rows-1 sm:grid-cols-2 md:flex lg:grid-cols-2 gap-2 shrink-0">
        <Button
          variant="default"
          onClick={() => {
            apply()
          }}
          disabled={!canApply || isLoading}
          className="w-full sm:max-w-[250px] disabled:opacity-25"
          tabIndex={5}
        >
          {isLoading ? <LoadingIcon className="w-6" /> : t('common:apply')}
        </Button>

        <ClearAllButton
          disabled={(!filters.artist && filters.venue.length == 0 && !filters.since && !filters.until) || isLoading}
          onClick={() => {
            clearLocalFilters()
            setFilters(emptyFilters)
          }}
          tabIndex={6}
        />
      </div>
    </section>
  )
}
