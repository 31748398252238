import { ReactComponent as FavoritesIcon } from '@assets/favorites-icon.svg'
import { useFlag } from '@config/site/app'
import { Flags } from '@config/site/app/flags'
import { useBookmarkStore } from '@stores/bookmarkStore'
import { cn } from '@utils/className'

export const BookmarkMenuButton = () => {
  const enabled = useFlag(Flags.ENABLE_FAVORITES)
  const { show, setShow } = useBookmarkStore((state) => ({ show: state.show, setShow: state.setShow }))

  if (!enabled) return null
  return (
    <div
      className={cn(
        'cursor-pointer p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center justify-center',
        show && 'bg-gray-100 dark:bg-gray-700',
      )}
      onClick={() => {
        setShow(!show)
      }}
    >
      <FavoritesIcon className="w-5 h-5 stroke-[1.5px] text-gray-400" />
    </div>
  )
}
