import { FilterField } from '@stores/tourMarketingStore'

const filterFieldsOrder = ['artist', 'showType', 'venue', 'perfDate']

export const sortCustomEventFilterFields = (customEventFilter: FilterField[]) => {
  const newOrdered: FilterField[] = []
  filterFieldsOrder.forEach((name) => {
    const filterField = customEventFilter.find((f) => f.name === name)
    if (filterField) newOrdered.push(filterField)
  })
  return newOrdered
}
