import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import merge from 'lodash/merge'
import { initReactI18next } from 'react-i18next'
import { APP, DEFAULT_APP } from '@config/site/app'

const appsLocales: Record<string, any> = import.meta.glob('/src/config/apps/*/locales/**/*', { eager: true })
const defaultLocales: Record<string, any> = import.meta.glob('/src/config/apps/default/locales/**/*', { eager: true })

const defaultTranslations: Record<string, any> = Object.entries(defaultLocales).reduce((obj, [key, value]) => {
  const locale = key.match(/locales\/(.*)\//)?.[1]
  const namespace = key.match(/([^/]+$)/)?.[0]?.replace('.json', '')

  if (!locale || !namespace) return obj

  const translation = {
    [locale]: {
      [namespace]: value.default,
    },
  }

  return merge(obj, translation)
}, {})

const appsTranslations: Record<string, any> = Object.entries(appsLocales).reduce((obj, [key, value]) => {
  const app = key.replace('/src/config/apps/', '').split('/')[0]
  const locale = key.match(/locales\/(.*)\//)?.[1]
  const namespace = key.match(/([^/]+$)/)?.[0]?.replace('.json', '')

  if (!locale || !namespace) return obj

  const defaultTranslation = defaultTranslations?.[locale]?.[namespace] || {}

  const appTranslation = {
    [app]: {
      [locale]: {
        [namespace]: merge(defaultTranslation, value.default),
      },
    },
  }

  return merge(obj, appTranslation)
}, {})

export const app = APP in appsTranslations ? APP : DEFAULT_APP

const resources = appsTranslations[app]

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  defaultNS: 'common',
  resources,
})

export default i18n
