import { Tooltip } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { Filters } from '@components/PredictionAssesmentPage/Generate/Filters'
import { Predictions } from '@components/PredictionAssesmentPage/Generate/Predictions'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export const Generate = () => {
  const { t } = useTranslation('prediction_assessment')
  return (
    <div className="flex flex-col mt-6 md:mt-8 items-start group-[.pdf-report-hide-generate]:hidden">
      <div className="flex flex-col gap-2 mb-5 md:mb-6">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-xl md:text-3xl font-semibold dark:text-white">{t('generate.title')}</h2>
          <Tooltip content={<div className="max-w-xs md:max-w-sm">{t('generate.info')}</div>}>
            <InformationCircleIcon className="h-5 w-5 text-gray-500 dark:text-gray-400 cursor-pointer" />
          </Tooltip>
        </div>
        <h3 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">{t('generate.subtitle')}</h3>
      </div>
      <Filters />
      <Predictions />
    </div>
  )
}
