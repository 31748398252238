import { cn } from '@utils/className'

type Option = {
  label: string
  value: any
}

type ButtonToggleProps = {
  options: [Option, Option]
  value: any
  onChange: (o: any) => void
}

export const ButtonToggle = ({ options, value, onChange }: ButtonToggleProps) => {
  const selectedIndex = options.findIndex((o) => o.value === value)

  return (
    <div className="relative w-full rounded-md bg-gray-100 dark:bg-gray-700 p-1 leading-tight text-sm font-medium">
      <ul className="grid grid-flow-col auto-cols-fr w-full bg-transparent">
        {options.map((o, index) => (
          <li
            key={index}
            onClick={() => onChange(o.value)}
            className={cn('cursor-pointer text-center bg-transparent p-2 rounded-md truncate z-[1]')}
          >
            {o.label}
          </li>
        ))}
      </ul>
      <div className="absolute top-0 left-0 w-full h-full p-1">
        <div
          className={cn(
            'bg-white dark:bg-gray-800 rounded-md h-full w-1/2 transition-all duration-100',
            selectedIndex === 1 && 'translate-x-full',
            selectedIndex !== 1 && 'translate-x-0',
          )}
        ></div>
      </div>
    </div>
  )
}
