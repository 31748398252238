import { Page, pageData } from '@config/site/app/pages'
import { BaseLayout } from '@layouts/BaseLayout'
import { LayoutRoutes, Route } from './types'

const ROUTES: Route = import.meta.glob('/src/pages/**/*.tsx', { eager: true })

export const layoutRoutes: LayoutRoutes[] = []

const pathPageMap = Object.entries(pageData).reduce((acc, v) => {
  const page = v[0] as Page
  const pageData = v[1]
  if (pageData.path) {
    acc[pageData.path] = page
  }
  return acc
}, {} as Record<string, Page>)

Object.keys(ROUTES).forEach((route) => {
  const layout = ROUTES[route]?.layout || BaseLayout
  const idx = layoutRoutes.findIndex((layoutRoute) => layoutRoute.layout === layout)
  const routes = idx >= 0 ? layoutRoutes[idx].routes : []
  const path = route
    .replace(/\/src\/pages|index|\.tsx$/g, '')
    .replace(/\[\.{3}.+\]/, '*')
    .replace(/\[([^\]]+)\]/g, ':$1')

  routes.push({
    path,
    component: ROUTES[route].default,
    page: pathPageMap[path],
  })
  if (idx >= 0) {
    layoutRoutes[idx].routes = routes
  } else {
    layoutRoutes.push({ layout, routes })
  }
})
