import { Fragment, useEffect } from 'react'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'

export type AuthRequiredProps = {
  children: React.ReactNode | React.ReactNode[]
}
export function AuthRequired({ children }: AuthRequiredProps) {
  const { oktaAuth, authState } = useOktaAuth()
  const hasAuthState = !!authState
  useEffect(() => {
    if (!hasAuthState) {
      return
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect()
    }
  }, [oktaAuth, hasAuthState, authState?.isAuthenticated])

  if (!authState || !authState?.isAuthenticated) {
    return null
  }

  return <Fragment>{children}</Fragment>
}
