import { useCallback } from 'react'
import { Button } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TimeIcon } from '@assets/calendar-days-clock.svg'
import { ReactComponent as DistanceIcon } from '@assets/map_pins_distance.svg'
import { ReactComponent as SubcategoryIcon } from '@assets/puzzle-piece-detach.svg'
import { ReactComponent as CategoryIcon } from '@assets/puzzle.svg'
import { EventContentProps } from '@components/EventContent'
import { Item, RemoteSelect } from '@components/RemoteSelect'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  CompetitorFilterField,
  CompetitorFilterItem,
  CompetitorFilterItemArray,
  CompetitorFilterKey,
} from '@stores/slices/eventDataSlice/competingEvents/types'
import { singleEventFilterToQueryParams } from '@stores/slices/eventDataSlice/eventDataSliceApi'
import { cn } from '@utils/className'

type ControlPanelProps<AdditionalParamsOnInternalChange> = Pick<
  EventContentProps<AdditionalParamsOnInternalChange>,
  'loading' | 'hasEventFilter' | 'eventFilter' | 'competitors' | 'additionalParamsForInternalChange'
>

export const ControlPanel = <AdditionalParamsOnInternalChange,>(
  props: ControlPanelProps<AdditionalParamsOnInternalChange>,
) => {
  const { t } = useTranslation('tour_marketing')
  const { loading, competitors, hasEventFilter, eventFilter } = props

  const filters = competitors.filters
  const setFilters = competitors.setFilters

  const getFiltersFor = useCallback(
    (key: CompetitorFilterKey) => {
      const preFilters: CompetitorFilterField[] = []
      for (const filter of filters) {
        if (filter.name === key) break
        preFilters.push(filter)
      }

      const params: Record<string, string | string[] | undefined> = {}

      if (hasEventFilter && eventFilter) {
        const qryParams = singleEventFilterToQueryParams(eventFilter)
        for (const p of qryParams.keys()) {
          params[p] = qryParams.get(p) as string
        }
      }
      for (const filter of preFilters) {
        Object.assign(params, filter.toParams())
      }
      return params
    },
    [hasEventFilter, filters, eventFilter],
  )

  const getValueFor = useCallback(
    (key: CompetitorFilterKey) => {
      const idx = filters.findIndex((filter) => filter.name === key)
      return idx > -1 ? filters[idx].data : ['category', 'subcategory'].includes(key) ? [] : null
    },
    [filters],
  )

  const handleSelectItem = (key: CompetitorFilterKey, value?: Item | null) => {
    if (!value) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters, props.additionalParamsForInternalChange)
    } else {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(
        [...newFilters, new CompetitorFilterItem(key, { ...value, id: String(value.id) })],
        props.additionalParamsForInternalChange,
      )
    }
  }

  const handleSelectItemArray = (key: CompetitorFilterKey, value?: Item[]) => {
    if (!value || value.length === 0) {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters(newFilters, props.additionalParamsForInternalChange)
    } else {
      const newFilters = filters.filter((filter) => filter.name !== key)
      setFilters([...newFilters, new CompetitorFilterItemArray(key, value)], props.additionalParamsForInternalChange)
    }
  }

  const clear = () => {
    setFilters([], props.additionalParamsForInternalChange)
  }

  return (
    <div className="flex flex-col sm:flex-row gap-2 items-start">
      <div className="text-base font-semibold text-gray-900 dark:text-white whitespace-nowrap pt-2">
        {t('competing_events.filters.filter_by')}:
      </div>
      <div>
        <RemoteSelect
          id="distance"
          onChange={(item) => handleSelectItem('distance', item)}
          extraParams={getFiltersFor('distance')}
          value={getValueFor('distance')}
          dataUrl="/data/competitor_density/distances"
          placeholder={t('competing_events.filters.distance')}
          nameField="name"
          idField="key"
          tabIndex={1}
          disabled={loading || !hasEventFilter}
          disableRemoteSearch={hasEventFilter}
          showRightArrow
          icon={DistanceIcon}
        />
      </div>
      <div>
        <RemoteSelect
          id="time"
          onChange={(item) => handleSelectItem('time', item)}
          extraParams={getFiltersFor('time')}
          value={getValueFor('time')}
          dataUrl="/data/competitor_density/times"
          placeholder={t('competing_events.filters.time')}
          nameField="name"
          idField="key"
          tabIndex={2}
          disabled={loading || !hasEventFilter}
          disableRemoteSearch={hasEventFilter}
          showRightArrow
          icon={TimeIcon}
        />
      </div>
      <div>
        <RemoteSelect
          id="category"
          onChange={(item) => handleSelectItemArray('category', item)}
          extraParams={getFiltersFor('category')}
          value={getValueFor('category')}
          dataUrl="/data/competitor_density/categories"
          placeholder={t('competing_events.filters.category')}
          nameField="name"
          idField="key"
          tabIndex={3}
          disabled={loading || !hasEventFilter}
          disableRemoteSearch={hasEventFilter}
          showRightArrow
          icon={CategoryIcon}
          multiple
        />
      </div>
      <div>
        <RemoteSelect
          id="subcategory"
          onChange={(item) => handleSelectItemArray('subcategory', item)}
          extraParams={getFiltersFor('subcategory')}
          value={getValueFor('subcategory')}
          dataUrl="/data/competitor_density/subcategories"
          placeholder={t('competing_events.filters.subcategory')}
          nameField="name"
          idField="key"
          tabIndex={4}
          disabled={loading || !hasEventFilter}
          disableRemoteSearch={hasEventFilter}
          showRightArrow
          icon={SubcategoryIcon}
          multiple
        />
      </div>

      <Button
        variant="outline"
        onClick={() => clear()}
        className={cn(
          'flex gap-2 text-blue-700 dark:text-blue-500 border-blue-700 dark:border-blue-500',
          filters.length === 0 ? 'invisible' : '',
          'print:hidden',
        )}
      >
        <XMarkIcon className="w-4 h-4 stroke-1.5" />
        {t('competing_events.filters.clear')}
      </Button>
    </div>
  )
}
