export const logErrorMessage = (error: unknown) => {
  const errorMessage = getErrorMessage(error)
  console.error(errorMessage)
}

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message
  return String(error)
}

/**
 * Fast, insecure and deterministic hash function for objects
 * @param obj
 * @returns
 */
export const generateChecksum = (obj: any) => {
  if (obj === undefined) return undefined
  const str = JSON.stringify(obj)
  let checksum = 0
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i)
    checksum = (checksum << 5) - checksum + chr
    checksum |= 0
  }
  return checksum.toString()
}
