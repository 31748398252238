import { cn } from '@utils/className'

export function SkeletonTableCard() {
  return (
    <div
      className={cn(
        'flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col w-full animate-pulse',
      )}
    >
      <div className="flex h-full flex-col justify-center gap-4 p-6 md:p-8 divide-y divide-gray-200 dark:divide-gray-700">
        <div className="px-6">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
          <dl className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 overflow-hidden rounded-lg divide-y divide-gray-200 dark:divide-gray-700 md:divide-x">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className={cn(
                  'px-4 py-5 sm:p-6',
                  index < 2 ? 'md:!border-t-0' : '',
                  index % 2 === 0 ? 'md:!border-l-0 lg:!border-l' : '',
                  index < 4 ? 'lg:!border-t-0' : '',
                  index % 4 === 0 ? 'lg:!border-l-0' : '',
                )}
              >
                <div className="bg-gray-200 rounded-lg dark:bg-gray-700 h-24"></div>
              </div>
            ))}
          </dl>
        </div>
        <div className="px-6 pt-4 text-base font-normal text-gray-500 dark:text-gray-200">
          <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
        </div>
      </div>
    </div>
  )
}
