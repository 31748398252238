import { Item } from '@components/RemoteSelect'
import { ApiFetch } from '@services/api'
import { TourMarketingContext } from '@stores/bookmarkStore/utils/types'
import { EventDataSlice } from '@stores/slices/eventDataSlice'
import { PageSlice } from '@stores/slices/pageSlice'
import { StoreSetFunction } from '@utils/zustand'
import { createTourMarketingStore } from './tourMarketingStore'

export type Filters = {
  artist: Item | null
  showType: Item | null
  venue: Item | null
  perfDate: Item | null
}

export const defaultFilters: Filters = {
  artist: null,
  showType: null,
  venue: null,
  perfDate: null,
}

type LoadOptions = {
  enableReload?: boolean
}

export interface TourMarketingStoreProps {
  getApiFetch: () => ApiFetch
}

export type TourMarketingStore = ReturnType<typeof createTourMarketingStore>

export type AdditionalParamsOnInternalChangeForTourMarketing = {
  searchParamsHandler: SearchParamsObject
}

export interface TourMarketingStoreState
  extends PageSlice<Filters>,
    EventDataSlice<AdditionalParamsOnInternalChangeForTourMarketing> {
  getApiFetch: () => ApiFetch

  setContext: (context: TourMarketingContext, searchParamsHandler: SearchParamsObject, stateOnly?: boolean) => void
  getContext: () => TourMarketingContext

  clearData: (searchParamsHandler?: SearchParamsObject) => void
  applyFilters: (filters: Filters, searchParamsHandler: SearchParamsObject) => Promise<void>
  loadData: (searchParamsHandler: SearchParamsObject, options?: LoadOptions) => Promise<void>
}

export type TourMarketingStoreSetFunction = StoreSetFunction<TourMarketingStoreState>

export type TourMarketingStoreGetFunction = () => TourMarketingStoreState
