import { useTranslation } from 'react-i18next'
import { Table } from '@components/Table'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { BinPriceTierData } from '@types'
import { formatUtils } from '@utils'

interface TicketsSoldByTierAndPriceChartProps {
  id: VisualizationId
  seriesData?: BinPriceTierData[]
}

export const TicketsSoldByPriceAndTierTable = ({ id, seriesData = [] }: TicketsSoldByTierAndPriceChartProps) => {
  const { t } = useTranslation('quantity_sold_by_price_tier')

  return (
    <div className="p-6 pb-2">
      <Table
        id={id}
        header={[
          { column: t('quantity_sold_by_price_tier_table.column_tier_price') },
          { column: t('quantity_sold_by_price_tier_table.column_quantity') },
        ]}
        body={seriesData
          .sort((a, b) => (a.price_total < b.price_total ? 1 : -1))
          .map((data) => ({
            row: [
              <span>
                <strong>{data.tier}</strong> (${data.price_total})
              </span>,
              data.quantity == null ? '-' : formatUtils.formatNumber(data.quantity),
            ],
          }))}
        showTable={seriesData.length > 0}
        isLoading={false}
        showTotal={false}
        hideDownload
      />
    </div>
  )
}
