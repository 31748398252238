import { useCallback, useEffect, useState } from 'react'
import { RoleName, useAsync } from 'carbonarc-ui'
import OktaAuth, { OktaAuthOptions, UserClaims } from '@okta/okta-auth-js'
import { AuthContextProvider } from './AuthContext'

export type AuthProviderProps = {
  oktaOptions: OktaAuthOptions
  logo?: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
  onAuthRequired: () => void
  oktaAuth: OktaAuth
}
export let Auth: OktaAuth
const roles: (string | null | undefined)[] = [
  RoleName.CLIENT_USER,
  RoleName.CLIENT_ADMIN,
  RoleName.CLIENT_OWNER,
  RoleName.CA_ADMIN,
]
export function AuthProvider({
  oktaAuth,
  oktaOptions,
  onAuthRequired,
  logo,
  header,
  footer,
  children,
}: AuthProviderProps) {
  const [user, setUser] = useState<UserClaims | null>(null)
  const signOut = useAsync(
    useCallback(async () => {
      await oktaAuth.signOut()

      const response = await oktaAuth.signOut()
      if (!response) {
        throw Error('Not able to sign out')
      }
    }, [oktaAuth]),
  )

  useEffect(() => {
    oktaAuth.isAuthenticated().then((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        oktaAuth.getUser().then((userInfo) => {
          setUser({ ...userInfo, role: 'client_user' })
        })
      }
    })
  }, [oktaAuth])

  const isAtLeast = useCallback((role: RoleName) => {
    const roleLevel = roles.indexOf(role)
    const organizationRoleLevel = roles.indexOf(RoleName.CLIENT_USER)
    return roleLevel <= organizationRoleLevel
  }, [])

  return (
    <AuthContextProvider
      value={{
        logo,
        header,
        footer,
        signOut: signOut,
        okta: oktaAuth,
        oktaOptions: oktaOptions,
        user: user,
        onAuthRequired,
        isAtLeast,
      }}
    >
      {children}
    </AuthContextProvider>
  )
}
