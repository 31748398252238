import { useCallback, useEffect, useState } from 'react'
import { EChartsOption } from 'echarts'
import { useTranslation } from 'react-i18next'
import { EChartsComponent, getDefaultChartColors } from '@components/EChartsComponent'
import { useTheme } from '@contexts/Theme'
import { SecondaryMarketTimeSeriesInventoryBySectionData } from '@types'
import { formatUtils, screenUtils } from '@utils'
import { getChartStyle, showAnimation, showSlider } from '@utils/charts'

interface SecondaryMarketStackedBarChartProps {
  secondarySeriesData?: SecondaryMarketTimeSeriesInventoryBySectionData[]
  name: string
  valuePropertyName: string
  labelKey: string
  yAxisFormatter?: string
}

export const SecondaryMarketStackedBarChart = ({
  secondarySeriesData = [],
  name,
  valuePropertyName,
  labelKey,
  yAxisFormatter = '{value}',
}: SecondaryMarketStackedBarChartProps) => {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const { theme } = useTheme()
  const { t } = useTranslation('tour_marketing')

  const getChartOption = useCallback(async () => {
    const color = getDefaultChartColors(theme)
    type ObjectKey = keyof (typeof secondarySeriesData)[0]

    setChartOption({
      legend: {
        data: [name],
      },

      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const toolTip = `${'Section'}: ${params.name}<br />
                ${t(labelKey)}: ${yAxisFormatter?.replace(
            `{value}`,
            formatUtils.formatNumber(params.data, {
              maximumFractionDigits: 2,
            }) || '',
          )}`
          return toolTip
        },
      },
      color,
      xAxis: [
        {
          name: 'Section',
          nameLocation: 'middle',
          nameGap: 38,
          type: 'category',
          data: secondarySeriesData.map((item) => item.section),
        },
      ],
      yAxis: [
        {
          name: `${t(labelKey)} By Section`,
          nameGap: 24,
          nameTextStyle: {
            align: 'left',
            padding: [0, -30],
          },
          type: 'value',
          offset: 0,
          axisLabel: {
            show: true,
            formatter: yAxisFormatter,
          },
        },
      ],

      series: {
        name: name,
        data: secondarySeriesData.map((item) => item[valuePropertyName as ObjectKey]),
        stack: 'secondary',
        type: 'bar',
        emphasis: {
          label: {
            show: true,
            position: 'inside',
            color: 'white',
          },
        },
      },
      dataZoom: [
        {
          xAxisIndex: [0],
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
          ...(screenUtils.isMobile(window) && {
            top: '45%',
            left: '5%',
            right: '5%',
          }),
        },
        {
          xAxisIndex: [1],
          type: 'slider',
          show: showSlider(),
          start: 0,
          end: 100,
          labelPrecision: 0,
          ...(screenUtils.isMobile(window) && {
            bottom: '3%',
            left: '5%',
            right: '5%',
          }),
        },
      ],
      animation: showAnimation(),
    })
  }, [theme, secondarySeriesData, t, labelKey, name, valuePropertyName])

  useEffect(() => {
    getChartOption()
  }, [getChartOption])

  if (!chartOption) return null

  return <EChartsComponent option={chartOption} style={getChartStyle()} />
}
