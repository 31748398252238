import { useCallback, useEffect } from 'react'
import { Button } from 'carbonarc-ui'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { Item, Selected } from '@components/Inputs'
import { LoadingIcon } from '@components/LoadingIcon'
import { RemoteSelect } from '@components/RemoteSelect'
import { MapPinIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import {
  FilterField,
  FilterItem,
  GenerateFilterKey,
  generateFilterFieldsToGenerateFilters,
  usePredictionAssessmentControlPanelStore,
  usePredictionAssessmentStore,
} from '@stores/predictionAssessmentStore'
import { objectUtils } from '@utils'
import { cn } from '@utils/className'

const isValidFilters = (filters: FilterField<GenerateFilterKey>[]) => {
  return filters.length === 2
}

export function Filters() {
  const { t } = useTranslation('prediction_assessment')
  const searchParamsHandler = useSearchParamsObject()
  const { isLoading, applyFilters, filterContext, clearState } = usePredictionAssessmentStore((state) => ({
    isLoading: state.isLoadingGenerate,
    applyFilters: state.applyGenerateFilters,
    filterContext: state.filterContext,
    clearState: state.clearGenerate,
  }))
  const { filters, setFilters, applied, setApplied } = usePredictionAssessmentControlPanelStore((state) => ({
    filters: state.generate.filters,
    setFilters: state.generate.setFilters,
    applied: state.generate.applied,
    setApplied: state.generate.setApplied,
  }))

  const handleSelectSingleItem = useCallback(
    (key: GenerateFilterKey, value?: Item | null) => {
      if (!value) {
        const newFilters = filters.filter((filter) => filter.name !== key)
        setFilters(newFilters)
      } else {
        const newFilters = filters.filter((filter) => filter.name !== key)
        setFilters([...newFilters, new FilterItem(key, { ...value, id: String(value.id) })])
      }
    },
    [filters, setFilters],
  )

  const getValueFor = useCallback(
    (key: GenerateFilterKey) => {
      const idx = filters.findIndex((filter) => filter.name === key)
      return idx > -1 ? (filters[idx].data as Item) : null
    },
    [filters],
  )

  const getFiltersFor = useCallback(
    (key: GenerateFilterKey) => {
      const preFilters: FilterField<GenerateFilterKey>[] = []
      for (const filter of filters) {
        if (filter.name === key) break
        preFilters.push(filter)
      }
      const params: Record<string, string | string[] | undefined> = {}
      for (const filter of preFilters) {
        Object.assign(params, filter.toParams())
      }

      return {
        brands: params.artist,
        venues: params.venue,
      }
    },
    [filters],
  )

  const isFirstFilter = useCallback(
    (key: GenerateFilterKey) => {
      return filters.length === 0 || filters.findIndex((filter) => filter.name === key) === 0
    },
    [filters],
  )

  useEffect(() => {
    const filterApplied = isEqual(generateFilterFieldsToGenerateFilters(filters), filterContext)
    if (filterApplied && !applied) {
      setApplied(true)
    } else if (!filterApplied && applied) {
      setApplied(false)
    }
  }, [filters, setApplied, filterContext, applied])

  const canApply = isValidFilters(filters) && !applied

  const _applyFilters = useCallback(async () => {
    try {
      await applyFilters(generateFilterFieldsToGenerateFilters(filters), searchParamsHandler)
      setApplied(true)
    } catch (error) {
      objectUtils.logErrorMessage(error)
    }
  }, [applyFilters, filters, searchParamsHandler, setApplied])

  return (
    <div
      className={cn(
        'flex flex-col md:flex-col gap-2.5  px-6 py-8 mb-4 w-full',
        'text-left bg-white dark:bg-gray-800 text-gray-900 group-[.pdf-report]:hidden',
        'border border-gray-200 rounded-lg shadow-md dark:border-gray-700',
      )}
    >
      <h4 className="text-base md:text-xl font-semibold text-gray-900 dark:text-white">{t('filters.title')}</h4>

      <div className={cn('flex flex-col md:flex-row gap-2.5 items-stretch w-full', ' group-[.pdf-report]:hidden')}>
        <RemoteSelect
          id="artist"
          onChange={(item) => handleSelectSingleItem('artist', item)}
          value={getValueFor('artist')}
          disabled={isLoading}
          dataUrl="/data/predictions/v2/brands"
          extraParams={getFiltersFor('artist')}
          placeholder={t('common:search_type', { type: t('filters.artists') })}
          openOnClick={!isFirstFilter('artist')}
          disableRemoteSearch={!isFirstFilter('artist')}
          searchMinimumLength={isFirstFilter('artist') ? 2 : 0}
          tabIndex={1}
          icon={UserGroupIcon}
          showSelectedValue={false}
        />

        <RemoteSelect
          id="venue"
          onChange={(item) => handleSelectSingleItem('venue', item)}
          value={getValueFor('venue')}
          disabled={isLoading}
          dataUrl="/data/predictions/v2/venues"
          extraParams={getFiltersFor('venue')}
          placeholder={t('common:search_type', { type: t('filters.venues') })}
          openOnClick={!isFirstFilter('venue')}
          disableRemoteSearch={!isFirstFilter('venue')}
          searchMinimumLength={isFirstFilter('venue') ? 2 : 0}
          tabIndex={2}
          icon={MapPinIcon}
          showSelectedValue={false}
        />
        <Button
          variant="default"
          onClick={() => _applyFilters()}
          disabled={isLoading || !canApply}
          className="w-[210px] disabled:opacity-25 print:hidden"
          tabIndex={4}
        >
          {isLoading ? <LoadingIcon className="w-6" /> : 'Apply Filter'}
        </Button>
      </div>
      <div className="flex items-center gap-2">
        {filters.map((filter) => {
          return <Selected className="mt-0 max-w-fit" items={[filter.data]} />
        })}
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            setFilters([])
            clearState(searchParamsHandler)
          }}
          disabled={isLoading || filters.length === 0}
          className={cn(
            'flex gap-2 text-blue-700 dark:text-blue-500 border-blue-700 dark:border-blue-500',
            filters.length === 0 ? 'hidden' : '',
            'print:hidden',
          )}
          tabIndex={5}
        >
          <XMarkIcon className="w-4 h-4 stroke-1.5" />
          {t('common:clear')}
        </Button>
      </div>
    </div>
  )
}
