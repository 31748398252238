import { forwardRef } from 'react'
import { Breadcrumb, useTheme } from 'carbonarc-ui'
import { useTranslation } from 'react-i18next'
import { BookmarkButton } from '@components/Bookmarks/BookmarkButton'
import { CopyReportUrlButton } from '@components/Bookmarks/CopyReportUrlButton'
import { EventContentRef } from '@components/EventContent'
import { useEventContentExportOptions } from '@components/EventContent/useExportOptions'
import { ExportComment } from '@components/Export'
import { ExportButton } from '@components/Export/ExportButton'
import { TCustomConfirmMethod } from '@components/Export/ExportModal'
import { EventContent } from '@components/TourPlanning/EventContent'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { AdditionalParamsOnInternalChangeForEventSearch } from '@stores/eventSearchStore'
import { useSingleEventStore } from '@stores/singleEventStore'
import { useTourPlanningStore } from '@stores/tourPlanningStore'
import { StarEventEntity } from '@types'
import { cn } from '@utils/className'
import { toISOStringDatePart } from '@utils/date'

type TSingleEventProps = {
  events?: any
  context?: any
  customConfirm?: TCustomConfirmMethod
}

export const SingleEvent = forwardRef<EventContentRef, TSingleEventProps>(({ events, context, customConfirm }, ref) => {
  const { t } = useTranslation('tour_planning')
  const breadcrumbTheme = useTheme().theme.breadcrumb
  const searchParamsHandler = useSearchParamsObject()
  const { getEventName, stringifyEvent, getPdfHeader } = useSingleEventStore((state) => ({
    context: state.context,
    getEventName: state.getEventName,
    stringifyEvent: state.stringifyEvent,
    getPdfHeader: state.getPdfHeader,
  }))

  const main = useSingleEventStore((state) => ({
    loading: state.isLoadingEvents(),
    summaryData: state.eventSummaryData,
    eventFilter: state.context.singleEventFilter,
  }))

  const hasEventFilter = !!(
    main &&
    main.eventFilter &&
    main.eventFilter.artist &&
    main.eventFilter.venue &&
    main.eventFilter.date
  )

  const event = events.items.find(
    (event: StarEventEntity) =>
      event.brand?.id?.toString() === main.eventFilter?.artist &&
      event.venue?.id?.toString() === main.eventFilter?.venue &&
      event.performance_date &&
      toISOStringDatePart(event.performance_date) === main.eventFilter?.date,
  )

  const customEvents = useSingleEventStore((state) => ({
    timeSeriesEntities: state.multipleCustomTimeSeriesEntities,
    fetch: state.fetchMultipleCustomTimeSeriesData,
    events: state.customEvents,
    setEvents: state.setCustomEvents,
  }))

  const benchmark = useSingleEventStore((state) => ({
    selected: state.selectedBenchmark,
    setSelected: state.setSelectedBenchmark,
  }))

  const primaryMarket = useSingleEventStore((state) => ({
    timeSeriesEntity: state.timeSeriesEntity,
  }))

  const secondaryMarket = useSingleEventStore((state) => ({
    timeSeriesData: state.secondaryMarketTimeSeriesData,
    timeSeriesInventoryData: state.secondaryMarketTimeSeriesInventoryDataV3,
    timeSeriesInventoryBySectionData: state.secondaryMarketTimeSeriesInventoryBySectionData,
    priceAndTierData: state.secondaryMarketPriceAndTierData,
    chartType: state.secondaryMarketChartType,
    setChartType: state.setSecondaryMarketChartType,
    daysFromEvent: state.daysFromEvent,
    setDaysFromEvent: state.setDaysFromEvent,
    maxDayFromEvent: state.secondaryMarketMaxDayFromEvent,
    minDayFromEvent: state.secondaryMarketMinDayFromEvent,
  }))

  const competitors = useSingleEventStore((state) => ({
    summaryData: state.competitorsSummaryData,
    summaryDataLoading: state.competitorsSummaryLoading,
    data: state.competitors,
    filters: state.competitorsFilters,
    setFilters: state.setCompetitorsFilters,
    getFilterContext: state.getCompetitorsFilterContext,
  }))

  const { backToList } = useTourPlanningStore((state) => ({
    backToList: state.backToList,
  }))

  const back = () => {
    backToList(searchParamsHandler)
  }

  const artist = event?.brand ? event.brand.name : main.summaryData?.event?.brand?.name || null
  const venue = event?.venue ? event.venue.name : main.summaryData?.event?.venue?.name || null
  const date = event?.performance_date ? event.performance_date : main.summaryData?.event?.performance_date || null

  const title = getEventName()
  const pdfHeader = getPdfHeader()
  const stringfiedFilterContext = stringifyEvent()

  const eventContentProps = {
    ...main,
    hasEventFilter,
    event,
    pdfHeader,
    stringfiedFilterContext,
    customEvents,
    benchmark,
    primaryMarket,
    secondaryMarket,
    competitors,
    additionalParamsForInternalChange: {
      searchParamsHandler,
    },
  }
  const exportOptions = useEventContentExportOptions<AdditionalParamsOnInternalChangeForEventSearch>({
    ...eventContentProps,
    secondaryMarket: { ...secondaryMarket, timeSeriesInventoryData: secondaryMarket.timeSeriesInventoryData.days },
  })

  const countUniqueArtists = () => {
    const artistNames = new Set()

    try {
      if (main.summaryData?.event?.brand?.name) {
        artistNames.add(main.summaryData.event.brand.name.toLowerCase())
      }

      customEvents.events.forEach((eventArray) => {
        const artistFilter = eventArray.find((item) => item.name === 'artist')
        if (artistFilter && artistFilter?.data?.name) {
          if (Array.isArray(artistFilter?.data?.name)) {
            artistFilter?.data?.name?.forEach((name: string) => {
              artistNames.add(name?.toLowerCase())
            })
          } else {
            artistNames.add(artistFilter?.data?.name?.toLowerCase())
          }
        }
      })
    } catch (error) {
      console.error('Error counting unique artists:', error)
    }

    return artistNames.size
  }

  const isMultipleArtistsSelected = countUniqueArtists() > 1
  return (
    <div className="px-4 py-6 md:p-8 !pb-0 group-[.pdf-report]:p-0 w-full">
      <div className="flex flex-row justify-between gap-2 mb-6 md:mb-8 w-full">
        <div className="min-w-0">
          <div className="pb-4 group-[.pdf-report]:hidden">
            <Breadcrumb
              theme={{
                list: cn(breadcrumbTheme.root.list, 'flex items-center gap-2'),
              }}
            >
              <Breadcrumb.Item
                theme={{
                  base: cn(breadcrumbTheme.item.base, 'min-w-0'),
                  href: {
                    off: cn(
                      breadcrumbTheme.item.href.off,
                      'cursor-pointer text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white',
                      'inline truncate',
                    ),
                  },
                }}
                onClick={back}
              >
                {t('single_event_page.title')}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                theme={{
                  base: cn(breadcrumbTheme.item.base, 'min-w-0'),
                  href: {
                    off: cn(breadcrumbTheme.item.href.off, 'inline truncate'),
                  },
                }}
              >
                {title}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="flex flex-row items-center w-full min-w-0">
            <div
              onClick={back}
              className="w-10 h-10 shrink-0 rounded-full flex items-center justify-center border border-gray-200 cursor-pointer mr-4 dark:border-gray-500 group-[.pdf-report]:hidden"
            >
              <ArrowLeftIcon className="w-5 h-5 text-gray-900 dark:text-white" />
            </div>

            <h1 className="text-2xl md:text-4xl font-semibold text-gray-900 dark:text-white truncate">{title}</h1>
          </div>
          <h2 className="text-sm md:text-base font-normal text-gray-500 dark:text-gray-400">
            {t('single_event_page.subtitle')}
          </h2>
        </div>
        <div className="flex gap-2">
          <ExportButton
            isLoading={false}
            pageId={BookmarkPageEnum.BRAND_VENUE_INSIGHTS}
            options={exportOptions}
            multipleArtistsSelected={isMultipleArtistsSelected}
            customConfirm={customConfirm}
          />
          <CopyReportUrlButton disabled={!hasEventFilter} />

          <BookmarkButton
            page={BookmarkPageEnum.BRAND_VENUE_INSIGHTS_SINGLE}
            context={context}
            extraData={{
              nameCreator: { artist, venue, date },
            }}
          />
        </div>
      </div>
      <ExportComment />
      <EventContent<AdditionalParamsOnInternalChangeForEventSearch>
        {...eventContentProps}
        ref={ref}
        exportOptions={exportOptions}
        page={BookmarkPageEnum.BRAND_VENUE_INSIGHTS}
      />
    </div>
  )
})
