import { Action, State } from '@hooks/usePaginatedData/types'

export const reducer = <T, F>(state: State<T, F>, action: Action<T, F>): State<T, F> => {
  if (action.type === 'SET_PAGE') {
    return { ...state, page: action.newPage }
  } else if (action.type === 'SET_PER_PAGE') {
    return { ...state, size: action.newSize, ...(action.newPage != null ? { page: action.newPage } : {}) }
  } else if (action.type === 'FETCH_START') {
    return {
      ...state,
      fetching: true,
      onFetchSuccess: () => {
        action.onFetchSuccess?.()
        state.onFetchSuccess?.()
      },
      onFetchFailure: () => {
        action.onFetchFailure?.()
        state.onFetchFailure?.()
      },
    }
  } else if (action.type === 'FETCH_SUCCESS') {
    state.onFetchSuccess?.()
    return {
      ...state,
      items: action.data.items,
      fetching: false,
      pages: action.data.pages,
      total: action.data.total,
      size: action.data.size,
      page: action.data.page,
      onFetchSuccess: undefined,
      onFetchFailure: undefined,
    }
  } else if (action.type === 'FETCH_FAILURE') {
    state.onFetchFailure?.()
    return { ...state, fetching: false }
  } else if (action.type === 'SET_FILTER') {
    return { ...state, filter: action.filter, page: 1 }
  } else if (action.type === 'RESET') {
    return { ...state, filter: null as F, page: 1, items: [], pages: 0, total: 0 }
  }
  return state
}
