import { PropsWithChildren, forwardRef, useCallback, useRef, useState } from 'react'
import { Badge, Dropdown, Navbar, UserApplications, useTheme } from 'carbonarc-ui'
import { NavLink } from 'react-router-dom'
import { BookmarkMenuButton } from '@components/Bookmarks/BookmarkMenuButton'
import { UserMenu } from '@components/Okta'
import { ThemeToggle } from '@components/ThemeToggle'
import { queryClient } from '@config/react-query'
import { usePages } from '@config/site/app'
import { HeaderLogo } from '@config/site/app/logo'
import { Divider } from '@config/site/app/pages'
import { Bars3BottomLeftIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { usePredictionAssessmentStore } from '@stores/predictionAssessmentStore'
import { useTourMarketingStore } from '@stores/tourMarketingStore'
import { useTourPlanningStore } from '@stores/tourPlanningStore'
import { useUpcomingEventsStore } from '@stores/upcomingEventsStore'
import { cn } from '@utils/className'

type NavbarCollapsProps = {
  className?: string
  isOpen: boolean
  close: () => void
  avoidAutoCollapseOnElements?: React.RefObject<HTMLElement>[]
} & PropsWithChildren

type NavbarToggleProps = {
  toggleMenu: () => void
}

const NavbarCollapse = ({ children, className, isOpen, close, avoidAutoCollapseOnElements }: NavbarCollapsProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const avoidElements = avoidAutoCollapseOnElements
    ?.filter((el) => el.current != null)
    .map((el) => el.current as HTMLElement)

  useOnClickOutside<HTMLDivElement>({
    ref,
    handler: close,
    avoidElements,
  })
  return (
    <div className={cn('w-full md:block md:w-auto', { hidden: !isOpen, block: isOpen }, className)} ref={ref}>
      <ul className="mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-4 md:text-sm md:font-medium items-center">
        {children}
      </ul>
    </div>
  )
}

const NavbarToggle = forwardRef<HTMLButtonElement, NavbarToggleProps>(({ toggleMenu }, ref) => {
  return (
    <button
      onClick={() => toggleMenu()}
      className={
        'inline-flex items-center rounded-lg p-2 text-sm text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden mr-auto'
      }
      ref={ref}
    >
      <span className="sr-only">Open main menu</span>
      <Bars3BottomLeftIcon className="w-6 h-6 shrink-0 stroke-[1.5px]" />
    </button>
  )
})

const IntegratedApps = () => {
  const { appLinks } = usePages()
  return <UserApplications appUrls={appLinks} />
}

const DocsMenu = () => {
  const { docsMenu } = usePages()
  const dropdownTheme = useTheme().theme.dropdown

  const show = docsMenu.filter((menu) => menu !== Divider).length > 0
  if (!show) return null

  return (
    <Dropdown
      inline
      arrowIcon={false}
      label={
        <div className="p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700">
          <QuestionMarkCircleIcon className="text-gray-400 stroke-[1.5px] w-[24px] h-[24px]" />
        </div>
      }
      theme={{
        content: cn(dropdownTheme.content, 'min-w-[195px]'),
      }}
    >
      {docsMenu.map((menu, index) => {
        if (menu === Divider) {
          return <Dropdown.Divider key={index} />
        }
        const Icon = menu.icon
        if (menu.download)
          return (
            <a href={menu.path} download>
              <Dropdown.Item>
                <div className="grid grid-cols-[auto,1fr] gap-1 items-center">
                  <div className="w-7">{Icon && <Icon className="w-5 h-5 text-gray-400 stroke-[1.5px]" />}</div>
                  {menu.label}
                </div>
              </Dropdown.Item>
            </a>
          )

        return menu?.newTab ? (
          <Dropdown.Item key={menu.path} onClick={() => window.open(menu.path, '_blank')}>
            <div className="grid grid-cols-[auto,1fr] gap-1 items-center">
              <div className="w-7">{Icon && <Icon className="w-5 h-5 text-gray-400 stroke-[1.5px]" />}</div>
              {menu.label}
            </div>
          </Dropdown.Item>
        ) : (
          <NavLink to={menu.path || '#'} key={menu.path} className={({ isActive }) => cn(isActive ? 'font-bold' : '')}>
            <Dropdown.Item>
              <div className="grid grid-cols-[auto,1fr] gap-1 items-center">
                <div className="w-7">{Icon && <Icon className="w-5 h-5 text-gray-400 stroke-[1.5px]" />}</div>
                {menu.label}
              </div>
            </Dropdown.Item>
          </NavLink>
        )
      })}
    </Dropdown>
  )
}

export const SiteHeaderHeight = 80

export function SiteHeader() {
  const { navBar } = usePages()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const mobileMenuTogglerRef = useRef<HTMLButtonElement>(null)
  const navbarTheme = useTheme().theme.navbar
  const [clearUpcomingEventsStore, setUpcomingEventsFirstLoad] = useUpcomingEventsStore((state) => [
    state.clearData,
    state.setFirstLoad,
  ])
  const [clearTourPlanningStore, setTourPlanningFirstLoad] = useTourPlanningStore((state) => [
    state.clearData,
    state.setFirstLoad,
  ])
  const [clearTourMarketingStore, setTourMarketingFirstLoad] = useTourMarketingStore((state) => [
    state.clearData,
    state.setFirstLoad,
  ])
  const [clearPredictionAssessmentStore, setPredictionAssessmentFirstLoad] = usePredictionAssessmentStore((state) => [
    state.clearData,
    state.setFirstLoad,
  ])

  const close = useCallback(() => setShowMobileMenu(false), [setShowMobileMenu])

  const clearSessionStorage = useCallback(() => {
    Object.keys(localStorage)
      .filter((key) => key.startsWith('session_'))
      .forEach((key) => {
        localStorage.removeItem(key)
      })
  }, [])

  const clearData = () => {
    queryClient.clear()
    clearSessionStorage()

    clearUpcomingEventsStore()
    clearTourPlanningStore()
    clearTourMarketingStore()
    clearPredictionAssessmentStore()

    setUpcomingEventsFirstLoad(true)
    setTourPlanningFirstLoad(true)
    setTourMarketingFirstLoad(true)
    setPredictionAssessmentFirstLoad(true)
  }

  return (
    <header className="md:h-[var(--main-nav-height)] print:hidden">
      <Navbar
        fluid
        className="border-b h-full p-4 md:py-3"
        theme={{
          inner: {
            base: cn(navbarTheme.root.inner.base, 'flex-nowrap'),
          },
        }}
      >
        <Navbar.Brand as="a" href="/">
          <HeaderLogo />
        </Navbar.Brand>
        <NavbarToggle toggleMenu={() => setShowMobileMenu(!showMobileMenu)} ref={mobileMenuTogglerRef} />
        <div className="flex md:order-2 gap-4 items-center">
          <div className="pr-4 border-r border-gray-200 dark:border-gray-700 flex z-30">
            <BookmarkMenuButton />
            <IntegratedApps />
            <DocsMenu />
          </div>
          <ThemeToggle />
          <UserMenu onLogout={() => clearData()} />
        </div>
        <NavbarCollapse
          close={close}
          isOpen={showMobileMenu}
          className={cn(
            'flex-grow md:ml-8',
            'bg-white dark:bg-gray-800',
            'absolute top-[var(--main-nav-height)] left-0 px-4 pb-4 z-50',
            'md:relative md:top-auto md:left-auto md:px-0 md:pb-0 md:z-auto',
            'border-b md:border-b-0 border-gray-200 dark:border-gray-700',
          )}
          avoidAutoCollapseOnElements={[mobileMenuTogglerRef]}
        >
          {navBar.map((item, index) => (
            <li key={index}>
              <NavLink
                to={item.path || '#'}
                className={({ isActive }) =>
                  cn(
                    'flex gap-y-0.5 gap-x-2 flex-wrap items-center text-sm text-gray-900 text-center justify-center hover:text-gray-700 font-medium px-2 py-1 rounded dark:text-gray-100',
                    isActive ? 'bg-gray-100 dark:bg-gray-700' : '',
                  )
                }
                onClickCapture={() => setShowMobileMenu(false)}
              >
                {item.label} {item?.badge && <Badge className="rounded-[80px]">{item.badge}</Badge>}
              </NavLink>
            </li>
          ))}
        </NavbarCollapse>
      </Navbar>
    </header>
  )
}
