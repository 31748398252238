import { createContext, useContext } from 'react'
import { AsyncFn, RoleName } from 'carbonarc-ui'
import OktaAuth, { OktaAuthOptions, UserClaims } from '@okta/okta-auth-js'

export type AuthContextType = {
  logo?: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  signOut: AsyncFn<void>
  okta: OktaAuth | null
  oktaOptions: OktaAuthOptions | null
  user: UserClaims | null
  onAuthRequired: () => void
  isAtLeast(role: RoleName): boolean
}

const nonImplemented = async () => {
  throw new Error('Not implemented')
}

nonImplemented.reset = nonImplemented

const authContext = createContext<AuthContextType>({
  signOut: nonImplemented,
  oktaOptions: null,
  okta: null,
  user: null,
  onAuthRequired: nonImplemented,
  isAtLeast: () => false,
})

export const AuthContextProvider = authContext.Provider
export function useAuth() {
  return useContext(authContext)
}
