import i18n from '@config/react-i18next'
import {
  Bookmark,
  BookmarkContext,
  BookmarkData,
  BookmarkPage,
  BookmarkPageEnum,
  CreateBookmarkExtraData,
  EventSearchContext,
  PredictionAssessmentContext,
  TourMarketingContext,
  TourPlanningContext,
} from '@stores/bookmarkStore/utils/types'
import { contextToUrl as eventSearchContextToQueryParams } from '@stores/eventSearchStore/queryParams'
import { contextToQueryParams as predictionAssessmentContextToQueryParams } from '@stores/predictionAssessmentStore/queryParams'
import { contextToQueryParams as tourMarketingContextToQueryParams } from '@stores/tourMarketingStore/queryParams'
import { contextToQueryParams as tourPlanningContextToQueryParams } from '@stores/tourPlanningStore/queryParams'
import { formatUtils, objectUtils } from '@utils'
import { Sorter } from '@utils/object-sort'
import { serializeBookmarkData } from './serializers'

const t = i18n.t

const bookmarkPagePath: Record<BookmarkPage, string> = {
  [BookmarkPageEnum.EVENT_INSIGHTS]: 'event-insights',
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS]: 'artist-venue-insights',
  [BookmarkPageEnum.PREDICTION_ASSESSMENT]: 'prediction-assessment',
  [BookmarkPageEnum.EVENT_ADVANCED_LIST]: 'event-insights',
  [BookmarkPageEnum.EVENT_ADVANCED_SINGLE]: 'event-insights',
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS_SINGLE]: 'artist-venue-insights',
}

const namesCreator = {
  [BookmarkPageEnum.EVENT_INSIGHTS]: (_context: BookmarkContext) => {
    const context = _context as TourMarketingContext
    const filters = context.filters
    const artistName = filters?.artist?.name
    const showType = filters?.showType?.name
    const venueName = filters?.venue?.name
    const date = filters?.perfDate?.name
    const fields = [artistName, showType, venueName, date].filter((field) => field).join(' | ')

    return `Events Insights | ${fields}`
  },
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS]: (_context: BookmarkContext) => {
    const context = _context as TourPlanningContext
    const filters = context.filters
    const artistName = filters.artist?.map((v) => v.name).join(', ')
    const showType = filters.showType?.map((v) => v.name).join(', ')
    const regionName = filters.region?.map((v) => v.name).join(', ')
    const venueName = filters.venue?.map((v) => v.name).join(', ')
    const date =
      filters.dateRangeOption?.value === 'custom'
        ? `${formatUtils.formatDate(filters.since)} - ${formatUtils.formatDate(filters.until)}`
        : filters.dateRangeOption?.label || null
    const eventQty = context.selectedEvents.length > 1 ? `${context.selectedEvents.length} Events` : null
    const fields = [artistName, showType, regionName, venueName, date, eventQty].filter((field) => field).join(' | ')

    return `${t('tour_planning:title')} | ${fields}`
  },
  [BookmarkPageEnum.PREDICTION_ASSESSMENT]: (_context: BookmarkContext) => {
    const context = _context as PredictionAssessmentContext

    const hasGenerate =
      context?.filters?.generate?.artist || context?.filters?.generate?.venue || !!context?.filters?.generate?.season

    const seasonOrMetricName = hasGenerate
      ? context.filters?.generate?.season?.name
      : context.filters?.backTest?.metric?.name

    const artistName = hasGenerate
      ? context.filters?.generate.artist?.name
      : context.filters?.backTest.artist?.map((v) => v.name).join(', ')
    const venueName = hasGenerate
      ? context.filters?.generate.venue?.name
      : context.filters?.backTest.venue?.map((v) => v.name).join(', ')
    const fields = [seasonOrMetricName, artistName, venueName].filter((field) => field).join(' | ')

    return `Prediction Assessment | ${fields}`
  },
  [BookmarkPageEnum.EVENT_ADVANCED_LIST]: (_context: BookmarkContext) => {
    const context = _context as EventSearchContext
    const filters = context.filters
    const artist = filters
      ?.find((f) => f.name === 'artist')
      ?.data?.map((item: any) => item.name)
      .join(', ')
    const venue = filters
      ?.find((f) => f.name === 'venue')
      ?.data?.map((item: any) => item.name)
      .join(', ')
    const venueName = filters?.find((f) => f.name === 'venueName')?.data
    const state = filters
      ?.find((f) => f.name === 'state')
      ?.data?.map((item: any) => item.name)
      .join(', ')
    const city = filters
      ?.find((f) => f.name === 'city')
      ?.data?.map((item: any) => item.name)
      .join(', ')
    const promoter = filters
      ?.find((f) => f.name === 'promoter')
      ?.data?.map((item: any) => item.name)
      .join(', ')
    const promoterName = filters?.find((f) => f.name === 'promoterName')?.data
    const fields = ['Event Insights - Advanced Search', artist, venue, venueName, state, city, promoter, promoterName]
      .filter((field) => field)
      .join(' | ')

    return fields
  },
  [BookmarkPageEnum.EVENT_ADVANCED_SINGLE]: (_context: BookmarkContext, extraData?: any) => {
    const artistName = extraData?.artist
    const venueName = extraData?.venue
    const date = extraData?.date
    const fields = ['Event Insights - Advanced Detail', artistName, venueName, date]
      .filter((field) => field)
      .join(' | ')
    return fields
  },
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS_SINGLE]: (_context: BookmarkContext, extraData?: any) => {
    const artistName = extraData?.artist
    const venueName = extraData?.venue
    const date = extraData?.date
    const fields = [t('tour_planning:single_event_page:title'), artistName, venueName, date]
      .filter((field) => field)
      .join(' | ')
    return fields
  },
}

export const createBookmarkName = (page: BookmarkPage, context: BookmarkContext, extraData?: any): string => {
  return namesCreator[page](context, extraData)
}

type QueryParamsGenerator = (ctx: BookmarkContext) => URLSearchParams | string

const queryParamsGenerator: Record<BookmarkPage, QueryParamsGenerator> = {
  [BookmarkPageEnum.EVENT_INSIGHTS]: (ctx: BookmarkContext) =>
    tourMarketingContextToQueryParams(ctx as TourMarketingContext),
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS]: (ctx: BookmarkContext) =>
    tourPlanningContextToQueryParams(ctx as TourPlanningContext),
  [BookmarkPageEnum.PREDICTION_ASSESSMENT]: (ctx: BookmarkContext) =>
    predictionAssessmentContextToQueryParams(ctx as PredictionAssessmentContext),
  [BookmarkPageEnum.EVENT_ADVANCED_LIST]: (ctx: BookmarkContext) =>
    eventSearchContextToQueryParams(ctx as EventSearchContext),
  [BookmarkPageEnum.EVENT_ADVANCED_SINGLE]: (ctx: BookmarkContext) =>
    eventSearchContextToQueryParams(ctx as EventSearchContext),
  [BookmarkPageEnum.BRAND_VENUE_INSIGHTS_SINGLE]: (ctx: BookmarkContext) =>
    tourPlanningContextToQueryParams(ctx as TourPlanningContext),
}

const generateBookmarkDataQueryParams = (bookmark: BookmarkData) => {
  const fn = queryParamsGenerator[bookmark.page]
  return fn(bookmark.context)
}

export const generateReportPath = (bookmark: Pick<Bookmark, 'data'>) => {
  const {
    data: { page },
  } = bookmark

  const path = bookmarkPagePath[page]
  const queryParams = generateBookmarkDataQueryParams(bookmark.data)
  return `/${path}?${queryParams?.toString()}`
}

const nullishContextForPages = [BookmarkPageEnum.EVENT_ADVANCED_LIST, BookmarkPageEnum.EVENT_ADVANCED_SINGLE]

export const calculateChecksum = (bookmark: BookmarkData) => {
  const serializedData = serializeBookmarkData(bookmark)
  const eraseNullish = bookmark.page in nullishContextForPages
  const sortedData = new Sorter(serializedData, eraseNullish).sort()
  return objectUtils.generateChecksum(sortedData) || ''
}

export const createBookmark = (
  page: BookmarkPage,
  context: BookmarkContext,
  extraData?: CreateBookmarkExtraData,
): Bookmark => {
  const checksum = calculateChecksum({ page, context })
  const data = serializeBookmarkData({ page, context })
  const name = createBookmarkName(page, context, extraData?.nameCreator)
  return { data, checksum, name }
}
