import { formatUtils } from '@utils'

type ChangeType = 'increase' | 'decrease' | 'neutral'

export const pivotMatrix = (matrix: any[][]) => {
  const result: any[][] = []
  matrix.forEach((row, rowIndex) => {
    row.forEach((value, columnIndex) => {
      if (!result[columnIndex]) {
        result[columnIndex] = []
      }
      result[columnIndex][rowIndex] = value
    })
  })
  return result
}

type PivotMatrixDataType = number | string

export const pivotMatrixFromDays = (
  days: number[],
  data: PivotMatrixDataType[][][],
  dataLength: number,
): string[][] => {
  const getMapByDays = (array: PivotMatrixDataType[][]) =>
    array.reduce(
      (obj, data) => ({
        ...obj,
        [data[0]]: data.slice(1),
      }),
      {},
    )

  const dataMapByDays: { [key: PivotMatrixDataType]: PivotMatrixDataType[] }[] = data.map(getMapByDays)

  return days.map((day) => {
    const row = []

    row.push(day.toString())

    dataMapByDays.forEach((map) => {
      if (map[day]) row.push(...map[day])
      else row.push(...Array(dataLength - 1).fill(null))
    })

    return row
  })
}

export const getChangeBetweenNumbers = (
  previous?: number | null,
  current?: number | null,
): {
  change: string
  changeType: ChangeType
} => {
  if (previous == null || current == null || previous === 0) {
    return {
      change: 'N/A',
      changeType: 'neutral',
    }
  }

  if (previous === current) {
    return {
      change: '0.00%',
      changeType: 'neutral',
    }
  }

  if (previous > current && current === 0) {
    return {
      change: '100%',
      changeType: 'decrease',
    }
  }

  return getChangeWithDecimalPrecision(previous, current)
}

function getChangeWithDecimalPrecision(
  previous: number,
  current: number,
): {
  change: string
  changeType: ChangeType
} {
  let percentage: number
  let changeType: ChangeType

  if (previous > current) {
    percentage = (previous - current) / previous
    changeType = 'decrease'
  } else {
    percentage = (current - previous) / previous
    changeType = 'increase'
  }

  const formattedPercentage = formatUtils.formatPercentage(percentage, 2)

  if (formattedPercentage === '0.00%') {
    return {
      change: '0.00%',
      changeType: 'neutral',
    }
  }

  return {
    change: formattedPercentage,
    changeType,
  }
}
