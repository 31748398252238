import html2canvas from 'html2canvas'
// eslint-disable-next-line
// @ts-ignore
import imageToBase64 from 'image-to-base64/browser'
import jsPDF from 'jspdf'
// organize-imports-disable-above
import { AppExportLogo, AppExportLogoSize } from '@config/site/app/logo'
import { formatDateTime } from '@utils/format'
import { getSearchParams, hasUrlParam, parseSearchParams } from '@utils/url'

export type PdfHeader = {
  text: string
  lines: number
} | null

interface ExportComponentToImageReturn {
  imageData: string
  componentWidth: number
  componentHeight: number
}

async function exportComponentToImage(Component: HTMLElement): Promise<ExportComponentToImageReturn> {
  const componentWidth = Component.offsetWidth
  const componentHeight = Component.offsetHeight

  const canvas = await html2canvas(Component)

  const imageData = canvas.toDataURL('image/png')

  return { imageData, componentWidth, componentHeight }
}

export interface ExportComponentToPDFProps {
  component?: HTMLElement | null
  htmlClassnames?: string[]
  filename: string
  header?: PdfHeader
  autoClose?: boolean
  customPdfReportHide?: string[]
  customPdfComment?: string
}

export async function exportComponentToPDF({
  component,
  htmlClassnames = ['pdf-report'],
  header,
  filename,
  autoClose = true,
  customPdfReportHide = undefined,
  customPdfComment = undefined,
}: ExportComponentToPDFProps) {
  if (!component) return

  const parsedSearchParams = parseSearchParams(getSearchParams()) as {
    'pdf-report-hide'?: string | string[]
    'pdf-report-comment': string
  }
  let pdfReportHideParams = customPdfReportHide ?? (parsedSearchParams?.['pdf-report-hide'] || [])

  if (typeof pdfReportHideParams === 'string') {
    pdfReportHideParams = [pdfReportHideParams]
  }

  const htmlElement = document.querySelector('html')
  htmlElement?.classList.add('group', ...htmlClassnames, ...pdfReportHideParams)
  const pdfReportCommentParams = customPdfComment ?? (parsedSearchParams?.['pdf-report-comment']?.trim() || '')
  if (pdfReportCommentParams) {
    htmlElement?.classList.add('pdf-report-comment')
  }

  // Delay before image capture to allow for CSS transitions to complete
  await delay(1000)

  const { imageData, componentWidth, componentHeight } = await exportComponentToImage(component)

  const pageMargin = 72
  const pageWidth = componentWidth + pageMargin * 2
  const pageHeight = componentHeight + pageMargin * 3

  const pdf = new jsPDF({
    orientation: pageWidth > pageHeight ? 'landscape' : 'portrait',
    unit: 'px',
    format: [pageWidth, pageHeight],
  })

  pdf.setFillColor('#E5E7EB')
  pdf.rect(0, pageMargin + 1, pageWidth, 1, 'F')

  pdf.setFillColor('#E5E7EB')
  pdf.rect(0, pageHeight - pageMargin + 1, pageWidth, 1, 'F')

  const logoImage = await imageToBase64(AppExportLogo)
  const [logoWidth, logoHeight] = AppExportLogoSize
  pdf.addImage(logoImage, 'PNG', pageMargin, pageMargin / 2 - 26, logoWidth, logoHeight)

  if (header) {
    const fontSize = header.lines > 4 ? 14 : 16

    const headerTextHeight = Math.round(fontSize * header.lines * 1.15)
    pdf.setTextColor('#6B7280')
    pdf.setFontSize(fontSize)
    pdf.text(header.text, pageWidth - pageMargin, pageMargin / 2 - headerTextHeight / 2 + 16, { align: 'right' })
    pdf.setFontSize(16)
  }

  const footerText = `Generated by STAR on ${formatDateTime(new Date())}`
  pdf.setTextColor('#6B7280')
  pdf.text(footerText, pageWidth / 2, pageHeight - pageMargin / 2, { align: 'center' })

  pdf.addImage({
    imageData,
    format: 'PNG',
    x: pageMargin,
    y: pageMargin * 1.5,
    width: componentWidth,
    height: componentHeight,
  })

  await pdf.save(`${filename}.pdf`, { returnPromise: true })

  try {
    htmlElement?.classList.remove('group', ...htmlClassnames, ...pdfReportHideParams)
  } catch (error) {
    console.error("Something went wrong while removing 'group' class:", error)
  }

  if (autoClose) {
    setTimeout(() => {
      window.close()
    }, 1000)
  }
}

export const hasDownloadPDFUrlParam = (id: string) => hasUrlParam('download-pdf', id)

export const downloadPDFInNewTab = (id: string, params: string[] = [], comment: string) => {
  const currentUrl = new URL(window.location.href)

  currentUrl.searchParams.set('download-pdf', id)
  params.forEach((param) => {
    currentUrl.searchParams.append('pdf-report-hide', param)
  })
  currentUrl.searchParams.append('pdf-report-comment', comment)

  window.open(currentUrl.toString(), '_blank')
}

export const isExportingPdf = () => hasUrlParam('download-pdf')

function delay(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds)
  })
}
