import { Props, Styles } from 'react-joyride'

export const defaultJoyrideStyles: Styles = {
  options: {
    zIndex: 10000,
  },
  tooltip: {
    padding: 12,
    width: 180,
  },
  tooltipContent: {
    padding: 0,
  },
  tooltipFooter: {
    display: 'none',
  },
  buttonClose: {
    width: 10,
    height: 10,
  },
  tooltipTitle: {
    paddingRight: 18,
  },
}

export const defaultJoyrideProps: Omit<Props, 'steps'> = {
  run: true,
  disableScrolling: true,
  disableScrollParentFix: true,
  styles: defaultJoyrideStyles,
}
