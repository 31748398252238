import { Trans } from 'react-i18next'
import { Step } from 'react-joyride'
import { isDesktop } from '@utils/screen'

export const selectArtistTooltipStep: Step = {
  target: '#control_panel',
  content: (
    <div className="text-left">
      <p className="leading-none text-sm font-medium text-gray-900 mb-1.5">
        <Trans i18nKey="joyride.title" />
      </p>
      <p className="leading-tight text-xs font-normal text-gray-500">
        <Trans i18nKey="joyride.content" components={[<b></b>]} />
      </p>
    </div>
  ),
  disableBeacon: true,
  placement: isDesktop(window) ? 'right' : 'auto',
  styles: {
    buttonNext: {
      display: 'none',
    },
  },
}
