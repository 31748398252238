import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import Joyride, { LIFECYCLE } from 'react-joyride'
import { EventContentRef } from '@components/EventContent'
import { ControlPanel } from '@components/EventsPages/EventSearch/ControlPanel'
import { ListContent } from '@components/EventsPages/EventSearch/ListContent'
import { SingleEvent } from '@components/EventsPages/EventSearch/SingleEvent'
import { Footer } from '@components/Footer'
import { SubNav } from '@components/SubNav'
import { selectArtistTooltipStep } from '@components/TooltipSteps/SelectArtistTooltipStep'
import { defaultJoyrideProps } from '@config/react-joyride'
import { usePrevious } from '@hooks'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useSearchParamsObject } from '@hooks/useSearchParamsObject'
import { BookmarkPageEnum } from '@stores/bookmarkStore/utils/types'
import { clearParams, useEventSearchStore } from '@stores/eventSearchStore'
import { cn } from '@utils/className'
import { exportComponentToPDF, hasDownloadPDFUrlParam } from '@utils/pdf'

export default function EventSearchPage() {
  const { t } = useTranslation('tour_marketing')
  const searchParamsHandler = useSearchParamsObject()
  const previousSearchParams = usePrevious(searchParamsHandler.searchParams)
  const contentRef = useRef<HTMLElement>(null)

  const { isShowingSingleEvent, loadFromUrl, initLoad, isLoading, getPdfHeader, stringifyEvent, isInitiated } =
    useEventSearchStore((state) => ({
      isShowingSingleEvent: state.isShowingSingleEvent,
      loadFromUrl: state.loadFromUrl,
      initLoad: state.initLoad,
      isLoading: state.isLoadingEvents,
      getPdfHeader: state.getPdfHeader,
      stringifyEvent: state.stringifyEvent,
      isInitiated: state.isInitiated,
    }))
  const mainRef = useRef<HTMLDivElement>(null)
  const [singleEventRef, setSingleEventRef] = useState<EventContentRef | null>(null)
  const loading = isLoading()
  const initiated = isInitiated()
  const showingSingleEvent = isShowingSingleEvent()

  useEffect(() => {
    const clearPrevious = clearParams(previousSearchParams)
    const clearCurrent = clearParams(searchParamsHandler.searchParams)
    if (clearPrevious !== clearCurrent && clearCurrent && clearCurrent.size > 0 && initiated) {
      loadFromUrl(searchParamsHandler).catch(() => {
        /* empty */
      })
    }
  }, [initiated, loadFromUrl, previousSearchParams, searchParamsHandler])

  useEffectOnce(() => {
    initLoad(searchParamsHandler)
  })

  useEffect(() => {
    const pdfHeader = getPdfHeader()
    const stringfiedFilterContext = stringifyEvent()

    if (!loading && initiated && !!pdfHeader && hasDownloadPDFUrlParam(BookmarkPageEnum.EVENT_ADVANCED_SINGLE)) {
      exportComponentToPDF({
        component: contentRef.current,
        filename: `${t('title')}${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`,
        header: pdfHeader,
      })
    }
  }, [getPdfHeader, loading, t, stringifyEvent, initiated])

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
        <meta name="description" content={t('title')} />
      </Helmet>

      <Joyride
        {...defaultJoyrideProps}
        run={!localStorage.tourMarketingJoyrideRunComplete}
        steps={[selectArtistTooltipStep]}
        callback={(data) => {
          if (data.lifecycle === LIFECYCLE.COMPLETE) {
            localStorage.tourMarketingJoyrideRunComplete = true
          }
        }}
      />

      <div ref={mainRef}>
        {showingSingleEvent && singleEventRef?.subnavItems && singleEventRef.subnavItems.length > 0 && (
          <SubNav container={mainRef.current?.parentElement as HTMLElement} items={singleEventRef.subnavItems} />
        )}
        <div className="flex flex-col lg:flex-row bg-transparent">
          <ControlPanel />
          <div
            className={cn(
              'w-full lg:w-[calc(100%-250px)] flex flex-col justify-between flex-1',
              showingSingleEvent
                ? 'min-h-[calc(100vh-var(--main-nav-height)-var(--sub-nav-height))]'
                : 'min-h-[calc(100vh-var(--main-nav-height))]',
            )}
          >
            <section
              className="flex-1 w-full group-[.pdf-report]:bg-white group-[.pdf-report]:dark:bg-gray-700"
              ref={contentRef}
            >
              {showingSingleEvent ? <SingleEvent ref={setSingleEventRef} /> : <ListContent />}
            </section>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}
