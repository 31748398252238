import { useCallback, useEffect, useState } from 'react'
import { EChartsOption } from 'echarts'
import { useTranslation } from 'react-i18next'
import { EChartsComponent } from '@components/EChartsComponent'
import { formatUtils } from '@utils'
import { showAnimation } from '@utils/charts'

interface TicketSoldPieChartProps {
  quantitySold?: number
  quantityRemaining?: number
}

const negativeToZero = (value?: number) => (value == null ? value : value < 0 ? 0 : value)

export function TicketSoldPieChart({ quantitySold = 0, quantityRemaining = 0 }: TicketSoldPieChartProps) {
  const [chartOption, setChartOption] = useState<EChartsOption | null>(null)
  const { t } = useTranslation('tour_marketing')

  const getChartOption = useCallback(() => {
    const data = [
      {
        value: negativeToZero(quantitySold),
        name: `${t('tickets_sold_vs_tickets_remaining_chart.tickets_sold')} - ${formatUtils.formatNumber(
          negativeToZero(quantitySold),
        )}`,
      },
    ]

    if (quantityRemaining > 0) {
      data.push({
        value: quantityRemaining,
        name: `${t('tickets_sold_vs_tickets_remaining_chart.tickets_remaining')} - ${formatUtils.formatNumber(
          quantityRemaining,
        )}`,
      })
    }

    setChartOption({
      tooltip: {
        trigger: 'item',
        formatter: '{b} ({d}%)',
      },
      legend: {
        orient: 'horizontal',
        top: 'left',
        type: 'scroll',
      },
      label: {
        show: true,
        position: 'inside',
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: '50%',
          data,
          color: ['#5470c6', '#93cd76'],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
      animation: showAnimation(),
    })
  }, [quantitySold, quantityRemaining, t])

  useEffect(() => {
    getChartOption()
  }, [getChartOption])

  if (!chartOption) return null

  return (
    <EChartsComponent
      option={chartOption}
      style={{ width: '100%', height: '100%', minHeight: '35vh', padding: '1rem 0' }}
    />
  )
}
