import { Fragment, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BenchmarkOption, BenchmarkOptionId } from '@components/EventContent/types'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { TimeSeriesEntity } from '@types'
import { getXAxisDaysFromEventData, getXAxisDaysSinceSalesOpenedData } from '@utils/charts'
import { cn } from '@utils/className'

export enum AxisType {
  DaysUntilShow = 'cumulative_tickets_sold_chart.days_from_event',
  DaysSinceTicketSalesOpened = 'cumulative_tickets_sold_chart.days_since_sales_opened',
}

type AxisConfig = {
  labelKey: string
  dataParser: (data: any[][]) => number[]
  getItemValue: (item: TimeSeriesEntity) => number
  showReverse: boolean
  unsupportedBenchmarks?: BenchmarkOptionId[]
  serieHasValue: (serie: TimeSeriesEntity[]) => boolean
}

export const axisConfig: Record<AxisType, AxisConfig> = {
  [AxisType.DaysUntilShow]: {
    labelKey: 'cumulative_tickets_sold_chart.days_from_event',
    dataParser: getXAxisDaysFromEventData,
    getItemValue: (item: TimeSeriesEntity) => item.days_from_event,
    showReverse: true,
    serieHasValue: () => true,
  },
  [AxisType.DaysSinceTicketSalesOpened]: {
    labelKey: 'cumulative_tickets_sold_chart.days_since_sales_opened',
    dataParser: getXAxisDaysSinceSalesOpenedData,
    getItemValue: (item: TimeSeriesEntity) => item.days_since_sales_opened || 0,
    showReverse: false,
    serieHasValue: (serie: TimeSeriesEntity[]) =>
      serie.length > 0 && serie.every((item) => item?.days_since_sales_opened != null),
  },
}

type SelectXAxisProps = {
  xAxis: AxisType
  setXAxis: (xAxis: AxisType) => void
  timeSeriesEntity?: TimeSeriesEntity[]
  multipleCustomTimeSeriesEntity?: TimeSeriesEntity[][]
  selectedBenchmark?: BenchmarkOption | null
  disabled?: boolean
}

export function SelectXAxis({
  xAxis,
  setXAxis,
  selectedBenchmark,
  multipleCustomTimeSeriesEntity,
  timeSeriesEntity,
  disabled = false,
}: SelectXAxisProps) {
  const { t } = useTranslation('tour_marketing')

  const currentAxisConfig = axisConfig[xAxis]

  const axisAvailable = useMemo(() => {
    const axisOptions = Object.values(AxisType)
    const axisAvailable: Record<AxisType, boolean> = {} as any
    for (const axis of axisOptions) {
      const config: AxisConfig = axisConfig[axis]

      const currentEventHasValue = config.serieHasValue(timeSeriesEntity || [])
      const allCustomEventsHasValue = (multipleCustomTimeSeriesEntity || []).every(
        (data) => data && config.serieHasValue(data),
      )
      const selectedBenchmarkSupported =
        selectedBenchmark == null || !config.unsupportedBenchmarks?.includes(selectedBenchmark.id)

      axisAvailable[axis] = currentEventHasValue && allCustomEventsHasValue && selectedBenchmarkSupported
    }
    return axisAvailable
  }, [multipleCustomTimeSeriesEntity, selectedBenchmark, timeSeriesEntity])

  useEffect(() => {
    if (!axisAvailable[xAxis] && xAxis !== AxisType.DaysUntilShow) {
      setXAxis(AxisType.DaysUntilShow)
    }
  }, [axisAvailable, setXAxis, xAxis])

  return (
    <div className="flex justify-end items-end gap-2 group-[.pdf-report]:hidden">
      <Listbox as="div" value={xAxis} onChange={setXAxis} className="relative flex" disabled={disabled}>
        <span
          className={cn(
            'border border-gray-300 dark:border-gray-500 rounded-l border-r-0',
            'text-gray-800 dark:text-gray-300 bg-gray-100 dark:bg-gray-500',
            'text-xs font-medium',
            'flex items-center py-1 px-2 whitespace-nowrap',
            disabled && 'opacity-50 pointer-events-none',
          )}
        >
          {t('cumulative_tickets_sold_chart.x-axis')}
        </span>
        <Listbox.Button
          className={cn(
            'relative cursor-pointer w-full py-1 px-2 pr-8 text-xs font-medium text-left overflow-hidden',
            'border border-gray-300 dark:border-gray-500 rounded-r',
            'bg-white dark:bg-gray-700 text-gray-500 dark:text-gray-300 ',
            disabled && 'opacity-50 pointer-events-none',
          )}
        >
          <span className="block break-all text-ellipsis">
            {currentAxisConfig.labelKey && t(currentAxisConfig.labelKey)}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-4 w-4 stroke-2" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 right-0 border border-gray-300 p-1 mt-1 md:w-[250px] overflow-auto rounded-lg bg-white py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:bg-gray-700 dark:border-gray-500">
            {Object.values(AxisType).map((option: AxisType) => (
              <Listbox.Option
                key={option}
                value={option}
                className={({ active, selected, disabled }) =>
                  `relative cursor-default select-none py-2 px-4 rounded text-gray-700 dark:text-gray-300 text-xs
                   flex items-center justify-start
                   ${
                     selected
                       ? 'font-medium bg-gray-100 dark:bg-gray-800'
                       : active
                       ? 'bg-gray-50 dark:bg-gray-800/50'
                       : 'bg-white dark:bg-gray-700'
                   }
                   ${disabled ? 'opacity-50' : ''}
                  `
                }
                disabled={!axisAvailable[option]}
              >
                {axisConfig[option].labelKey && t(axisConfig[option].labelKey)}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )
}
