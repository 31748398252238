import { createContext } from 'react'
import { RoleName } from 'carbonarc-ui'
import { FlagResult, Flags } from '@config/site/app/flags'
import { IntegratedApp } from '@config/site/app/integratedApps'
import { Divider, ExternalPage, Page, PageData } from '@config/site/app/pages'

export type PageDataWithNewTab = PageData & { newTab?: boolean; download?: boolean }

export type DocsMenuPage = PageDataWithNewTab | typeof Divider

export type GetPageData = (page: Page | ExternalPage) => PageDataWithNewTab | undefined

type AppType = {
  externalLinks: Record<ExternalPage, string | null>
  flags: Record<Flags, FlagResult>
  canSeePage: (page: Page | ExternalPage) => boolean
  navBar: PageDataWithNewTab[]
  docsMenu: DocsMenuPage[]
  initialPath: string
  getPageData: GetPageData
  getMinimumRoleForPage: (page: Page | ExternalPage) => RoleName | undefined
  isLoadingPageFlag: (page: Page | ExternalPage) => boolean
  appLinks: Record<IntegratedApp, string>
}

export const AppConfigContext = createContext<AppType>({
  externalLinks: {} as Record<ExternalPage, string | null>,
  flags: {} as Record<Flags, FlagResult>,
  canSeePage: () => false,
  navBar: [],
  docsMenu: [],
  initialPath: '',
  getMinimumRoleForPage: () => undefined,
  isLoadingPageFlag: () => false,
  getPageData: () => undefined,
  appLinks: {} as Record<IntegratedApp, string>,
})
