import { cn } from '@utils/className'

const regEscape = (v: string) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

const colorizedChunks = (str: string, searchTxt: string): [string, boolean][] => {
  if (searchTxt === '') return [[str, false]]
  const caracterizedText: [string, boolean][] = []
  const arr = str?.split(new RegExp(regEscape(searchTxt), 'ig')) || []

  let start = 0
  for (let i = 0; i < arr.length - 1; i++) {
    const txt = arr[i]
    start += txt.length
    caracterizedText.push([txt, false])
    const originalSearchChunk = str.substring(start, start + searchTxt.length)
    start += originalSearchChunk.length
    caracterizedText.push([originalSearchChunk, true])
  }
  caracterizedText.push([arr[arr.length - 1], false])

  return caracterizedText
}

interface ColorizedTextProps {
  fullText: string
  colorizedText: string
}

export const ColorizedText = (props: ColorizedTextProps) => {
  const { fullText, colorizedText } = props
  const indexes = colorizedChunks(fullText, colorizedText)

  return indexes.map(([chunk, colorized], index) => (
    <span
      key={index}
      className={cn({
        'text-blue-600 dark:text-blue-400': colorized,
        'text-gray-500 dark:text-white': !colorized,
      })}
    >
      {chunk}
    </span>
  ))
}
