import { Outlet } from 'react-router-dom'
import { AuthRequired } from '@components/Okta'
import { AppConfigProvider } from '@config/site/app/provider'
import { VisualizationEvaluationProvider } from '@contexts/VisualizationEvaluation/provider'
import { StoresProvider } from '@stores/StoresProvider'
import { TourMarketingStoreProvider } from '@stores/tourMarketingStore/tourMarketingStoreProvider'
import { TourPlanningStoreProvider } from '@stores/tourPlanningStore'

export default function Root() {
  return (
    <AuthRequired>
      <AppConfigProvider>
        <VisualizationEvaluationProvider>
          <TourPlanningStoreProvider>
            <TourMarketingStoreProvider>
              <StoresProvider>
                <Outlet />
              </StoresProvider>
            </TourMarketingStoreProvider>
          </TourPlanningStoreProvider>
        </VisualizationEvaluationProvider>
      </AppConfigProvider>
    </AuthRequired>
  )
}
