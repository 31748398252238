import { useState } from 'react'
import { Button } from 'carbonarc-ui'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { BookmarkModalDelete } from '@components/Bookmarks/BookmarkModalDelete'
import { LoadingIcon } from '@components/LoadingIcon'
import { Transition } from '@headlessui/react'
import { Square2StackIcon, StarIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useApi, useApiFetch } from '@services/api'
import { useBookmarkStore } from '@stores/bookmarkStore'
import { Bookmark, generateReportPath } from '@stores/bookmarkStore/utils'
import { cn } from '@utils/className'
import { copyToClipboard } from '@utils/string'

const BookmarkItem = ({ bookmark }: { bookmark: Bookmark }) => {
  const { t } = useTranslation()
  const { setShow } = useBookmarkStore((state) => ({
    setShow: state.setShow,
  }))
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const copyPathToClipboard = (path: string) => {
    const currentUrl = new URL(window.location.href)
    copyToClipboard(`${currentUrl.origin}${path}`)
    toast.success(t('bookmark.copied_report_to_clipboard'))
  }
  const path = generateReportPath(bookmark)
  const { fetch: apiFetch } = useApi()
  const queryClient = useQueryClient()
  const remoteBookmark = useQuery<Bookmark>({
    queryKey: [bookmark?.checksum],
    queryFn: () => apiFetch(`/app/userbookmarks/by_checksum?checksum=${bookmark?.checksum}`),
    onSuccess: () => {
      queryClient.invalidateQueries([bookmark.data.page, bookmark?.checksum])
    },
    enabled: bookmark?.checksum != null,
  })

  return (
    path && (
      <>
        <li
          className="text-gray-500 border-b dark:text-gray-400 dark:border-gray-700 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer hover:rounded-lg"
          key={bookmark.uid}
        >
          <div className="px-2 flex justify-between">
            <Link to={path} onClick={() => setShow(false)}>
              <span className="text-xs font-semibold">{bookmark.name}</span>
            </Link>
            <Button
              variant="ghost"
              size="sm"
              className="!ring-0 focus:ring-offset-0 hover:bg-gray-200"
              onClick={() => copyPathToClipboard(path)}
            >
              <Square2StackIcon className="w-4 h-4 stroke-2 text-gray-500 dark:text-gray-400" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="!ring-0 focus:ring-offset-0 hover:bg-gray-200"
              onClick={() => setShowDeleteModal(true)}
            >
              <TrashIcon className="w-4 h-4 stroke-2 text-gray-500 dark:text-gray-400" />
            </Button>
          </div>
        </li>
        <BookmarkModalDelete
          remoteBookmark={remoteBookmark}
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          onClose={() => {
            setShowDeleteModal(false)
          }}
        />
      </>
    )
  )
}

export const BookmarksPanel = () => {
  const apiFetch = useApiFetch()
  const { t } = useTranslation()
  const { show, setShow, isLoading, bookmarks, fetch } = useBookmarkStore((state) => ({
    show: state.show,
    setShow: state.setShow,
    isLoading: state.isLoading,
    bookmarks: state.bookmarks,
    fetch: state.fetchBookmarks,
  }))

  useEffectOnce(() => {
    fetch(apiFetch)
  })

  return (
    <Transition
      show={show}
      enter="transition-transform duration-300"
      enterFrom="translate-x-80"
      enterTo="translate-x-0"
      leave="transition-transform duration-150"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-80"
      className="fixed top-[var(--main-nav-height)] right-0 h-screen shadow-md max-h-[calc(100vh-var(--main-nav-height))] flex flex-col w-80 z-20 p-4 overflow-y-auto bg-white dark:bg-gray-800 dark:border-l dark:border-gray-700"
    >
      <>
        <div className="flex justify-between pb-4">
          <div className="flex flex-col">
            <h5 className="text-base font-semibold text-gray-500 dark:text-gray-400 flex items-center gap-2">
              <StarIcon className="w-4 h-4 stroke-2" />
              {t('bookmark.title')}
            </h5>
            <h6 className="text-xs font-normal text-gray-500 dark:text-gray-400">{t('bookmark.subtitle')}</h6>
          </div>

          <Button variant="ghost" size="sm" className="!ring-0 -mt-2" onClick={() => setShow(false)}>
            <XMarkIcon className="w-4 h-4 stroke-2" />
            <span className="sr-only">{t('bookmark.close')}</span>
          </Button>
        </div>
        <div className={cn('pb-4 flex-grow', isLoading && 'opacity-50')}>
          {isLoading && (
            <div className="text-xs font-normal text-gray-500 dark:text-gray-400 h-full w-full flex flex-col items-center justify-center absolute ">
              <LoadingIcon className="w-4 h-4" />{' '}
            </div>
          )}
          {!isLoading && bookmarks.length === 0 && (
            <div className="text-xs font-normal text-gray-500 dark:text-gray-400 h-full flex flex-col items-center justify-center">
              {t('bookmark.empty_message')}
            </div>
          )}
          <ul className={cn('space-y-2 font-medium mt-2', bookmarks.length > 0 && 'border-t dark:border-gray-700')}>
            {bookmarks?.map((bookmark) => {
              return <BookmarkItem bookmark={bookmark} />
            })}
          </ul>
        </div>
      </>
    </Transition>
  )
}
