import { useTranslation } from 'react-i18next'
import { ApiError } from '@services/api'

export function ErrorMessage({ error }: { error: unknown }) {
  const { t } = useTranslation('')

  if (error instanceof ApiError) {
    const apiError = error as ApiError
    if (apiError.code === 401) {
      return <>{t('error.no_permission')}</>
    }
  }
  return <>{t('error.retrieving_information')}</>
}
