import { EventContentProps } from '@components/EventContent'
import i18n from '@config/react-i18next'

const t = i18n.t.bind(i18n)

type ExportOptionProps<AdditionalParamsOnInternalChange> = Pick<
  EventContentProps<AdditionalParamsOnInternalChange>,
  'summaryData' | 'primaryMarket' | 'secondaryMarket' | 'competitors'
>

type ExportOption = {
  id: string
  label: string
  disabled: boolean
}

export const useEventContentExportOptions = <AdditionalParamsOnInternalChange>(
  props: ExportOptionProps<AdditionalParamsOnInternalChange>,
) => {
  const showPredictions = false
  return [
    {
      id: 'pdf-report-hide-summary',
      label: t('tour_marketing:summary.title'),
      disabled: !props.summaryData,
    },
    {
      id: 'pdf-report-hide-timeseries-chart',
      label: t('tour_marketing:primary_market.title'),
      disabled: (props.primaryMarket.timeSeriesEntity || [])?.length === 0,
    },
    {
      id: 'pdf-report-hide-secondary-market-chart',
      label: t('tour_marketing:secondary_market_chart.title'),
      disabled: (props.secondaryMarket.timeSeriesData || [])?.length === 0,
    },
    showPredictions
      ? {
          id: 'pdf-report-hide-predictions',
          label: t('tour_marketing:predictions.title'),
          disabled: !props.summaryData?.prediction,
        }
      : null,
    {
      id: 'pdf-report-hide-competing-events-table',
      label: t('tour_marketing:competing_events.title'),
      disabled: props.competitors.data?.total === 0,
    },
  ].filter((v) => v != null) as ExportOption[]
}
