import { Fragment, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorizedText } from '@components/Inputs/Select/ColorizedText'
import { LoadingIcon } from '@components/LoadingIcon'
import { Item } from '@components/RemoteSelect'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/solid'
import { cn } from '@utils/className'
import { isDesktop } from '@utils/screen'

interface SelectionPanelProps {
  items: Item[]
  selectedValue: Item | Item[] | null
  searchValue: string
  isLoading?: boolean
  isError?: boolean
  emptyMessage?: string
  open: boolean
  closeOnSelect?: boolean | string[]
  expandWidthTo?: HTMLElement
  close?: () => void
}

export const SelectionPanel = (props: SelectionPanelProps) => {
  const { t } = useTranslation('')
  const {
    items,
    selectedValue,
    searchValue,
    isLoading = false,
    isError = false,
    emptyMessage = t('data_tips.no_results.title'),
    open,
    close,
    closeOnSelect,
    expandWidthTo,
  } = props
  const ref = useRef<HTMLUListElement>(null)

  const isSelected = useCallback(
    (item: Item) => {
      if (Array.isArray(selectedValue)) {
        return selectedValue.some((s) => s.id === item.id)
      }
      return selectedValue?.id === item.id
    },
    [selectedValue],
  )

  const onSelect = useCallback(
    (item: Item) => {
      if (!close || !closeOnSelect) return

      if (isSelected(item)) return //deselecting never triggers close

      if (typeof closeOnSelect === 'boolean') {
        if (closeOnSelect) close()
      } else if (closeOnSelect.some((s) => s === item.id)) {
        close()
      }
    },
    [closeOnSelect, close, isSelected],
  )

  const width = expandWidthTo?.getBoundingClientRect().width

  return (
    <Transition
      as={Fragment}
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={open}
      beforeEnter={() => {
        // expand to top in case it's too close to the bottom
        const showTop =
          document.body.getBoundingClientRect().height - (ref.current?.getBoundingClientRect().y || 0) < 240

        if (showTop && isDesktop(window)) {
          ref.current?.style.setProperty('top', `-${(+ref.current?.clientHeight || 0) + 8}px`)
        }
      }}
    >
      <Combobox.Options
        static
        className={cn(
          'absolute mt-1 max-h-60 overflow-auto border rounded-lg text-base shadow-sm ring-1 ring-opacity-5 focus:outline-none sm:text-sm z-50',
          'bg-white border-gray-200 ring-black',
          'dark:border-gray-700 dark:bg-gray-800',
          !expandWidthTo && 'w-full',
          expandWidthTo && 'right-0',
        )}
        style={
          expandWidthTo
            ? {
                width: `${width}px`,
              }
            : {}
        }
        ref={ref}
      >
        {items.length === 0 ? (
          <div className="relative cursor-default select-none py-3 px-4 font-normal text-gray-500 dark:text-white text-center">
            {isLoading ? (
              <LoadingIcon className="w-4 h-4" />
            ) : isError ? (
              <span className="text-red-400 dark:text-red-500">{t('error.error')}</span>
            ) : (
              emptyMessage
            )}
          </div>
        ) : (
          items.map((item) => {
            const isItemSelected = isSelected(item)

            return (
              <Combobox.Option
                key={item.id}
                className={({ active }) =>
                  cn('relative cursor-default select-none py-3 px-4', {
                    'bg-gray-100 dark:bg-gray-700': active,
                    'pr-7': isItemSelected,
                  })
                }
                value={item}
                onClick={() => onSelect(item)}
              >
                <Fragment key={item.id}>
                  <span
                    className={cn('block truncate text-left', {
                      'font-medium': isItemSelected,
                      'font-normal': !isItemSelected,
                    })}
                  >
                    <ColorizedText fullText={item.name} colorizedText={searchValue} />
                  </span>
                  {isItemSelected ? (
                    <span className={cn('absolute inset-y-0 right-0 flex items-center pr-3')}>
                      <CheckIcon className="h-5 w-5 text-gray-500 dark:text-white" aria-hidden="true" />
                    </span>
                  ) : null}
                </Fragment>
              </Combobox.Option>
            )
          })
        )}
      </Combobox.Options>
    </Transition>
  )
}
