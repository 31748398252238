import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BoxPlotIcon } from '@assets/box-plot-icon.svg'
import { ReactComponent as StackedBarIcon } from '@assets/stacked-bar-icon.svg'
import { ReactComponent as TimeSeriesIcon } from '@assets/time-series-icon.svg'
import { ChartCard } from '@components/ChartCard'
import { Dropdown, DropdownOption } from '@components/Dropdown'
import { EventContentProps } from '@components/EventContent'
import { SecondaryMarketStackedBarChart } from '@components/EventContent/InsightsAndTrends/SecondaryMarketChartCard/SecondaryMarketStackedBarChart'
import { useFlag } from '@config/site/app'
import { Flags } from '@config/site/app/flags'
import { VisualizationId } from '@contexts/VisualizationEvaluation/types'
import { ChartBarSquareIcon, PresentationChartBarIcon, TableCellsIcon } from '@heroicons/react/24/outline'
import { SecondaryMarketChartType } from '@stores/slices/eventDataSlice/eventDataSliceTypes'
import { BasePriceTierEntity } from '@types'
import { TIER_BIN_SIZE, calculateBins } from '@utils/charts'
import { SecondaryMarketTimeSeriesChart } from './SecondaryMarketTimeSeriesChart'
import { SecondaryMarketTimeSeriesInventoryChart } from './SecondaryMarketTimeSeriesInventoryChart'

type SecondaryMarketChartCardProps<AdditionalParamsOnInternalChange> = Pick<
  EventContentProps<AdditionalParamsOnInternalChange>,
  | 'loading'
  | 'pdfHeader'
  | 'stringfiedFilterContext'
  | 'secondaryMarket'
  | 'summaryData'
  | 'additionalParamsForInternalChange'
  | 'exportOptions'
  | 'page'
>

export function SecondaryMarketChartCard<AdditionalParamsOnInternalChange>(
  props: SecondaryMarketChartCardProps<AdditionalParamsOnInternalChange>,
) {
  const showTimeSeriesSales = useFlag(Flags.SHOW_PRICING_INSIGHTS_TIME_SERIES_SALES)
  const { t } = useTranslation('tour_marketing')
  const { chartType, setChartType } = props.secondaryMarket

  const dropdownOptions: DropdownOption[] = [
    {
      id: SecondaryMarketChartType.StackedBarChart,
      label: t('secondary_market_chart.types.stacked_bar_chart'),
      icon: StackedBarIcon,
      onClick: () => setChartType(SecondaryMarketChartType.StackedBarChart, props.additionalParamsForInternalChange),
    },
    ...(showTimeSeriesSales
      ? [
          {
            id: SecondaryMarketChartType.TimeSeries,
            label: t('secondary_market_chart.types.time_series'),
            icon: TimeSeriesIcon,
            onClick: () => setChartType(SecondaryMarketChartType.TimeSeries, props.additionalParamsForInternalChange),
          },
        ]
      : []),
    {
      id: SecondaryMarketChartType.TimeSeriesInventory,
      label: t('secondary_market_chart.types.time_series_inventory'),
      icon: TimeSeriesIcon,
      onClick: () =>
        setChartType(SecondaryMarketChartType.TimeSeriesInventory, props.additionalParamsForInternalChange),
    },
    {
      id: SecondaryMarketChartType.Table,
      label: t('secondary_market_chart.types.table'),
      icon: TableCellsIcon,
      onClick: () => setChartType(SecondaryMarketChartType.Table, props.additionalParamsForInternalChange),
      disabled: true,
    },
    {
      id: SecondaryMarketChartType.BoxPlot,
      label: t('secondary_market_chart.types.box_plot'),
      icon: BoxPlotIcon,
      onClick: () => setChartType(SecondaryMarketChartType.BoxPlot, props.additionalParamsForInternalChange),
      disabled: true,
    },
    {
      id: SecondaryMarketChartType.ScatterPlot,
      label: t('secondary_market_chart.types.scatter_plot'),
      icon: ChartBarSquareIcon,
      onClick: () => setChartType(SecondaryMarketChartType.ScatterPlot, props.additionalParamsForInternalChange),
      disabled: true,
    },
  ]

  const {
    loading: isLoadingSecondaryMarket,
    pdfHeader,
    stringfiedFilterContext,
    secondaryMarket,
    summaryData: eventSummaryData,
  } = props

  const optionSelected = dropdownOptions.find((option) => option.id === chartType)

  const secondarySeriesData = useMemo(() => {
    const priceTiersData = secondaryMarket.priceAndTierData.map<BasePriceTierEntity>((data) => ({
      price_total: data.price_per_ticket,
      tier: data.tier,
      quantity: data.total_tickets_listed,
      avg_number_of_days_listed: data.avg_number_of_days_listed,
      avg_number_of_price_changes: data.avg_number_of_price_changes,
    }))

    return calculateBins(priceTiersData)
  }, [secondaryMarket.priceAndTierData])

  const primarySeriesData = useMemo(() => {
    if (!eventSummaryData) return []

    return eventSummaryData.price_tiers ? calculateBins(eventSummaryData.price_tiers) : []
  }, [eventSummaryData])

  const chartsByType = {
    [SecondaryMarketChartType.StackedBarChart]: (
      <SecondaryMarketStackedBarChart
        secondarySeriesData={secondarySeriesData}
        primarySeriesData={primarySeriesData}
        tierBinSize={TIER_BIN_SIZE}
      />
    ),
    [SecondaryMarketChartType.BoxPlot]: (
      <div className="h-full min-h-[35vh] text-sm text-gray-500 flex flex-col items-center justify-center dark:text-gray-400">
        <div className="w-24 h-24 bg-gray-50 dark:bg-gray-700 rounded-full gap-2 flex items-center justify-center mb-2">
          <BoxPlotIcon className="w-12 text-gray-300 dark:text-gray-500 stroke-1" />
        </div>
        {t('common:data_tips.no_data.title')}
      </div>
    ),
    [SecondaryMarketChartType.TimeSeries]: (
      <SecondaryMarketTimeSeriesChart secondaryMarketTimeSeriesData={secondaryMarket.timeSeriesData} />
    ),
    [SecondaryMarketChartType.TimeSeriesInventory]: (
      <SecondaryMarketTimeSeriesInventoryChart
        secondaryMarketTimeSeriesInventoryData={secondaryMarket.timeSeriesInventoryData}
      />
    ),
    [SecondaryMarketChartType.ScatterPlot]: null,
    [SecondaryMarketChartType.Table]: null,
  }

  return (
    <ChartCard
      exportOptions={props.exportOptions}
      page={props.page}
      id={VisualizationId.TicketsSoldByPriceAndTierSecondaryMarket}
      visualization={{ id: VisualizationId.TicketsSoldByPriceAndTierSecondaryMarket, subTypes: [chartType] }}
      title={t('secondary_market_chart.title')}
      info={t('secondary_market_chart.info')}
      lastUpdatedDate={new Date()} //FIXME find right data
      chart={chartsByType[chartType]}
      showChart={(secondarySeriesData || primarySeriesData || [])?.length > 0}
      noDataIcon={PresentationChartBarIcon}
      isLoading={isLoadingSecondaryMarket}
      exportFilename={`secondary_market_sold_average${stringfiedFilterContext ? '-' + stringfiedFilterContext : ''}`}
      pdfHeader={pdfHeader}
      chartControlsComponent={<Dropdown options={dropdownOptions} defaultOption={optionSelected} />}
    />
  )
}
