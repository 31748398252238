import { XMarkIcon } from '@heroicons/react/24/outline'
import { cn } from '@utils/className'

type SelectedBadgeProps = {
  text: string
  icon?: React.ElementType
  onClick?: () => void
  onRemove?: () => void
  size?: 'md' | 'sm'
}

export const Badge = ({ text, icon: Icon, onClick, onRemove, size = 'md' }: SelectedBadgeProps) => {
  return (
    <span
      className={cn(
        'inline-flex items-center text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300 print:bg-white print:text-black',
        size === 'md' && 'text-sm font-medium px-2 py-1',
        size === 'sm' && 'text-xs font-medium px-2.5 py-0.5',
      )}
      onClick={onClick}
    >
      {Icon && <Icon className={cn(size === 'md' && 'mr-2 h-4 w-4', size === 'sm' && 'mr-1 h-3.5 w-3.5')} />}
      {text}
      {onRemove && (
        <button
          type="button"
          className="inline-flex items-center p-0.5 ml-2 text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
          onClick={() => {
            onRemove()
          }}
          aria-label="Remove"
        >
          <XMarkIcon className="w-4 h-4 text-blue-500 stroke-2 print:hidden" />
          <span className="sr-only">Remove badge</span>
        </button>
      )}
    </span>
  )
}
