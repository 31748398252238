import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid'
import { Dropdown, DropdownProps, UserAvatar } from 'carbonarc-ui'
import { twMerge } from 'tailwind-merge'
import { useAuth } from '..'

export type UserMenuProps = Omit<DropdownProps, 'label'> & {
  onLogout?: () => void
}

export function UserMenu({ className, onLogout, ...rest }: UserMenuProps) {
  const { user, signOut } = useAuth()
  const userEmail = user?.email
  const userFullName = user?.name
  return (
    <Dropdown
      inline
      className={twMerge('!min-w-56 rounded-lg', className)}
      arrowIcon={false}
      label={<UserAvatar size="sm" />}
      placement="bottom-end"
      {...rest}
    >
      <Dropdown.Header className="text-sm font-light text-gray-500 truncate dark:text-gray-400">
        <p className="font-semibold text-gray-900 dark:text-white">{userFullName}</p>
        <p className="truncate">{userEmail}</p>
      </Dropdown.Header>

      <Dropdown.Item
        className="py-3 text-red-500"
        onClick={() => {
          signOut().then(() => {
            onLogout?.()
          })
        }}
      >
        <ArrowRightOnRectangleIcon className="w-5 h-5 mr-3" />
        Logout
      </Dropdown.Item>
    </Dropdown>
  )
}
