import {
  CompetitorFilterField,
  CompetitorFilterItem,
  CompetitorFilterItemArray,
  CompetitorFilters,
  CompetitorsExclusiveFilters,
} from '@stores/slices/eventDataSlice/competingEvents/types'
import { filtersToQueryParams } from '@stores/tourMarketingStore/queryParams'

export const competitorFilterFieldsToCompetitorFilters = (filters: CompetitorFilterField[]) => {
  return {
    category: filters.find((filter) => filter.name === 'category')?.data || [],
    subcategory: filters.find((filter) => filter.name === 'subcategory')?.data || [],
    distance: filters.find((filter) => filter.name === 'distance')?.data || null,
    time: filters.find((filter) => filter.name === 'time')?.data || null,
  }
}

export const competitorFiltersToCompetitorFilterFields = (obj: CompetitorsExclusiveFilters) => {
  const filters: CompetitorFilterField[] = []
  if (obj?.category && obj.category.length > 0) filters.push(new CompetitorFilterItemArray('category', obj.category))
  if (obj?.subcategory && obj.subcategory.length > 0)
    filters.push(new CompetitorFilterItemArray('subcategory', obj.subcategory))
  if (obj?.distance) filters.push(new CompetitorFilterItem('distance', obj.distance))
  if (obj?.time) filters.push(new CompetitorFilterItem('time', obj.time))
  return filters
}

export const competitorFiltersToQueryParams = (filters: CompetitorFilters) => {
  const urlSearchParams = filtersToQueryParams(filters)
  const competitorsSearchParams = competitorExclusiveFiltersToQueryParams(filters as CompetitorsExclusiveFilters)

  for (const [key, value] of competitorsSearchParams.entries()) {
    urlSearchParams.append(key, value)
  }
  return urlSearchParams
}

const competitorExclusiveFiltersToQueryParams = (filters: CompetitorsExclusiveFilters) => {
  const urlSearchParams = new URLSearchParams()
  const { category, subcategory, distance, time } = filters

  category?.forEach((c) => urlSearchParams.append('category', c.id))
  subcategory?.forEach((c) => urlSearchParams.append('subcategory', c.id))
  distance && urlSearchParams.append('distance', distance?.id)
  time && urlSearchParams.append('time', time?.id)

  return urlSearchParams
}
