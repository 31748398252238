import qs from 'qs'
import { removeLeftSlashes, removeRightSlashes } from './string'

export const buildURL = (path: string, baseURL = '') => {
  const url = new URL(removeRightSlashes(baseURL) + '/' + removeLeftSlashes(path))

  return url
}

export const hasUrlParam = (key: string, value?: any) => {
  const matchesKey = new URL(window.location.href).searchParams.has(key)
  const matchesValue = !value || new URL(window.location.href).searchParams.get(key) === value

  return matchesKey && matchesValue
}

export const hasFirstUrlParam = (param: string) => window.location.href.includes(`?${param}`)

export const getSearchParams = () => new URL(window.location.href).searchParams

export const parseSearchParams = (searchParams: string | URLSearchParams) => {
  const isString = typeof searchParams === 'string'

  return qs.parse(isString ? searchParams : searchParams.toString())
}

export const stringifySearchParams = (searchParams: any) => qs.stringify(searchParams)

export const updateSearchParam = (paramName: string, paramValue: any) =>
  updateSearchParamQuery(getSearchParams(), paramName, paramValue)

export const updateSearchParamQuery = (searchString: string | URLSearchParams, paramName: string, paramValue: any) => {
  const parsedSearchParams = parseSearchParams(searchString)

  const newSearchParams = {
    ...parsedSearchParams,
    [paramName]: paramValue,
  }
  return stringifySearchParams(newSearchParams)
}

export const objToQueryParam = (obj: any) => window.btoa(JSON.stringify(obj))

export const queryParamToObj = (queryParam: string) => JSON.parse(window.atob(queryParam))

export const removeSearchParam = (searchString: string | URLSearchParams, paramName: string) => {
  const parsedSearchParams = parseSearchParams(searchString)
  if (!parsedSearchParams[paramName]) return searchString
  delete parsedSearchParams[paramName]

  return stringifySearchParams(parsedSearchParams)
}
