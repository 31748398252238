import { Outlet } from 'react-router-dom'
import { BookmarksPanel } from '@components/Bookmarks/BookmarksPanel'
import { FeedbackButton } from '@components/FeedbackButton'
import { SiteHeader } from '@components/SiteHeader'
import { EmptyLayout } from '@layouts/EmptyLayout'
import { useOktaAuth } from '@okta/okta-react'

export const BaseLayout = () => {
  const { authState } = useOktaAuth()

  if (!authState) {
    return <EmptyLayout />
  }

  return (
    <div className="h-full flex flex-col overflow-hidden">
      <SiteHeader />
      <main className="flex-1 bg-gray-50 dark:bg-gray-700 md:h-[calc(100vh-var(--main-nav-height))] overflow-auto print:bg-white relative">
        <Outlet />
        <BookmarksPanel />
        <FeedbackButton />
      </main>
    </div>
  )
}
