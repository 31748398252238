import { DateRangeInputValue } from 'carbonarc-ui'
import snakeCase from 'lodash/snakeCase'
import { Item } from '@components/RemoteSelect'

export type FilterKey =
  | 'artist'
  | 'showType'
  | 'state'
  | 'city'
  | 'venue'
  | 'date'
  | 'promoter'
  | 'sellableCapacity'
  | 'venueName'
  | 'promoterName'

export interface Filter {
  name: FilterKey
  data: any
  toParams(): Record<string, string | string[] | undefined>
}

export class FilterItem implements Filter {
  name: FilterKey
  data: Item[]
  constructor(name: FilterKey, data: Item[]) {
    this.name = name
    this.data = data
  }

  toParams() {
    const filterKey = snakeCase(this.name)
    return {
      [filterKey]: this.data?.map((v) => v.id),
    }
  }
}

export class FilterDateRange implements Filter {
  name: FilterKey
  data: DateRangeInputValue | null
  constructor(name: FilterKey, data: DateRangeInputValue | null) {
    this.name = name
    this.data = data
    if (this.data) {
      this.data.start = this.data.start ? new Date(this.data.start) : null
      this.data.end = this.data.end ? new Date(this.data.end) : null
    }
  }

  toParams() {
    return {
      since: this.data?.start?.toISOString().split('T')[0],
      until: this.data?.end?.toISOString().split('T')[0],
    }
  }
}

export type NumberRange = {
  min: number | null
  max: number | null
}

export class FilterNumberRange implements Filter {
  name: FilterKey
  data: NumberRange
  constructor(name: FilterKey, data?: NumberRange) {
    this.name = name
    this.data = {
      min: data?.min ?? null,
      max: data?.max ?? null,
    }
  }

  toParams() {
    const minKey = snakeCase(`${this.name}Min`)
    const maxKey = snakeCase(`${this.name}Max`)
    return {
      ...(this.data.min != null ? { [minKey]: this.data.min.toString() } : {}),
      ...(this.data.max != null ? { [maxKey]: this.data.max.toString() } : {}),
    }
  }
}

export class FilterText implements Filter {
  name: FilterKey
  data: string
  constructor(name: FilterKey, data: string) {
    this.name = name
    this.data = data
  }

  toParams() {
    if (this.data === '') return {}
    const filterKey = snakeCase(this.name)
    return {
      [filterKey]: this.data,
    }
  }
}

export type FilterKlass = new (...args: any[]) => Filter

export const filterNameToClass: Record<FilterKey, FilterKlass> = {
  artist: FilterItem,
  showType: FilterItem,
  state: FilterItem,
  city: FilterItem,
  venue: FilterItem,
  date: FilterDateRange,
  promoter: FilterItem,
  sellableCapacity: FilterNumberRange,
  venueName: FilterText,
  promoterName: FilterText,
}
