import { RoleName } from 'carbonarc-ui'
import { App } from '@config/site/app'
import { Page } from '@config/site/app/pages'

export const InitialPage: Partial<Record<App, Record<RoleName, Page>>> = {
  [App.STAR]: {
    [RoleName.CA_ADMIN]: Page.OVERVIEW,
    [RoleName.CLIENT_ADMIN]: Page.OVERVIEW,
    [RoleName.CLIENT_USER]: Page.OVERVIEW,
    [RoleName.CLIENT_OWNER]: Page.OVERVIEW,
  },
  [App.MUSIC]: {
    [RoleName.CA_ADMIN]: Page.PREDICTION_ASSESSMENT,
    [RoleName.CLIENT_ADMIN]: Page.PREDICTION_ASSESSMENT,
    [RoleName.CLIENT_USER]: Page.PREDICTION_ASSESSMENT,
    [RoleName.CLIENT_OWNER]: Page.PREDICTION_ASSESSMENT,
  },
}

export const FallbackInitialPage: Partial<Record<App, Page>> = {
  [App.STAR]: Page.OVERVIEW,
  [App.MUSIC]: Page.PREDICTION_ASSESSMENT,
}
