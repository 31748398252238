import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { createStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { BookmarkPageEnum, TourPlanningContext } from '@stores/bookmarkStore/utils/types'
import { createEventDataSlice } from '@stores/slices/eventDataSlice'
import { createPageSlice } from '@stores/slices/pageSlice'
import { createPaginatedSlice } from '@stores/slices/paginatedSlice/paginatedSlice'
import { FilterField, useTourPlanningControlPanelStore } from '@stores/tourPlanningStore'
import { getLocalFilters, saveLocalFilters } from '@stores/tourPlanningStore/localFilters'
import { contextToQueryParams, filtersToQueryParams, queryParamsToContext } from '@stores/tourPlanningStore/queryParams'
import { EventsData, StarEventEntity } from '@types'
import { updateSearchParam } from '@utils/url'
import * as api from './tourPlanningApi'
import {
  Filters,
  PaginatedControllers,
  TourPlanningStoreGetFunction,
  TourPlanningStoreProps,
  TourPlanningStoreSetFunction,
  TourPlanningStoreState,
  defaultFilters,
} from './tourPlanningStoreTypes'
import {
  filterFieldsToPageFilters,
  getPdfHeader,
  pageFilterToFilterFields,
  stringifyFilters,
} from './tourPlanningStoreUtils'

export const createTourPlanningStore = (initialProps: TourPlanningStoreProps) => {
  return createStore<TourPlanningStoreState>()(
    immer(
      (set, get, store) =>
        ({
          ...initialProps,
          ...createEventDataSlice(
            {
              getApiFetch: () => get().getApiFetch(),
              onSetBenchmark: (additionalParamsForInternalChange) => {
                const benchmark = get().selectedBenchmark
                let newContext: TourPlanningContext
                const context = get().context
                if (benchmark) {
                  const customEvents = get().customEvents.filter((event) => event.length > 0)
                  newContext = {
                    ...context,
                    benchmark: {
                      option: benchmark,
                      events: customEvents,
                    },
                  }
                } else {
                  newContext = {
                    ...context,
                    benchmark: undefined,
                  }
                }
                set({ context: newContext, syncingUrl: true })
                additionalParamsForInternalChange.searchParamsHandler.searchParams
              },
              onSetCustomEvents: (additionalParamsForInternalChange) => {
                const benchmark = get().selectedBenchmark
                let newContext: TourPlanningContext
                const context = get().context
                if (benchmark) {
                  const customEvents = get().customEvents.filter((event) => event.length > 0)
                  newContext = {
                    ...context,
                    benchmark: {
                      option: benchmark,
                      events: customEvents,
                    },
                  }
                } else {
                  newContext = {
                    ...context,
                    benchmark: undefined,
                  }
                }
                set({ context: newContext, syncingUrl: true })
                additionalParamsForInternalChange.searchParamsHandler.searchParams
              },
              onSetSecondaryMarket: (additionalParamsForInternalChange) => {
                const chartType = get().secondaryMarketChartType
                const context = get().context
                const newContext = {
                  ...context,
                  secondaryMarket: { chartType },
                }
                set({ context: newContext, syncingUrl: true })
                additionalParamsForInternalChange.searchParamsHandler.searchParams
              },
              getFilterContext: () => {
                const filters = get().context.singleEventFilter
                return {
                  artist: { id: filters?.artist },
                  venue: { id: filters?.venue },
                  perfDate: { id: filters?.date },
                  showType: { id: '' },
                } as any
              },
            },
            set,
            get,
            store,
          ),

          ...createPageSlice<Filters>({ stringifyFilters, getPdfHeader }, set, get, store),

          eventsListbox: createPaginatedSlice<StarEventEntity, Filters>(
            {
              fieldName: 'eventsListbox',
              fetchUrl: '/data/eventsummarylist',
              getFilters: () => get().filterContext,
              toQueryParams: (f: Filters) => filtersToQueryParams(f) as URLSearchParams,
              appendItems: true,
            },
            set as any,
            get as any,
            store as any,
          ),

          isLoadingEvents: false,
          isSelectingEvents: false,
          selectedEventIds: [],
          selectedEvents: [],
          context: {
            filters: defaultFilters,
            selectedEvents: [],
            singleEventFilter: null,
          } as TourPlanningContext,
          page: BookmarkPageEnum.BRAND_VENUE_INSIGHTS,
          exportOptions: [],
          syncingUrl: false,
          setExportOptions: (exportOptions) => {
            set({ exportOptions: exportOptions })
          },
          addSelectedEvents: async (eventsToAdd, searchParamsHandler) => {
            const currentEventIds = get().selectedEventIds

            const newIds = eventsToAdd
              .map((event: EventsData | number) =>
                typeof event === 'object' ? (event as EventsData).dmid : (event as number),
              )
              .filter((id) => !currentEventIds.includes(id))

            await get().setSelectedEvents([...currentEventIds, ...newIds], searchParamsHandler)
            set({ isSelectingEvents: false })
          },
          removeSelectedEvents: async (eventsToRemove, searchParamsHandler) => {
            const currentEventIds = get().selectedEventIds

            const remIds = eventsToRemove.map((event: EventsData | number) =>
              typeof event === 'object' ? (event as EventsData).dmid : (event as number),
            )

            const keepEvents = currentEventIds.filter((eventId) => !remIds.includes(eventId))

            await get().setSelectedEvents(keepEvents, searchParamsHandler)
            set({ isSelectingEvents: false })
          },
          setSelectedEvents: async (newSelectedEvents, searchParamsHandler) => {
            const apiFetch = get().getApiFetch()
            let newSelectedEventIds = newSelectedEvents.map((event: EventsData | number) =>
              typeof event === 'object' ? (event as EventsData).dmid : (event as number),
            )
            if (newSelectedEventIds.length === 0) {
              // auto select first event if none selected
              const eventItems = get().events[0].items
              if (eventItems.length > 0) newSelectedEventIds = [eventItems[0].id]
            }
            set({ selectedEventIds: newSelectedEventIds, isSelectingEvents: true })
            searchParamsHandler.replaceSearchParams(updateSearchParam('selectedEvents', newSelectedEventIds))

            const currentLoaded = get().selectedEvents
            set({ isLoadingEvents: true })
            const newSelectedEventsObjects = await api.fetchEventsWithSeries(
              apiFetch,
              currentLoaded,
              newSelectedEventIds,
            )
            set({ selectedEvents: newSelectedEventsObjects, isLoadingEvents: false })
            set({ context: { ...get().context, selectedEvents: newSelectedEventIds } })
          },

          clearData: (searchParamsHandler) => {
            get().events[1]({ type: 'RESET' })
            get().eventsListbox.reset()
            set({
              isLoading: false,
              filterContext: undefined,
              stringfiedFilterContext: '',
              isLoadingEvents: false,
              isSelectingEvents: false,
              selectedEvents: [],
              selectedEventIds: [],
            })
            if (searchParamsHandler) searchParamsHandler.replaceSearchParams()
          },

          setContext: (context: TourPlanningContext, searchParamsHandler, stateOnly: false) => {
            set({ context })
            get().setFilterContext(context.filters)
            get().setSelectedEvents(context.selectedEvents, searchParamsHandler)
            set({ isSelectingEvents: false })
            if (stateOnly) return
            const { searchParams, replaceSearchParams } = searchParamsHandler
            saveLocalFilters(pageFilterToFilterFields(context.filters))
            const urlContext = queryParamsToContext(searchParams)
            if (!isEqual(urlContext, context)) {
              replaceSearchParams(contextToQueryParams(context))
            }
          },

          backToList: (searchParamsHandler: SearchParamsObject) => {
            const newContext = {
              ...get().context,
              singleEventFilter: null,
            }
            get().setContext(newContext, searchParamsHandler)
          },

          applyFilters: async (filters, paginatedControllers, searchParamsHandler) => {
            get().clearData()
            await applyFilters(set, get, paginatedControllers, filters)

            // auto select first
            const eventItems = get().events[0].items
            const selectedEvents = eventItems.length > 0 ? [eventItems[0].id] : []

            get().setContext({ filters, selectedEvents, singleEventFilter: null }, searchParamsHandler)
          },

          selectEvent: (singleEventFilter, searchParamsHandler) => {
            const context = { ...get().context, singleEventFilter }
            get().setContext(context, searchParamsHandler)
          },
          isShowingSingleEvent: () => !!get().context.singleEventFilter,
          loadData: async (paginatedControllers, searchParamsHandler, options) => {
            const isSelectingEvents = get().isSelectingEvents
            if (isSelectingEvents) {
              set({ isSelectingEvents: false })
              return
            }
            const { enableReload = false } = options || {}
            const firstLoad = get().firstLoad
            const currentContext = buildContext(get)
            const { searchParams } = searchParamsHandler

            let newFilterFields: FilterField[] = []
            let newContext: TourPlanningContext = {} as TourPlanningContext

            if (searchParams && searchParams.size > 0) {
              newContext = queryParamsToContext(searchParams)
              newFilterFields = pageFilterToFilterFields(newContext.filters)
            } else if (firstLoad) {
              const localSavedObject = getLocalFilters()
              if (localSavedObject?.length) {
                newFilterFields = localSavedObject
                newContext = {
                  filters: filterFieldsToPageFilters(newFilterFields),
                  selectedEvents: [],
                  singleEventFilter: null,
                }
              }
            }
            const contextChanged = !isEqual(currentContext, newContext) && !isEmpty(newContext)

            if ((firstLoad || enableReload) && contextChanged) {
              useTourPlanningControlPanelStore.setState({ filters: newFilterFields })
              get().setContext(newContext, searchParamsHandler, true)
              await initLoad(set, get, paginatedControllers, newContext)
              get().setContext(newContext, searchParamsHandler)
            } else if (!contextChanged) {
              //   replaceSearchParams(contextToQueryParams(currentContext))
            }

            set({ firstLoad: false })
          },
        } as TourPlanningStoreState),
    ),
  )
}

const applyFilters = async (
  set: TourPlanningStoreSetFunction,
  get: TourPlanningStoreGetFunction,
  paginatedControllers: PaginatedControllers,
  filters: Filters,
) => {
  try {
    const apiFetch = get().getApiFetch()
    set({ isLoading: true })
    await Promise.all([paginatedControllers.events.fetch(filters), get().eventsListbox.fetch(filters, apiFetch)])
  } finally {
    set({ isLoading: false })
  }
}

const initLoad = async (
  set: TourPlanningStoreSetFunction,
  get: TourPlanningStoreGetFunction,
  paginatedControllers: PaginatedControllers,
  context: TourPlanningContext,
) => {
  const apiFetch = get().getApiFetch()
  const eventsListbox = get().eventsListbox
  try {
    set({ isLoading: true, isLoadingEvents: true })
    const { filters } = context
    await Promise.all([
      paginatedControllers.events.fetch(filters),
      api.fetchEventsWithSeries(apiFetch, [], context.selectedEvents),
      eventsListbox.fetch(filters, apiFetch),
    ])
  } finally {
    set({ isLoading: false, isLoadingEvents: false })
  }
}

const buildContext = (get: TourPlanningStoreGetFunction): TourPlanningContext => {
  const filterContext = get().filterContext
  const selectedEventsIds: number[] = get().selectedEventIds

  return {
    ...get().context,
    filters: filterContext || defaultFilters,
    selectedEvents: selectedEventsIds,
    singleEventFilter: get().context.singleEventFilter,
  }
}
