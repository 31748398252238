import { ConfigCatProvider } from 'carbonarc-ui'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import * as Okta from '@components/Okta'
import { Toaster } from '@components/Toaster'
import '@config/echarts'
import '@config/react-gtm-module'
import '@config/react-i18next'
import { queryClient } from '@config/react-query'
import '@config/sentry'
import { ThemeProvider } from '@contexts/Theme'
import OktaAuth from '@okta/okta-auth-js'
import ErrorBoundary from './pages/ErrorBoundary'
import { Routes } from './routes'

const loginCallback = '/login/callback'

const okta = {
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID || '',
  redirectUri: window.location.origin + loginCallback,
  issuer: import.meta.env.VITE_OKTA_ISSUER || '',
  pkce: true,
  scopes: ['openid', 'profile', 'email'],
}
export const oktaAuth = new OktaAuth(okta)

export default function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <Okta.AuthProvider
              oktaAuth={oktaAuth}
              oktaOptions={okta}
              onAuthRequired={() => {
                window.location.href = ''
              }}
            >
              <ConfigCatProvider sdkKey={import.meta.env.VITE_CONFIGCAT_SDK_KEY ?? ''}>
                <Toaster />
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </ConfigCatProvider>
            </Okta.AuthProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </HelmetProvider>
  )
}
