import { PropsWithChildren } from 'react'
import { Card } from 'carbonarc-ui'
import qs from 'qs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Badge } from '@components/Badge'
import { useFlag } from '@config/site/app'
import { Flags } from '@config/site/app/flags'
import {
  ArrowRightIcon,
  CalendarIcon,
  DocumentChartBarIcon,
  GlobeAltIcon,
  MapPinIcon,
  PresentationChartLineIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline'
import { StarEventEntity } from '@types'
import { formatUtils } from '@utils'
import { cn } from '@utils/className'
import { formatCityState, formatPercentage } from '@utils/format'

interface EventInfoProps {
  value?: string
  icon: React.ElementType
  isLoading: boolean
}

const MiniStatCard = ({ text, subtext }: { text: string; subtext: string }) => {
  return (
    <div className="flex flex-col">
      <span className="text-base font-semibold text-gray-900 dark:text-white">{text}</span>
      <span className="text-xs font-normal text-gray-500 dark:text-gray-400">{subtext}</span>
    </div>
  )
}

const StatsGroup = ({
  isLoading = false,
  title,
  children,
  horizontal = false,
}: { isLoading?: boolean; title: string; horizontal?: boolean } & PropsWithChildren) => {
  return (
    <div className={cn('flex flex-col gap-1')}>
      {isLoading ? (
        <>
          <div className="rounded-full bg-gray-200 dark:bg-gray-700 w-24 h-3.5" />
          <div className={cn('flex gap-2', horizontal ? 'flex-col' : 'flex-col sm:flex-row')}>
            {Array(2)
              .fill(1)
              .map((_, index) => (
                <div className="flex flex-col mt-2 gap-2" key={index}>
                  <div className="rounded-full bg-gray-200 dark:bg-gray-700 w-16 h-6 mr-4" />
                  <div className="rounded-full bg-gray-200 dark:bg-gray-700 w-20 h-3" />
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <div className="text-base font-normal text-gray-400 dark:text-gray-500">{title}</div>
          <div className={cn('flex gap-4', horizontal ? 'flex-col' : 'flex-col sm:flex-row')}>{children}</div>
        </>
      )}
    </div>
  )
}

export function EventInfo({ value, icon: Icon, isLoading }: EventInfoProps) {
  if (isLoading) {
    return (
      <div className="flex items-center">
        <div className="rounded-full w-4 h-4 bg-gray-200 dark:bg-gray-700 mr-3" />
        <div className="rounded-full w-24 h-4 bg-gray-200 dark:bg-gray-700" />
      </div>
    )
  }

  return (
    <div className="flex items-center">
      <Icon className="w-5 h-5 stroke-2 mr-2" />
      <span className={cn(isLoading && 'rounded-full bg-gray-200 dark:bg-gray-700 w-24 h-3')}>{value}</span>
    </div>
  )
}

interface EventProps {
  event?: StarEventEntity
  isLoading?: boolean
  size?: 'sm' | 'normal'
}

export function EventCard({ event, isLoading = false, size = 'normal' }: EventProps) {
  const { t } = useTranslation('upcoming_events')
  const showPredictions = useFlag(Flags.SHOW_PREDICTIONS)
  const currentQuantitySold =
    event?.sales_summary?.current_quantity_sold == null
      ? '-'
      : formatUtils.formatNumber(event.sales_summary.current_quantity_sold)
  const sellableCap = event?.sellable_cap == null ? '-' : formatUtils.formatNumber(event.sellable_cap)
  const currentPercentSold =
    event?.sales_summary?.current_percent_sold == null
      ? '-'
      : formatUtils.formatPercentage(event.sales_summary.current_percent_sold / 100)

  const averagePrice =
    event?.upcoming_summary?.average_price == null
      ? '-'
      : formatUtils.formatCurrency(event.upcoming_summary.average_price)

  const secondaryQuantitySold =
    event?.upcoming_summary?.secondary_quantity_sold == null
      ? '-'
      : formatUtils.formatNumber(event.upcoming_summary.secondary_quantity_sold)
  const secondaryAveragePrice =
    event?.upcoming_summary?.secondary_quantity_sold != null &&
    event.upcoming_summary.secondary_quantity_sold > 0 &&
    event?.upcoming_summary.secondary_total_revenue != null
      ? formatUtils.formatCurrency(
          event.upcoming_summary.secondary_total_revenue / event.upcoming_summary.secondary_quantity_sold,
        )
      : '-'

  const hasSecondaryData = event?.upcoming_summary?.secondary_exists || false
  const hasPredictionData = showPredictions && (event?.upcoming_summary?.predictions || []).length > 0
  const hasAudienceReport =
    !!event?.upcoming_summary?.audience_report?.pdf || !!event?.upcoming_summary?.audience_report?.pptx

  const predictions = event?.upcoming_summary?.predictions?.map((eventPrediction) => ({
    quantity_percentage:
      event?.sellable_cap && event?.sellable_cap > 0
        ? formatPercentage(eventPrediction.predicted_quantity_sold || 0 / event.sellable_cap)
        : null,
    quantity_sold: formatUtils.formatNumber(eventPrediction.predicted_quantity_sold, {
      maximumFractionDigits: 0,
    }),
    revenue: formatUtils.formatCurrency(eventPrediction.predicted_revenue),
    ticket_price: formatUtils.formatCurrency(eventPrediction.predicted_ticket_price),
  }))

  const prediction = predictions?.[0] || {
    quantity_percentage: '-',
    quantity_sold: '-',
    revenue: '-',
    ticket_price: '-',
  }

  const date = formatUtils.formatDateNumbersOnly(event?.performance_date, { returnDate: true })?.date?.toISOString()
  const params = {
    extended: true,
    singleEventFilter: {
      artist: event?.brand?.id,
      venue: event?.venue?.id,
      date: event?.performance_date,
    },
    filters: [
      {
        name: 'artist',
        data: [
          {
            id: event?.brand?.id,
            name: event?.brand?.name,
          },
        ],
      },
      {
        name: 'venue',
        data: [
          {
            id: event?.venue?.id,
            name: event?.venue?.name,
          },
        ],
      },
      {
        name: 'date',
        data: {
          start: date,
          end: date,
          option: {
            value: 'custom',
            label: 'Custom',
          },
        },
      },
    ],
  }

  const viewDetailsPath = '/event-insights?' + qs.stringify(params)

  const cityState = formatCityState(event?.venue?.location?.city, event?.venue?.location?.state)

  return (
    <Link
      to={viewDetailsPath}
      state={{
        showBackButton: true,
      }}
      className={cn(size === 'normal' ? 'mb-4' : '', isLoading && 'animate-pulse')}
    >
      <Card
        theme={{
          root: {
            base: 'flex flex-col cursor-pointer rounded-lg shadow-md border border-gray-200 bg-white hover:bg-blue-50/50 dark:border-gray-700 dark:bg-gray-800 hover:dark:bg-gray-800/75 hover:shadow-lg group h-full bg-gray-50',
            children: 'p-0',
          },
        }}
      >
        {(hasPredictionData || hasSecondaryData || hasAudienceReport) && (
          <div className="bg-white dark:bg-gray-800 pt-8 px-4 md:px-8 flex-1">
            <div className="flex gap-4">
              {hasSecondaryData && (
                <Badge icon={PresentationChartLineIcon} text={t('badges.secondary_market')} size="sm" />
              )}
              {hasPredictionData && <Badge icon={SparklesIcon} text={t('badges.predictions')} size="sm" />}
              {hasAudienceReport && <Badge icon={DocumentChartBarIcon} text={t('badges.audience_report')} size="sm" />}
            </div>
          </div>
        )}
        <div
          className={cn(
            'bg-white dark:bg-gray-800 flex justify-between items-start border-b border-gray-200 dark:border-gray-700',
            size === 'sm' ? 'flex-col gap-4 p-4' : 'flex-col md:flex-row md:items-center gap-4 md:gap-8 p-4 md:p-8',
          )}
        >
          <div>
            <h6
              className={cn(
                'mb-2 text-xl font-semibold text-gray-900 dark:text-white',
                isLoading && 'rounded-full bg-gray-200 dark:bg-gray-700 w-10/12 h-4 mb-4',
              )}
            >
              {event?.brand?.name}
            </h6>

            <div
              className={cn(
                'flex items-start mt-4 text-gray-500 dark:text-gray-400 md:mt-0 w-full flex-wrap',
                size === 'sm' ? 'gap-4' : 'gap-4 md:gap-8',
              )}
            >
              <EventInfo value={event?.venue?.name} icon={MapPinIcon} isLoading={isLoading} />
              <EventInfo value={cityState} icon={GlobeAltIcon} isLoading={isLoading} />
              <EventInfo
                value={event?.performance_date && formatUtils.formatDateNumbersOnly(event.performance_date)}
                icon={CalendarIcon}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className={cn('flex justify-end shrink-0')}>
            {!isLoading && (
              <div className="flex items-center text-blue-600 text-sm font-medium">
                {t('view_details')} <ArrowRightIcon className="w-4 h-4 stroke-2 ml-1.5" />
              </div>
            )}
          </div>
        </div>
        <div
          className={cn(
            'bg-gray-50 dark:bg-gray-800 flex flex-col sm:flex-row gap-8 group-hover:bg-blue-50/50 group-hover:dark:bg-gray-800/75 px-8 py-4 rounded-b-lg',
            size === 'sm' ? 'flex-row' : 'flex-col',
          )}
        >
          <StatsGroup title={t('tickets_sold')} isLoading={isLoading} horizontal={true}>
            <MiniStatCard text={currentQuantitySold} subtext={`of ${sellableCap} (${currentPercentSold})`} />
            {hasSecondaryData && <MiniStatCard text={secondaryQuantitySold} subtext={t('on_secondary_market')} />}
            {hasPredictionData && (
              <MiniStatCard
                text={prediction.quantity_sold}
                subtext={`${prediction.quantity_percentage ? `(${prediction.quantity_percentage}) ` : ''}${t(
                  'predicted',
                )}`}
              />
            )}
          </StatsGroup>
          <StatsGroup title={t('average_ticket_price')} isLoading={isLoading} horizontal={true}>
            <MiniStatCard text={averagePrice} subtext={t('on_primary_market')} />
            {hasSecondaryData && <MiniStatCard text={secondaryAveragePrice} subtext={t('on_secondary_market')} />}
            {hasPredictionData && <MiniStatCard text={prediction.ticket_price} subtext={t('predicted')} />}
          </StatsGroup>
        </div>
      </Card>
    </Link>
  )
}
