import { formatCurrency, formatDateNumbersOnly } from '@utils/format'

// FIX ME: Get this out of here and into the component controlling the chat
export const AI_USER_NAME = 'Insights AI'
export const isAIMessage = (user: string) => user === AI_USER_NAME // FIXME... we should flag this on the database or something so we have opportunity to change the name of the AI... the name should be controlled clientside
// END

export const highlightMessageKeyWord = (message: string, _tableData: (string | number)[][]) => {
  // FIXME: Regex used here not compatible with iOS. Will add Markdown support to ChatApiServer so all apps can take advantage of styled output if they want.

  // let messageHighlighted = message
  // if (message == undefined || typeof message !== 'string') return ''

  // tableData.forEach((row) => {
  //   row.forEach((cell) => {
  //     messageHighlighted = messageHighlighted.replace(new RegExp(`(?<!<b>)${cell}(?!</b>)`), `<b>${cell}</b>`)
  //   })
  // })
  // return messageHighlighted

  return message
}

const dateRegex = /(\d{4}-\d{2}-\d{2})/g
export const formatMessageDates = (message: string) => {
  if (message == undefined || typeof message !== 'string') return ''

  return message.replaceAll(dateRegex, (value) => formatDateNumbersOnly(value))
}

const moneyRegex = /(\d+)(<\/b>)?(\.\d+)/g

export const formatDollarNumber = (message: string) => {
  if (message == undefined || typeof message !== 'string') return ''

  return message.replaceAll(moneyRegex, (_: string, value: string, bold = '', cents = '') => {
    return `${formatCurrency(Number(value + cents))}${bold}`
  })
}
