import { ChatBotMessage } from '@components/InsightsChatBot/ChatBotMessage'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { AI_USER_NAME } from '@utils/insights'

export function LoadingMessage({ abortAPIFetch }: { abortAPIFetch: () => void }) {
  return (
    <ChatBotMessage
      user={AI_USER_NAME}
      message={
        <div className="relative">
          <span className="space-x-1">
            <span className="animate-insights-loading inline-block w-2 h-2 bg-slate-600 dark:bg-slate-200 rounded-full" />
            <span className="animate-insights-loading delay-75 inline-block w-2 h-2 bg-slate-600 dark:bg-slate-200 rounded-full" />
            <span className="animate-insights-loading delay-100 inline-block w-2 h-2 bg-slate-600 dark:bg-slate-200 rounded-full" />
          </span>
          <XMarkIcon
            title="Cancel request"
            className="absolute -right-6 -top-6 w-4 text-gray-500 cursor-pointer"
            onClick={() => abortAPIFetch()}
          >
            {/* Only visible for screenreaders */}
            <span className="absolute m-[-1px] h-[1px] w-[1px] overflow-hidden border-0 p-0">send</span>
          </XMarkIcon>
        </div>
      }
    />
  )
}
